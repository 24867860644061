.add-main-container {
    .quantity-adjust-title {
      font-size: 1rem;
      color: #000;
      font-weight: 500;
      margin-bottom: 0.625rem;
      margin-top: 1.875rem;
    }
    .quantity-input {
      border-radius: 0.625rem;
      border-color: #000;
      opacity: 1;
      width: 100%;
      line-height: 1.1876em;
      border-width:1px ;
      .MuiInputBase-root  {
        padding: 0.781rem  0.875rem;
      }
      .MuiFilledInput-root{
        &:focus-within{
              background-color:var(--bs-white);
              outline: 0.13rem solid var(--bs-black) !important;  
        }
      }
      .error-message {
        margin-bottom: 1.25rem;
      }
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: #000000 !important;
      }
      input {
        padding: 0;
        height: auto;
        color: #000;
        font-weight: 500;
      }
      .input-btn {
        background: #DDDDDD 0% 0% no-repeat padding-box;
        display: flex;
        padding: 0.3125rem 0 0.5rem;
        border-radius: 0.3125rem;
        .btn-minus-value {
          padding: 0 0.8125rem;
          border-right: 1px solid #aaaaaa;
          border-radius: 0;
        }
        .btn-plus-value {
          padding: 0 0.8125rem;
        }
        .btn-plus-value,
        .btn-minus-value {
          font-size: 1.75rem;
          color: #000;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 1.125rem;
          &:hover {
            filter: unset !important;
          }
        }
      }
      fieldset {
        top: 0;
      }
    }
    .quantity-move {
      padding: 12.5px 0.875rem !important;
    }
    .adjust-reason-optional-text{
      color: #AAAAAA;
    }
    .adjust-stock-btn-div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top:1.875rem;
      .adjust-stock-btn {
        border-radius: 0.9375rem;
        height: 2.75rem;
        padding: 0.75rem 0.9375rem;
        background-color: #000 !important;
        color: #fff !important;
        .button-label {
          font-size: 1rem;
          text-transform: capitalize;
        }
      }
      .button-primary.button-accent {
        --bgcolor: var(--bs-black) !important
      }
    }
}
.remove-stock-btn{
  min-width: 12rem;
  max-width: 12rem;
  width: 12rem;
  height: 2.75rem;
}

.move-stock-btn{
  min-width: 10rem;
  max-width: 10rem;
  width: 10rem;
  height: 2.75rem;
}