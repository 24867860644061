@import "../../apps/pos/assets/css/variable.scss";
@import "../../ui/scss/device.scss";
@import "../../ui/scss/common.scss";

.notepad-wrapper {
  background-color: $bg-note-white;
  position: fixed;
  box-shadow: 0 10px 15px $black-note;
  right: 0;
  top: 0;
  height: 100vh;
  @include setFlexCenter;
  flex-direction: column;
  transition: transform 0.1s ease-out, opacity 0.1s ease-out;
  z-index: 10000;
  border-radius: 1.5rem 0 0 1.5rem;
  overflow: hidden;

  &-header {
    position: absolute;
    background-color: $bg-note-white;
    top: 0;
    padding: 2rem 1rem 1.5rem;
    width: 100%;
    @include setFlexCenter;

    .notepad-close {
      border: none;
      margin: 0;
      @include setFlexCenter;
      background-color: transparent;
      cursor: pointer;
      position: absolute;
      left: 1.25rem;
      border-radius: 500px;
      padding: 0.5rem;
    }

    .notepad-title-group {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    .notepad-title {
      font-family: serif !important;
      font-style: italic;
      font-size: 1.125rem;
      letter-spacing: -0.015em;
      color: $bg-note-green;
      width: 100%;
      text-align: center;
      outline: none;
      background-color: $bg-note-white;
      border: 0;
      padding: 0;
      margin: 0;
    }

    .notepad-actions {
      position: absolute;
      right: 1.25rem;
      display: flex;
    }

    .notepad-window-btn,
    .notepad-dock-btn {
      align-items: center;
      justify-content: center;
      padding: 0.5rem;
      background: transparent;
      border: none;
      cursor: pointer;
      border-radius: 500px;
    }

    .notepad-window-btn {
      display: flex;
    }

    .notepad-wrapper.floating .notepad-dock-btn {
      display: flex;
    }

    .notepad-wrapper.floating .notepad-window-btn {
      display: none;
    }

    .busy-indicator {
      @include setFlexCenter;
      position: absolute;
      right: -1rem;
      transition: all 0.6s ease-out;
    }

    .waiting-indicator {
      position: absolute;
      transition: all 0.1s;
      animation: waiting-indicator-anim 0.8s steps(8) infinite;
    }

    @keyframes waiting-indicator-anim {
      to {
        transform: rotate(360deg);
      }
    }

    .check-indicator {
      position: absolute;
      transition: all 0.1s;
    }

    .divider {
      position: absolute;
      bottom: 0;
      height: 0.375rem;
      width: 100%;
      border-top: 0.0625rem solid $green-light;
      border-bottom: 0.0625rem solid $green-light;
      background: linear-gradient(to right, $green-light 0.0625rem, transparent 0.125rem);
      background-size: 3.125rem;
    }

  }

  &-textarea {
    background-color: $bg-note-white;
    color: $bg-note-green;
    width: 35vw;
    min-width: 25rem;
    max-width: 100%;
    height: 100%;
    padding-inline: 2rem;
    padding-top: 6rem;
    padding-bottom: 5vh;
    scroll-padding-bottom: 5vh;
    font-size: 1rem;
    line-height: 1.75;
    outline: none;
    resize: none;
    border: 0;
    font-family: inherit;
    overscroll-behavior: contain;
  }

  .notepad-wrapper.floating .notepad-wrapper-textarea {
    max-width: 65ch;
    width: 100%;
    min-width: auto;
  }

  .notepad-wrapper-textarea::placeholder {
    color: #899c8e;
  }

}

@include tabletL {
  .notepad-wrapper {
    width: 100%;
    padding: 0;
  }
}


.notepad-wrapper.floating {
  width: 100%;
  border-radius: 0;
}

.notepad-close .notepad-closebtn-back-icon {
  display: block;
}

.notepad-close .notepad-closebtn-close-icon {
  display: none;
}

.notepad-wrapper.floating .notepad-close .notepad-closebtn-back-icon {
  display: none;
}

.notepad-wrapper.floating .notepad-close .notepad-closebtn-close-icon {
  display: block;
}

.notepad-wrapper.visible {
  opacity: 100;
  transform: translateX(0) rotate(0);
}

.notepad-wrapper:not(.floating).hidden {
  transform: translateX(5%);
  opacity: 0;
  pointer-events: none;
}

.notepad-wrapper.hidden {
  transform: translateX(5%);
  opacity: 0;
  pointer-events: none;
}

.notepad-wrapper-textarea::placeholder {
  color: $gray-note;
}

@include tabletL {
  .notepad-wrapper-textarea {
    width: 100%;
    min-width: auto;
    padding-inline: 1rem;
  }
}

.notepad-overlay {
  position: fixed;
  inset: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $black-overlay;
  z-index: 9999;
  transition: opacity 0.3s ease-out;
}

.notepad-overlay.visible {
  opacity: 100;
}

.notepad-overlay.hidden {
  opacity: 0;
  pointer-events: none;
}

.indicator-op-0 {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.indicator-op-1 {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.notepad-tooltip {
  position: absolute;
  z-index: 9999;
  top: 0;
  left: auto;
  display: none;
  user-select: none;
  align-items: flex-start;
  justify-content: center;
  pointer-events: none;
  transition: all 0.2s cubic-bezier(0, 0.71, 0.22, 1.1);
}

.notepad-tooltip-button {
  pointer-events: all;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  white-space: nowrap;
  border: none;
  border-radius: 31.25rem;
  padding: 0.5em 1em 0.5em 0.75em;
  gap: 0.25em;
  background-color: #105222;
  color: #fff;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0, 0.71, 0.22, 1.1);
  animation: tooltip-anim 0.3s cubic-bezier(0, 0.7, 0.1, 1.25);
  clip-path: circle(100% at 25%);
  transform: translateY(calc(-100% - 0.5rem)) scale(1);
}

.notepad-tooltip-complete .notepad-tooltip-button {
  width: 48px;
  padding: 0.5em;
  pointer-events: none;
  transition: all 0.4s cubic-bezier(0, 0.71, 0.22, 1.1);
  animation: tooltip-anim-complete 0.2s cubic-bezier(0, 0.7, 0.1, 1.25) 0.4s forwards;
}

@keyframes tooltip-anim-complete {
  to {
    clip-path: circle(0% at 50%);
    transform: translateY(calc(-100% + 1rem)) scale(1);
  }
}

.notepad-tooltip-complete span {
  width: 0;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0, 0.71, 0.22, 1.1);
}

.notepad-tooltip-complete .notepad-tooltip-pencil {
  width: 0;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0, 0.71, 0.22, 1.1);
}

.notepad-tooltip-complete .notepad-tooltip-check {
  width: 20px;
  height: 20px;
  transition: all 0.4s cubic-bezier(0, 0.71, 0.22, 1.1);
}

.notepad-tooltip-button:active {
  transform: translateY(calc(-100% - 0.5em)) scale(0.975);
}

@keyframes tooltip-anim {
  from {
    clip-path: circle(0% at 25%);
    transform: translateY(calc(-100% + 0.5em)) scale(0.85);
  }
}

.notepad-tooltip-button::after {
  content: "";
  position: absolute;
  height: 0.6875rem;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAyOSAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgMEgyOC44NkMyMy4zODc2IDAgMTguNDg5MyAzLjM5NDU3IDE2LjU2NzggOC41MTg1MkMxNS44MjcgMTAuNDkzOCAxMy4wMzMgMTAuNDkzOCAxMi4yOTIyIDguNTE4NTJDMTAuMzcwNyAzLjM5NDU3IDUuNDcyMzggMCAwIDBaIiBmaWxsPSIjMTA1MjIyIi8+Cjwvc3ZnPgo=);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  bottom: 0;
  transform: translateY(calc(100% - 0.0625rem));
  width: 100%;
  left: 0;
  pointer-events: none;
}

.notepad-tooltip-check {
  width: 0;
  transition: all 0.4s cubic-bezier(0, 0.71, 0.22, 1.1);
}