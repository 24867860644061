.historyLeftbarTitle {
    font-family: "Roboto Slab", serif;
    font-size: 30px;
    line-height: 21px;
    font-weight: 300;
    color: #000000;
}
.manage-sub-btn {
    height: 40px;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .manage-sub-txt {
    text-align: center;
    letter-spacing: 0.21px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    color: #ffffff;
  }
  .history-list-container {
    flex: auto;
    margin: 0.8rem;
  }
  .download-img {
    height: 15px;
    width: 15px;
    margin-right:5px;
    background: var(--unnamed-color-000000) 0% 0% no-repeat padding-box;
    background: #000000 0% 0% no-repeat padding-box;  
  }
  .noRecordTitle {
    font-family: "Roboto Slab", serif;
    font-size: 1.25rem;
    line-height: 21px;
    font-weight: 300;
    color: #000000;
}