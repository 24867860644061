@import "../../apps/pos/assets/css/variable.scss";

div {
    &.bookmarks-dialog {
        &.cashDrawer-dialog {
            max-width: 40.375rem;
            padding: 1.875rem;
            min-width: inherit;

            .dialog-title {
                font-size: 1.25rem;
            }

            .hide-price-checkbox {
                margin-top: 0;

                label {
                    margin-right: 0;
                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        left: 0.6875rem;
                        right: 0;
                        bottom: 0;
                        width: 100%;
                        height: 0.0625rem;
                        background-color: $bright-Gray;
                    }

                    &:last-child {
                        &::before {
                            display: none;
                        }
                    }

                    span {
                        font-weight: 500;
                    }
                }
            }

            .datePicker-group {
                display: flex;
                justify-content: space-between;
                gap: 0.625rem;

                .storage-date-picker {
                    .MuiFormControl-root {
                        .MuiInputBase-root {
                            padding: 0 8px 0 15px;
                            height: 2.75rem;
                        }
                    }
                }

                .title-text {
                    font-size: 1rem;
                    font-weight: 500;
                }
            }

            .days-group {
                display: flex;
                flex-wrap: wrap;
                gap: 0.625rem;

                .days-list {
                    background-color: $light-gray;
                    border-radius: 2rem;
                    padding: 0.5rem 0.875rem;
                    font-size: 0.875rem;
                    margin-bottom: 0.4375rem;
                    cursor: pointer;
                }

                .selected {
                    background: #000000 0% 0% no-repeat padding-box;
                    border-radius: 50px;
                    color: #FFFFFF;
                }
            }

            .actions {
                margin-top: 1.375rem;
                padding-top: 1.875rem;
                border-top: 0.0625rem solid $border-color;
            }
        }
    }
}