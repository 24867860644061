.leftbarTitle {
  font-family: "Roboto Slab", serif;
  font-size: 24px;
  line-height: normal;
  font-weight: 500;
  color: #000000;
}
.addressTitle {
  font-family: "Roboto Slab", serif;
  font-size: 1rem;
  line-height: 21px;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 10px;
  color: #000000;
}

.typeText {
  font-family: "Roboto Slab", serif;
  font-size: 1rem;
  line-height: 21px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #000000;
}
.dimensions {
  font-family: "Roboto Slab", serif;
  font-size: 12px;
  font-weight: 500;
  color: #000000;
}
.decription {
  font-size: 12px;
  margin-top: -1rem;
  font-weight: 100;
  color: "gray";
}
.formContainer {
  /* width: 50%; */
  max-width: 470px;
}
.formContainer label {
  font-weight: 500;
}
.delete-img {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.radio-button {
  margin-right: 10px;
}
.carriers-select {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
}

.carriers-options {
  flex: 1;
  margin: 2px 5px;
}
.input-style {
  height: 100px;
  text-align: start;
  vertical-align: text-top;
}
.setting-list-container {
  height: 100%;
  /* margin: 0.8rem; */
}
.manage-btn {
  height: 50px;
  width: 200px;
  border-radius: 15px;
  margin-top: 40px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.manage-button-txt {
  text-align: center;
  letter-spacing: 0.21px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 300;
  color: white;
}
.manage-lightbutton-txt {
  text-align: center;
  /* width: 110px; */
  letter-spacing: 0.21px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 400;
  color: black;
}

.cityinput {
  flex: auto;
}

.phoneinput {
  height: 45px;
  flex: auto;
}

.extinput {
  height: 45px;
  flex: auto;
  margin-top: 2px;
}
.devider2px {
  color: #000;
  background-color: #000;
  height: 2px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.devider1px {
  color: lightgray;
  background-color: lightgray;
  height: 1px;
  margin-top: 30px;
}
.emailcontainer {
  flex: 1;
}
.sendtypecontainer {
  flex: 0.4;
  margin-left: 20px;
}
.carriers-container {
  background-color: #f1f3f2;
  border-radius: 10px;
  padding: 0.938rem !important;
  border-radius: 10px;
  width: 50%;
}

.subscription-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subscription-header-left {
  display: flex;
  flex-direction: column;
}

.subscription-types {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid lightgray;
  margin-top: 20px;
  max-width: 470px;
  border-radius: 10px;
  padding: 10px 20px;
}

.right-side-container {
  background-color: #000000;
  width: 416px;
  height: 100%;
}

.subscription-text {
  margin-top: 2rem;
  width: 65%;
}

.carriers-default {
  display: flex;
  align-items: center;
}

.carriers-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background-color: #f1f3f2;
  margin-top: 30px;
  border-radius: 10px;
  width: 50%;
  padding: 0px 20px;
}

.container {
  background-color: #f1f3f2;
  border-radius: 10px;
  margin: 15px;
  flex-direction: column;
  padding: 0.938rem !important;
}
.imagecontainer {
  border-radius: 10px;
  border: 1px solid lightgray;
  padding: 20px;
  width: 50%;
  height: 200px;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.phone-container {
  width: 70%;
}
.phone-container label,
#payment-methods label,
.carriers-container label,
.actors-section-container label
.setting-input-styling .label-styling {
  font-weight: 500;
  color: #000 !important;
  font-size: 14px;
}

.subscription-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subscription-btn {
  border: none;
  background-color: transparent;
  font-weight: 600;
  font-size: 14px;
  color: #000;
}

.subscription-plan-text {
  font-weight: 500;
  font-size: 1.25rem;
}

.main-container {
  display: flex;
}

.heading {
  font-size: 48px;
  font-weight: 300;
}

.list-container {
  display: flex;
  flex-direction: column;
  /* margin-top: 1rem; */
  height: 100vh;
  /* position: sticky; */
  position: fixed;
  top: 0;
  width: 260px;
  margin-top: 68px;
}

.setting-container {
  max-width: 1088px;
  /* width: 90vw; */
  margin: 0;
}
.list {
  margin: 0px;
  padding: 0px;
  border: 1px solid lightgray;
  list-style-type: none;
  /* width: 260px; */
  border-radius: 0.5rem;
  margin-top: 0rem;
  color: #000;
}

.list-item {
  margin: 0px;
  padding: 9px;
  border-bottom: 1px solid lightgray;
  /* width: 259px; */
  /* line-height: 34px; */
  font-size: 14px;
  cursor: pointer;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.list-item-nth {
  width: 260px;
  line-height: 35px;
  font-size: 1rem;
  padding-left: 10px;
  cursor: pointer;
  padding-left: 15px;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.listitem-nth:hover {
  background-color: #f1f1f1;
  border: 1px solid gray;
}

.setting-container {
  /* width: 1000px; */
  /* margin-top: 6rem; */
  /* margin-left: 25px; */
  /* padding-top: 20px; */
}
.setting-right-content-section {
  padding-top: 96px;
  padding-left: 36px;
  width: calc(100% - 260px);
}
.setting-right-content-section .form-group label,
.setting-right-content-section .form-group .optionaltext {
  margin-bottom: 10px;
  line-height: normal;
}
.setting-right-content-section .form-group .optionaltext {
  color: #aaaaaa;
}
.setting-right-content-section .form-group input {
  margin-top: 0 !important;
}
.setting-right-content-section .form-group {
  margin-top: 30px;
}
.setting-right-content-section .decription {
  margin-top: 6px;
  color: #aaaaaa;
  font-weight: 400;
}
.setting-right-content-section .ext-container {
  max-width: 100px;
}
.setting-right-content-section .masked-phone-input {
  margin-top: 60px;
}
.setting-right-content-section .setting-phone-number-info {
  margin-left: 10px;
  margin-right: 10px;
}
.setting-right-content-section .setting-company-address-sec {
  margin-top: 60px;
}
.setting-right-content-section .setting-country-select-div {
  min-width: 112px;
}
#addEmailDistorsForm .container,
.carriers-container,
.phone-container .container,
#addWarehousesInfoForm {
  background: #f9f9f9 0% 0% no-repeat padding-box;
}
#addEmailDistorsForm .emailcontainer input {
  margin-left: 0 !important;
}
.setting-container .ps {
  overflow: unset !important;
  overflow-anchor: unset;
  -ms-overflow-style: unset;
  touch-action: none;
  position: unset;
  height: auto;
}

.warehouse-container {
  background-color: #f1f1f1;
  padding: 20px;
  border-radius: 10px;
}

.add-warehouse-container {
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-warehouse-text {
  font-size: 14px;
  color: #000;
}

.set-default-text {
  font-size: 14px;
  color: #000;
  margin-left: 10px;
}

.select-title-bold {
  font-weight: 600;
}

.partners-rep-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.access-title {
  font-size: 14px;
  color: #000;
  margin-left: 10px;
}

.access-description {
  font-size: 12px;
  color: #000;
  margin-left: 10px;
}

.phoneinput {
  margin-left: 10px !important;
}

.width-20 {
  width: 20px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.access-subcontainer1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #dddddd;
}

.access-subcontainer2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.validation-error-text {
  color: red;
  margin-top: -12px;
  padding: 0px;
  font-family: "Roboto Slab", serif;
  font-size: 12px;
  font-weight: 400;
}

.validation-error-text-sp {
  color: red;
  margin-top: -5px;
  padding: 0px;
  font-family: "Roboto Slab", serif;
  font-size: 12px;
  font-weight: 400;
}

.masked-phone-input {
  margin-top: 1.5rem;
  border: none;
  border: 1px solid lightgray;
  border-radius: 5px;
  height: 45px;
  width: 100%;
}

.masked-phone-input-customer-info {
  border: none;
  border: 1px solid lightgray;
  border-radius: 5px;
  height: 45px;
  padding-left: 15px;
  width: 70%;
  font-size: 14px;
  font-family: "Inter", Regular;
}

.masked-phone-ext-input-customer-info {
  border: none;
  border: 1px solid lightgray;
  border-radius: 5px;
  height: 45px;
  width: 100%;
  padding-left: 15px;
  font-size: 14px;
  font-family: "Inter", Regular;
}

.masked-fax-input-customer-info {
  border: none;
  border: 1px solid lightgray;
  border-radius: 5px;
  height: 45px;
  padding-left: 15px;
  width: 100%;
  font-size: 14px;
  font-family: "Inter", Regular;
}

.customer-info-phone {
  color: black !important;
  font-family: "Inter", Regular;
}

.optional-text {
  font-size: 14px;
  font-family: "Inter", Regular;
  color: #aaaaaa;
}

.phone-input-ext {
  display: flex;
}

.ext-container {
  display: flex;
  flex-direction: column;
}
.setting-main-section {
  height: 100%;
  overflow: auto;
}

.validation-error-text-stay {
  color: #000000;
  margin-top: -12px;
  padding: 0px;
  font: normal normal normal 12px/15px Inter;
  font-weight: 400;
}