//font
$card-font: normal normal bold 10px / 20px Inter;
$extra-small-font-size: 12px;
$small-font-size-14: 14px;
$small-font-size: 15px;
$large-font-size: 24px;

//Color
$bright-Gray: #eeeeee;
$gray-100: #f1f1f1;
$gray-200: #eeeeee;
$gray-300: #ddd;
$gray-400: #ccc;
$gray-800: #555555;
$gray-900: #222;
$black: #000;
$white: #fff;
$red: #fc6100;
$light-grey: #999;


//common variable
$display-flex: flex;

//Flex variables
@mixin setFlexCenter {
  display: $display-flex;
  align-items: center;
  justify-content: center;
}
