@import "../../../assets/css/variable.scss";

.search-return-product-modal {
  .MuiDialog-container {
    .MuiDialog-paper {
      width: 100%;
      max-width: 60.625rem;
      height: 100%;
      max-height: 50rem;
      border-radius: 1.25rem;
      max-height: 90vh;
    }
    .search-product-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        padding: 20px 0px 0px 28px;
        color: $black-color;
        opacity: 1;
        font-size: 1rem;
        font-weight: normal;
        letter-spacing: 0px;
        margin-bottom: 0;
      }
      .model-header-close-button {
        margin-right: 10px;
        margin-top: 7px;
      }
    }
    .MuiDialogContent-root {
      padding: 8px 28px;
      overflow-y: hidden;
      .search-return-product-modal-section {
        .title-with-search-icon {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .product-title {
            font-size: 28px;
            font-weight: bold;
            letter-spacing: -0.56px;
            color: $black-color;
            opacity: 1;
          }
          .search-icon {
            margin-right: 20px;
            height: 3.75rem;
            @media only screen and (min-width: 600px) {
              height: 100%;
            }
          }
        }
        .search-product-input {
          margin-top: 30px;
          .MuiOutlinedInput-root {
            padding: 11px 20px;
            .MuiInputBase-input {
              &::placeholder {
                color: $gray;
                opacity: 1;
              }
            }
            .MuiInputAdornment-root {
              color: $black-color;
              opacity: 1;
            }
          }
        }
        .product-search-table-main-container {
          .product-search-table {
            .product-search-table-head {
              .product-search-table-header-row {
                th {
                  padding: 5px 10px;
                }
              }
            }
            .table-border-styling {
              tr {
                padding: 5px 10px;
                position: relative;
                &:hover {
                  background-color: $bright-Gray;
                  border-radius: 0.625rem;
                  opacity: 1;
                  cursor: pointer;
                }
                &::after {
                  content: "";
                  position: absolute;
                  bottom: 0;
                  left: 90px;
                  width: calc(100% - 90px);
                  height: 0.063rem;
                  background-color: var(--bs-gray-200);
                }
                td {
                  padding: 7px 10px;
                  .product-name-with-code {
                    display: flex;
                    flex-direction: column;
                    gap: 3px;
                    width: 100%;
                    max-width: 530px;
                    .manufacture-code {
                      font: 0.875rem normal;
                      color: $description-color;
                      opacity: 1;
                      letter-spacing: 0px;
                    }
                    
                  }
                  .vender-name-text {
                    font: 1rem normal;
                    letter-spacing: 0px;
                    color: $black-color;
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
