.product-detail-toggle-switch {
  .Mui-checked.MuiButtonBase-root.MuiSwitch-switchBase {
    top: 0;
    left: 0;
    .MuiSwitch-thumb {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
  .MuiButtonBase-root.MuiSwitch-switchBase {
    top: 0.188rem;
    left: 0.188rem;
    .MuiSwitch-thumb {
      width: 0.875rem;
      height: 0.875rem;
    }
  }
  span.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: #000000;
  }
  span.MuiSwitch-track {
    background-color: #aaaaaa;
  }
}
.product-detail-toggle-switch {
  span {
    font-size: 1rem;
    font-weight: 600;
  }
}
.metadata-toggle-common-spacing {
  padding: 8px 0 !important;
}
