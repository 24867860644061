.drafts-container {
    &-heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 2.813rem;
        &-text {
            font-size: 1.75rem;
            font-weight: 600;
        }
        &-order-btn {
            .start-new-order-btn {
                height: 44px !important;
                width: 196px;
                height: 2.875rem;
                padding: 0 !important;
            }
            .order-text{
                text-align: left;
                letter-spacing: -0.32px;
            }
        }
    }
    &-search {
        padding-top: 10px;
        .document-search-input {
            width: 100%;
            .MuiFilledInput-root {
                height: 44px;
                padding: 0;
                border-radius: 1rem;
                padding-right: 12px;
                padding-left: 12px;
            }
        }
    }
    &-selection {
        display: flex;
        gap: 8px;
        width: 100%;
        padding-top: 14px;
    }
}

.sort-by-value {
    margin: 5px 0 0 39px !important;
    background-color: #eeeeee !important;
    &-btn {
        border-bottom: unset !important;
    }
}

.sort-by-dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .relative-light-border {
        min-width: 86%;
    }
}
.custom-sort-by-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 6px 28px 6px 8px;
    border: none;
    font-size: 14px;
    letter-spacing: 0.1px;
    cursor: pointer;
    border-radius: 8px;
    user-select: none;
    background-color: unset;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNMCwwSDE2VjE2SDBaIiBmaWxsPSJub25lIi8+PHBhdGggZD0iTTEwLjYwOSw1LjAyM2wxLjY0LDEuNjRhLjY2NS42NjUsMCwwLDAsLjk0LS45NEwxMS4wNzYsMy42YS42NjQuNjY0LDAsMCwwLS45NCwwTDguMDIzLDUuNzIzYS42NjUuNjY1LDAsMCwwLC45NC45NFptMCw4LjIyNy0xLjY0LTEuNjRhLjY2NS42NjUsMCwwLDAtLjk0Ljk0bDIuMTEzLDIuMTJhLjY2NC42NjQsMCwwLDAsLjk0LDBMMTMuMiwxMi41NTZhLjY2NS42NjUsMCwwLDAtLjk0LS45NFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yLjYwOSAtMS4xMzYpIi8+PC9zdmc+);
    background-repeat: no-repeat;
    background-position: calc(100% - 8px) center;
    background-size: 16px;

    &:hover {
        background-color: unset;
    }
    &-options {
        background-color: #ddd;
    }
}
