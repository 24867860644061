.notes-input-wrapper {
  --transition: all 0.6s cubic-bezier(0.03, 1, 0.2, 1.05);
  --horizontal-padding: 15px;
  border-radius: 10px;
  border: 1px solid var(--bs-gray-100);
  background-color: var(--bs-gray-100);
  overflow: hidden;
  padding: 0 var(--horizontal-padding);
  position: relative;
  transition: var(--transition);

  @media (prefers-reduced-motion) {
    transition: none;
  }

  ::placeholder {
    color: var(--bs-gray-800);
    transition: var(--transition);
  }

  .add-new-note-icon {
    position: absolute;
    top: 14px;
    right: 12px;
    user-select: none;
    pointer-events: none;
    color: var(--bs-gray-800);
  }

  &.notes-input-value-changed {
    &::after {
      content: "";
      position: absolute;
      top: 8px;
      right: 8px;
      background-color: var(--bs-primary);
      color: #fff;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 1px;
      border-radius: 6px;
      height: 10px;
      width: 10px;
      cursor: pointer;
      pointer-events: none;
      transition: var(--transition);
    }

    &:hover {
      &::after {
        content: "Unsaved changes";
        height: auto;
        width: auto;
        padding: 6px 12px;
      }
    }

    @media (max-width: 1000px) {
      &::after {
        content: "Unsaved changes";
        height: auto;
        width: auto;
        padding: 6px 12px;
        font-size: 10px;
        font-weight: 600;
      }
    }
  }

  textarea {
    padding: 14px 0;
    width: 100%;
    resize: none;
    font-size: 16px;
    line-height: 1.35;
    background-color: var(--bs-gray-100);
    outline: none;
    border: none;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: var(--transition);
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &:focus {
      background-color: #fff;
    }
  }

  .notes-input-controls {
    pointer-events: none;
    position: absolute;
    left: var(--horizontal-padding);
    right: var(--horizontal-padding);
    display: grid;
    grid-template-columns: 1fr auto auto;
    gap: 6px;
    padding: 20px 0;
    border-top: 1px solid var(--bs-gray-200);
    transition: var(--transition);

    button {
      pointer-events: all;
    }

    .notes-input-btn {
      --outline-color: var(--bs-gray-800);
      --outline-offset: 4px;
      --outline-width: 2px;
      padding: 8px 12px;
      border-radius: 10px;
      border: none;
      background-color: var(--bs-gray-200);
      color: var(--bs-black);
      line-height: 1;
      font-size: 14px;
      font-weight: 500;
      outline: none;
      transition: var(--transition);
      max-width: fit-content;

      &:disabled {
        opacity: 0.3;
      }

      &:not(:disabled) {
        &:focus-visible {
          transform: scale(1.05);
          box-shadow: 0 0 0 var(--outline-offset) #fff,
            0 0 0 calc(var(--outline-offset) + var(--outline-width))
              var(--outline-color);
        }

        &:active {
          transform: scale(0.95);
        }
      }

      &.primary {
        background-color: var(--bs-primary);
        color: #fff;
      }

      &.secondary {
        background-color: var(--bs-gray-200);
        color: var(--bs-black);
      }
    }

    .notes-input-delete-btn {
      color: var(--bs-gray-800);

      &:hover,
      &:focus-visible {
        color: var(--bs-danger);
      }
    }
  }

  &:focus-within {
    border: 1px solid var(--bs-black);
    background-color: #fff;
    padding-bottom: 70px;

    &.notes-input-value-changed {
      &::after {
        display: none;
      }
    }

    .add-new-note-icon {
      display: none;
    }

    textarea {
      background-color: #fff;

      &::placeholder {
        color: var(--bs-gray-600);
      }
    }
  }
}
