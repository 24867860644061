@import "../../constants/colors.scss";

.left-side-tabs-header.card-header {
  padding-top: 20px !important;
  padding-bottom: 0 !important;
  margin: 0;
  .card-header-tabs {
    margin-bottom: 0;
  }
}
.status-info-dot {
  // position: relative;
  .dot {
    height: 8px;
    width: 8px;
    border-radius: 25px;
  }
}
.status-info-hover-text-styling {
  background-color: #000000;
  position: absolute;
  bottom: 0;
  color: #fff;
}
.special-tooltip {
  position: relative;
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 30px;
  margin-right: 20px;
  margin-left: 30px;
  .tooltiptext {
    font-size: 12px;
    font-weight: 400;
    visibility: hidden;
    width: max-content;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 10px;
    padding: 5px 10px;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 100%;
    margin-top: 10px;
  }
  &:hover .tooltiptext {
    visibility: visible;
  }
}
.open-quotes-id {
  margin-right: 20px;
}
.small-dot {
  height: 6px;
  width: 6px;
  display: block;
  border-radius: 30px;
  background-color: #dddddd;
  margin: 0 10px;
}
.creator-info-sec {
  color: #555555;
  font-size: 12px;
}
.quotes-left-side-info-sec {
  padding: 0.625rem !important;
}
.quote-id-name-sec {
  margin-bottom: 8px;
}
.overflow-unset {
  overflow: unset !important;
}
.created-by-status-info {
  margin-bottom: 30px;
}
.quote-main-info-header-section {
  margin-bottom: 10px;
}
.quote-item-total-row.MuiTableCell-root {
  padding-top: 10px;
  padding-bottom: 20px;
}
.email-quote-main-btn {
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 6px !important;
  border-top-right-radius: 0 !important;
  span {
    font-size: 12px;
  }
}
.email-quote-btn-dropdown {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 6px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 6px !important;
}
.extended-week-info {
  margin-top: 10px;
}
.expirationDate {
  input {
    border: 1px solid #dddddd;
  }
}
.extend-date-change-btn{
  border-color: #000000 !important;
  font-size: 14px;
}      
.new-estimator-select, .new-estimator-checkbox{
  label{
    color: #000000;
  }
}
.new-estimator-checkbox{
  label{
    font-weight: 400;
  }
  input{
    border: 2px solid;
  }
}

.quote-search-box {
  margin-top: 1.563rem;
  margin-right: 1rem;
}
.no-quote-available-icon {
  flex-direction: column;
  img {
    height: 66px;
    margin-top: 50px;
  }
}

.salesperson-dropdown {
  margin-left: 15px
}

.quote-search-box {
  .MuiFormControl-root {
    .MuiInputBase-root {
      width: 100%;
      padding: 0.8rem 1.25rem;
      border-radius: 0.7rem;
      background-color: #f1f1f1;
    }
  }
}

.change-estimator-remove-btn {
  .MuiButton-label {
    color: $red-color;
  }
}

.text-gray {
  color: $light-gray-color;
}

.quote-list-small-dot {
  height: 0.125rem;
  width: 0.125rem;
  display: block;
  border-radius: 1.875rem;
  background-color: $darkGray;
  margin: 0 0.625rem;
}

.quote-details-history {
  color: black !important;
  opacity: initial !important;
}

.quote-details-history-no {
  color: black !important;
  opacity: 0.35 !important;
}