@import "src/apps/pos/assets/css/variable.scss";

.alert-modal-container {
  .MuiDialogContent-root {
    width: 34rem;
    max-height: 26rem;
    border-radius: 1.25rem !important;
    padding: 1.875rem !important;
  }
  .MuiSelect-select {
    padding: 0.9375rem !important;
  }

  .MuiDialog-paper {
    border-radius: 1.25rem !important;

    .MuiDialogContent-root {
      position: relative;
      padding: 1.875rem !important;

      .alert-modal-info-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
      }

      .close-alert-modal-icon {
        min-width: 2.5rem;
        max-width: 2.5rem;
        height: 2.5rem;
        left: 1rem;
        bottom: 1rem;
        color: gray;
      }

      .alert-modal-title {
        font-size: 1.25rem;
        font-weight: 600;
        color: $black-color;
      }

      .alert-confirm-btn{
        margin-top: 1.25rem;
        min-width: 4rem;
        width: 4rem;
        max-width: 4rem;
        height: 2.75rem;
      }

      .files-list-container{
        max-height: 7rem;
        overflow-y: auto;
        border-radius: 10px;
        margin-top: 0.5rem;
        background-color: rgb(221, 221, 221);
        .file-details-section{
          background: #F9F9F9;
          padding: 1rem;
          margin-bottom: 1px;
          opacity: 1;
        }
        .doc-name{
          max-width: 17rem;
        }
      }

      .doc-reupload-modal-add-doc-btn{
        margin: 1rem 0;
        font-weight: 500;
        background-color: black;
        color: white;
        top: 1rem;
      }

      .alert-modal-files-list-header{
        margin-top: 2rem;
        font-size: 0.625rem;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }
  }
}
