.ellipsis-span{
  margin-inline-end: 0.25em;
}

.ellipsis-button {
  border: none;
  background: inherit;
  font: inherit;
  font-weight: 500;
  cursor: pointer;
  padding: 0;
  outline-offset: 0.25em;
  outline-color: currentColor;
  text-decoration: underline;
}

.ellipsis-button-display{
  display: initial;
}