.product-table-container {
  width: 100%;
  .product-image {
    width: 2.5rem;
    height: 2.5rem;
    img {
      height: 100%;
      border-radius: 0.625rem;
    }
  }
  .cart-product-listing-data {
    overflow-x: unset;
    .head-table {
      top: 0;
    }
    th:first-child{
      padding-left: 10px;
    }
    th{
      letter-spacing: 1px;
    }
  }
  .prod-name {
    font-weight: 500;
  }
  .prod-manufacturere-name {
    color: #555555;
    font-weight: 400;
  }
  .table-body {
    td:first-child {
      border-top-left-radius: 0.625rem;
      border-bottom-left-radius: 0.625rem;
    }
    td:last-child {
      border-top-right-radius: 0.625rem;
      border-bottom-right-radius: 0.625rem;
    }
    .product-quick-info {
      display: flex;
      padding-left: 10px;
    }

    td {
      border-bottom: 1px solid #eeeeee !important;
      // padding-left: 10px;
      // padding-left: 0.625rem;
    }
    .product-text-info {
      display: flex;
      flex-direction: column;
      padding-left: 1.25rem;
    }
  }
  .table-body:hover {
    background-color: #eeeeee;
    border-radius: 0.625rem;
  }
  .prod-status {
    font-size: 0.875rem;
    font-weight: normal;
    .prod-status-title {
      margin-right: 0.125rem;
    }
    .prod-status-icon {
      margin-right: 0.0625rem;
      svg {
        font-size: 0.875rem;
      }
    }
  }
}
