.product-card-container {
  height: auto;
  width: 25rem;
  margin-top: 1.25rem;
  border-bottom: 0.016rem solid #dddddd;
  margin-bottom: 3.125rem;
  cursor: pointer;
  
  .product-image {
    width: 5rem;
    height: 5rem;
    img {
      height: 100%;
      border-radius: 0.625rem;
    }
  }
  .product-quick-info {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    .prod-name {
      font-size: 1rem;
      font-weight: 500 !important;
    }
    .prod-manufacturere-name {
      color: #555555;
      font-size: 1rem;
    }
    .prod-status {
      color: #000000;
      font-size: 0.875rem;
      .prod-status-icon {
        margin-left: 0.125rem;
        margin-right: 0.0625rem;
      }
      .MuiSvgIcon-root {
        font-size: 1rem;
      }
    }
  }
  .prod-availability-description {
    margin-top: 1.875rem;
    color: #555555;
    font-size: 0.875rem;
    display: flex;
    flex-direction: column;
  }
  .product-quick-details{
    display: flex;
    flex-direction: column;
  }
  .prod-price {
    margin-top: 1.875rem;
    .price {
      font-size: 1.375rem;
      font-weight: 500;
    }
    .divider {
      padding: 0 0.125rem;
    }
  }
  .gray-dot{
    height: 0.4rem;
    width: 0.4rem;
    background-color: #AAAAAA;
    border-radius: 50%;
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
  }
}
.coloured-prod-status-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  svg.MuiSvgIcon-root {
    font-size: unset;
    width: 12px;
    height: 12px;
    circle {
      width: 12px;
      height: 12px;
      r: 12px;
    }
  }
}