@import "../../../pos/assets/css/variable.scss";

.item-history-main-section {
  padding: 1.875rem 0;
  .item-history-section {
    padding: 2rem;
    .item-history-container {
      display: flex;
      justify-items: flex-start;
      width: 100%;
      align-items: center;
      height: 100%;
      gap: 2.25rem;
      .back-button {
        border-radius: 1.25rem;
        background: $bgColor 0% 0% no-repeat padding-box;
        padding: 1.125rem;
        .back-icon {
          fill: $black-color;
        }
      }
      .item-history-title-container {
        width: 100%;
        display: flex;
        justify-content: left;
        align-items: center;
        flex-wrap: wrap;
        gap: 30px;
        @media only screen and (min-width: 1200px) {
          gap: 170px;
        }
        .item-history-products-container {
          width: 100%;
          border: 0.065rem solid $border-color;
          max-width: 40rem;
          border-radius: 0.9375rem;
          padding: 0.625rem;
          .product-ledger-image {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 0.8rem;
            .product-ledger-name{
              .name{
                font-weight: 500;
              }
              .key{
                font-size: 12px;
              }
            }
          }
        }
        .download-csv-btn {
          padding: 0.75rem 0.9375rem;
        }
        .title{
          letter-spacing: -0.035rem;
          margin-right: 4.75rem;
        }
      }
    }
  }
  .table-main-container {
    padding-left: 7.625rem;
    padding-right: 7.625rem;
    margin-top:1rem;
    .divider-div {
      background: $black-color 0% 0% no-repeat padding-box;
      opacity: 1;
    }
    .second-divider-div {
      background: #EEEEEE 0% 0% no-repeat padding-box;
      opacity: 1;
      margin: 0.9375rem 0;
    }
    .show-price-quantity-container {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: repeat(8,1fr);
      margin-top: 0.5rem;
      .price-key {
        color: $black-color;
        opacity: 1;
        text-align: left;
        font-size: 1.125rem;
      }
      .price-title {
        color: $black-color;
        opacity: 0.8;
        text-align: left;
        font-size: 0.625rem;
        letter-spacing: 0.0625rem;
      }
    }
    .item-history-data-grid-container {
      .MuiDataGrid-main {
        .MuiDataGrid-row {  
          color: #000000;
        }
        .MuiDataGrid-columnHeaders {
          .MuiDataGrid-columnHeadersInner {
          border-top-right-radius: 0.9375rem;
          border-top-left-radius: 0.9375rem;
            .MuiDataGrid-columnHeader {
              .MuiDataGrid-columnSeparator {
                visibility: hidden;
                display: none;
              }
              .MuiDataGrid-columnHeaderTitleContainer {
                .MuiDataGrid-columnHeaderTitle {
                  font-size: 0.625rem;
                  font-weight: 500;
                  letter-spacing: 0.0313rem;
                  opacity: 1;
                }
              }
            }
            .MuiDataGrid-columnHeader:last-child {
              .MuiDataGrid-columnHeaderTitleContainer {
                justify-content: center;
              }
            }
        }
        }
        .MuiDataGrid-virtualScroller {
          .MuiDataGrid-virtualScrollerContent {
            .MuiDataGrid-virtualScrollerRenderZone {
              .MuiDataGrid-row:nth-child(odd) {
                background-color: $gray-50;
              }
            }
          }
        }
      }
      .pagination-next-container {
        flex-shrink: 0;
        margin-left: 1.25rem;
        .next-button-icon {
          color: $black-color;
        }
        .Mui-disabled {
          color: $black-color;
          opacity: 0.3;
        }
      }

      .table-body-col{
        padding:0.625rem 1.25rem;
      }
      .table-body-row-history{
        & > .item-history-table-header{
          padding: 0.625rem 1.25rem;
        }
      }
    }
  }
  
}


.table-body-row-history,
.table-head-row-history {
    display: grid;
    grid-row-gap: 1em;
    grid-template-columns: 1fr 1fr 1fr;
}
.table-head-row, .sales-history-table-data, .sales-history-header-filter-sec {
    border-bottom: 0.0625rem solid #DDDDDD;
}

.item-history-table-header {
  font-size: 0.625rem;
  color: #000000;
  padding: 0.9375rem 0;
  text-align: left;
}

.vertical-line{
    border-left: 0.0625rem solid #EEEEEE;
    height: calc(100% - 1.25rem);
    margin-top: 0.625rem;
    display: block;
  }

.table-body-row-history:nth-child(even) {
  background-color: #F9F9F9;
}

.lead-header {
  font-size: 1.25rem;
  padding: 0.9375rem 0;
  font-weight: 600;
}

.table-body-row-history-lead,
.table-head-row-history-lead {
  display: grid;
  grid-row-gap: 1em;
  grid-template-columns: 2fr 1fr;
}

.table-body-row-history-lead:nth-child(even) {
  background-color: #F9F9F9;
}

.text-right{
  text-align: right !important;
}

.pl-122{
  padding-left: 7.625rem !important;
}

.font-small-icon{
  color: #AAAAAA;
  font-size: large !important;
}
.text-center-icon{
  text-align: center !important;
}

.ml-90{
  margin-left: 5.625rem;
}