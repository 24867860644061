.product-detail-modal-footer-spacing {
  padding-top: 1.875rem !important;
  flex-direction: column !important;
  @media (min-width: 600px) {
    flex-direction: row !important;
  }
  flex-wrap: wrap;
  .first-product-detail-btn {
    max-width: 10rem;
  }
  .second-product-detail-btn {
    margin-top: 1.25rem;
    max-width: 10rem;
    @media (min-width: 600px) {
      margin-top: 0;
    }

    &.button-is-loading-black {
      position: relative;
      overflow: hidden;
      pointer-events: none;
      .button-label,
      .button-icon {
        opacity: 0;
      }

      &::before {
        content: "";
        position: absolute;
        background-color: var(--bs-black);
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        border-radius: 0.625rem;
      }

      &::after {
        content: "";
        position: absolute;
        height: 1.5rem;
        width: 1.5rem;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNNiAxMGMtMS4xIDAtMiAuOS0yIDJzLjkgMiAyIDIgMi0uOSAyLTItLjktMi0yLTJ6bTEyIDBjLTEuMSAwLTIgLjktMiAycy45IDIgMiAyIDItLjkgMi0yLS45LTItMi0yem0tNiAwYy0xLjEgMC0yIC45LTIgMnMuOSAyIDIgMiAyLS45IDItMi0uOS0yLTItMnoiLz48L3N2Zz4=");
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0;
        animation: button-loading-anim 0.3s ease-out alternate infinite;

        @keyframes button-loading-anim {
          to {
            opacity: 1;
            transform: scale(1.1);
          }
        }
      }
    }

    &.button-accent {
      &.button-is-loading-black {
        &::after {
          filter: invert(1);
        }
      }
    }
  }
}

.product-detail-noted-modal-content {
  padding: 0.938rem 1.875rem 1.875rem;
  .total-notes-count-section {
    padding: 1.25rem 0;
  }
}

.product-detail-notes-modal {
  max-width: 100% !important;
  margin-top: 0% !important;
  margin-left: 0% !important;
  .product-detail-notes-paper-div {
    width: 40rem;
    height: 56.25rem;
    max-height: 95vh;
    max-width: 90vw;
    transition-duration: 0s !important;
    &.logs-detail {
      width: 55rem;
    }
    &.retail-detail {
      width: 60rem;
    }
    .notes-model.product-detail-notes-model {
      height: 100%;
      line-height: normal;
      .product-detail-noted-modal-content {
        max-height: 49.375rem;
      }
      .text-color-gray {
        color: var(--bs-gray-silver-chalice);
      }
    }
  }
}

.detail-modal-content-bottom-spacing {
  padding-bottom: 0;
  height: calc(100% - 4.4rem) !important;
  max-height: unset !important;
  @media (min-width: 600px) {
    padding-bottom: 0;
    height: calc(100% - 4.4rem) !important;
  }
}
.product-detail-common-spacing {
  margin-bottom: 0.625rem;
}
.light-gray-opacity {
  opacity: 0.5;
}
.product-detail-info-sec-border {
  border-bottom: 0.063rem solid #dddddd7d;
}

.product-detail-select {
  width: 100%;
  border-radius: 0.625rem !important;
  .MuiSelect-root.MuiFilledInput-input {
    padding: 0.813rem;
  }
}
.input-with-delete-icon {
  position: relative;
  margin-top: 0.625rem;
  .detail-input-sec {
    margin: 0;
    width: 100%;
    .MuiInputBase-root {
      padding-right: 2.5rem;
    }
  }
  .detail-delete-section {
    position: absolute;
    right: 0.625rem;
  }
  &::after {
    content: "";
    position: absolute;
    right: 2.5rem;
    height: 1.5rem;
    width: 0.063rem;
    background-color: var(--bs-gray-500);
  }
}
.notes-added-title {
  font-size: 0.75rem;
  font-weight: 600;
}
.notes-remove-title {
  font-size: 0.75rem;
  font-weight: 500;
  color: #aaaaaa;
  cursor: pointer;
}
.notes-added-user-name {
  font-size: 1rem;
  font-weight: 600;
}
.product-details-notes-main-content-section {
  padding: 25px 0;
  border-top: 1px solid #dddddd7d;

  .product-detail-note {
    padding: 0.75rem;
    background-color: #f1f1f1;
    margin: 0.625rem 0;
    border-radius: 0.625rem;
    span {
      font-size: 1rem;
      font-weight: 500;
    }
  }
  .last-edited-info {
    font-size: 0.875rem;
    font-weight: 500;
    color: #555555;
  }
}
.btn.MuiButtonBase-root.product-detail-common-small-btn {
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.313rem 0.313rem;
  margin: 0;
  border-radius: 0.5rem;
  min-width: 3.125rem;
  span {
    font-size: 0.875rem !important;
    width: fit-content;
    letter-spacing: 0;
  }
}
.side-link {
  text-decoration: underline;
  font-size: 0.875rem;
  font-weight: 500;
  color: #000000;
}
a:is(.side-link:not(.nav-link, .dropdown-item, .page-tips-footer a, .btn)) {
  color: black !important;
  border-bottom: 1px solid black;
  width: fit-content;
  display: inline-block;
  float: right;
  text-decoration: none;
  &:visited,
  &:link,
  &:hover,
  &:active {
    color: black !important;
  }
}

.scroll--input {
  textarea {
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 5px !important;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1 !important;
    }

    &::-webkit-scrollbar-thumb {
      background: #888 !important;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555 !important;
    }
  }
}

.notes-dialog-costs-price {
  outline: none;
  border-radius: 1.25rem;
  width: 40.625rem;
  max-width: 40.625rem;
  min-height: 85vh;
  max-height: 85vh;
  padding: 0;
  border: none;
  padding-bottom: 50vh;
  overscroll-behavior: none;
  box-shadow: 0 0.75rem 1.75rem 0 rgba(0, 0, 0, 0.2),
    0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 0.0625rem rgba(255, 255, 255, 0.5);
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #fff;
  display: grid;
  grid-template-rows: auto 1fr auto;
  padding-bottom: 0;
  overflow: hidden;
  scroll-padding-top: 10.625rem;

  &::-webkit-scrollbar {
    display: none;
  }


  &::backdrop {
    background: rgba(0, 0, 0, 0.4);
  }

  @media (max-width: 62.5rem) {
    max-width: 100%;
    width: 100%;
    min-height: auto;
    height: 100vh;
    max-height: 100vh;
    border-radius: 0;
    inset: unset;
    top: 0;
  }

  .dialog-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    padding: 1.875rem;
    position: relative;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(1.25rem);
    -webkit-backdrop-filter: blur(1.25rem);
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
    position: sticky;
    top: 0;
    z-index: 1;

    .dialog-back-to-top {
      pointer-events: none;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .dialog-back-to-top-btn {
        pointer-events: all;
      }
    }

    h3 {
      font-size: 1.5rem;
      font-weight: 600;
      letter-spacing: -0.0125rem;
      color: black;

      @media (max-width: 62.5rem) {
        font-size: 1.125rem;
      }
    }

    .close-btn {
      position: absolute;
      right: 0.625rem;
      top: 0.625rem;

      @media (max-width: 62.5rem) {
        position: relative;
        right: auto;
        top: auto;
      }
    }
  }

  .dialog-body {
    padding-bottom: 50px;
    scroll-padding-top: 10.625rem;
    overflow-y: scroll;
    padding: 1.875rem;
    padding-top: 0.625rem;
    display: flex;
    flex-direction: column;

    .notes-manager-controls {
      padding-top: 1.25rem;
      padding-bottom: 0.25rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .notes-manager-count,
    .notes-manager-btn {
      text-transform: uppercase;
      font-size: 0.75rem;
      letter-spacing: 0.0125rem;
      user-select: none;
      font-weight: 600;
      padding: 0;
      margin: 0;
      line-height: 1;
    }

    .notes-manager-btn {
      --outline-color: var(--bs-gray-500);
      --outline-offset: 0.25rem;
      --outline-width: 0.125rem;
      border: none;
      background-color: #fff;
      color: var(--bs-black);
      opacity: 0.35;
      padding: 0.625rem;
      border-radius: 0.625rem;
      outline: none;
      max-width: fit-content;

      &:focus-visible {
        transform: scale(1.05);
        box-shadow: 0 0 0 var(--outline-offset) #fff,
          0 0 0 calc(var(--outline-offset) + var(--outline-width))
            var(--outline-color);
      }

      &:active {
        transform: scale(0.95);
      }

      &:hover,
      &:focus-visible {
        opacity: 1;
        background-color: var(--bs-gray-200);
      }
    }
  }

  .notes-model-footer {
    padding: 1.875rem;
    padding-top: 0;

    .int-msg {
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0px;
      color: #000000;
      max-width: 21.1875rem;
      margin: 5px 20px 0;
    }

    hr {
      &.top--border {
        display: block;
        border-top: 1px solid #dddddd;
        width: 100%;
        margin: 0 0 35px;
      }
    }
  }
}

.overview-dialog {
  .MuiPaper-root {
    border: solid red;
    width: 40.625rem;
    max-width: 40.625rem;
    min-height: 85vh;
    max-height: 85vh;
    padding: 0;
    border: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-padding-top: 10.625rem;
    border-radius: 1.25rem;
  }

  .MuiDialogContent-root {
    scroll-padding-top: 10.625rem;
  }

  .dialog-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    padding: 1.875rem;
    position: relative;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(1.25rem);
    -webkit-backdrop-filter: blur(1.25rem);
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
    position: sticky;
    top: 0;
    z-index: 1;

    .dialog-back-to-top {
      pointer-events: none;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .dialog-back-to-top-btn {
        pointer-events: all;
      }
    }

    h3 {
      font-size: 1.5rem;
      font-weight: 600;
      letter-spacing: -0.0125rem;
      color: black;

      @media (max-width: 62.5rem) {
        font-size: 1.125rem;
      }
    }

    .close-btn {
      position: absolute;
      right: 0.625rem;
      top: 0.625rem;

      @media (max-width: 62.5rem) {
        position: relative;
        right: auto;
        top: auto;
      }
    }
  }

  .notes-model-footer {
    padding: 1.875rem;

    .int-msg {
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0px;
      color: #000000;
      max-width: 21.1875rem;
      margin: 5px 20px 0;
    }

    hr {
      &.top--border {
        display: block;
        border-top: 1px solid #dddddd;
        width: 100%;
        margin: 0 0 35px;
      }
    }
  }
}
