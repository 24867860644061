@import "../../../../../constants/colors.scss";

.dark-mint-green-text-label {
    color: $dartmouth-green !important;
}

.dark-red-color-text-label {
    color: $dark-red-color !important;
}

.price-history-modal {
    .MuiDialog-container {
        .MuiDialog-paper {
            width: 49.25rem !important;
            max-width: 49.25rem !important;
        }
    }

    &.set-customer-code-modal-container {
        .modal-header {
            padding: 1.5625rem !important;

            .text-gray {
                color: $gray;
            }
        }
    }

    table {
        .change-by-cell {
            min-width: 12.5rem;
        }
    }
    .customer-table-styling {
        th {
            letter-spacing: 0.0625rem;
        }
        .font-small-icon{
            color: $darkGray;
            font-size: large;
        }
        .text-center-td{
            text-align: center;
        }
    }
}
.min-w{
    &-220{
        min-width: 13.75rem;
    }
    &-150{
        min-width: 9.375rem;
    }
    &-100{
        min-width: 6.25rem;
    }
}