select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 6px 28px 6px 8px;
    border: none;
    font-size: 14px;
    letter-spacing: 0.1px;
    cursor: pointer;
    border-radius: 8px;
    user-select: none;
    background-color: #eee;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNMCwwSDE2VjE2SDBaIiBmaWxsPSJub25lIi8+PHBhdGggZD0iTTEwLjYwOSw1LjAyM2wxLjY0LDEuNjRhLjY2NS42NjUsMCwwLDAsLjk0LS45NEwxMS4wNzYsMy42YS42NjQuNjY0LDAsMCwwLS45NCwwTDguMDIzLDUuNzIzYS42NjUuNjY1LDAsMCwwLC45NC45NFptMCw4LjIyNy0xLjY0LTEuNjRhLjY2NS42NjUsMCwwLDAtLjk0Ljk0bDIuMTEzLDIuMTJhLjY2NC42NjQsMCwwLDAsLjk0LDBMMTMuMiwxMi41NTZhLjY2NS42NjUsMCwwLDAtLjk0LS45NFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yLjYwOSAtMS4xMzYpIi8+PC9zdmc+);
    background-repeat: no-repeat;
    background-position: calc(100% - 8px) center;
    background-size: 16px;
    
    &:hover{
      background-color: #ddd;
    }
  }
  
  
  // extras
//   *{
//     box-sizing: border-box;
//   }
//   body{
//     margin: 0;
//     padding: 2rem;
//     line-height: 1;
//     min-height: 100vh;
//     font-family: 'Inter', sans-serif;
//     display: grid;
//     place-items: center;
//   }