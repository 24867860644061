:root {
    --sidenav-width: 300px;
    --sidenav-background: #fff;
    --sidenav-padding-top: calc(60px + 20px);
    --sidenav-icon-size: 22px;
    --sidenav-item-height: 40px;
    --font-14: 14px;
    --sidenav-collapsed-width: calc(var(--sidenav-icon-size) + 20px + 20px);
    --sidenav-expanded-transition: all 0.2s cubic-bezier(0.2, 0, 0, 1);
    --sidenav-collapsed-transition: all 0.2s cubic-bezier(0.34, 0.01, 0.23, 0.98);
    --sidenav-transition: var(--sidenav-expanded-transition);
    /* grays */
    --bs-gray-50: #F9F9F9;
    --bs-gray-88: #888888;
    --bs-gray-100: #F1F1F1; /* Secondary */
    --bs-gray-200: #EEE;
    --bs-gray-300: #DDD;
    --bs-gray-400: #CCC;
    --bs-gray-500: #AAA;
    --bs-gray-600: #999;
    --bs-gray-700: #777;
    --bs-gray-800: #555;
    --bs-gray-900: #222;
    --bs-black: #000;
}

header.master-header {
    z-index: 10;
    background-color: rgba(255, 255, 255, 1);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px 20px 10px 10px;
    border-bottom: 1px solid var(--bs-gray-200);
}

@media print {
    header.master-header {
        display: none;
    }
}
.header-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
.header-left-block {
    display: flex;
    align-items: center;
    position: absolute;
    left: 10px;
}

/* sidenav switch */
.hidden {
    display: none;
}
.sidenav-switch {
    --size: 40px;
    padding: 0;
    height: var(--size);
    width: var(--size);
    min-height: var(--size);
    min-width: var(--size);
    border-radius: 500px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}
.sidenav-switch:hover {
    background-color: var(--bs-gray-200);
}
.sidenav-switch:active {
    background-color: var(--bs-gray-300);
    transform: scale(0.9);
}
.sidenav-switch:focus {
    outline-color: var(--bs-primary);
}
.sidenav-switch-icon {
    --size: 24px;
    height: var(--size);
    width: var(--size);
}
.sidenav-switch-icon.sidenav-switch-expand {
    background-image: url(data:image/svg+xml;base64,PHN2ZwogICAgICAgICAgICAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgICAgICAgICAgICBoZWlnaHQ9IjI0IgogICAgICAgICAgICAgIHZpZXdCb3g9IjAgMCAyNCAyNCIKICAgICAgICAgICAgICB3aWR0aD0iMjQiCiAgICAgICAgICAgID4KICAgICAgICAgICAgICA8cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiIC8+CiAgICAgICAgICAgICAgPHBhdGgKICAgICAgICAgICAgICAgIGQ9Ik00IDE4aDE2Yy41NSAwIDEtLjQ1IDEtMXMtLjQ1LTEtMS0xSDRjLS41NSAwLTEgLjQ1LTEgMXMuNDUgMSAxIDF6bTAtNWgxNmMuNTUgMCAxLS40NSAxLTFzLS40NS0xLTEtMUg0Yy0uNTUgMC0xIC40NS0xIDFzLjQ1IDEgMSAxek0zIDdjMCAuNTUuNDUgMSAxIDFoMTZjLjU1IDAgMS0uNDUgMS0xcy0uNDUtMS0xLTFINGMtLjU1IDAtMSAuNDUtMSAxeiIKICAgICAgICAgICAgICAvPgogICAgICAgICAgICA8L3N2Zz4=);
}
.sidenav-switch-icon.sidenav-switch-collapse {
    background-image: url(data:image/svg+xml;base64,PHN2ZwogICAgICAgICAgICAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgICAgICAgICAgICBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyNCAyNCIKICAgICAgICAgICAgICBoZWlnaHQ9IjI0IgogICAgICAgICAgICAgIHZpZXdCb3g9IjAgMCAyNCAyNCIKICAgICAgICAgICAgICB3aWR0aD0iMjQiCiAgICAgICAgICAgID4KICAgICAgICAgICAgICA8cGF0aCBkPSJNMCwwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiIC8+CiAgICAgICAgICAgICAgPHBhdGgKICAgICAgICAgICAgICAgIGQ9Ik00LDE4aDExYzAuNTUsMCwxLTAuNDUsMS0xdjBjMC0wLjU1LTAuNDUtMS0xLTFINGMtMC41NSwwLTEsMC40NS0xLDF2MEMzLDE3LjU1LDMuNDUsMTgsNCwxOHogTTQsMTNoOGMwLjU1LDAsMS0wLjQ1LDEtMXYwIGMwLTAuNTUtMC40NS0xLTEtMUg0Yy0wLjU1LDAtMSwwLjQ1LTEsMXYwQzMsMTIuNTUsMy40NSwxMyw0LDEzeiBNMyw3TDMsN2MwLDAuNTUsMC40NSwxLDEsMWgxMWMwLjU1LDAsMS0wLjQ1LDEtMXYwIGMwLTAuNTUtMC40NS0xLTEtMUg0QzMuNDUsNiwzLDYuNDUsMyw3eiBNMjAuMywxNC44OEwxNy40MiwxMmwyLjg4LTIuODhjMC4zOS0wLjM5LDAuMzktMS4wMiwwLTEuNDFsMCwwIGMtMC4zOS0wLjM5LTEuMDItMC4zOS0xLjQxLDBsLTMuNTksMy41OWMtMC4zOSwwLjM5LTAuMzksMS4wMiwwLDEuNDFsMy41OSwzLjU5YzAuMzksMC4zOSwxLjAyLDAuMzksMS40MSwwbDAsMCBDMjAuNjgsMTUuOTEsMjAuNjksMTUuMjcsMjAuMywxNC44OHoiCiAgICAgICAgICAgICAgLz4KICAgICAgICAgICAgICA8cGF0aCBkPSJNMCwwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiIC8+CiAgICAgICAgICAgIDwvc3ZnPg==);
}
.header-logo-wrapper {
    margin-left: 10px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.header-logo-link {
    display: flex;
    align-items: center;
    height: 30px;
    min-width: 100px;
}
.header-logo {
    -o-object-fit: contain;
    object-fit: contain;
    height: 100%;
    width: auto;
    pointer-events: none;
}

/* HEADER ACTIONS */
.header-actions {
    display: flex;
    position: absolute;
    right: 20px;
}

.header-actions.hidden {
    display: none;
}
.header-action {
    position: relative;
    outline: none;
}
.header-action:not(:first-child) {
    margin-left: 10px;
}
.logout-button{
    color: white;
    background-color: var(--bs-primary);
    font-size: var(--font-14);
    font-weight: 600;
    &:hover {
        color: white;
        background-color: var(--bs-primary) !important
    }
}
.icon-button {
    --icon-button-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 500px;
    height: var(--icon-button-size);
    width: var(--icon-button-size);
    cursor: pointer;
    padding: 0;
    overflow: hidden;
}
.icon-button:hover {
    background-color: var(--bs-gray-100);
}
.icon-button:active {
    background-color: var(--bs-gray-200);
    transform: scale(0.9);
}
.icon-button {
    opacity: 0.3;

    @media (max-width: 1000px) {
        opacity: 1;
    }
}
.icon-button:hover {
    opacity: 1;
}
.header-action-profile .icon-button {
    opacity: 1;
}
.header-action-notepad .icon-button,
.header-action-search .icon-button,
.header-action-support .icon-button {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px;
    font-size: 0;
    background-color: var(--sidenav-background);
}
.header-action-search .icon-button {
    background-image: url(data:image/svg+xml;base64,PHN2ZwogICAgICAgICAgICAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgICAgICAgICAgICBoZWlnaHQ9IjI0IgogICAgICAgICAgICAgIHZpZXdCb3g9IjAgMCAyNCAyNCIKICAgICAgICAgICAgICB3aWR0aD0iMjQiCiAgICAgICAgICAgID4KICAgICAgICAgICAgICA8cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiIC8+CiAgICAgICAgICAgICAgPHBhdGgKICAgICAgICAgICAgICAgIGQ9Ik0xNS41IDE0aC0uNzlsLS4yOC0uMjdjMS4yLTEuNCAxLjgyLTMuMzEgMS40OC01LjM0LS40Ny0yLjc4LTIuNzktNS01LjU5LTUuMzQtNC4yMy0uNTItNy43OSAzLjA0LTcuMjcgNy4yNy4zNCAyLjggMi41NiA1LjEyIDUuMzQgNS41OSAyLjAzLjM0IDMuOTQtLjI4IDUuMzQtMS40OGwuMjcuMjh2Ljc5bDQuMjUgNC4yNWMuNDEuNDEgMS4wOC40MSAxLjQ5IDAgLjQxLS40MS40MS0xLjA4IDAtMS40OUwxNS41IDE0em0tNiAwQzcuMDEgMTQgNSAxMS45OSA1IDkuNVM3LjAxIDUgOS41IDUgMTQgNy4wMSAxNCA5LjUgMTEuOTkgMTQgOS41IDE0eiIKICAgICAgICAgICAgICAvPgogICAgICAgICAgICA8L3N2Zz4=);
}

.header-action-support .icon-button {
    background-image: url(data:image/svg+xml;base64,PHN2ZwogICAgICAgICAgICAgICAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogICAgICAgICAgICAgICAgaGVpZ2h0PSIyNCIKICAgICAgICAgICAgICAgIHZpZXdCb3g9IjAgMCAyNCAyNCIKICAgICAgICAgICAgICAgIHdpZHRoPSIyNCIKICAgICAgICAgICAgICA+CiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiIC8+CiAgICAgICAgICAgICAgICA8cGF0aAogICAgICAgICAgICAgICAgICBkPSJNMTIgMkM2LjQ4IDIgMiA2LjQ4IDIgMTJzNC40OCAxMCAxMCAxMCAxMC00LjQ4IDEwLTEwUzE3LjUyIDIgMTIgMnptMSAxN2gtMnYtMmgydjJ6bTIuMDctNy43NWwtLjkuOTJjLS41LjUxLS44Ni45Ny0xLjA0IDEuNjktLjA4LjMyLS4xMy42OC0uMTMgMS4xNGgtMnYtLjVjMC0uNDYuMDgtLjkuMjItMS4zMS4yLS41OC41My0xLjEuOTUtMS41MmwxLjI0LTEuMjZjLjQ2LS40NC42OC0xLjEuNTUtMS44LS4xMy0uNzItLjY5LTEuMzMtMS4zOS0xLjUzLTEuMTEtLjMxLTIuMTQuMzItMi40NyAxLjI3LS4xMi4zNy0uNDMuNjUtLjgyLjY1aC0uM0M4LjQgOSA4IDguNDQgOC4xNiA3Ljg4Yy40My0xLjQ3IDEuNjgtMi41OSAzLjIzLTIuODMgMS41Mi0uMjQgMi45Ny41NSAzLjg3IDEuOCAxLjE4IDEuNjMuODMgMy4zOC0uMTkgNC40eiIKICAgICAgICAgICAgICAgIC8+CiAgICAgICAgICAgICAgPC9zdmc+);
}

.header-action-notepad .icon-button {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDI0IDI0IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0cHgiIGZpbGw9IiMwMDAiPjxyZWN0IGZpbGw9Im5vbmUiIGhlaWdodD0iMjQiIHdpZHRoPSIyNCIvPjxwYXRoIGQ9Ik0xOC44NSwxMC4zOWwxLjA2LTEuMDZjMC43OC0wLjc4LDAuNzgtMi4wNSwwLTIuODNMMTguNSw1LjA5Yy0wLjc4LTAuNzgtMi4wNS0wLjc4LTIuODMsMGwtMS4wNiwxLjA2TDE4Ljg1LDEwLjM5eiBNMTMuMTksNy41NmwtOS4wNSw5LjA1QzQuMDUsMTYuNyw0LDE2LjgzLDQsMTYuOTZ2My41NEM0LDIwLjc4LDQuMjIsMjEsNC41LDIxaDMuNTRjMC4xMywwLDAuMjYtMC4wNSwwLjM1LTAuMTVsOS4wNS05LjA1IEwxMy4xOSw3LjU2eiBNMTksMTcuNWMwLDIuMTktMi41NCwzLjUtNSwzLjVjLTAuNTUsMC0xLTAuNDUtMS0xczAuNDUtMSwxLTFjMS41NCwwLDMtMC43MywzLTEuNWMwLTAuNDctMC40OC0wLjg3LTEuMjMtMS4yIGwxLjQ4LTEuNDhDMTguMzIsMTUuNDUsMTksMTYuMjksMTksMTcuNXogTTQuNTgsMTMuMzVDMy42MSwxMi43OSwzLDEyLjA2LDMsMTFjMC0xLjgsMS44OS0yLjYzLDMuNTYtMy4zNkM3LjU5LDcuMTgsOSw2LjU2LDksNiBjMC0wLjQxLTAuNzgtMS0yLTFDNS43NCw1LDUuMiw1LjYxLDUuMTcsNS42NEM0LjgyLDYuMDUsNC4xOSw2LjEsMy43Nyw1Ljc2QzMuMzYsNS40MiwzLjI4LDQuODEsMy42Miw0LjM4QzMuNzMsNC4yNCw0Ljc2LDMsNywzIGMyLjI0LDAsNCwxLjMyLDQsM2MwLDEuODctMS45MywyLjcyLTMuNjQsMy40N0M2LjQyLDkuODgsNSwxMC41LDUsMTFjMCwwLjMxLDAuNDMsMC42LDEuMDcsMC44Nkw0LjU4LDEzLjM1eiIvPjwvc3ZnPg==")
}

.header-profile-avatar {
    // -webkit-user-select: none;
    // -moz-user-select: none;
    // -ms-user-select: none;
    // user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--avatar-size);
    width: var(--avatar-size);
    // font-family: var(--inter-font-family);
    // font-size: 14px;
    // font-weight: 600;
    // overflow: hidden;
    // background-color: var(--bs-primary);
    // color: #fff;
    border-radius: 0%;
    background-color: transparent;
  
    // patch: avatar changes z-index on hover
    &>div {
        &:hover {
            z-index: initial !important;
        }
    }
}
.header-action-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: 10px;
    pointer-events: none;
    will-change: transform, opacity;
    transform: translateY(-10px);
    opacity: 0;
    transition: all 0.2s ease-out;
}
.header-action-profile:focus-within .header-action-dropdown {
    transform: translateY(0%);
    opacity: 1;
    pointer-events: all;
}
.header-profile-wrapper {
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid var(--bs-gray-200);
    border-radius: 10px;
    min-width: 280px;
    overflow: hidden;
}
.header-profile-info {
    padding: 20px;
    display: flex;
    flex-direction: column;
}
.header-profile-name {
    font-size: 14px;
    font-weight: 500;
}
.header-profile-email {
    font-size: 12px;
    margin-top: 5px;
    color: var(--bs-gray-800);
}
.header-profile-links {
    display: flex;
    flex-direction: column;
    padding: 6px 10px 10px 10px;
    border-top: 1px solid var(--bs-gray-200);
}
.header-profile-links-divider {
    width: 100%;
    height: 1px;
    background-color: var(--bs-gray-200);
    margin-top: 6px;
    margin-bottom: 6px;
}
.header-profile-link {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 10px;
    border-radius: 5px;
    color: inherit;
    text-decoration: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.header-profile-link:focus {
    outline: none;
}
.header-profile-link:hover {
    background-color: var(--bs-gray-100);
    text-decoration: none;
}
.header-profile-link:active {
    background-color: var(--bs-gray-300);
    transform: scale(0.975);
}
.header-profile-link svg {
    height: 20px;
    width: 20px;
}
.header-profile-link span {
    margin-left: 10px;
    color: var(--bs-black);
}

@media (max-width: 1000px) {
    .sidenav-desktop-only {
        display: none;
    }
    .sidenav-mobile-only {
        display: flex;
    }
    .header-logo-link {
        height: 28px;
    }
    header.master-header {
        padding: 0;
    }
    .master-header .header-wrapper {
        height: 60px;
    }
    .header-left-block {
        position: absolute;
        width: 100%;
    }
    .sidenav-switch {
        height: 45px;
        width: 45px;
    }
    .header-actions {
        position: absolute;
        right: 10px;
    }
    .icon-button {
        height: 45px;
        width: 45px;
    }
    .icon-button svg {
        opacity: 1;
    }
}

.sidenav {
    height: 100vh !important;
    width: var(--sidenav-width);
    min-width: var(--sidenav-width);
    background-color: var(--sidenav-background);
    overflow: hidden;
    z-index: 9;
    outline: none;
    transition: var(--sidenav-transition);
    
    @media print {
        display: none;
    }

    button {
        font-family: var(--inter-font-family);
    }

    .sidenav-wrapper {
        height: 100%;
        width: var(--sidenav-width);
        display: flex;
        flex-direction: column;

        .sidenav-content {
            height: 100%;
            overflow-y: scroll;
            margin-bottom: 10px;
            padding-top: var(--sidenav-padding-top);
            position: relative;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::after {
                content: "";
                width: 100%;
                height: 20px;
                display: flex;
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--sidenav-background));
                left: 0;
                bottom: 0;
                position: sticky;
            }

            &::-webkit-scrollbar {
                display: none;
            }

            a {
                text-decoration: none;
                color: inherit;
            }

            .sidenav-item-head {
                display: flex;
                font-size: 10px;
                letter-spacing: 0.1ch;
                text-transform: uppercase;
                font-weight: 600;
                padding: 8px 10px 4px 10px;
                color: var(--bs-gray-600);
                user-select: none;
            }

            .sidenav-item {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 14px;
                min-height: var(--sidenav-item-height);
                height: var(--sidenav-item-height);
                padding-left: 10px;
                padding-top: 10px;
                padding-bottom: 10px;
                line-height: 1.35;
                cursor: pointer;
                user-select: none;
                transition: transform 1s cubic-bezier(0, -0.01, 0, 1.31);
                border-radius: 10px;
                margin: 0 10px;

                &:hover,
                &:focus {
                    background-color: var(--bs-gray-100);
                }

                &:active {
                    transform: scale(0.965);
                }

                &.sidenav-item-active {
                    position: relative;

                    .sidenav-item-label {
                        color: #000;
                        font-weight: 500;
                    }

                    &::before {
                        position: absolute;
                        content: "";
                        left: 0;
                        height: 16px;
                        width: 4px;
                        border-radius: 10px;
                        background-color: var(--bs-primary);
                        transform-origin: left;
                        animation: sidenav-item-active-intro 1s cubic-bezier(0.5, 1, 0, 1.1);
                        z-index: 1;

                        @keyframes sidenav-item-active-intro {
                            from {
                                transform: scaleX(0);
                            }
                            to {
                                transform: scaleX(1);
                            }
                        }
                    }
                }

                &.sidenav-item-new-tab {
                    &:hover,
                    &:focus {
                        .sidenav-item-icon {
                            background-color: #eee;
                            border-radius: 500px;
                            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNMTggMTlINmMtLjU1IDAtMS0uNDUtMS0xVjZjMC0uNTUuNDUtMSAxLTFoNWMuNTUgMCAxLS40NSAxLTFzLS40NS0xLTEtMUg1Yy0xLjExIDAtMiAuOS0yIDJ2MTRjMCAxLjEuOSAyIDIgMmgxNGMxLjEgMCAyLS45IDItMnYtNmMwLS41NS0uNDUtMS0xLTFzLTEgLjQ1LTEgMXY1YzAgLjU1LS40NSAxLTEgMXpNMTQgNGMwIC41NS40NSAxIDEgMWgyLjU5bC05LjEzIDkuMTNjLS4zOS4zOS0uMzkgMS4wMiAwIDEuNDEuMzkuMzkgMS4wMi4zOSAxLjQxIDBMMTkgNi40MVY5YzAgLjU1LjQ1IDEgMSAxczEtLjQ1IDEtMVY0YzAtLjU1LS40NS0xLTEtMWgtNWMtLjU1IDAtMSAuNDUtMSAxeiIvPjwvc3ZnPg==");
                            background-size: contain;
                            background-position: center;
                            background-repeat: no-repeat;

                            img,
                            svg {
                                opacity: 0;
                            }
                        }
                    }
                }

                .sidenav-item-label {
                    cursor: pointer;
                    text-overflow: ellipsis;
                    overflow-x: hidden;
                    white-space: nowrap;
                    padding-right: 10px;
                    font-family: var(--inter-font-family);
                    color: var(--bs-gray-800);
                    font-size: 14px;
                    transition: opacity 0.1s cubic-bezier(0.2, 0, 0, 1);

                    &.sidenav-item-long-label {
                        --animation-duration: 15s;
                        will-change: transform;
                    }
                }

                &.sidenav-saved-bookmark {
                    overflow: hidden;

                    &:hover,
                    &:focus {
                        .sidenav-item-long-label {
                            --animation-duration: 15s;
                            overflow-x: visible;
                            position: relative;
                            animation: sidenav-item-long-label-anim var(--animation-duration) linear alternate infinite;

                            @keyframes sidenav-item-long-label-anim {
                                0%,
                                5% {
                                    transform: translateX(0);
                                }
                                90%,
                                100% {
                                    transform: translateX(calc(-100% + var(--sidenav-width) - 80px));
                                }
                            }
                        }
                    }
                }
            }

            .sidenav-item-icon {
                width: var(--sidenav-icon-size);
                height: var(--sidenav-icon-size);
                min-width: var(--sidenav-icon-size);
                min-height: var(--sidenav-icon-size);
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                will-change: transform;
                color: var(--bs-black);
                z-index: 1;

                svg,
                img {
                    width: var(--sidenav-icon-size);
                    height: var(--sidenav-icon-size);
                    object-fit: contain;
                }

                &:empty {
                    background-color: var(--bs-gray-400);
                    border-radius: 6px;
                }
            }

            .sidenav-group-switch-icon {
                position: absolute;
                right: 10px;
                width: var(--sidenav-icon-size);
                height: var(--sidenav-icon-size);
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0.35;
                transition: transform 0.2s cubic-bezier(0, 1, 0, 1);
                will-change: transform, opacity;
                color: var(--bs-black);
                display: none;
            }

            .sidenav-group-switch {
                position: relative;

                &:hover,
                &:focus {
                    &::after {
                        opacity: 0.75;
                    }
                }

                &:active {
                    &::after {
                        opacity: 1;
                    }
                }

                &::after {
                    content: "";
                    position: absolute;
                    right: 10px;
                    width: var(--sidenav-icon-size);
                    height: var(--sidenav-icon-size);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    opacity: 0.35;
                    transition: transform 0.2s cubic-bezier(0, 1, 0, 1);
                    will-change: transform, opacity;
                    color: var(--bs-black);
                    background-size: 95%;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMjQgMjRIMFYwaDI0djI0eiIgZmlsbD0ibm9uZSIgb3BhY2l0eT0iLjg3Ii8+PHBhdGggZD0iTTE1Ljg4IDkuMjlMMTIgMTMuMTcgOC4xMiA5LjI5Yy0uMzktLjM5LTEuMDItLjM5LTEuNDEgMC0uMzkuMzktLjM5IDEuMDIgMCAxLjQxbDQuNTkgNC41OWMuMzkuMzkgMS4wMi4zOSAxLjQxIDBsNC41OS00LjU5Yy4zOS0uMzkuMzktMS4wMiAwLTEuNDEtLjM5LS4zOC0xLjAzLS4zOS0xLjQyIDB6Ii8+PC9zdmc+);
                }

                &.sidenav-group-switch-expanded {
                    background-color: #fff;
                    margin: 0 4px;
                    margin-top: 5px;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    transition: margin 1s cubic-bezier(0, 1, 0, 1);

                    &::after {
                        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNMTggMTNINmMtLjU1IDAtMS0uNDUtMS0xcy40NS0xIDEtMWgxMmMuNTUgMCAxIC40NSAxIDFzLS40NSAxLTEgMXoiLz48L3N2Zz4=);
                    }

                    &:active {
                        transform: scale(1);
                    }
                }
            }

            .sidenav-group-switch-expanded + .sidenav-item-group {
                display: flex;
                animation: sidenav-item-group-intro 1s cubic-bezier(0, 1, 0, 1) forwards;
                animation-play-state: running;
                background-color: #fff;
                margin: 0 4px;
                margin-bottom: 5px;
                padding: 10px;
                padding-top: 0;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
                transition: margin 1s cubic-bezier(0, 1, 0, 1);

                .sidenav-item {
                    border-radius: 5px;
                    margin: 0;
                }
            }

            .sidenav-item-group {
                display: none;
                left: calc(var(--sidenav-icon-size) + 20px);
                flex-direction: column;
                overflow: hidden;
                will-change: transform, opacity;
                animation: none;
                animation-play-state: paused;

                @keyframes sidenav-item-group-intro {
                    from {
                        transform: translateY(-20px);
                        opacity: 0;
                    }
                    to {
                        transform: translateY(0);
                        opacity: 1;
                    }
                }

                .sidenav-item-group .sidenav-item {
                    padding-left: calc(var(--sidenav-icon-size) + 20px);
                }

                .sidenav-saved-bookmarks {
                    .sidenav-bookmarks-actions {
                        display: flex;
                        gap: 10px;
                        padding: 4px 0;

                        button {
                            width: 100%;
                            cursor: pointer;
                            display: flex;
                            padding: 6px 12px;
                            margin: 0;
                            align-items: center;
                            justify-content: center;
                            user-select: none;
                            font-size: 0.813rem;
                            font-weight: 500;
                            color: #fff;
                            border: none;
                            background-color: var(--bs-primary);
                            border-radius: 8px;

                            &.manage-bookmarks-btn {
                                background-color: var(--bs-gray-100);
                                color: #000;

                                &:active {
                                    background-color: var(--bs-gray-300);
                                }
                            }

                            &:focus-visible {
                                outline: none;
                                box-shadow: 0 0 0 2px #fff, 0 0 0 4px var(--bs-gray-400);
                            }

                            &:active {
                                transform: scale(0.975);
                            }

                            @media (max-width: 800px) {
                                padding: 12px;
                            }
                        }
                    }
                }

                .sidenav-saved-bookmarks:empty {
                    display: none;
                }
            }

            .sidenav-mobile-only {
                display: none;

                @media (max-width: 1000px) {
                    display: flex;
                }
            }
        }

        .sidenav-item-divider {
            height: 1px;
            width: 100%;
            background-color: var(--bs-gray-200);
        }

        .master-sidenav-footer {
            height: 50px;
            display: flex;
            flex-direction: column;

            .sidenav-footer-logo {
                width: 100%;
                height: 100%;
                padding: 10px 15px;
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;
                pointer-events: none;
                user-select: none;
            }

            .sidenav-footer-logo-full {
                height: 30px;
                width: auto;
                max-width: -webkit-fit-content;
                max-width: -moz-fit-content;
                max-width: fit-content;
                margin-bottom: 10px;
                pointer-events: none;
                user-select: none;
            }
        }
    }

    &:not(.sidenav-collapsed) {
        .sidenav-footer-logo-symbol {
            display: none;
        }
        .sidenav-item-label {
            opacity: 1;
        }
    }

    &.sidenav-collapsed {
        .sidenav-footer-logo {
            width: var(--sidenav-collapsed-width);
            padding: 10px 0;
            justify-content: center;
        }

        .sidenav-footer-logo-full {
            display: none;
        }

        .sidenav-footer-logo-symbol {
            display: block;
            width: calc(var(--sidenav-item-height) - 15px);
            height: calc(var(--sidenav-item-height) - 15px);
            opacity: 0.5;
        }
        .sidenav-item-label {
            opacity: 0;
        }
    }

    &.sidenav-collapsed:not(:hover) {
        --sidenav-transition: var(--sidenav-collapsed-transition);
        width: var(--sidenav-collapsed-width);
        min-width: var(--sidenav-collapsed-width);
        transition: var(--sidenav-transition);

        .sidenav-item-head {
            display: none;
        }

        .sidenav-item-label {
            opacity: 0;
        }
    }

    &.sidenav-collapsed:hover {
        .sidenav-footer-logo-full {
            display: block;
        }

        .sidenav-footer-logo-symbol {
            display: none;
        }

        .sidenav-item-label {
            opacity: 1;
        }
    }
}

.sidenav-mobile-only {
    display: none;

    @media (max-width: 1000px) {
        display: flex;
    }
}

.sidenav-bookmarks-manager {
    position: fixed;
    left: 50vw;
    top: 50vh;
    display: flex;
    flex-direction: column;
    margin-left: calc(var(--sidenav-icon-size) + 20px);
    padding-right: 10px;
    will-change: transform, opacity;
    padding-bottom: 10px;
    border: solid;
}

@media (max-width: 1000px) {
    .sidenav {
        --sidenav-width: 100%;
        --sidenav-collapsed-width: 0;
        --sidenav-item-height: 45px;
    }
}

.lift-watcher {
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    pointer-events: none;
    user-select: none;

    @media print {
        display: none;
    }
}

.lift-btn-container {
    position: fixed;
    z-index: 8;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding-bottom: 20px;
    transition: transform 0.8s cubic-bezier(0, -0.01, 0, 1.1);
    will-change: transform;

    @media print {
        display: none;
    }

    &.top-floor {
        transition: transform 0.2s ease-in-out;
        transform: translateY(100%) scale(0.5);
        pointer-events: none;
    }

    .lift-btn,
    .hide-lift-btn {
        padding: 6px 12px;
        cursor: pointer;
        user-select: none;
        background-color: #fff;
        border-radius: 500px;
        border: none;
        border: 1px solid var(--bs-gray-100);
        box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
            inset 0 0 0 1px rgba(255, 255, 255, 0.5);
        font-size: 14px;
        transition: transform 1s cubic-bezier(0, -0.01, 0, 1.1);
        will-change: transform;
        position: relative;
        display: flex;
        align-items: center;
        color: #000;

        &::before {
            content: "";
            --size: 24px;
            height: var(--size);
            width: var(--size);
            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDI0IDI0IiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCI+PGc+PHJlY3QgZmlsbD0ibm9uZSIgaGVpZ2h0PSIyNCIgd2lkdGg9IjI0Ii8+PHJlY3QgZmlsbD0ibm9uZSIgaGVpZ2h0PSIyNCIgd2lkdGg9IjI0Ii8+PC9nPjxnPjxnPjxwYXRoIGQ9Ik02LjcsMTguMjlMNi43LDE4LjI5YzAuMzksMC4zOSwxLjAyLDAuMzksMS40MSwwTDEyLDE0LjQybDMuODgsMy44OGMwLjM5LDAuMzksMS4wMiwwLjM5LDEuNDEsMGwwLDAgYzAuMzktMC4zOSwwLjM5LTEuMDIsMC0xLjQxbC00LjU5LTQuNTljLTAuMzktMC4zOS0xLjAyLTAuMzktMS40MSwwTDYuNywxNi44OEM2LjMxLDE3LjI3LDYuMzEsMTcuOSw2LjcsMTguMjl6Ii8+PHBhdGggZD0iTTYuNywxMS43TDYuNywxMS43YzAuMzksMC4zOSwxLjAyLDAuMzksMS40MSwwTDEyLDcuODNsMy44OCwzLjg4YzAuMzksMC4zOSwxLjAyLDAuMzksMS40MSwwbDAsMCBjMC4zOS0wLjM5LDAuMzktMS4wMiwwLTEuNDFsLTQuNTktNC41OWMtMC4zOS0wLjM5LTEuMDItMC4zOS0xLjQxLDBMNi43LDEwLjI5QzYuMzEsMTAuNjgsNi4zMSwxMS4zMSw2LjcsMTEuN3oiLz48L2c+PC9nPjwvc3ZnPg==);
            margin-right: 4px;
            background-position: center;
            background-repeat: no-repeat;
        }

        &:hover {
            transform: scale(1.05);
        }

        &:active {
            transform: scale(0.9);
        }
    }

    .hide-lift-btn {
        font-size: 0;
        padding: 6px;

        &::before {
            margin-right: 0;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNMTguMyA1LjcxYy0uMzktLjM5LTEuMDItLjM5LTEuNDEgMEwxMiAxMC41OSA3LjExIDUuN2MtLjM5LS4zOS0xLjAyLS4zOS0xLjQxIDAtLjM5LjM5LS4zOSAxLjAyIDAgMS40MUwxMC41OSAxMiA1LjcgMTYuODljLS4zOS4zOS0uMzkgMS4wMiAwIDEuNDEuMzkuMzkgMS4wMi4zOSAxLjQxIDBMMTIgMTMuNDFsNC44OSA0Ljg5Yy4zOS4zOSAxLjAyLjM5IDEuNDEgMCAuMzktLjM5LjM5LTEuMDIgMC0xLjQxTDEzLjQxIDEybDQuODktNC44OWMuMzgtLjM4LjM4LTEuMDIgMC0xLjR6Ii8+PC9zdmc+);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 90%;
            opacity: 0.5;
        }

        &:hover {
            &::before {
                opacity: 1;
            }
        }
    }
}
