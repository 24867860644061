.privacy-settings {
  padding-top: 94px;
  position: relative;
  .privacy-container {
    display: flex !important;
    .back-button {
      .back-button-func {
        background-color: transparent;
        width: 60px;
        height: 60px;
        border-radius: 20px;
        background-color: #f1f1f1;
        border: none;
        position: absolute;
        left: -90px;
      }
    }
    .heading-text {
      margin-left: 100px;
      font-weight: bolder;
      color: #000000
    }
  }
  .inbox-lists-tabs {
    margin: 0;
    display: block !important;
    cursor: pointer;
  }

  .ws-select-general{
    line-height: 1.5 !important;
    background-color: #F1F1F1 !important;
    width: 100%;
    max-width: 420px !important;
    height: 56px !important;
    border-radius: 15px !important;
    margin-top: 10px;
    border: 2px solid #000000 !important;
    font-size: 16px !important;
    font-family: "Inter", Medium;

    option {
      font-size: 1rem;
    }
  }
  .privacy-tab {
    margin: 40px 0px 0px 0;
    width: 26.25rem;

    .tag-divider {
      margin-right: 80px;
      color: rgb(138, 137, 137);
    }
    .show-costs {
      margin-top: 30px;
      font-size: 1.25rem;
      font-weight: 500;
      color:#000000;
    }
    .set-privacy {
      display: flex;
      width:31.25rem;
    }
    .const-price {
      width: 31.25rem;
      font-size: 1rem;
      color: #000000;
    }
    .privacy-switch {
      cursor: pointer;
      margin: -25px 0px 100px 20px;
      border-radius: 100%;
    }
  }
  .card-header-tabs{
    margin: 50px 0 -1rem 0;
    a.nav-link{
      font-size: 1rem;
      color: #999999 !important;
      &:hover{
        color: #999999 !important;
      }
    }
    a.active{
      color: #000 !important;
      &:hover{
        color: #000 !important;
      }
    }
  }
}

.return-cart{
  padding-top: 70px;
}
.return-cart-no-padding{
  padding-top: 0px;
}

.btn.btn-save-warehouse {
  background-color: #000000;
  border-radius: 0.9375rem;
  display: flex;
  justify-content: center;
  width: fit-content;
  height: 2.75rem;
  margin-top: 1.875rem;
  align-items: center;
  font-family: "inter";
  line-height: 1.3125rem;
  font-size: 1rem;
  color: #ffffff;
  border: none;
  &:hover{
    color: #ffffff;
  }
}

.ws-heading-text {
  font-size: 1.25rem;
  color: #000;
  margin: 20px 0px;
  font-weight: 600;
}
.ws-sub-text{
  font-size: 1rem;
  font-weight: 500;
}
.ws-heading-text, .ws-sub-text {
  color: #000;
}

.form-group {
  margin-bottom:unset;
}

.ws-note-text{
  font-size: 12px;
}

.setting-general-select{
  select.ws-select-general{
    background-image: url(../../springboard/images/icons/select-up-down-arrow.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    background-size: 25px;
    border: 1px solid transparent !important;
    border-radius: 20px !important;
  }
}

.sales-history-no-data-found {
  display: flex;
  justify-content: center;
  padding: 0.938rem 0;
}