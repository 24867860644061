@import "../pos/assets/css/variable.scss";

.cart-container {
  padding-top: 4.563rem !important;
}

.add-product-main-container {
  padding-top: 1.25rem;
  background-color: $white-color;

  .main-product-page {
    overflow: unset;
  }

  .cart-items-header {
    z-index: 1;
    background: white;
    grid-column: 1 / -1;
    padding-top: 1.25rem;
    z-index: 11;
    max-width: 95rem;
    width: 80%;
    justify-self: center;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  // May need
  // .customer-search-results{  
  //   margin-top: 3.5rem !important;
  // }

  .search-title-wrapper {
    max-width: 95rem;
    width: 80%;
    justify-self: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  #cart-wrapper {
    width: 100%;
    margin-bottom: 3.125rem;

    .MuiTableContainer-root {
      height: 36.375rem;
    }
  }

  .info-header-add-product-container {
    position: fixed;
    width: 83%;
    z-index: 2;
    background: white;
  }

  .search-new-product-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .import-and-product-btn-div {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-width: 22rem;
      gap: 0.625rem;

      // margin-top: 0.125rem;  might need later
      .import-quote-btn {
        padding: 0;
        width: 10.7rem;
        height: 2.75rem;

        &:focus-visible {
          outline: 0.13rem solid var(--bs-gray-600) !important;
        }

        .MuiButton-label {
          margin-left: unset;
          padding: 0.8rem 1.2rem;
        }
      }

      .new-product-btn {
        width: 10.65rem;
        height: 2.75rem;
        border-radius: 0.625rem;
        padding: 0;

        .MuiButton-label {
          margin-left: unset;
          padding: 0.8rem 1.2rem;
        }

        img {
          height: 1.063rem;
        }

        &:focus-visible {
          outline: 0.13rem solid var(--bs-gray-600) !important;
        }
      }

      button {
        span {
          font-size: 1rem;
        }
      }
    }
  }

  .clear-cart-button {
    width: 6.55rem;
    min-width: 6.55rem;
    max-width: 6.55rem;
    height: 2rem;

    .button-label {
      font-size: 1rem;
      font-weight: bold;
      text-transform: none;
    }
  }

  .clear-cart-button-focus {
    &:focus-visible {
      outline: none;
      box-shadow: 0 0 0 2px var(--bs-white), 0 0 0 4px var(--bs-gray-600);
    }
  }

  .search-product-suggestion-container {
    .hint {
      margin-top: 1.6rem !important;
    }

    .divider-main-div {
      margin-top: 1.2rem;

      .import-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }

      .import-quote-btn {
        width: 10.7rem;
        min-width: 10.7rem;
        max-width: 10.7rem;
        height: 2.75rem;
        margin-top: 1.5rem;
        padding: 0.75rem 0.938rem !important;
      }
    }
  }

  .scroll-element {
    @media (min-width: 1200px) {
      overflow: scroll;
      height: 100%;
      padding-bottom: 20vh !important;
    }
  }

  .fixed-cart-pos-header {
    grid-column: 1 / -1;
    position: relative;
    padding: 0;

    @media (min-width: 1200px) {
      position: fixed;
    }


    max-width: 95rem;
    width: 100%;

    @media only screen and (min-width: 1200px) {
      width: calc(80% - 3.125rem);
    }

    justify-self: center;
    z-index: 100;
    background-color: $white-color;
  }

  .cart-setting-icon {
    position: fixed;
    top: 3.75rem;
    right: 1.5rem;
    z-index: 11;
    background-color: $white-color;
    width: 100%;
  }


  .search-empty-icon-div {
    margin-top: 3.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .search-text {
      font-size: 1.125rem;
      opacity: 1;
      color: $black-color;
    }

    .import-quote-btn {
      width: 10.7rem;
      height: 2.75rem;
      margin-top: 1.5rem;
      padding: 0.75rem 0.938rem !important;
    }
  }
}

.add-product-form {
  .location-button {
    padding: 1.25rem !important;
    font-size: 1.125rem !important;
  }
}

.cart-page-loader {
  position: fixed;
  top: 0;
  width: 100%;
}

.filter-container {
  margin-top: 0.75rem;

  .product-list-select-dropdown {
    .MuiInputBase-root {
      .MuiSelect-select {
        &:focus-visible {
          outline: 0.13rem solid var(--bs-gray-600) !important;
          border-radius: 0.625rem;
        }
      }
    }
  }
}

.searchHeader-fix-top {
  top: 19rem !important;
  padding: 0rem 1.5rem !important;
}

.searchHeader-fix-top-focus {
  top: 6.75rem !important;
}

.search-table-header {
  top: 22.375rem !important;
}

.suggetion-focus:focus-visible {
  outline: 0.13rem solid var(--bs-gray-600) !important;
}