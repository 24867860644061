.icon-button-primary {
    --size: 40px;
    height: calc(var(--size) * 1px);
    width: calc(var(--size) * 1px);
    background-color: var(--bs-gray-200);
    border: none;
    border-radius: 500px;
    font-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 1s cubic-bezier(0, -0.01, 0, 1.31);
    will-change: transform;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    color: #000;

    &:not(:disabled) {
        cursor: pointer;
    }

    &:disabled {
        opacity: 0.35;
        cursor: default;
    }

    &:hover:not(:disabled) {
        background-color: var(--bs-gray-300);
    }

    &:focus-visible:not(:disabled) {
        outline: none;
        box-shadow: 0 0 0 2px #fff, 0 0 0 4px var(--bs-gray-800);
    }

    &:active:not(:disabled) {
        transform: scale(0.9);
        background-color: var(--bs-gray-400);
    }

    &.icon-button-secondary:not(:disabled) {
        opacity: 0.75;
        background-color: rgba(255, 255, 255, 0);

        &:hover:not(:disabled),
        &:focus-visible:not(:disabled) {
            background-color: var(--bs-gray-200);
            opacity: 1;
        }

        &:active:not(:disabled) {
            background-color: var(--bs-gray-400);
        }
    }

    .icon {
        overflow: hidden;
    }
}
