@import "src/apps/pos/assets/css/variable.scss";

.attach-document-modal {
    .MuiPaper-root {
        position: relative;
        height: 45rem;
        width: 50rem;
        border-radius: 1.25rem;
    }

    .attach-document-close-icon {
        position: absolute;
        right: 0.6rem;
        top: 0.6rem;
        background: transparent;
        border: 0;
        padding: 0;
    }

    .MuiDialogContent-root {
        padding: 1.875rem !important;

        .attach-document-title {
            font-size: 1.5rem;
            font-weight: 700;
        }

        .attach-document-list {
            display: flex;
            justify-content: space-between;
            border-bottom: 0.063rem solid $bright-Gray;
            padding: 0.938rem 0.938rem;
            align-items: center;

            &:hover {
                background-color: $bright-Gray;
                border-radius: 0.625rem;
            }

            .attach-document-data {
                .attach-document-name {
                    font-size: 1.125rem;
                    font-weight: 500;
                }

                .attach-document-type {
                    display: flex;
                    align-items: center;

                    .attach-document-type-info,
                    .attach-document-status {
                        font-size: 0.625rem;
                        font-weight: 400;
                    }

                    .small-dot-type{
                        height: 0.25rem;
                        width: 0.25rem;
                        background-color: #AAAAAA;
                        border-radius: 50%;
                        display: inline-block;
                        margin-left: 0.625rem;
                        margin-right: 0.625rem;
                    }

                }
            }

            .attach-document-remove-link {
                border: 0;
                border-bottom: 0.063rem solid $black-color;
                font-weight: 600;
                cursor: pointer;
                &:focus-visible{
                    outline: 0.13rem solid var(--bs-gray-600) !important;
                    outline-offset: 0.125rem;
                }
                background:transparent;
                padding: 0;
            }
        }
    }
}

.documents-added-title-sec {
    align-items: center;
    margin-bottom: 0.625rem;

    .documents-added-title {
        font-weight: 500;
    }
}

.add-doc-btn{
    min-width: 12rem;
    max-width: 12rem;
    width: 12rem;
    height: 2.75rem;
}