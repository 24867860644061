.signature-pad-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  width: 100%;

  &::after {
    content: "";
    position: absolute;
    width: calc(70% + 12px);
    height: 2px;
    background-color: #aaa;
    bottom: calc(60px - 9px);
    border-radius: 500px;
    mix-blend-mode: darken;
  }

  canvas.signature-pad {
    width: 100% !important;
    height: 200px !important;
    background: radial-gradient(#ddd 1px, #fff 1px);
    background-size: 8px 8px;
    background-position: center;
    cursor: crosshair;
  }
}

.signature-pad {
  background: radial-gradient(#ddd 1px, #fff 1px);
  background-size: 8px 8px;
  background-position: center;
  cursor: crosshair;
}

.signature-btn {
  display: none;
}

.signature-dialog {
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  opacity: 1;
  transform: translateY(-25%);
  animation: dialog-intro .2s cubic-bezier(0, -.01, 0, 1.31);
  background-color: white;
  border-radius: 1.5rem;
  padding: 30px;
  width: 90%;
  max-width: 600px;
  border: none;

  @media (min-width: 768px) {
    width: 600px;
  }
}

@keyframes dialog-intro {
  0% {
    opacity: 0;
    transform: scale(0.975);
  }
}

.signature-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 !important;
}

.signature-button-container {
  display: flex;
  align-items: start;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  border: none;
}

.signature-button {
  width: 100%;
  max-width: 18.75rem;
  min-width: 18.75rem;
  min-height: 7.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #4b5563;
  border-radius: 0.5rem;
  transition: colors 0.2s;

  &:hover {
    color: #1f2937;
    border-color: #9ca3af;
  }

  &.has-signature {
    border: 1px solid black;
    border-radius: 0;
    background-color: white;
  }

  &:not(.has-signature) {
    border: 1px solid transparent;
    background-image:
      linear-gradient(white, white),
      repeating-linear-gradient(-45deg,
        black 0,
        black 12.5%,
        transparent 0,
        transparent 25%,
        black 0,
        black 37.5%,
        transparent 0,
        transparent 50%);
    background-clip: padding-box, border-box;
    background-size: 100% 100%, 1em 1em;
    animation: marching-ants 15s linear infinite;
  }
}

.signature-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  &:not(:has(img)) {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: black;

    p {
      font-size: 1rem;
      margin: 0;
    }
  }

  img {
    max-height: 80px;
    margin: 0 auto;
    user-select: none;
    pointer-events: none;
    max-width: 100%;
    object-fit: contain;
  }

  p {
    font-size: 0.875rem;
    color: black;
    text-align: center;
    font-weight: 500;
  }
}

.clear-signature-btn {
  color: #6b7280;
  display: flex;
  align-items: center;
  gap: 0.125rem;
  padding: 0.25rem 0.5rem;
  width: auto;
  justify-content: center;
  margin-left: 7.313rem;
  border: none;
  background-color: white;

  &:hover {
    color: #dc2626;
  }

  p {
    font-size: 0.875rem;
    margin-bottom: 0;
  }

  @media print {
    display: none;
  }
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;

  .draw-signature {
    font-weight: 600;
    font-size: 1.25rem;
  }

  button {
    color: #6b7280;

    &:hover {
      color: #374151;
    }
  }
}

.dialog-actions {
  display: flex;
  padding-top: 1.5rem;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  border: none;

  .clear-btn,
  .save-btn {
    border: none;
  }

  .clear-btn {
    padding: 0.5rem 1rem;
    color: #6b7280;
    font-weight: 500;
    border-radius: 0.75rem;
    user-select: none;

    &:hover {
      color: #991b1b;
      background-color: #fef2f2;
    }
  }

  .save-btn {
    padding: 0.5rem 1rem;
    font-weight: 500;
    background-color: black;
    color: white;
    border-radius: 0.75rem;
    user-select: none;
  }
}

@keyframes marching-ants {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100% 100%;
  }
}

.signature-actions button span:nth-of-type(2) {
  /* Your styles here */
  display: flex;
}