@import "apps/pos/assets/css/variable.scss";

.update-product-container {
  .update-product-heading {
    margin-bottom: 15px;
    .heading-black-text {
      font-size: 1.25rem;
      letter-spacing: 0px;
    }
  }
  .update-product-decription {
    font-size: 1rem;
    margin-top: 0.125rem;
    letter-spacing: 0px;
    color: $black-color;
    opacity: 1;
  }
  .update-product-items{
    height: 306px;
    .update-product-changes {
      font-size: 0.625rem;
      text-transform: uppercase;
      color: $black-color;
      letter-spacing: 0.063rem;
      margin: 1.6rem 0rem 0.6rem 0.563rem;
    }
  }
  .update-product-list-container{
    border-radius: 0.625rem;
    overflow: auto;
    .update-product-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f9f9f9 0% 0% no-repeat padding-box;
    height: 2.5rem;
    padding: 0rem 0.875rem 0rem 0.438rem;
    img{
      width: 1.875rem;
      height: 1.875rem;    
      margin-right: 0.3rem;
    }
    .update-product-price-change{
      font-size: 0.875rem;
      color: $black-color;
    }
    .update-product-price-item{
      font-size: 0.875rem;
      color: $black-color;
      text-align: right;
      font-weight: 500;
    }
  }
  }
  .update-product-footer {
    display: flex;
    color: $black-color;
    font-size: 0.875rem;
    width: auto;   
    align-items: center;
    img{
      width: 1rem;
      height: 1rem;
    }
     .update-product-alert-icon{
        width:1rem;
        height: 1rem;
        margin: 0.125rem 0.313rem 0rem 0rem;
        opacity: 1;
    }
  }
  .update-product-btn {
    margin-top: 0.938rem;
    display: flex;
    justify-content: space-between;
    .update-skip-odl-price {
      background-color: #f1f1f1;
      padding: 0.938rem;
      height: 2.75rem;
      width: 14rem;
      border-radius: 0.938rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-weight: 600;
    }
    .btn-review-item{
         width: 8.625rem;
         border-radius: 0.938rem;
         height: 2.75rem;
         font-size: 0.875rem;
    }
  }
}
.update-product-container-line{
  margin: 0;
  background: #DDDDDD 0% 0% no-repeat padding-box;
  opacity: 1;
}
.review-item-modal-btn{
  width:     8.625rem;
  min-width: 8.625rem;
  max-width: 8.625rem;
  height: 2.75rem;
}