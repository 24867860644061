.return-to-line-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0.625rem;

  @media only screen and (min-width: 600px) {
    background-color: var(--bs-gray-100);
    display: grid;
    grid-template-columns: 12.5rem repeat(2, 1fr) 3.125rem;
    gap: 0.063rem;
    align-items: center;

    &.activeFlag-return {
      grid-template-columns: 12.5rem auto 3.125rem;
    }
  }

  .return-line-delete-button {
    opacity: 0.5;

    &:hover,
    &:focus-visible {
      opacity: 1;
    }
  }

  .location-button {
    border: none;
    font-size: 0.875rem;
    font-family: var(--inter-font-family);
    cursor: pointer;
    width: 12.5rem;
    padding: 0.75rem;
    padding-right: 0.313rem;
    border-radius: 0.625rem;
    background-color: var(--bs-gray-100);
    will-change: transform;
    transition: all 1s cubic-bezier(0, -0.01, 0, 1.31), box-shadow 0.1s ease-out;
    text-align: start;
    display: flex;
    justify-content: space-between;
    align-items: center;
    outline: none;

    &.location-button-active {
      animation: location-button-shimmer 1s ease-out alternate infinite;

      @keyframes location-button-shimmer {
        to {
          background-color: var(--bs-gray-300);
        }
      }
    }

    .location-button-label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 1.25;
    }

    &:hover,
    &:focus-visible {
      background-color: var(--bs-gray-300);
    }

    &:active {
      background-color: var(--bs-gray-400);
      transform: scale(0.975);
    }

    &:focus-visible {
      background-color: #fff;
      box-shadow: inset 0 0 0 0.125rem#000;
    }
  }

  .qty-return-group.return-to-input-group {
    @media only screen and (min-width: 600px) {
      &::before {
        content: "";
        position: absolute;
        left: -0.063rem;
        height: 1.5rem;
        width: 0.063rem;
        background-color: var(--bs-gray-500);
      }
    }
  }

  .return-to-input-group {
    position: relative;
    display: flex;
    align-items: center;

    &:first-of-type {
      &::before {
        display: none;
      }
    }

    &:last-of-type {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .return-qty-input {
    width: 100%;
    border: none;
    padding: 0.75rem 0.938rem;
    font-size: 0.875rem;
    font-family: var(--inter-font-family);
    border-radius: 0.625rem;
    outline: none;
    background-color: var(--bs-gray-100);
    transition: all 0.1s ease-out;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:focus-visible {
      background-color: #fff;
      box-shadow: inset 0 0 0 0.063rem #000;
    }
  }
}

.disable-return-replace {
  grid-template-columns: unset;

  .location-button {
    width: 100%;
  }
}

.return-to-responsive-title {
  display: block;

  @media only screen and (min-width: 600px) {
    display: none;
  }
}

.return-to-input-group-sec {
  display: flex;
  width: 100%;
  margin-bottom: 0.625rem;

  @media only screen and (min-width: 600px) {
    margin-bottom: 0;
  }

  .return-to-responsive-title {
    width: 100%;
    max-width: 9.375rem;
  }

  .return-to-input-group {
    width: 100%;

    button {
      width: 100%;
    }
  }
}

.return-to-line-item {
  padding-right: 2.5rem;
  position: relative;
  border-bottom: 0.063rem solid var(--bs-gray-200);
  margin-bottom: 0.5rem;

  @media only screen and (min-width: 600px) {
    padding-right: 0;
    border-bottom: none;
    margin-bottom: 0;
  }

  .return-to-delete-icon {
    position: absolute;
    right: 0;

    @media only screen and (min-width: 600px) {
      position: relative;
      padding-right: 0;
      right: unset;
    }
  }
}

.location-button-pointer-not-allowed {
  cursor: not-allowed !important;
}

.return-to-line-overship-item {
  grid-template-columns: 12.5rem repeat(1, 1fr) 3.125rem;
}