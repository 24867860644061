@import "../pos/assets/css/variable.scss";

.keep-draft-btn {
  width: 60%;
  max-width: 25rem;
}
.delet-draft-btn {
  width: 40%;
  max-width: 15.625rem;
}
.update-product-dialog-box{
  .MuiDialogTitle-root{
    padding: 0;
  }
  .MuiDialogContent-root{
    padding: 1.875rem;
  }
  .MuiDialog-paper{
    width: 34.375rem;
    height:35rem;
    border-radius: 1.25rem;
  }
}

.draft-badge {
  border: 0.125rem solid $dusty-gray-color ;
  height: 0.25rem;
  width: 0.25rem;
  border-radius: 1.25rem;
  margin: 0 0.625rem;
}

.sort-by-sec {
  width: 100%;
}

.sort-by-focus{
  :focus{
    filter: brightness(.8) contrast(1.1);
  }
}
.delete-all-draft-btn-sec {
  width: 100%;
  margin-top: 1.25rem;
  .delete-all-draft-btn.MuiButtonBase-root {
    justify-content: flex-start;
    margin: 0 !important;
    padding: 0 !important;
  }
}
@media only screen and (min-width: 768px) {
  .sort-by-sec {
    width: 50%;
  }
  .delete-all-draft-btn-sec {
    width: 50%;
    margin-top: 0;
    display: flex;
    justify-content: end;
    .delete-all-draft-btn.MuiButtonBase-root {
      justify-content: flex-end;
    }
  }
}


.select-list-data {
  margin-right: 0;
  @media only screen and (min-width: 600px) {
    margin-right: 5.625rem;
  }
}

.button-focus-resume-draft{
   &:focus-visible {
        border-radius: 1.25rem !important;
         outline: 0.13rem solid var(--bs-gray-600) !important;
      }
}

.trash-button-focus{
      &:focus-visible {
        border-radius: 1.563rem !important;
        outline: 0.13rem solid var(--bs-gray-600) !important;
        .trash-icon{
          opacity: 1;
          background-color: #EEEEEE;
          padding: 5px;
          border-radius: 50%;
        }
      }
    }
.disable-delete-draft {
  pointer-events: none;
  opacity: 0.7;
}
