.info-block-sb {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  justify-content: flex-end;

  p {
    &.info-block-label {
        text-transform: uppercase;
        font-size: 0.625rem;
        letter-spacing: 0.0625rem;
        color: #aaaaaa;
        line-height: 1;
        text-align: end;
    }

    &.info-block-value {
      font-size: 0.875rem;
      letter-spacing: 0.1px;
      line-height: 1.1;
      display: flex;
      justify-content: flex-end;
      white-space: nowrap;
    }
  }
  pointer-events: none;
}
