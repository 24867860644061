.item-group-wrapper {
  display: flex;
  flex-direction: column;
  gap: 3.75rem;
  padding-bottom: 20rem;
  font-family: "Inter", sans-serif;
  font-feature-settings: "cv01", "cv02", "cv03", "cv04", "cv05", "cv06", "cv08",
    "cv10", "cv11", "cpsp", "calt", "ccmp", "locl", "kern", "tnum", "salt";
    
  p, h3 {
    margin: 0;
  }
}
