@import "apps/pos/assets/css/variable.scss";

.customer-storage-sub-info-container.customer-info-container {
  .customer-storage-sub-tab-header.customer-tab-header {
    width: fit-content;
    .MuiTabs-flexContainer {
      display: flex !important;
      align-items: center;
      justify-content: center;
      padding: 0.188rem;
      background-color: $bright-Gray;
      border-radius: 1.563rem;
      .Mui-selected {
        border-radius: inherit;
        background-color: $white-color;
        font-weight: 600;
      }
      button {
        min-width: 6.25rem;
        border-radius: inherit;
      }
    }
    .MuiTabs-indicator {
        display: none;
    }
  }
}
.storage-info-table {
  .customer-info-table-container {
    overflow-y: scroll;
  }
}