.characteristics-main-info {
    position: relative;
    margin-top: 0.625rem;
  .characteristics-main-info-sec {
    background-color: #f1f1f1;
    display: flex;
    border-radius: 0.625rem;
    padding: 0.938rem;
    width: calc(100% - 40px);
    .remove-characteristics-icon {
      position: absolute;
      right: 0;
    }
  }
}
