@import "apps/pos/assets/css/variable.scss";

$spacer: 0.625rem;

.justify-content-between {
  justify-content: space-between;
}

.main-title-section {
    margin-top: 2.5rem;
    margin-bottom: $spacer;
    padding-right: $spacer;
    .sec-main-title {
      font-size: 1.375rem;
      font-weight: 600;
    }
    .up-downb-arrow img {
      opacity: 0.8;
      width: 0.75rem;
    }
}

.group-wrapper {
  grid-column: 5 / 16;
  display: flex;
  flex-direction: column;
  .group-content {
    padding-top: $spacer;
    .schedules-empty-state {
      color: $description-color;
      font-size: 0.875rem;
      padding-top: $spacer;
    }
  }
}
.boolean-span {
  gap: 0.375rem;
}

  .quote-metadata-section{
    .quote-metadata-container {
      .report-div {
        position: relative;
        padding: $spacer 4.2rem $spacer 0.438rem;
        &:nth-child(even) {
          background: $gray-50;
        }
        .star-icon-report {
          opacity: 0;
          margin-right: 0.313rem;
          background-color: unset;
          .star-icon {
            width: 0.938rem;
            height: 0.938rem;
          }
          &:focus-visible {
            opacity: 1;
            background-color: $bgColor;
          }
          &:hover {
            background-color: $bgColor;
            .star-icon {
              fill: $black-color;
            }
          }
          .star-icon-starred {
            width: 0.938rem;
            height: 0.938rem;
            fill: $black-color;
          }
        }
        .edit-hover-btn {
          opacity: 0;
          position: absolute;
          right: $spacer;
        }
        &:hover {
          .star-icon-report {
            opacity: 1;
            color: $gray;
            margin-right: 0.313rem;
            
          }
          .edit-hover-btn {
            opacity: 1;
          }
        }
      }
      .MuiGrid-item{
        &:first-of-type{
          padding-left: 1.875rem;
          .star-icon-report{
            position: absolute;
            left: 0;
            top: 0.375rem;
          }
          .small-text{
            span{
              margin-left: 0;
            }
          }
        }
      }
    }
  }