.app-loader{
    position: absolute;
    width: 100%;
    .MuiLinearProgress-root{
        background-color: unset !important;
        z-index: 1301 !important;
    }
    .MuiLinearProgress-barColorPrimary{
        background-color: black;
    }
}