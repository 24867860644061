.location-picker-wrapper {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 400px;
    border-radius: 20px;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid var(--bs-gray-200);
    overflow: hidden;
    background-color: #fff;
    z-index: 11;
    position: fixed;
    top: 5vh;

    .location-picker-header {
        padding: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid var(--bs-gray-200);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 10px;
        height: 120px;

        .location-picker-header-title {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            gap: 10px;

            .location-picker-search-wrapper {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                .location-picker-search-icon {
                    position: absolute;
                    left: 8px;
                    color: var(--bs-gray-600);
                    pointer-events: none;
                }

                .location-picker-search {
                    border: none;
                    width: 100%;
                    font-size: 14px;
                    padding: 8px 30px;
                    padding-right: 40px;
                    background-color: var(--bs-gray-100);
                    border-radius: 5px;
                    outline: none;
                }

                .location-search-clear-btn {
                    position: absolute;
                    right: 0;
                }
            }
        }

        .selection-title {
            font-family: var(--inter-font-family);
            font-size: 18px;
            font-weight: 500;
            line-height: 1;
            width: 100%;
            text-transform: capitalize;
        }

        .location-back-btn {
            user-select: none;
            display: flex;
            align-items: center;
            cursor: pointer;
            border: none;
            background-color: #fff;
            color: var(--bs-gray-500);
            font-size: 14px;
            gap: 4px;
            line-height: 1;
            max-width: fit-content;
            padding: 0;
            margin: 0;
            outline: none;
            border-radius: 5px;
            will-change: transform;
            transition: all 1s cubic-bezier(0, -0.01, 0, 1.31), box-shadow 0s;

            &:hover,
            &:focus-visible {
                color: #000;
            }

            &:active {
                transform: scale(0.95);
            }

            &:focus-visible {
                box-shadow: 0 0 0 2px #000;
            }
        }
    }

    .location-picker-list {
        max-height: 65vh;
        overflow-y: scroll;
        padding-bottom: 20px;
        overscroll-behavior: contain;

        .location-picker-error-message {
            padding: 20px;
            padding-bottom: 0;
            display: flex;
            line-height: 1.35;
            font-size: 14px;
            color: var(--bs-gray-600);
        }

        .list-header {
            position: sticky;
            top: 0;
            z-index: 1;
            display: flex;
            padding: 10px 40px;
            font-size: 10px;
            color: var(--bs-gray-600);
            letter-spacing: 1px;
            background-color: #fff;
            text-transform: uppercase;

            &:not(:first-of-type) {
                margin-top: 10px;
            }
        }
    }

    .location-picker-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 10px 8px 40px;
        cursor: pointer;
        width: 100%;
        border: none;
        border-bottom: 1px solid var(--bs-gray-100);
        background-color: #fff;
        position: relative;
        outline: none;
        user-select: none;
        will-change: transform;
        transition: all 1s cubic-bezier(0, -0.01, 0, 1.31), box-shadow 0s;

        .location-picker-item-label {
            font-size: 14px;
            font-family: var(--inter-font-family);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 1.25;
        }

        &:hover,
        &:focus-visible {
            background-color: var(--bs-gray-100);

            .arrow-icon {
                color: var(--bs-gray-500);
            }
        }

        &:focus-visible {
            box-shadow: inset 0 0 0 2px #000;
        }

        &:active {
            background-color: var(--bs-gray-300);

            .arrow-icon {
                transform: translateX(15%);
                color: #000;
            }
        }

        .checked-icon {
            left: 10px;
            position: absolute;
        }

        .arrow-icon {
            color: var(--bs-gray-300);
        }
    }
}

.location-picker-empty-state {
    padding: 20px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;

    & > div {
        height: 10px;
        width: 90%;
        border-radius: 500px;
        background-color: var(--bs-gray-50);
        animation: location-picker-loading-anim 0.6s alternate infinite;

        @keyframes location-picker-loading-anim {
            to {
                background-color: var(--bs-gray-200);
            }
        }

        &:nth-child(2) {
            width: 50%;
            animation-delay: 0.1s;
        }
    }
}
