.return-cart-search-input-container{
    flex-direction: column;
    background-color: transparent;
    @media only screen and (min-width: 900px) {
        background-color: #eeeeee;
        flex-direction: row;        
    }
    .return-cart-search-new-product-input{
        margin-top: 1.25rem;
        @media only screen and (min-width: 900px) {
            margin-top: 0;
        }
    }
}