@import "apps/pos/assets/css/variable.scss";

.adjust-stock-container {
    .adjust-stock-name {
      .heading-black-text {
        font-size: 1.25rem !important;
      }
      .adjust-stock-quanity {
        margin: 0 0.625rem;
        width: 100%;
        border: none !important;
        height: 3.125rem;
        font-size: 1.25rem;
        font-family: "Inter", Regular;
        background-color: #ffffff;
        font-weight: 500;
      }
    }
    // TODO:need this code 
    // .quantity-input {
    //   min-height: 3.125rem;
    //   border-radius: 0.625rem;
    //   border-color: #000;
    //   opacity: 1;
    //   padding: 0.5rem;
    //   width: 100%;
    //   input::-webkit-inner-spin-button {
    //     -webkit-appearance: none;
    //     margin: 0;
    //   }
    //   .MuiOutlinedInput-notchedOutline {
    //     border-color: #000000 !important;
    //   }
    //   input {
    //     padding: 0;
    //     height: auto;
    //     color: #000;
    //     font-weight: 500;
    //   }
    //   .input-btn {
    //     background: #DDDDDD 0% 0% no-repeat padding-box;
    //     display: flex;
    //     padding: 0.313rem 0 0.5rem;
    //     border-radius: 0.313rem;
    //     .btn-minus-value {
    //       padding: 0 0.813rem;
    //       border-right: 0.063rem solid #aaaaaa;
    //       border-radius: 0;
    //     }
    //     .btn-plus-value {
    //       padding: 0 0.813rem;
    //     }
    //     .btn-plus-value,
    //     .btn-minus-value {
    //       font-size: 1.75rem;
    //       color: #000;
    //       font-weight: 500;
    //       display: flex;
    //       align-items: center;
    //       justify-content: center;
    //       height: 1.25rem;
    //       &:hover {
    //         filter: unset !important;
    //       }
    //     }
    //   }
    //   fieldset {
    //     top: 0;
    //   }
    // }
    // .quantity-move {
    //   padding: 0.78125rem 0.875rem !important;
    // }
    .quantity-helper-text-sec {
      margin-bottom: 1.875rem;
      .quantity-helper-text {
        font-size: 0.75rem;
        color: #000;
        font-weight: 400;
      }
    }
    .quantity-location-sec {
      margin-bottom: 1.875rem;
    }
    .quantity-helper-location {
      font-size: 1rem;
      color: #000000;
      font-weight: 600;
    }
    .adjust-quantity-selector {
      display: flex;
      padding: 0.3125rem 0.625rem;
      border-radius: 0.625rem;
      background: #f1f1f1;
      .label-name {
        padding-left: 0.2rem;
        margin-bottom: unset;
      }
      .adjust-stock-select {
        background-color: transparent;
        border: 0.0625rem solid transparent;
        padding: 0.5rem 0;
        &:focus {
          border: 0.0625rem solid transparent;
        }
      }
      .adjust-form-control {
        width: 100%;
        width: 33.33%;
        border-right: 0.0625rem solid #aaaaaa;
        padding: 0 0.625rem;
        .adjust-stock-location-info-title {
          font-size: 0.625rem;
          color: #000;
          font-weight: 500;
          letter-spacing: 0.05rem;
        }
        &:last-child {
          border-right: unset !important;
        }
        .MuiInputBase-root {
          margin-top: 0;
          padding: 0.4375rem 0 0.25rem;
        }
        .MuiFormLabel-root {
          font-size: 0.625rem;
          color: #000;
          font-weight: 700;
          margin-bottom: 0.25rem;
          left: 0.625rem;
          letter-spacing: 0.05rem;
        }
        .MuiInput-underline {
          &::before,
          &::after {
            border-bottom: unset;
          }
          .MuiNativeSelect-root {
            padding: 0;
            .Mui-focused {
              background: unset;
            }
          }
          .MuiNativeSelect-select {
            &:focus {
              background-color: unset;
            }
          }
        }
        .MuiFormLabel-root.Mui-focused {
          color: unset;
        }
        .MuiSvgIcon-root.MuiNativeSelect-icon {
          color: #000;
          width: 1.25rem;
        }
      }
    }
    .adjust-reason-option {
      margin-top: 0.125rem;
      color: #000000;
      font-weight: bold;
      font-size: 0.9375rem;
      .optional {
        color: #aaaaaa;
      }
    }
    // TODO:need this code 
    // .input-reason-option {
    //   background: #F1F1F1 0% 0% no-repeat padding-box;
    //   width: 100%;
    //   outline: none;
    //   border: unset !important;
    //   border-radius: 0.625rem;
    //   padding: 0.625rem;
    // }
    .adjust-stock-footer {
      margin-top: 1.875rem;
      width: 92%;
      .stock-available-qty, .stock-adjust-qty{
        display: flex;
        justify-content: space-between;
        padding: 0.75rem 0;
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        padding-right: 0.375rem;
        align-items: center;
      }
      .stock-adjust-qty{
        padding-right: 0.375rem;
        border-bottom: 1px solid $border-color;
      }
    }
    .adjust-stock-cancel-btn {
      position: relative;
      bottom: unset;
      margin: 1.25rem 0;
      @media only screen and (min-width: 600px) {
        position: absolute;
        bottom: 0;
        margin: 0;
      }
      .cancel-btn {
        padding: 0.75rem 0;
      }
    }
    .adjust-stock-quantity {
      display: flex;
      gap: 0.9375rem;
      .adjust-current-quantity {
        width: 50%;
        border-top: 0.063rem solid #000;
        span {
          font: normal 0.625rem "Inter";
          letter-spacing: 0.063rem;
          color: #000000;
        }
      }
      .adjust-quanitity {
        width: 50%;
        border-top: 0.063rem solid #000;
        span {
          font: normal 0.625rem "Inter";
          letter-spacing: 0.063rem;
          color: #000000;
        }
      }
      .qty-value-current {
        color: #000000;
        font-size: 1.5rem;
        font-weight: 500;
      }
      .qty-value-adjust {
        color: #000000;
        font-size: 1.5rem;
        font-weight: 500;
      }
    }
    .adjust-stock-modal-btn {
      display: flex;
      justify-content: space-between;
      margin-top: 1.875rem;
      .adjust-stock-btn{
        padding: 0.625rem 1.3125rem !important;
        border-radius: 0.9375rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: 0;
        font-size: 1rem;
        font-weight: 500;
        letter-spacing: -0.02rem;
      }
      .cancel-btn {
        background-color: #f1f1f1;      
        color: #000;
        svg {
          opacity:1;
        }
      }
      .btn-adjust-btn {
        background-color: #000;
        color: #fff;
      }
    }
  }
  .adjust-stock-left-col,
  .adjust-stock-right-col {
    width: 100%;    
    @media only screen and (min-width: 600px) {
      width: 50%;    
    }
  }
  .adjust-stock-left-col{
    padding-right: 1.875rem;
    position: relative;
  }
  .adjuct-stock-product-img {
    margin-bottom: 0.625rem;
    width: 5rem;
    height: 5rem;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .adjuct-stock-manufacturer-name {
    color: #999999;
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 0.3125rem;
  }
  .adjuct-stock-product-name {
    font-size: 1.125rem;
    font-weight: 600;
    color: #000;
    line-height: normal;
    margin-bottom: 0.3125rem;
  }
  .adjuct-stock-manufacturer-code {
    color: #000;
    font-size: 0.875rem;
    font-weight: 500;
  }
  .adjust-stock-title {
    font-size: 1.5rem;
    color: #000;
    font-weight: 600;
    margin-bottom: 1.875rem;
  }
  .adjust-toggle-button {
    .adjust-stock-tab-container {
      background: #EEEEEE 0% 0% no-repeat padding-box;
      border-radius: 0.5rem;
      opacity: 1;
      min-height: 2.1875rem;
      .MuiTabs-scroller {
        .MuiTabs-flexContainer {
          flex-wrap: wrap;
          justify-content: center;
          .MuiTab-root {
            min-height: 2.1875rem;
            padding: 0.1875rem 0.75rem;
            text-transform: capitalize;
            width: 100%;
            min-width: 8.4375rem;
            max-width: 100%;
            @media only screen and (min-width: 960px) {
              width: 100%;
              max-width: 8.4375rem;
            }
          }
          .Mui-selected {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 0.125rem solid #EEEEEE;
            border-radius: 0.5rem;
            opacity: 1;
            min-height: 2.1875rem;
          }
        }
      }
      .MuiTabs-indicator {
        background-color: unset;
        display: none;
      }
    }
  }
  
  .tabs-disabed-cursor-not-allowed {
    cursor: not-allowed;
  }

  .hide-move-tab {
    max-width: 50% !important;
    &:focus-visible{
                   border: 0.13rem solid var(--bs-black) !important;  
    }
  }
  .do-not-hide-move-tab {
    &:focus-visible{
                   border: 0.13rem solid var(--bs-black) !important;  
    }
  }
  .stock-available-qty-title, .stock-adjust-qty-title {
    color: #000;
    font-weight: 500;
  }

  .add-stock-btn{
    min-width: 9.563rem;
    max-width: 9.563rem;
    height: 2.75rem !important;
  }