.search-input{
    height: 50px;
    font-size: 18px;
    color: black;
    border-radius: 10px
}

.label-text-black{
    color: black;
    font-size: 14px
}

.label-text-light{
    color: #AAAAAA;
    font-size: 12px
}

.flex-direction-column{
   flex-direction: column;
   display: flex;

}

.flex-row-space-between{
    flex-direction: row;
    display: flex;
    justify-content: space-between;
 }

 .date-input{
     height: 50px;
     border-color: #EEEEEE !important;
 }