.page-heading {
  line-height: normal;
  span {
    font-size: 1.75rem;
    font-weight: 600;
  }
  .heading-gray-text {
    color: #999999;
  }
  .heading-black-text {
    color: #000000;
  }
  .medium-heading-text {
    font-size: 1.375rem;
  }
}
