
@mixin threeColumns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: flex-start;
  gap: 0.625rem;
}

.item-group-header {
  display: grid;
  align-items: flex-end;
  grid-template-columns: var(--header-columns);
  gap: 1.25rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #eee;

  .item-group-rep {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;

    h3 {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.15;
      letter-spacing: -0.1px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 20ch;
      max-width: 100%;
    }

    .item-group-rep-metadata {
      display: flex;
      align-items: center;
      gap: 0.75rem;

      p {
        font-size: 0.75rem;
        line-height: 1.1;
        color: #555555;
        letter-spacing: 0.1px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .item-group-prices {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    gap: 0.75rem;
    width: 100%;

    .info-block-sb:nth-child(2) {
      padding-left: 0.875rem;
    }

    .info-block {
      text-align: right;
    }
  }

  .item-group-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
  }
}

.item-list-header {
  display: grid;
  grid-template-columns: var(--header-columns);
  gap: 1.25rem;
  grid-column: 1 / -1;
  padding: 0.75rem 0;
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1;

  p {
    text-transform: uppercase;
    font-size: 0.625rem;
    letter-spacing: 0.0625rem;
    color: #aaaaaa;
    line-height: 1.25;
  }

  .item-tab-header {
    display: grid;
    grid-template-columns: var(--item-columns);
    align-items: flex-start;
    gap: 0.325rem;

    p {
      &:nth-child(2) {
        padding-left: calc(1.875rem + 1.25rem);
      }
      &:last-child {
        text-align: right;
      }
    }
  }

  .prices {
    @include threeColumns();
    text-align: right;
  }

  .metadata {
    @include threeColumns();
    grid-template-columns: repeat(2, 1fr);
  }
}

.add_item_tag {
  background: #F1F1F1 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
  padding: 10px;
  margin-right: 5px;
}
