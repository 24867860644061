@import "src/apps/pos/assets/css/variable.scss";

.document-main-section {
    .document-container {
        width: 100%;

        .input-with-add-button {
            display: flex;
            padding: 1.125rem 0;
            gap: 1.25rem;
            align-items: center;

            .document-search-input {
                width: 100%;

                .MuiFilledInput-root {
                    padding: 0.75rem;
                    border-radius: 0.625rem;
                }

                input {
                    &::placeholder {
                        /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: $black-color;
                        opacity: 1;
                        /* Firefox */
                        font-weight: 400;
                    }

                    &:-ms-input-placeholder {
                        /* Internet Explorer 10-11 */
                        color: $black-color;
                    }

                    &::-ms-input-placeholder {
                        /* Microsoft Edge */
                        color: $black-color;
                    }
                }
            }

            .add-document-main-btn {
                letter-spacing: -0.32px;

                span {
                    font-weight: 600;
                }
            }
        }

        .MuiDivider-root {
            background-color: $gray;
        }

        .document-list-main-container {
            width: 100%;

            .list-header-container {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                background: $white-color;
                z-index: 99;
                margin: 1.25rem 0;

                .list-header-wrapper {
                    .list-title {
                        font: 0.625rem normal;
                        letter-spacing: 0;
                        color: $black-color;
                        opacity: 1;
                        width: 90%;
                    }
                }
            }

            .document-list-show-container {
                overflow-x: scroll;
                height: 535px;

                .list-item-show {
                    .item-list-wrapper {
                        cursor: pointer;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        background: $white-color;
                        z-index: 99;
                        padding: 0.625rem;
                        justify-content: space-between;

                        .list-title {
                            font: 1rem normal;
                            letter-spacing: 0;
                            color: $black-color;

                            opacity: 1;
                        }

                        .name-with-id {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        .name-document {
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                        }
                    }
                }
            }
        }

        .txt-align {
            text-align: right;
        }

        .sort {
            font: normal normal normal 10px/12px Inter;
            letter-spacing: 1px;
            color: #000000;
            text-transform: uppercase;
            opacity: 1;
        }
    }
}

.name-with-read-section {
    display: flex;
    justify-content: space-between;
    width: 50%;
}

.list-document-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.list-document-id {
    font-size: 0.75rem;
    font-weight: 500;
}

.read-indicator {
    width: 100%;
    max-width: 7.25rem;
}

.list-document-type,
.list-document-status,
.list-document-date,
.list-document-description {
    height: 100%;
    width: 15%;

    span.list-title {
        font-weight: 500 !important;
    }
}

.list-document-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    max-height: 1.563rem;
}

.read-only-tag {
    background-color: $cinnamon-color;
    text-transform: uppercase;
    color: $white-color;
    height: fit-content;
    padding: 0.188rem 0.5rem;
    font-size: 0.75rem;
    border-radius: 1.25rem;
    margin: 0 1rem;
    width: fit-content !important;
    min-width: 5.625rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sort-by-dropdown {
    position: relative;
    display: flex;
    align-items: center;

    .relative-light-border {
        border-top: 0.063rem solid #eeeeee;
        height: 0.063rem;
        max-width: 100%;
        min-width: 100%;
    }

    .sorting-dropdown-btn.btn {
        position: absolute;
        background: #fff !important;
        padding: 0 0 0 0.625rem;
        right: 0;
        z-index: 1;
    }
}

.cust-info-add-doc-btn {
    min-width: 13rem;
    width: 13rem;
    max-width: 13rem;
    height: 2.75rem;
}

.doc-to-be-uploaded-msg {
    max-width: 95rem;
    height: 3rem;
    margin-bottom: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fef4e5;
    border-radius: 10px;
    padding: 10px;
    color: #000000;
    font-family: "Inter", Regular;
    letter-spacing: 0.16px;

    .icon-text-style {
        display: flex;
    }

    .doc-msg-text {
        margin-left: 1rem;
    }

    .close-icon-style {
        opacity: 0.5;
        cursor: pointer;
    }
}

.tabButton-focus {
    &.MuiButton-root {
        &:focus {
            outline: 0.13rem solid var(--bs-black) !important;
        }
    }
}

.tabInput-focus {
    .MuiFilledInput-root {
        padding: 0.75rem;
        border-radius: 0.625rem;

        &:focus-within {
            outline: 0.13rem solid var(--bs-black) !important;
        }
    }
}

.document-table {
    max-width: 100%;
    margin: 20px;
    overflow: auto;
    height: 700px;


    table {
        width: 100%;
        caption-side: unset;
        border-collapse: separate;
        margin-bottom: 2rem;
        border: 1px solid #EEEEEE;
        border-radius: 20px;
        border-spacing: 0px;

        thead {
            position: sticky;
            top: 0;
            background-color: #fff;
            color: #000;
        }

        thead tr th:first-child {
            border-top-left-radius: 20px;
        }

        thead tr th:last-child {
            border-top-right-radius: 20px;
        }

        .first-th {
            text-align: left;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: -0.36px;
            color: #000000;
        }

        .all-th {
            font-size: 10px;
            font-weight: 300;
            letter-spacing: 1px;
            color: #000000;
            text-transform: uppercase;
        }

        th,
        td {
            padding: 12px;
            text-align: left;
        }

        td {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0px;
            color: #000000;
            border-bottom: 1px solid #EEEEEE;
        }

        td span {
            font-size: 0.85rem;
            color: #555555;
        }

        tbody {
            tr {
                &:hover {
                    background-color: #EEEEEE;
                    border-radius: 20px;
                    cursor: pointer;
                }
            }
        }

        tr:last-child td {
            border-bottom: none; // Remove bottom border on the last row
        }

    }

    @media (max-width: 768px) {
        table {

            th,
            td {
                padding: 10px;
            }

            td span {
                display: block;
                margin-top: 4px;
            }
        }
    }

    @media (max-width: 480px) {
        table {
            thead {
                display: none;
            }

            tbody {
                tr {
                    display: block;
                    margin-bottom: 10px;
                }

                td {
                    display: block;
                    text-align: right;
                    padding: 8px 10px;
                    position: relative;
                    border: none;
                }

                td:before {
                    content: attr(data-label);
                    float: left;
                    font-weight: bold;
                    text-transform: uppercase;
                }

            }
        }
    }
}