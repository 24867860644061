@import "../../../constants/colors.scss";

.layout-container {
    display: flex;
    .header-back-button{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .lable-button-div{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .label-div{
        font: normal normal bold 1.75rem/2.5rem Inter;
        letter-spacing: -0.035rem;
        color: #000000;
        opacity: 1;
      }
    }
    .header-container {
      height: 12.5rem;
      width: 100%;
      .back-next-btn-small-left {
        top: unset;
        left: unset;
      }
    }
    .back-button-container {
      width: 10%;
      height: 100%;
    }
    .lable-export-new-product-button-container{
      width: 80%;
      height: 100%;
      .btn-div{
        display: flex;
        flex: content;
        button{
          margin-right: 0.625rem;
        }
      }
    }
    .main-container {
      flex-direction: row;
      width: 100%;
      justify-content: flex-start;
    }
    .heading-black-text {
      text-align: left;
      font: normal normal bold 1.5rem/1.875rem Inter;
      letter-spacing: -0.03rem;
      color: #000000;
      opacity: 1;
    }
    .heading-list {
        margin-top: 4%;
        flex-direction: column;
        width: 10%;
        align-items: center;
        justify-content: center;
    }
    .heading-list-container {
        margin-bottom: 0.313rem;
    }
    .heading-product-container {
      width: 5.188rem;
      height: 1.813rem;
    }
    .heading-product-des-container {
      width: 46.875rem;
      height: 1.813rem;
    }
    .heading-item-list {
        margin-top: 4%;
        width: 50%;
        align-items: center;
        justify-content: center;
    }
    .heading-item {
        flex-direction: row;
    }
    .heading-gray-text {
        font: normal normal bold 1.5rem/1.875rem Inter;
        letter-spacing: -0.03rem;
        color: #AAAAAA;
        opacity: 1;
      }
    .row-container {
        flex-direction: row;
        justify-content: center;
        font: normal normal bold 1.375rem/1.5rem Inter;
        letter-spacing: 0rem;
        color: #000000;
        opacity: 1;
        margin-top: 0.625rem;
    }
    .button-container {
        margin-top: 4.375rem;
        width: 100%;
        justify-content: flex-start;
        flex-direction: row;
        display: flex;
    }
    .posButton-container {
        margin-left: 0.625rem;
    }
    .image-container {
      align-self: center;
    }
    .product-image-container {
      width: 8.75rem;
      height: 8.75rem;
    }
    .heading-title {
      font: normal normal normal
    }
    .neworder-mfg-code {
      text-align: left;
      font: normal normal normal 0.875rem/1.063rem Inter;
      letter-spacing: 0.009rem;
      color: #999999;
      text-transform: uppercase;
      opacity: 1;
    }
    .neworder-mfg-code-container {
      width: 3.125rem;
      height: 1.063rem;
    }
    .product-units-available-title {
      width: 3rem;
      height: 1.25rem;
      margin-top: 1.25rem;
      display: flex !important;
      flex-direction: row !important;
      align-items: center;
      cursor: pointer;
    }
    .product-status-title {
      text-align: left;
      font: normal normal normal 16px/16px Inter;
      letter-spacing: 0.01rem;
      color: #000000;
      opacity: 1;
    }
    .units-available-badge-dot-style {
      margin-left: 0.5rem;
        height: 0.75rem;
        min-width: 0.75rem;
        border-radius: 0.563rem;
        background-color: var(--bs-green);
        align-self: center;
    }
    .product-price-container {
      width: 4.188rem;
      height: 1.625rem;
      margin-right: 0.625rem;
    }
    .product-price-text {
      text-align: left;
      font: normal normal bold 1.375rem/1.5rem Inter;
      letter-spacing: 0rem;
      color: #000000;
      opacity: 1;
    }
    .product-price-main-container {
      margin-top: 1.25rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .product-set-title {
      text-align: left;
      font: normal normal normal 0.875rem/1.5rem Inter;
      letter-spacing: 0rem;
      color: #000000;
      opacity: 1;
    }
    .product-set-container {
      width: 3.875rem;
      height: 1.063rem;
    }
    .product-status-container {
      display: flex !important;
      flex-direction: column !important;
      padding: 0.625rem !important;
    }
    .product-status {
      display: flex;
      flex-direction: row ;
      padding: 0.625rem;
      height: 6.25rem;
      width: 6.25rem;
    }
    .product-status-detail-container {
      display: flex !important;
      flex-direction: row !important;
    }
  }

.highcharts-legend-item {
  .highcharts-graph {
    display: none;
  }
}

.highcharts-series-group {
  .highcharts-point {
  display: none;
  
  }
  }

  .product-report-section {
    .product-report-container {
      .report-div {
        position: relative;
        padding: 0.625rem 4.20rem 0.625rem 0.438rem;
        &:nth-child(even) {
          background: $gray-50;
        }
        .star-icon-report {
          opacity: 0;
          margin-right: 0.313rem;
          background-color: unset;
          .star-icon {
            width: 0.938rem;
            height: 0.938rem;
          }
          &:hover {
            background-color: $bgColor;
            .star-icon {
              fill: black;
            }
          }
          .star-icon-starred {
            width: 0.938rem;
            height: 0.938rem;
            fill: black;
          }
        }
        .edit-hover-btn {
          opacity: 0;
          position: absolute;
          right: 0.625rem;
          .btn{
            background-color: $bgColor;
          }
          .btn:hover{
            background-color: $bright-Gray;
            text-decoration: none;
            color: $black-color;
          }
        }
        &:hover {
          .star-icon-report {
            opacity: 1;
            color: gray;
            margin-right: 0.313rem;
            
          }
          .edit-hover-btn {
            opacity: 1;
          }
        }
      }
      .MuiGrid-item{
        &:first-of-type{
          padding-left: 1.875rem;
          .star-icon-report{
            position: absolute;
            left: 0;
            top: 0.375rem;
          }
          .small-text{
            span{
              margin-left: 0;
            }
          }
        }
      }
    }
  }
  
  .edit-main-btn {
    .btn{
      background-color: $bgColor;
    }
    .btn:hover{
      background-color: $bright-Gray;
      text-decoration: none;
      color: $black-color;
    }
  }