.inline-note {
  letter-spacing: 0.05ch;
  display: flex;
  align-items: center;
  background-color: #f7eefc;
  border-radius: 20rem;
  padding: 0.25rem 0.5rem;
  max-width: fit-content;
  color: #555;
  border: none;
  outline: revert !important; 

  &:not(:disabled) {
    cursor: pointer;

    &:hover,
    &:focus-visible {
      color: #000;
      outline-offset: 0.25rem;
      box-shadow: 0 0 0 0.25rem #f7eefc;
    }

    &:active {
      background-color: #e5c7f4;
      box-shadow: 0 0 0 0.125rem #e5c7f4;
    }
  }

  &:disabled {
    background-color: #f7eefc;
  }
  
  span {
    --max-length: 35;
    max-width: calc(1ch * var(--max-length));
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.inline-note-viewer {
  gap: 1.25rem;
  max-height: 60vh;
  overflow-y: scroll;
  padding: 0;
  border-radius: 1.25rem;
  border: none;

  header {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;

    h3 {
      font-size: 1.25rem;
      padding: 1.875rem;
      padding-bottom: 1.25rem;
      font-weight: 600;
    }

    .icon-btn {
      right: 0.625rem;
      top: 0.625rem;
      position: absolute;
    }
  }

  .full-note {
    padding: 1.875rem;
    padding-top: 0;
    max-width: 50ch;
    line-height: 1.5;
    font-size: 1rem;
  }
}
