@import "../assets/css/variable.scss";

.return-cart-product-container.MuiContainer-root.new-container {
  padding: 0 !important;

  .return-cart-product-close-icon {
    width: 60px;
    height: 60px;
    border-radius: 20px;
    left: 130px;
    top: 158px;

    &:hover {
      background-color: #f1f1f1 !important;

      .MuiSvgIcon-root {
        fill: $black-color;
        opacity: 1;
      }
    }

    .MuiSvgIcon-root {
      fill: $gray;
      opacity: 1;
    }
  }

  .return-product-cart-main-section {
    overflow: scroll;
    padding-top: 70px;
    padding-bottom: 110px;
    height: 50.375rem;

    .return-product-cart-heading {
      margin: 25px 0;
      width: 100%;

      .return-product-items-div {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        padding-top: 6px;

        .return-clear-cart.MuiButtonBase-root {
          padding: 8px 8px;
          width: fit-content !important;
          height: fit-content !important;

          &:hover {
            background-color: #dddddd;
          }
        }
      }
    }

    .return-product-cart-list-main-section {
      padding-top: 30px;

      .return-product-order-list-main {
        border: 1px solid #eeeeee;
        border-radius: 15px;
        opacity: 1;

        .order-list-header {
          height: 100%;
          min-height: 68px;
          padding: 10px 15px;
          display: flex;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 16px;
            width: calc(100% - 35px);
            height: 0.063rem;
            background-color: var(--bs-gray-200);
          }

          .order-list-name {
            display: flex;
            flex-direction: column;
            width: 100%;

            position: relative;
            height: 100%;

            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 191px;
              width: calc(100% - 188px);
              height: 40px;
              background-color: var(--bs-gray-200);
            }

            .order-title {
              font: 14px normal;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
            }

            .order-product-id {
              font-size: 18px;
              font-weight: 600;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
            }
          }

          .order-list-title-fee-div {

            height: 100%;

            grid-template-columns: repeat(6, 9.0625rem);
            align-items: start;
            position: relative;

            .shipping-cart-div {
              display: flex;
              flex-direction: column;
              padding: 6px 10px;
              cursor: pointer;

              .title {
                font: 10px normal normal normal;
                letter-spacing: 1px;
                color: #000000;
                text-transform: uppercase;
                opacity: 1;
              }

              .order-product-id {
                font: 1rem;
                font-weight: 500;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
              }

              &:hover:enabled {
                background: #eeeeee 0% 0% no-repeat padding-box;
                border-radius: 10px;
                opacity: 1;
              }

              .is-disabled {
                pointer-events: none;
                opacity: 0.3;
                cursor: default;
              }
            }

            .refund-via-sec {
              width: max-content;

              &:disabled {
                cursor: default;
              }

              &:not(:disabled) {
                &:focus-within {
                  border-radius: 0.5rem;
                  outline: 2px solid #000;
                }
              }
            }

            .refund-via-sec:hover {
              cursor: pointer;
              background-color: #eee;
              border-radius: 0.5rem;
            }

            //may need in future
            // .refund-via-sec.shipping-cart-div.order-list-paid-sec:hover {
            //   background: transparent;
            //   border-radius: 0;
            //   cursor: default;
            // }
          }

          .order-list-title-item-credit-div {
            align-items: center;
            position: relative;

            .shipping-cart-item-credit-div {
              display: flex;
              flex-direction: column;
              padding: 6px 10px;
              cursor: default;

              .title {
                font: 10px normal normal normal;
                letter-spacing: 1px;
                color: #000000;
                text-transform: uppercase;
                opacity: 1;
              }

              .order-product-id {
                font: 1rem;
                font-weight: 500;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
              }

              .is-disabled {
                pointer-events: none;
                opacity: 0.3;
                cursor: default;
              }
            }
          }

          .order-list-paid-sec {
            border: 0rem;
            border-left: 0.1875rem solid #eeeeee;
            background: transparent;
          }
        }

        .returns-order-list-table-main-div {
          .returns-order-table-header-div {
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-columns: 60px 364px 264px 150px 140px 150px 110px 100px 100px;
            align-items: center;
            background: $white-color;
            margin-bottom: 10px;

            .return-order-wrapper-main {
              color: #000000;
              opacity: 1;
              font: 10px normal;
              letter-spacing: 1px;
              text-transform: uppercase;

              &:nth-last-child(-n + 3) {
                text-align: end;
              }
            }
          }

          .returns-order-table-body-div {
            .return-order-body-wrapper-main {
              cursor: pointer;
              width: 100%;
              height: 100%;
              display: grid;
              grid-template-columns: 58px 364px 264px 150px 140px 150px 110px 100px 100px;
              padding: 10px 0px;
              position: relative;

              &:last-child {
                &::after {
                  display: none;
                }
              }

              &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 59px;
                width: calc(100% - 65px);
                height: 0.063rem;
                background-color: var(--bs-gray-200);
              }

              .order-product-title-div {
                display: flex;
                flex-direction: column;
                gap: 3px;

                .product-name {
                  font: 0.875rem normal;
                  color: $black-color;
                  opacity: 1;
                  letter-spacing: 0px;
                }

                .manufacture-code {
                  font: 0.75rem normal;
                  color: $description-color;
                  opacity: 1;
                  letter-spacing: 0.12px;
                  text-transform: uppercase;
                }
              }

              .order-product-name {
                font: 0.875rem normal;
                color: $black-color;
                opacity: 1;
                letter-spacing: 0px;

                &:nth-last-child(-n + 3) {
                  text-align: end;
                }
              }
            }
          }
        }
      }
    }
  }
}

.order-details-modal-container {
  .MuiDialog-container {
    .MuiDialog-paper {
      width: 100%;
      min-width: 34.375rem;
      border-radius: 1.25rem;

      .MuiDialogTitle-root {
        padding: 15px 6px 0px 20px;

        .MuiTypography-root {
          .MuiBox-root {
            .MuiTypography-root {
              justify-content: flex-start;
              font-size: 1.25rem;
              font-weight: 600;
              letter-spacing: 0px;
              color: $black-color;
              opacity: 1;
            }

            .MuiButtonBase-root {
              padding: 4px 5px;

              &:hover {
                background-color: #f1f1f1 !important;

                .MuiSvgIcon-root {
                  fill: $black-color;
                  opacity: 1;
                }
              }

              .MuiSvgIcon-root {
                fill: $gray;
                opacity: 1;
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }
    }
  }
}

.no-product-found-text {
  font-size: 1.125rem;
}

.no-product-found-info {
  display: flex;
  padding: 2.5rem 0;
}

.cart-data-header-sec {
  padding: 0 1.25rem;

  .cart-product-data-header {
    font-size: 0.625rem;
  }

  .MuiGrid-root:nth-child(5) {
    text-align: right;
  }
}

.return-cart-product-name-sec {
  position: relative;

  .return-cart-product-image {
    position: absolute;

    img {
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  .return-cart-product-name {
    padding-left: 3.75rem;
    padding-right: 10px;
    display: flex;
    flex-direction: column;

    .product-name {
      font-weight: 500;
      line-break: anywhere;
    }

    .manufacture-code {
      color: $description-color;
    }
  }
}

.return-cart-customer-name {
  display: flex;
  flex-direction: column;

  .customer-name {
    font-weight: 500;
  }

  .customer-code {
    color: $description-color;
  }
}

.cart-product-info {
  font-size: 0.875rem;
}

.cart-product-first-data-header {
  padding-left: 3.75rem;
}

.cart-data-header-sec,
.return-cart-data-sec {
  .MuiGrid-root:nth-child(5) {
    text-align: center;
  }

  .MuiGrid-root:nth-child(6),
  .MuiGrid-root:nth-child(7),
  .MuiGrid-root:nth-child(8),
  .MuiGrid-root:nth-child(10),
  .MuiGrid-root:nth-child(11),
  .MuiGrid-root:nth-child(9) {
    text-align: right;
  }
}

.return-cart-data-info {
  .return-cart-data-sec {
    position: relative;
    padding: 1.2rem 1.25rem 1.2rem;
    width: 100%;
    cursor: pointer;

    &:hover {
      background-color: $bright-Gray !important;
      border-radius: 0.5rem;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      right: 0%;
      border-bottom: 0.063rem solid #eeeeee;
      width: calc(100% - 3.75rem);
    }
  }
}

.returns-order-list-table-main-div {
  .return-cart-data-sec:first-of-type::after {
    display: none;
  }
}

.small-dollar-sign {
  font-size: 0.625rem;
  position: absolute;
  left: -0.125rem;
  top: 0.25rem;
}

.data-with-currancy {
  display: flex;
  position: relative;
  padding-left: 0.375rem;

  img {
    vertical-align: text-bottom;
    width: 1.25rem;
    height: 1.25rem;
  }
}

.return-invoice-all-data {
  padding-bottom: 1.25rem;
  height: 33.3125rem;
}

div.is-disabled {
  pointer-events: none;
  opacity: 0.3;
  cursor: default;
}

.total-refund-cash-section {
  margin-top: 80px;
  bottom: 0;
  max-width: 95rem;
  width: 100%;
  position: fixed;
  background-color: $white-color;
}

.total-refund-cash-section-title {
  font: 10px normal normal normal;
  letter-spacing: 1px;
  color: $black-color;
  text-transform: uppercase;
  opacity: 1;
}

.total-refund-cash-section-amount {
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: 0px;
  color: $black-color;
  opacity: 1;
}

.total-refund-main-div {
  border-top: 0.125rem solid black;
  padding-top: 0.938rem;
  padding-bottom: 0.938rem;


  .order-list-title-item-credit-div {
    margin-bottom: 0.938rem;
  }

  .complete-return-block {
    display: flex;
    justify-content: space-between;

    .tax-message {
      display: flex;
      flex-direction: column;
      margin-right: 1.5625rem;
    }
  }
}

.set-replacement-info-icon {
  background: transparent url('../../springboard/images/icons/Group_2842.svg') 0% 0% no-repeat padding-box;
  opacity: 1;
}

.set-replacement-info-icon-div {
  margin-right: 10px;
}

.set-replacement-info-main-div {
  background: white;

  //might need later
  // bottom: 0;
  // right: 0;
  // margin: auto;   
  // left: 0; 
  // @media only screen and (min-width: 960px) {
  // left: 3.875rem;
  // position: fixed;
  // }
  .return-cart-product-container.new-container {
    width: 100%;
    // @media only screen and (min-width: 960px) {
    //   width: 80%;
    // }
  }
}

.info-blocks-sr {
  grid-column: 4 / 17;
  border-left: 0.063rem solid var(--bs-gray-300);
  padding-left: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 11rem;

  .info-block {
    border-radius: 0.625rem;
    width: 10rem;
    padding: 0 0.5rem 0.5rem 0.5rem;

  }
}

.order-first-sec {
  justify-content: space-between;
  width: 100%;

  padding-right: 30px;

  .order-delete-section {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0.625rem;
    top: 0.938rem;
    cursor: pointer;

    img {
      width: 24px;
      height: 24px;
    }

    .delet-order-img {
      display: block;
    }

    .delet-order-hover-img {
      display: none;
    }

    &:hover {
      .delet-order-img {
        display: none;
      }

      .delet-order-hover-img {
        display: block;
      }
    }
  }
}

.return-set-picking-btn {
  padding: 12px 20px;
  font-weight: 500;
  letter-spacing: -0.32px;

  &.button-accent {
    color: var(--bs-white);
  }

  &.button-is-loading-black {
    position: relative;
    overflow: hidden;
    pointer-events: none;

    .button-label,
    .button-icon {
      opacity: 0;
    }

    &::before {
      content: "";
      position: absolute;
      background-color: var(--bs-black);
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      border-radius: 0.625rem;
    }

    &::after {
      content: "";
      position: absolute;
      height: 1.5rem;
      width: 1.5rem;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNNiAxMGMtMS4xIDAtMiAuOS0yIDJzLjkgMiAyIDIgMi0uOSAyLTItLjktMi0yLTJ6bTEyIDBjLTEuMSAwLTIgLjktMiAycy45IDIgMiAyIDItLjkgMi0yLS45LTItMi0yem0tNiAwYy0xLjEgMC0yIC45LTIgMnMuOSAyIDIgMiAyLS45IDItMi0uOS0yLTItMnoiLz48L3N2Zz4=");
      background-position: center;
      background-repeat: no-repeat;
      opacity: 0;
      animation: button-loading-anim 0.3s ease-out alternate infinite;

      @keyframes button-loading-anim {
        to {
          opacity: 1;
          transform: scale(1.1);
        }
      }
    }
  }

  &.button-accent {
    &.button-is-loading-black {
      &::after {
        filter: invert(1);
      }
    }
  }

}

.sr-select-taker-drawer {
  .MuiDrawer-paperAnchorRight {
    border-radius: 30px 0px 0px 30px;
  }
}

.return-cart-responsive-table-sec {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.063rem;
    background-color: var(--bs-gray-200);
  }

  &:last-child {
    &::after {
      display: none;
    }
  }

  .return-cart-responsive-table {
    .responsive-sec-title {
      min-width: 5.813rem;
      width: fit-content;

      .title {
        font-size: 0.625rem;
      }
    }

    .product-info-data {
      font-size: 0.875rem;
      padding-left: 0.75rem;
    }

    .product-name,
    .customer-name {
      font-weight: 500;
      line-break: anywhere;
    }

    .product-multiple-info-sec {
      display: flex;
      align-items: flex-start;
    }

    .product-multiple-info {
      display: flex;
      flex-direction: column;
    }

    .manufacturer-code-color {
      color: #555555;
    }

    .MuiGrid-root {
      padding-right: 1.875rem;
      margin-bottom: 1.25rem;
    }

    .return-cart-responsive-data-div {
      display: flex;
    }
  }
}

.return-cart-data-sec.MuiGrid-root,
.return-cart-table-main-header {
  display: none;
}

.return-cart-responsive-table-sec {
  display: flex;
  margin: 1.25rem 0;
}

.returns-order-list-table-main-div {
  padding: 0 0.938rem;
}

.order-list-title-fee-div {
  display: flex;
  flex-wrap: wrap;
}

.order-first-sec {
  align-items: flex-start;
}

.order-list-name {
  max-width: 6.25rem;
}

@media only screen and (min-width: 1200px) {

  .return-cart-data-sec.MuiGrid-root,
  .return-cart-table-main-header {
    display: flex;
  }

  .return-cart-responsive-table-sec {
    display: none;
  }

  .returns-order-list-table-main-div {
    padding: 0.625rem 0.938rem;
  }

  .order-list-title-fee-div {
    display: grid;
  }

  .order-first-sec {
    align-items: center;
  }

  .order-list-name {
    max-width: 11.875rem;
  }

  .order-list-title-item-credit-div {
    height: 100%;
  }
}

.order-list-header {
  flex-direction: column;

  @media only screen and (min-width: 600px) {
    flex-direction: row;
  }
}

.return-product-table-customer {
  display: flex;
  flex-direction: column;
}

.return-product-table-customer-secondary-text {
  color: var(--bs-gray-600);
}

.sr-fixed-footer {
  margin: 0 auto;
  width: 100%;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  max-width: 80%;
}

.sr-use-default-button-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.9375rem;

  .use-default-btn {
    font-weight: 500;
    letter-spacing: -0.02rem;
    height: 2.5rem;
    width: 4.375rem;
  }

  .use-complete-btn {
    height: 2.5rem;
    max-width: 13.25rem;
  }
}

.sr-payment-refund-review {
  background: #F8F9F9;
  border-radius: 10px;
}