.grid-wrapper {
  display: grid;
  grid-column-gap: 1em;
  grid-template-columns: repeat(10, 1fr);
  align-items: center;
  text-align: center;
}
.box {
  column-count: 1;
}
.quote-history-table-header {
  font-size: 0.625rem;
  font-weight: 500;
  padding: 0.9375rem 0;
  opacity: 1;
  letter-spacing: 0.0313rem;
}
.table-body-col {
  font-size: 0.875rem;
  color: #000000;
  padding: 0.9375rem 0;
}
.inv-date-col {
  padding: 0.9375rem 1.25rem;
}
.table-body-row-quote-history,
.table-head-row-quote-history {
  display: grid;
  grid-row-gap: 1em;
  grid-template-columns: repeat(10, 1fr);
}
.table-head-row-quote-history,
.quote-history-table-data,
.quote-history-header-filter-sec {
  border-bottom: 0.0625rem solid #dddddd;
}
.quote-history-header-filter-sec {
  display: flex;
  padding: 1.125rem 1.25rem;
  .filter-icon-section {
    .filter-icon {
      width: 0.75rem;
    }
  }
  .quote-history-header-filter-info {
    background-color: #333333;
    color: #fff;
    width: fit-content;
    padding: 0.25rem 0.5rem;
    border-radius: 1.25rem;
    line-height: normal;
    font-size: 0.875rem;
    margin-left: 1.25rem;
    img {
      height: 0.625rem;
      width: 0.625rem;
      margin-right: 0.375rem;
    }
  }
}

.quote-history-user-info {
  width:100%;
  border: 0.065rem solid #dddddd;
  max-width: 40rem;
  border-radius: 0.9375rem;
  padding: 0.625rem;
  .quote-user-main-name {
    font-size: 0.875rem;
    color: #000;
    font-weight: 600;
  }
  .quote-user-id {
    font-size: 0.75rem;
    color: #000;
    font-weight: 500;
  }
}
.quote-history-table-section {
  border: 0.0625rem solid #dddddd;
  border-radius: 0.9375rem !important;
}
.quote-user-icon {
  margin-right: 0.9375rem;
  width: 3.125rem;
  height: 3.125rem;
}
.download-csv-icon {
  padding: 0.9375rem !important;
  background-color: #f1f1f1;
  border-radius: 0.9375rem;
  font-size: 1rem;
  font-weight: 500;
  color: #000;
  &:hover {
    color: #000;
  }
  img {
    height: 1.125rem;
    width: 1.125rem;
  }
}
.table-body-row-quote-history:nth-child(even) {
  background-color: #F9F9F9;
}
.customer-code{
  color : #888888;
  font-size: 0.75rem;
}