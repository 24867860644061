.product-detail-id-icon-btn {
  --bs-danger: 209, 48, 39;
  --size: 40;
  height: calc(var(--size) * 0.0625rem);
  width: auto;
  min-width: 7.1875rem;
  margin-right: 0.1875rem;
  aspect-ratio: 1;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 31.25rem;
  outline: revert !important;
  border: none;
  opacity: 1;
  text-align: left;
  font-size: 0.75rem;
  letter-spacing: 0.075rem;
  text-transform: uppercase;
  .icon {
    user-select: none;
  }

  &[disabled] {
    opacity: 0.35;
    cursor: default;
  }

  &:link {
    text-decoration: none !important;
  }

  &.type-secondary {
    background-color: transparent;
    --hover: var(--bs-gray-300);
    --active: var(--bs-gray-400);
    color: var(--bs-gray-600);

    .icon {
      opacity: 1;
      color: var(--bs-gray-600);
    }
  }

  &:not(:disabled) {
    cursor: pointer;

    &:hover,
    &:focus-visible {
      background-color: var(--hover);
      color: black !important;
      .icon {
        opacity: 1;
      }
    }

    &:active {
      background-color: var(--active);
    }
  }

  &.busy {
    cursor: wait;
  }
}
