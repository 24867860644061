@import "src/apps/pos/assets/css/variable.scss";

.snackbar-platform {
  position: fixed;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding-inline-start: 2.5rem;
  padding-block-end: 3.75rem;
  z-index: 9999;
  pointer-events: none;

  .snackbar {
    display: flex;
    background: $black-color;
    gap: 0.625rem;
    align-items: center;
    justify-content: space-between;
    max-width: 31.25rem;
    min-width: 28.125rem;
    color: $white-color;
    padding: 0.625rem 0.625rem 0.625rem 1.25rem;
    border-radius: 16px;
    box-shadow: 0 20px 40px 0 rgba(104, 81, 81, 0.3);
    transition: all 0.6s cubic-bezier(0.34, 0.32, 0, 1.08);

    &.visible {
      transform: translateY(0) scale(1);
      opacity: 1;
      pointer-events: all;
    }

    &.hidden {
      transform: translateY(300%) scale(0.8);
      opacity: 0;
      transition: all 0.4s cubic-bezier(0.5, 0.35, 0, 1.05);
    }

    &:not(.hidden).leaving {
      opacity: 0.8;
      transition: all 1s cubic-bezier(0.49, 0.47, 0.36, 0.99);
      transform: translateY(0) scale(0.95);
    }

    .snackbar-actions {
      display: flex;
      gap: 2px;

      .snackbar-action-button {
        border: none;
        padding: 0.5rem 0.75rem;
        font-size: 0.875rem;
        border-radius: 8px;
        background-color: transparent;
        color: $yellow;
        text-transform: uppercase;
        letter-spacing: 0.1px;
        font-weight: 500;
        transition: all 0.2s ease;

        &:not(:disabled) {
          cursor: pointer;

          &:hover,
          &:focus-visible {
            background-color: rgba(255, 255, 255, 0.2);
          }

          &:active {
            transform: scale(0.975);
            background-color: rgba(255, 255, 255, 0.3);
          }
        }
      }
    }
  }

  .snackbar-message {
    font-size: 0.875rem;
    color: $white-color;
    line-height: 1.35;
    user-select: none;
    margin: 0 !important;
  }
}