.product-listing-container {
  .product-found-info {
    padding: 2rem 0 !important;
  }

  .product-listing {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 0.625rem;
  }

  .product-footer {
    height: 3.125rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent !important;
  }
}