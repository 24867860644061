.dialog-wrapper {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
    pointer-events: none;

    @media (max-width: 800px) {
        align-items: flex-end;
    }

    .admin-dialog {
        border-radius: 10px;
        background-color: #fff;
        border: 1px solid var(--bs-gray-300);
        pointer-events: all;
        overflow-y: scroll;
        max-width: 34.375rem;
        max-height: 90vh;
        -ms-overflow-style: none;
        scrollbar-width: none;
        outline: none;

        &::-webkit-scrollbar {
            display: none;
        }

        @media (max-width: 800px) {
            width: 100%;
            max-width: 100%;
            border-radius: 20px 20px 0 0;
        }

        .dialog-contents {
            position: relative;

            .dialog-close-button {
                position: absolute;
                right: 10px;
                top: 10px;
            }
        }
    }

}

.add-new-user-dialog,
.confirmation-dialog {
    padding: 20px;

    @media (max-width: 800px) {
        padding-bottom: 40px;
    }
}