@import 'ui/scss/general.scss';

.customer-order-table-title {
    &-text {
        text-transform: uppercase;
    }
    font-size: 0.75rem !important;
    letter-spacing: 0;
    color: $light-grey !important;
    opacity: 1;
    padding: 1rem 1rem 1rem 0px !important;
}

.customer-table-row-data-styling-for-order{
    padding: 1rem 1rem 1rem 0px !important;
}

.third-table-row {
    width: 20% !important;
}

.fourth-table-row {
    width: 30% !important;
}