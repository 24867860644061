@import "../../../assets/variable.scss";

.icon-button-primary {
    --size: 2.5rem;
    height: calc(var(--size) * 1px);
    width: calc(var(--size) * 1px);
    background-color: var(--bs-gray-200);
    border: none;
    border-radius: 31.25rem;
    font-size: 1.5rem;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 1s cubic-bezier(0, -0.01, 0, 1.31);
    will-change: transform;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    color: var(--bs-black);

    &:not(:disabled) {
        cursor: pointer;
    }

    &:disabled {
        opacity: 0.35;
        cursor: default;
    }

    &:hover:not(:disabled) {
        background-color: var(--bs-gray-300);
    }

    &:focus-visible:not(:disabled) {
        outline: none;
        box-shadow: 0 0 0 0.125rem var(--bs-white), 0 0 0 0.25rem var(--bs-gray-800);
    }

    &:active:not(:disabled) {
        transform: scale(0.9);
        background-color: var(--bs-gray-400);
    }

    &.icon-button-secondary:not(:disabled) {
        opacity: 0.75;
        background-color: $list-bg-color;

        &:hover:not(:disabled),
        &:focus-visible:not(:disabled) {
            background-color: var(--bs-gray-200);
            opacity: 1;
        }

        &:active:not(:disabled) {
            background-color: var(--bs-gray-400);
        }
    }

    .icon{
        overflow: hidden;
    }
}
