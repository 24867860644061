@import "../../../assets/css/variable.scss";

.select-item-import-main-container {
  height: 100%;
  width: 100%;
  .select-item-loading {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: unset !important;
    margin-left: unset !important;
  }
  .select-model-header-main-section {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 1;
    background-color: $white-color;
    .header-div-container {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      .header-title {
        display: flex;
        flex-direction: column;
        gap: 0.125rem;
        padding: 1.5625rem 0px 0px 1.5625rem;
        .main-title {
          color: $black-color;
          opacity: 1;
          font-size: 1.25rem;
          font-weight: bold;
        }
        .description {
          color: $black-color;
          display: flex;
          align-items: center;
          font-size: 0.875rem;
          font-weight: normal;
          opacity: 1;
          letter-spacing: 0px;
          .MuiBadge-root {
            margin: 0 0.438rem;
            .MuiBadge-badge {
              background-color: $black-color;
              opacity: 0.5;
              min-width: none;
              height: 0.25rem;
              min-width: 0.25rem;
            }
          }
          .select-all-text {
            margin-left: 0.438rem;
            text-decoration: underline;
            color: $black-color !important;
          }
        }
      }
      .model-header-close-button {
        padding: 0.3125rem;
        margin-right: 0.625rem;
        margin-left: 10.625rem;
        svg {
          fill: $gray;
          width: 1.5rem;
          height: 1.5rem;
        }
        &:hover {
          background-color: $bright-Gray;
          svg {
            fill: $black-color;
          }
        }
      }
    }
    .item-import-header-section {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content:space-between;
      align-items: center;
      background: $white-color;
      z-index: 99;
      padding: 0 1.875rem;
      .item-import-wrapper-main {
          color: $black-color;
          opacity: 1;
          font-size: 0.75rem;
          .title {
            .MuiButtonBase-root {
              .MuiSvgIcon-root  {
                fill: $border-color;
              width: 1.25rem;
              height: 1.25rem;
              }
              .check-icon { 
                color: $black-color;
                fill: unset !important;
              }
            }
          }
      }
    }
  }
  .items-import-table-main-section {
    padding: 7.4375rem 1.5625rem;
    .import-no-data-found {
      height: 100%;
      width: 100%;
      min-height: 34.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .item-import-table-body-section {
      height: 100%;
      width: 100%;
    .stock-item-list {
        padding-bottom: 3.125rem;
        .item-isSelected-values {
          &:first-child {
            border-top-left-radius: 0.625rem;
            border-top-right-radius: 0.625rem;
          }
          &:last-child {
            border-bottom-left-radius: 0.625rem;
            border-bottom-right-radius: 0.625rem;
          }
        }
      }
      .images-with-title-div {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 3.125rem auto;
        margin: 0.5rem 0;
        align-items: center;
        .icon-with-stock-title {
          display: flex;
          flex-direction: column;
          margin-left: 0.625rem;
          border-bottom: 1px solid $border-color;
          .stock-title {
            color: $black-color;
            opacity: 1;
            font-size: 0.875rem;
            font-weight: bold;
          }
          .stock-description {
            color: $black-color;
            opacity: 1;
            font-size: 0.875rem;
            padding-bottom: 0.375rem;
          }
        }
      }
      .item-isSelected-values {
        background: $gray-50 0% 0% no-repeat padding-box;
        opacity: 1;
        cursor: pointer;
      }
      .item-import-body-section {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding: 0.188rem 0.3125rem;
        cursor: pointer;
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 7.188rem;
          width: calc(100% - 130px);
          height: 0.063rem;
          background-color: var(--bs-gray-200);
        }
        .item-import-wrapper-main {
          &:nth-child(3)  {
            .title {
              font: 0.875rem normal;
            }
          }
            .title {
              color: $black-color;
              opacity: 1;
              font: 1rem normal;
              letter-spacing: 0px;
              .MuiButtonBase-root {
                .MuiSvgIcon-root  {
                  fill: $border-color;
                width: 1.25rem;
                height: 1.25rem;
                }
                .check-icon { 
                  color: $black-color;
                  fill: unset !important;
                }
              }
            }
            .image-with-title {
              display: flex;
              align-items: center;
              .product-title-div {
                display: flex;
                flex-direction: column;
                margin-left: 0.625rem;
                .product-name {
                  font: 0.875rem normal;
                  color: $black-color;
                  opacity: 1;
                  letter-spacing: 0px;
                }
                .manufacture-code {
                  font: 0.75rem normal;
                  color: $description-color;
                  opacity: 1;
                  letter-spacing: 0.12px;
                  text-transform: uppercase;
                }
              }
            }
        }
      }
    }
  }
  .select-item-footer-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    max-height: 6.5rem;
    position: absolute;
    bottom: 0;
    z-index: 9999999;
    background-color: $white-color;
    padding: 0 1.5625rem;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 25;
      width: calc(100% - 53px);
      height: 0.063rem;
      background-color: $border-color;
    }
    .back-button {
      min-width: 5rem;
      padding: 0.688rem 0;
      .MuiButton-label {
        color: $black-color;
        opacity: 1;
        font-size: 1rem;
        font-weight: 500;
      }
    }
    .select-item-footer-import-all {
      display: flex;
      gap: 0.688rem;
      .import-all-item {
        padding: 0.75rem 1.25rem;
        max-width: 9.5625rem;
        .MuiButton-label {
          font-size: 1rem;
          font-weight: 500;
          opacity: 1;
          letter-spacing: -0.32px;
        }
      }
      .select-item-btn {
        padding: 0.75rem 1.125rem;
        .MuiButton-label {
          font-size: 1rem;
          font-weight: 500;
          opacity: 1;
          letter-spacing: -0.32px;
        }
      }
    }
  }
}

.radio-inp-focus{
  &:has(input:focus-visible){
   border-radius: 1.563rem !important;
          outline: 0.13rem solid var(--bs-gray-600) !important;
  }
}