.user-profile-dialog {
    height: 800px;
    width: 500px;
    display: flex;
    flex-direction: column;

    @media (max-width: 800px) {
        width: 100%;
        height: 90vh;
    }

    .dialog-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 40px;
        border-bottom: 1px solid var(--bs-gray-300);

        @media (max-width: 800px) {
            align-items: flex-start;
            padding: 20px;
            padding-bottom: 0;
        }

        .user-profile-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @media (max-width: 800px) {
                display: grid;
                grid-template-columns: 1fr auto;
                align-items: center;
                column-gap: 10px;
                margin-bottom: 20px;
            }

            .avatars {
                margin-bottom: 10px;
                position: relative;

                @media (max-width: 800px) {
                    grid-row: span 2;
                    margin-bottom: 0;
                }

                .avatars-lock-button {
                    background-color: #000;
                    color: #fff;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    outline: 2px solid #fff;
                }
            }

            .dialog-title {
                font-size: 24px;
                font-family: "Roboto Slab" !important;
                font-weight: 400;
                margin-bottom: 5px;
                line-height: 1.5;

                @media (max-width: 800px) {
                    font-size: 1.25rem;
                    margin-bottom: 0;
                }
            }

            .user-profile-joining-date {
                font-size: 12px;
                color: var(--bs-gray-600);
                margin-bottom: 30px;

                @media (max-width: 800px) {
                    margin-bottom: 0;
                }
            }
        }

        .user-profile-tabs {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 8px;
            gap: 4px;

            @media (max-width: 800px) {
                justify-content: flex-start;
            }

            .user-profile-tab {
                background-color: #fff;
                border: none;
                text-transform: capitalize;
                user-select: none;
                cursor: pointer;
                padding: 10px 12px;
                font-size: 14px;
                font-weight: 400;
                position: relative;
                color: var(--bs-gray-600);
                transition: transform 1s cubic-bezier(0, -0.01, 0, 1.31);
                border-radius: 10px;
                will-change: transform;

                p {
                    margin: 0;
                    padding: 0;
                    line-height: 1;
                    font-family: var(--inter-font-family);
                }

                &:hover,
                &:focus-visible {
                    background-color: var(--bs-gray-100);
                    z-index: 1;
                    outline: none;
                    color: #000;
                }

                &:active {
                    transform: scale(0.95);
                    background-color: var(--bs-gray-300);

                    &::after {
                        transform: translateY(2px) scale(0.95);
                    }
                }

                &:focus-visible {
                    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #000;
                }

                &.active {
                    color: #000;

                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: -8px;
                        width: 100%;
                        height: 2px;
                        background-color: var(--bs-primary);
                        transition: transform 1s cubic-bezier(0, -0.01, 0, 1.31);
                        will-change: transform;
                    }
                }
            }
        }
    }

    .dialog-body {
        flex: 1;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        .user-profile-tab-panes {
            .user-profile-tab-pane {
                padding: 30px 20px;
                padding-bottom: 40px;
                display: flex;
                flex-direction: column;

                &.settings {
                    padding: 0;

                    .settings-button {
                        cursor: pointer;
                        border: none;
                        display: flex;
                        align-items: center;
                        padding: 12px 20px;
                        gap: 20px;
                        background-color: #fff;
                        position: relative;
                        outline-color: #000;
                        outline-offset: -2px;

                        &::after {
                            content: "";
                            position: absolute;
                            width: calc(100% - 60px);
                            right: 0;
                            bottom: 0;
                            height: 1px;
                            background-color: var(--bs-gray-300);
                        }

                        &:disabled {
                            cursor: default;
                        }

                        &:not(:disabled):hover,
                        &:not(:disabled):focus-visible {
                            background-color: var(--bs-gray-100);
                            z-index: 1;

                            &::after {
                                display: none;
                            }

                            .settings-button-arrow-icon {
                                opacity: 1;
                            }
                        }

                        &:not(:disabled):active {
                            background-color: var(--bs-gray-300);
                        }

                        .settings-button-label-group {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 4px;

                            .settings-button-label {
                                font-size: 1rem;
                                font-family: var(--inter-font-family);
                                font-weight: 300;
                                line-height: 1;
                            }
                        }

                        .settings-button-icon-danger {
                            color: var(--bs-danger);
                        }

                        .settings-button-arrow-icon {
                            align-self: flex-end;
                            opacity: 0.25;
                        }
                    }
                }

                &.permissions {
                    .permissions-group {
                        display: flex;
                        flex-direction: column;

                        .permissions-title {
                            font-size: 10px;
                            color: var(--bs-gray-800);
                            text-transform: uppercase;
                            letter-spacing: 1px;
                            margin-bottom: 20px;
                        }

                        .permissions-checkbox-group {
                            padding: 4px;
                            cursor: pointer;
                            font-size: 1rem;
                            font-weight: 300;
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            user-select: none;
                            border-radius: 4px;

                            &.highlight {
                                background-color: var(--bs-gray-50);
                            }

                            input {
                                height: 20px;
                                width: 20px;
                                accent-color: var(--bs-primary);
                            }

                            &:hover,
                            &:focus-visible {
                                background-color: var(--bs-gray-50);
                            }

                            &:active {
                                background-color: var(--bs-gray-300);
                            }
                        }
                    }
                }

                &.hidden {
                    display: none;
                }
            }
        }
    }

    .dialog-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 20px;
        gap: 10px;

        @media (max-width: 800px) {
            justify-content: space-between;
        }
    }

    .skeleton {
        cursor: wait;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        padding: 0;
        padding-top: 40px;
        min-height: 100%;
        width: 100%;

        .skeleton-icon {
            --size: 80px;
            height: var(--size);
            width: var(--size);
            margin-bottom: 10px;
        }

        .dialog-title {
            height: 24px;
            width: 250px;
            margin-bottom: 5px;
        }

        .user-profile-joining-date {
            height: 12px;
            width: 100px;
            margin-bottom: 30px;
        }

        @media (max-width: 800px) {
            min-height: auto;
            display: grid;
            grid-template-columns: 1fr auto;
            column-gap: 10px;
            margin-bottom: 20px;
            width: fit-content;
            align-items: flex-start;
            padding: 20px;
            padding-bottom: 0;

            .skeleton-icon {
                --size: 60px;
                grid-row: span 2;
                margin-bottom: 0;
            }

            .dialog-title {
                height: 20px;
                margin-bottom: 0;
                align-self: flex-end;
            }

            .user-profile-joining-date {
                margin-bottom: 0;
            }
        }

        .skeleton-icon,
        .dialog-title,
        .user-profile-joining-date {
            background-color: var(--bs-gray-200);
            border-radius: 500px;
            animation: skeleton-loop-anim 0.6s ease-out alternate infinite;

            @keyframes skeleton-loop-anim {
                to {
                    opacity: 0.5;
                }
            }
        }
    }
}