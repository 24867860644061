.password-options-dialog {
    .dialog-header {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;

        .dialog-title {
            padding: 0 10px;
            font-size: 24px;
            font-weight: 400;
            font-family: "Roboto Slab", serif !important;

            @media (max-width: 800px) {
                font-size: 1.25rem;
            }
        }
    }

    .password-options {
        margin-top: 20px;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        border-top: 1px solid var(--bs-gray-100);

        .password-option-button {
            border: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 12px 20px;
            gap: 20px;
            background-color: #fff;
            outline-color: #000;
            outline-offset: -2px;
            position: relative;

            &.with-description {
                align-items: flex-start;
            }

            &::after {
                content: "";
                height: 1px;
                width: calc(100% - 60px);
                background-color: var(--bs-gray-200);
                position: absolute;
                bottom: 0;
                right: 0;
            }

            &:hover,
            &:focus-visible {
                background-color: var(--bs-gray-200);

                .arrow-icon {
                    opacity: 1;
                }
            }

            &:active {
                background-color: var(--bs-gray-300);
            }

            .label-group {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                text-align: left;
                font-family: var(--inter-font-family);
                font-weight: 400;

                .password-option-label {
                    font-size: 1rem;
                }

                .password-option-description {
                    font-size: 14px;
                    color: var(--bs-gray-600);
                    line-height: 1.25;
                }
            }

            .arrow-icon {
                opacity: 0.25;
                align-self: center;
            }
        }
    }

    @media (max-width: 800px) {
        padding-bottom: 40px;
    }
}
