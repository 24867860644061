@import "src/apps/pos/assets/css/variable.scss";

.account-register-titles-modal-header {
  border-bottom: 1px solid $light-opacity-gray-color !important;
  button{
    &:focus-visible{
      .clear-icon{
         border-radius: 1.563rem !important;
         outline: 0.13rem solid var(--bs-gray-600) !important;
      }
    }
  }
  .account-register-titles {
    span {
      font-size: 1.25rem;
    }
    img,
    svg {
      height: 1.25rem;
      width: 1.25rem;
      font-size: 1.25rem;
    }
    .awaiting-title {
      color: $darkGray;
    }
  }
}
