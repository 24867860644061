@import "../../../assets/css/variable.scss";

.search-return-customer-modal {
  .MuiDialog-container {
    .MuiDialog-paper {
      width: 100%;
      max-width: 60.625rem;
      height: 100%;
      max-height: 50rem;
      border-radius: 1.25rem;
      max-height: 90vh;
    }
    .search-customer-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        padding: 20px 0px 0px 28px;
        color: $black-color;
        opacity: 1;
        font-size: 1rem;
        font-weight: normal;
        letter-spacing: 0px;
        margin-bottom: 0;
      }
      .model-header-close-button {
        margin-right: 10px;
        margin-top: 7px;
      }
    }
    .MuiDialogContent-root {
      padding: 8px 28px;
      .search-return-customer-modal-section {
        .title-with-search-icon {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .customer-title {
            font-size: 1.75rem;
            font-weight: bold;
            letter-spacing: -0.56px;
            color: $black-color;
            opacity: 1;
          }
          .search-icon {
            margin-right: 20px;
          }
        }
        .search-customer-input {
          margin-top: 25px;
          .MuiOutlinedInput-root {
            padding: 11px 20px;
          }
        }
        .customer-search-table-main-section {
					.customer-search-table-main-container {
						.customer-search-table {
							.customer-search-table-head {
								.customer-search-table-header-row {
									th {
										padding: 5px 10px;
										background-color: white;
										font-size: 10px;
										letter-spacing: 1px;
										color: #000000;
										text-transform: uppercase;
										opacity: 1;
									}
								}
							}
							.table-border-styling {
								tr {
									padding: 5px 10px;
									position: relative;
									&:hover {
										background-color: $bright-Gray;
										border-radius: 0.625rem;
										opacity: 1;
										cursor: pointer;
									}
									&::after {
										content: "";
										position: absolute;
										bottom: 0;
										left: 90px;
										width: calc(100% - 90px);
										height: 0.063rem;
										background-color: var(--bs-gray-200);
									}
									td {
										padding: 15px 10px;
										.user-avatar-list {
											margin-left: 0px;
										}
									}
								}
							}
						}
					}
				}
      }
    }
  }
}
.searched-customer-phone {
  min-width: 5rem;
}
.searched-customer-order-date {
  min-width: 9.375rem;
}