$border-color: #DDDDDD;
$gray: #919191;
$gray-50: #F9F9F9;
$white-color: #ffffff;
$darkGray : #AAAAAA;
$black-color: #000000;
$bgColor: #f1f1f1;
$bright-Gray: #EEEEEE;
$description-color: #555555;
$light-black-color: #0000007a;
$orange-color: #FC6100;
$cinnamon-color: #785200;
$dusty-gray-color: #999999;
$solitaire-color: #fef4e5;
$box-shadow-color: #CCCCCC;
$light-box-shadow-color: #d8d6d6;
$red-color: #ff0000;
$light-gray: #F5F5F5;
$light-gray-color: #c1c1c1;
$light-gray-border: #797979e0;
$gray-88: #888888;
$light-parrot-color: #EAF9EE;
$light-opacity-gray-color: #aaaaaa40;
$white-fff-color: #FFF5F4;
$white: #eaf9ef;
$dark-green : #014E15;
$light-green : #006F1C;
$bg-note-white : #ebf9ef;
$bg-note-green : #105222;
$green-light :#4eb26a;
$black-note : #0000001a;
$gray-note : #899c8e;
$black-overlay : #00000033;
$yellow: #ffd60a;
$dark-yellow: #FFCF6E;