.item-group {
  --header-columns: 2fr 350px 1fr;
  --item-columns: 2rem 5fr 1fr 1fr;

  .item-lines {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}
