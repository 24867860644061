@import "../../apps/pos/assets/css/variable.scss";

.days-group {
    display: flex;
    flex-wrap: wrap;
    gap: 0.625rem;
    margin-top: 0.625rem;

    .days-list {
        background-color: $light-gray;
        border-radius: 2rem;
        padding: 0.5rem 0.875rem;
        font-size: 0.875rem;
        margin-bottom: 1.0625rem;
        cursor: pointer;

        &:has(input:checked) {
            background-color: $black-color !important;
            color: $white-color;
        }

    }
}

.datePicker-group {
    display: flex;
    gap: 0.9375rem;

    .storage-date-picker {
        .MuiFormControl-root {
            .MuiInputBase-root {
                padding: 0 0.5rem 0 0.9375rem;
                height: 2.75rem;
            }
        }
    }

    .title-text {
        font-size: 1rem;
        font-weight: 500;
    }
}

.moving-history-actions{
    margin-top: 1.375rem;
    padding-top: 1.875rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1.25rem;
    gap: 0.625rem;
}

input[type="radio"] {
    position: fixed;
    opacity: 0;
    pointer-events: none;

    &:checked {
        background-color: $black-color;
        color: $white-color;
        font-weight: 500;
    }
}

.custom-range-selected {
    background-color: $black-color !important;
    color: $white-color;
}

.moving-history-table {
    overflow: hidden;
    border-top-left-radius: 1.25rem !important;
    border-top-right-radius: 1.25rem;
    font-family: "Inter", sans-serif;

    .numeric-column {
        max-width: 12ch;
    }

    thead {
        border-bottom: 0.0625rem solid rgb(221, 221, 221);
        top: 0;
        background-color: $white-color;

        tr {
            text-transform: uppercase;
            font-size: 0.625rem;
            letter-spacing: 0.0625rem;

            th {
                padding: 0.9375rem 1.25rem;
                font-weight: 400;
            }
        }
    }

    .moving-history-tbody {
        tr {
            &:nth-child(even) {
                background-color: $gray-50;
            }

            td {
                font-size: .875rem;
                color: $black-color;
                padding: 0.9375rem 1.25rem;
                text-align: left;
            }
        }

        border-bottom: 0.0625rem solid $border-color!important;
    }

}