@import '../../apps/pos/assets/css/variable.scss';

.pos-auto-size-text-area-container {
    background-color: $bgColor;
    padding: 0.9375rem;
    border-radius: 1.25rem;

    .pos-auto-size-text-area {
        width: 100%;
        border-color: $bgColor;
        background-color: $bgColor;
    }
}