.stock-info-dropdown {
    .MuiInputBase-root.MuiFilledInput-root {
       width: fit-content;
       background-color: transparent;
       .MuiSelect-root {
           width: fit-content;
           background-color: transparent;
           padding: 0 2.375rem 0 0;
       }
    }
   }
   