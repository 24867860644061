.card-number-field{
    border: 0;
    background-color: none;
}

.card-provider-icon {
    width: 7%;
    height: auto;
    margin-right: 1rem;
}
.card-provider-icon-none {
    height: 47.222px;
    width: 61.371px;
}

.qwerty {
    .Mui-error {
        border: 0 !important;
    }
}