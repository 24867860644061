@import "src/apps/pos/assets/css/variable.scss";

.btn.btn-discard-edit {
  background-color: #f1f1f1;
  border-radius: 20px;
  display: flex;
  margin-right: 20px;
  align-items: center;
  font-size: 18px;
  font-weight: bold !important;
  font-family: "inter";
  line-height: 21px;
  color: #000000;
  padding-left: 25px;
  border: none;
  justify-content: flex-start !important;
  &:hover {
    background-color: #f1f1f1 !important;
    color: #000000;
  }
}

.btn-save-start-order {
  
 .tabIndex-button{
  width: 445px;
  height: 65px;
  background-color: var(--bs-black);
  border-radius: 1.25rem !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: "inter";
  line-height: 21px;
  color: var(--bs-white);
  padding-left: 25px;
  border: none;
  justify-content:flex-start!important;
  &:focus{
    outline: 2px solid var(--bs-gray-600);
  }
 }
}

.add-cust-loader {
  color: #fff;
  .MuiCircularProgress-colorPrimary {
    color: #ddd !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.username-text {
  font-size: 1rem;
  font-family: "Inter", Regular;
  color: #999999;
  line-height: 20px;
  margin-top: 0.625rem;
}
.customer-company-address {
    .MuiInputBase-input {
      height:6.25rem !important;
  }
  label{
    display: none;
  }
}
.continue-btn-style{
  margin-bottom: 40px;
}

.page-top-btm-padding{
  margin-bottom: 18.75rem;
  margin-top: 1.875rem;
}

.margin-top0{
  margin-top:0px !important;
}

.file-choose-sec{
  input{
    height: 100%;
  }
}

.document-close-icon{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.938rem;
}

.masked-phone-number {
  width: 100%;
  margin-right: 1.25rem !important;
}

.masked-phone-number-input {
  border-radius: 1.25rem;
  border: none;
  display: flex;
  align-items: center;
  padding-left: 1.25rem;
  background-color: var(--bs-gray-100);
  height: 3.75rem;
  margin-bottom: 1.563rem;
  margin-top: 0.625rem !important;
  margin-right: 1.25rem !important;
  font-size: 1.125rem;
  font-style: "inter";
  font-weight: 500;
  width: 100%;
}
.tabInput-mobile:focus{
  outline: 0.13rem solid var(--bs-black);
  background-color: var(--bs-white);
}

.arrow-black {
  .MuiSelect-icon{
    color : rgba(0, 0, 0, 1)
  }
  .MuiSelect-iconFilled{
    right: 1.25rem;
  }
  svg {
    min-width: 1.875rem;
    margin-right: 0.9375rem;
   }
}
.btn.btn-savechanges-add-cust,
.btn-savechanges-add-cust {
  border-radius: 0.9375rem;
  cursor: pointer;
  display: flex;
  // margin-left: 15px;
  align-items: center;
  font-weight: bold;
  font-family: "inter";
  line-height: 1.3125rem;
  color: var(--bs-white);
  border: none;
  justify-content: flex-start;
  font-size: 1rem;
  &:focus {
    outline: 2px solid var(--bs-gray-600) !important;
  }
}

.add-new-customer-continue-btn{
  width: 25rem;
  min-width: 25rem;
  max-width: 25rem;
  height: 4rem;
}

.add-new-customer-go-back-btn{
  min-width: 15.625rem;
  max-width: 15.625rem;
  width: 15.625rem;
  height: 4rem;
}

.new-cust-add-doc-btn{
  width: 13rem;
  min-width: 13rem;
  max-width: 13rem;
  height: 3rem;
}

.form-field-space{
  margin-top: 0.625rem;
}

.lts-1{
  letter-spacing: -1px;
}
.phone-number-field{
  .MuiInputBase-root{
    .MuiFilledInput-input{
      padding-top: 21px;
      padding-bottom: 21px;
    }
  }
}

.form-field-title {
  color: var(--bs-black);
}

.registered-common-info {
  background-color: $light-parrot-color;
  padding: 0.938rem 1.25rem 0.938rem 3.375rem;
  position: relative;
  border-radius: 0.625rem;
  margin-top: 3.125rem;
  img {
    position: absolute;
    left: 1.25rem;
    top: 1.125rem;
    height: 1.5rem;
    width: 1.5rem;
  }
  span {
    font-weight: 500;
  }
}
.tabInput{
  .MuiFilledInput-root{
    padding: 0 !important;
    input{
      width: 100%;
      outline: 0 !important;
      padding: 1.25rem !important;
      border-radius: 1rem;
      background-color: var(--bs-gray-100) !important;
      &:focus{
        background-color: var(--bs-white) !important;
        outline: 0.13rem solid var(--bs-black) !important;  
      }
    }
  }
}
.tabInput-focus{
  .PrivateSwitchBase-input:focus{
      background-color: var(--bs-white) !important;
      outline: 0.13rem solid var(--bs-gray-600) !important;   
    
  }
  input[type="checkbox"]:focus{
    & + {
      .MuiSvgIcon-root{
        border: 0.06rem solid;
      }
    }
  }
  &:focus{
    outline: 0.13rem solid var(--bs-gray-600) !important;
  }
  
}