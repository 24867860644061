@import "../assets/css/variable.scss";

.cart-page-drawer-sec.main-drawer-classes .MuiPaper-root {
    width: 100%;
}

.cart-drawer-close-icon.btn {
    right: 0rem !important;
    left: 0 !important;
    top: 6.25rem !important;
    position: relative !important;

    @media only screen and (min-width: 60rem) {
        position: absolute !important;
        left: -4.75rem !important;
        right: unset !important;
    }
}

.balance-left-info {
    position: relative;
}
.px-16{
    padding-left: 1rem;
    padding-right: 1rem;
}

.balance-left-add-info:before,
.balance-left-remove-info:before {
    position: absolute;
    font-size: 1.25rem;
    font-weight: 500;
    left: -25px;
}

.balance-left-add-info {
    span {
        text-align: end !important;
    }

    &.text-warning-balance {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: baseline !important;
        font-size: 0.875rem;
    }

    .span-text-red {
        color: $orange-color;

        .text-blod {
            font-weight: bold;
        }
    }
}

.balance-left-remove-info {
    &.text-warning-balance {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: baseline !important;
        font-size: 0.875rem;
    }

    .span-text-red {
        color: $orange-color;

        .text-blod {
            font-weight: bold;
        }
    }
}

.balance-left-add-info:before {
    content: "+";
}

.balance-left-remove-info:before {
    content: "-";
}

.cart-drawer-close-icon.btn {
    right: 0rem !important;
    left: 0 !important;
    top: 6.25rem !important;
    position: relative !important;

    @media only screen and (min-width: 60rem) {
        position: absolute !important;
        left: -5.625rem !important;
        right: unset !important;
    }
}

.unpaid-balance {
    position: relative;

    &::before {
        position: absolute;
        top: 0.5625rem;
        left: 1.25rem;
        width: 0.625rem;
        height: 0.625rem;
        content: "$";
        display: inline-block;
        font-size: 1.0625rem;
        z-index: 1;
        color: $black-color;
    }

    input {
        width: 100% !important;
        background-color: $bright-Gray !important;
        padding: 0.625rem 2.0625rem !important;
        border-radius: 0.625rem !important;

        &:focus {
            border: 0.0625rem solid $black-color !important;
            background-color: $bright-Gray !important;
        }
    }

    &.error-invalid {
        input {
            &:focus {
                background: $white-fff-color 0% 0% no-repeat padding-box;
                border: 0.0625rem solid $orange-color !important;
            }
        }
    }
}

.saved-draft-list-new{
    pointer-events: auto !important;
}

.skip-for-now-btn{
    min-width: 15.625rem;
    max-width: 15.625rem;
    width: 15.625rem;
    height: 3.75rem;
}

.balance-credit-btn{
    min-width: 25rem;
    max-width: 25rem;
    width: 25rem;
    height: 3.75rem;
}
