.header-search-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    outline: none;

    &:focus-within {
        .header-search-clear-btn {
            display: flex;
        }

        .header-search-shortcut {
            display: none;
        }
    }

    @media (max-width: 1000px) {
        &.header-mobile-hidden {
            display: none;
        }
    }

    @media (max-width: 800px) {
        width: calc(100% - 70px);
        left: 20px;
    }

    @media print {
        display: none;
    }
}
.header-search-icon {
    --size: 22px;
    height: var(--size);
    width: var(--size);
    position: absolute;
    left: 12px;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-image: url(data:image/svg+xml;base64,PHN2ZwogICAgICAgICAgICAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgICAgICAgICAgICBoZWlnaHQ9IjI0IgogICAgICAgICAgICAgIHZpZXdCb3g9IjAgMCAyNCAyNCIKICAgICAgICAgICAgICB3aWR0aD0iMjQiCiAgICAgICAgICAgID4KICAgICAgICAgICAgICA8cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiIC8+CiAgICAgICAgICAgICAgPHBhdGgKICAgICAgICAgICAgICAgIGQ9Ik0xNS41IDE0aC0uNzlsLS4yOC0uMjdjMS4yLTEuNCAxLjgyLTMuMzEgMS40OC01LjM0LS40Ny0yLjc4LTIuNzktNS01LjU5LTUuMzQtNC4yMy0uNTItNy43OSAzLjA0LTcuMjcgNy4yNy4zNCAyLjggMi41NiA1LjEyIDUuMzQgNS41OSAyLjAzLjM0IDMuOTQtLjI4IDUuMzQtMS40OGwuMjcuMjh2Ljc5bDQuMjUgNC4yNWMuNDEuNDEgMS4wOC40MSAxLjQ5IDAgLjQxLS40MS40MS0xLjA4IDAtMS40OUwxNS41IDE0em0tNiAwQzcuMDEgMTQgNSAxMS45OSA1IDkuNVM3LjAxIDUgOS41IDUgMTQgNy4wMSAxNCA5LjUgMTEuOTkgMTQgOS41IDE0eiIKICAgICAgICAgICAgICAvPgogICAgICAgICAgICA8L3N2Zz4=);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.header-search-icon-loading {
    background-image: none;
}
.header-search-icon-loading::after {
    content: "";
    --size: 16px;
    height: var(--size);
    width: var(--size);
    border: 2px solid var(--bs-primary);
    border-top-color: rgba(255, 255, 255, 0);
    border-left-color: rgba(255, 255, 255, 0);
    display: flex;
    border-radius: 500px;
    animation: search-loading-anim 0.4s linear infinite;
}
@keyframes search-loading-anim {
    to {
        transform: rotate(360deg);
    }
}

.header-search {
    height: 40px;
    max-width: 650px;
    width: 650px;
    display: flex;
    align-items: center;
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-100);
    border-radius: 500px;
    cursor: text;
    padding-left: calc(20px + 24px);
    padding-right: calc(20px + 24px);
    font-size: 15px;
    line-height: 1;
    font-family: var(--inter-font-family);
    transition: background-color 0.1s ease, border 0.1s ease, transform 0.2s ease-out;

    &:hover {
        border: 1px solid var(--bs-gray-600);
    }

    &:focus {
        background-color: #fff;
        border: 1px solid var(--bs-primary);
        outline: none;
    }

    @media (max-width: 1200px) {
        max-width: 500px;
        width: 500px;
    }

    @media (max-width: 800px) {
        max-width: 100%;
        width: 100%;
    }
}
.header-search-clear-btn {
    --size: 30px;
    background-color: rgba(255, 255, 255, 0);
    background-image: url(data:image/svg+xml;base64,PHN2ZwogICAgICAgICAgICAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgICAgICAgICAgICBoZWlnaHQ9IjI0IgogICAgICAgICAgICAgIHZpZXdCb3g9IjAgMCAyNCAyNCIKICAgICAgICAgICAgICB3aWR0aD0iMjQiCiAgICAgICAgICAgID4KICAgICAgICAgICAgICA8cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiIG9wYWNpdHk9Ii44NyIgLz4KICAgICAgICAgICAgICA8cGF0aAogICAgICAgICAgICAgICAgZD0iTTEyIDJDNi40NyAyIDIgNi40NyAyIDEyczQuNDcgMTAgMTAgMTAgMTAtNC40NyAxMC0xMFMxNy41MyAyIDEyIDJ6bTQuMyAxNC4zYy0uMzkuMzktMS4wMi4zOS0xLjQxIDBMMTIgMTMuNDEgOS4xMSAxNi4zYy0uMzkuMzktMS4wMi4zOS0xLjQxIDAtLjM5LS4zOS0uMzktMS4wMiAwLTEuNDFMMTAuNTkgMTIgNy43IDkuMTFjLS4zOS0uMzktLjM5LTEuMDIgMC0xLjQxLjM5LS4zOSAxLjAyLS4zOSAxLjQxIDBMMTIgMTAuNTlsMi44OS0yLjg5Yy4zOS0uMzkgMS4wMi0uMzkgMS40MSAwIC4zOS4zOS4zOSAxLjAyIDAgMS40MUwxMy40MSAxMmwyLjg5IDIuODljLjM4LjM4LjM4IDEuMDIgMCAxLjQxeiIKICAgICAgICAgICAgICAvPgogICAgICAgICAgICA8L3N2Zz4=);
    background-position: center;
    background-repeat: no-repeat;
    font-size: 0;
    border: none;
    cursor: pointer;
    height: var(--size);
    min-width: var(--size);
    padding: 0;
    border-radius: 500px;
    align-items: center;
    justify-content: center;
    will-change: transform, opacity;
    opacity: 0.35;
    position: absolute;
    right: 5px;
    display: none;
    animation: search-clear-btn-intro 0.2s cubic-bezier(0, -0.01, 0, 1.1);

    @keyframes search-clear-btn-intro {
        from {
            opacity: 0;
            transform: scale(0.75);
        }
        to {
            opacity: 0.35;
            transform: scale(1);
        }
    }

    &:focus-visible {
        outline: none;
        box-shadow: 0 0 0 2px #fff, 0 0 0 4px var(--bs-gray-800);
    }

    &:hover,
    &:focus-visible {
        background-color: var(--bs-gray-200);
        opacity: 1;
    }

    &:active {
        transform: scale(0.9);
        background-color: var(--bs-gray-400);
    }
}

.header-search-wrapper:focus-within .header-search-clear-btn-hidden {
    display: none;
}
.header-search-shortcut {
    position: absolute;
    right: 15px;
    font-size: 12px;
    color: var(--bs-gray-800);
    user-select: none;
    pointer-events: none;

    span {
        border: 1px solid var(--bs-gray-200);
        background-color: var(--bs-gray-50);
        border-radius: 4px;
        padding: 2px 4px;
        box-shadow: -1px 1px 0 0 rgba(0, 0, 0, 0.05), 1px 1px 0 0 rgba(0, 0, 0, 0.05);
        margin: 0 4px;
    }

    @media (max-width: 1000px) {
        display: none;
    }
}

.header-search-tooltip {
    position: absolute;
    right: 15px;
    font-size: 12px;
    color: var(--bs-gray-500);
    user-select: none;
    pointer-events: none;
    display: flex;
    align-items: center;
    gap: 5px;
    visibility: hidden;
    margin: 0;

    .return-icon {
        height: 1em;
        width: 1em;
        background-image: url(data:image/svg+xml;base64,PHN2ZwogICAgICAgICAgICAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgICAgICAgICAgICB3aWR0aD0iMTIiCiAgICAgICAgICAgICAgaGVpZ2h0PSI3LjIxNiIKICAgICAgICAgICAgICB2aWV3Qm94PSIwIDAgMTIgNy4yMTYiCiAgICAgICAgICAgID4KICAgICAgICAgICAgICA8cGF0aAogICAgICAgICAgICAgICAgZD0iTTEzLjEyNiw3LjQzOVY5LjM3N2gtOC41bDEuODYtMS44NmEuNjQ0LjY0NCwwLDAsMC0uOTExLS45MTFMMi42MDYsOS41N2EuNjQzLjY0MywwLDAsMCwwLC45MTFMNS41NywxMy40NDVhLjY0NC42NDQsMCwwLDAsLjkxMS0uOTExbC0xLjg2LTEuODY2aDkuMTUxYS42NDguNjQ4LDAsMCwwLC42NDYtLjY0NlY3LjQzOWEuNjQ2LjY0NiwwLDAsMC0xLjI5MiwwWiIKICAgICAgICAgICAgICAgIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yLjQxOCAtNi40MTgpIgogICAgICAgICAgICAgICAgZmlsbD0iI2FhYSIKICAgICAgICAgICAgICAvPgogICAgICAgICAgICA8L3N2Zz4=);
        background-position: center;
        background-repeat: no-repeat;
    }
}
.header-search-wrapper:focus-within .header-search-tooltip {
    visibility: visible;
}
.header-search-tooltip.hidden {
    display: none;
}

.header-search-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    position: fixed;
    top: 60px;
    left: 0;
    background-color: #fff;
    height: calc(100vh - 60px);
    outline: none;
    overflow-y: scroll;
    transform: translateY(-10px);
    opacity: 0;
    will-change: transform, opacity;
    animation: header-search-intro 0.4s cubic-bezier(0, -0.01, 0, 1) forwards;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    &.hidden {
        display: none;
    }

    @keyframes header-search-intro {
        to {
            transform: translateY(0%);
            opacity: 1;
        }
    }

    @media print {
        display: none;
    }

    .search-suggestions {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        max-width: 650px;

        &.hidden {
            display: none;
        }

        @media (max-width: 1200px) {
            max-width: 500px;
            width: 500px;
        }

        @media (max-width: 1000px) {
            max-width: 100%;
            width: 100%;
            padding: 0 20px;
        }
    }

    .search-suggestions-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 8px;

        .title {
            font-size: 14px;
            color: var(--bs-gray-600);
            margin: 0;
            padding: 0;
        }

        .clear-all-searches-btn {
            border: none;
            font-size: 12px;
            cursor: pointer;
            padding: 6px 12px;
            border-radius: 500px;
            background-color: #fff;
            font-family: var(--inter-font-family);
            font-weight: 500;
            user-select: none;
            color: var(--bs-gray-600);
            transition: transform 1s cubic-bezier(0, -0.01, 0, 1.31);
            will-change: transform;

            &:hover,
            &:focus {
                background-color: var(--bs-gray-100);
                color: #000;
                outline: none;
                transform: scale(1.05);
            }

            &:focus-visible {
                box-shadow: 0 0 0 2px #fff, 0 0 0 4px var(--bs-gray-400);
            }

            &:active {
                transform: scale(0.95);
                background-color: var(--bs-gray-200);
            }
        }
    }

    .search-suggestions-list {
        display: flex;
        flex-direction: column;
    }

    .search-suggestion-item {
        padding: 12px 40px;
        padding-left: calc(20px + 24px);
        background-color: rgba(255, 255, 255, 0);
        user-select: none;
        cursor: pointer;
        border: none;
        position: relative;
        border-radius: 10px;
        padding-right: 10px;
        transition: transform 1s cubic-bezier(0, -0.01, 0, 1.31);
        will-change: transform;
        display: flex;
        align-items: center;
        color: #000;
        line-height: 1;

        &:hover,
        &:focus {
            outline: none;
            background-color: var(--bs-gray-100);

            &::before {
                opacity: 1;
            }
        }

        &:focus-visible {
            box-shadow: 0 0 0 2px #fff, 0 0 0 4px var(--bs-gray-400);
        }

        &:active {
            background-color: var(--bs-gray-200);
            transform: scale(0.995);
        }

        &::before {
            content: "";
            height: 20px;
            width: 20px;
            display: flex;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNMTMuMjYgM0M4LjE3IDIuODYgNCA2Ljk1IDQgMTJIMi4yMWMtLjQ1IDAtLjY3LjU0LS4zNS44NWwyLjc5IDIuOGMuMi4yLjUxLjIuNzEgMGwyLjc5LTIuOGMuMzEtLjMxLjA5LS44NS0uMzYtLjg1SDZjMC0zLjkgMy4xOC03LjA1IDcuMS03IDMuNzIuMDUgNi44NSAzLjE4IDYuOSA2LjkuMDUgMy45MS0zLjEgNy4xLTcgNy4xLTEuNjEgMC0zLjEtLjU1LTQuMjgtMS40OC0uNC0uMzEtLjk2LS4yOC0xLjMyLjA4LS40Mi40Mi0uMzkgMS4xMy4wOCAxLjQ5QzkgMjAuMjkgMTAuOTEgMjEgMTMgMjFjNS4wNSAwIDkuMTQtNC4xNyA5LTkuMjYtLjEzLTQuNjktNC4wNS04LjYxLTguNzQtOC43NHptLS41MSA1Yy0uNDEgMC0uNzUuMzQtLjc1Ljc1djMuNjhjMCAuMzUuMTkuNjguNDkuODZsMy4xMiAxLjg1Yy4zNi4yMS44Mi4wOSAxLjAzLS4yNi4yMS0uMzYuMDktLjgyLS4yNi0xLjAzbC0yLjg4LTEuNzF2LTMuNGMwLS40LS4zNC0uNzQtLjc1LS43NHoiLz48L3N2Zz4=);
            background-position: center;
            background-repeat: no-repeat;
            position: absolute;
            left: 10px;
            opacity: 0.35;
        }

        .label {
            font-size: 1rem;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            line-height: 1.25;
            text-overflow: ellipsis;
            color: #000;
        }
    }

    .search-results-wrapper {
        width: 100%;
        padding: 0 60px;
        display: flex;
        flex-direction: column;
        padding-bottom: 50vh;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        &.hidden {
            display: none;
        }

        @media (max-width: 1000px) {
            padding: 0;
        }

        .search-tabs {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0;
            padding-bottom: 8px;
            padding-top: 10px;
            gap: 4px;
            border-bottom: 1px solid var(--bs-gray-100);
            position: sticky;
            top: 0;
            z-index: 11;
            background-color: #fff;
            line-height: 1;
            margin: 0;

            &.hidden {
                display: none;
            }

            @media (max-width: 1000px) {
                padding-left: 30px;
                padding-right: 30px;
                display: flex;
                overflow-x: scroll;
                flex-shrink: 0;
            }

            .search-tab {
                white-space: nowrap;
                display: flex;
                align-items: center;
                padding: 8px;
                cursor: pointer;
                border-radius: 8px;
                border: none;
                background-color: #fff;
                color: #000;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0.25px;
                font-family: var(--inter-font-family);
                transition: transform 1s cubic-bezier(0, -0.01, 0, 1.31);
                will-change: transform;

                &:hover,
                &:focus {
                    background-color: var(--bs-gray-100);
                    outline: none;
                }

                &:focus-visible {
                    box-shadow: 0 0 0 2px #fff, 0 0 0 4px var(--bs-gray-400);
                }

                &:active {
                    background-color: var(--bs-gray-300);
                    transform: scale(0.95);
                }

                &.faded {
                    opacity: 0.25;

                    &:hover,
                    &:focus,
                    &:focus-visible {
                        opacity: 1;
                    }
                }

                &::before {
                    content: attr(data-count);
                    padding-right: 0.25em;
                }

                &.active {
                    position: relative;
                    opacity: 1;
                    color: var(--bs-primary);

                    &::after {
                        position: absolute;
                        content: "";
                        bottom: -8px;
                        left: 0;
                        width: 100%;
                        height: 2px;
                        border-radius: 500px;
                        background-color: var(--bs-primary);
                    }
                }
            }
        }

        .search-results-container {
            display: flex;
            flex-direction: column;

            &.hidden {
                display: none;
            }

            .search-tab-block {
                @media (max-width: 1000px) {
                    padding: 0 30px;
                    padding-bottom: 50vh;
                }

                .header {
                    display: grid;
                    align-items: flex-start;
                    grid-template-columns: 1fr 5fr 1fr 1fr;
                    gap: 20px;
                    padding: 10px;
                    padding-top: 20px;
                    position: sticky;
                    top: 48px;
                    background-color: #fff;
                    border-bottom: 1px solid var(--bs-gray-200);

                    @media (max-width: 1000px) {
                        display: none;
                    }

                    .header-label {
                        font-size: 10px;
                        font-weight: 500;
                        letter-spacing: 1px;
                        color: var(--bs-gray-800);
                        text-transform: uppercase;
                        user-select: none;
                    }
                }

                .list-search {
                    margin: 0;
                    padding: 0;
                }

                .more-results {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-end;
                    width: 100%;
                    height: 120vh;
                    position: relative;
                    top: -100vh;
                    margin-bottom: -100vh;
                    pointer-events: none;

                    &::before {
                        content: "";
                        --size: 24px;
                        height: var(--size);
                        width: var(--size);
                        border: 3px solid var(--bs-primary);
                        border-top-color: rgba(255, 255, 255, 0);
                        border-left-color: rgba(255, 255, 255, 0);
                        display: flex;
                        border-radius: 500px;
                        animation: more-results-loading-anim 0.4s linear infinite;

                        @keyframes more-results-loading-anim {
                            to {
                                transform: rotate(360deg);
                            }
                        }
                    }

                    &::after {
                        content: "Looking for more results...";
                        text-align: center;
                        display: flex;
                        margin-top: 20px;
                        margin-bottom: 40px;
                        font-size: 14px;
                        color: var(--bs-gray-600);
                    }
                }

                .search-empty-state-container {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 50px;
                    gap: 4px;

                    &::before {
                        --size: 80px;
                        content: "";
                        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4OS4yNTciIGhlaWdodD0iOTYuNTA4IiB2aWV3Qm94PSIwIDAgODkuMjU3IDk2LjUwOCI+CiAgPGcgaWQ9Ikdyb3VwXzI3MjQiIGRhdGEtbmFtZT0iR3JvdXAgMjcyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEzNDQgLTQ4NS40OTIpIj4KICAgIDxjaXJjbGUgaWQ9IkVsbGlwc2VfMjIiIGRhdGEtbmFtZT0iRWxsaXBzZSAyMiIgY3g9IjE2IiBjeT0iMTYiIHI9IjE2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMzg5LjQzOCA0ODUuNDkyKSByb3RhdGUoMjApIiBmaWxsPSIjZGRkIi8+CiAgICA8ZWxsaXBzZSBpZD0iRWxsaXBzZV8yMyIgZGF0YS1uYW1lPSJFbGxpcHNlIDIzIiBjeD0iMzUiIGN5PSIxMCIgcng9IjM1IiByeT0iMTAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEzNjcuNDc5IDQ5MC4yNzEpIHJvdGF0ZSgyMCkiIGZpbGw9IiNhYWEiLz4KICAgIDxwYXRoIGlkPSJQYXRoXzM0OCIgZGF0YS1uYW1lPSJQYXRoIDM0OCIgZD0iTTUzLDBWNzNIMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEzNDQgNTA5KSIgZmlsbD0iI2VlZSIvPgogICAgPGcgaWQ9Ikdyb3VwXzI3MjMiIGRhdGEtbmFtZT0iR3JvdXAgMjcyMyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTM2MyA1NTApIj4KICAgICAgPHBhdGggaWQ9IlBhdGhfMzQ2IiBkYXRhLW5hbWU9IlBhdGggMzQ2IiBkPSJNMCwwSDMwVjMwSDBaIiBmaWxsPSJub25lIi8+CiAgICAgIDxwYXRoIGlkPSJQYXRoXzM0NyIgZGF0YS1uYW1lPSJQYXRoIDM0NyIgZD0iTTE4LjYyNSwxNi43NWgtLjk4N2wtLjM1LS4zMzhhOC4xNDgsOC4xNDgsMCwxLDAtLjg3NS44NzVsLjMzOC4zNXYuOTg3bDUuMzEzLDUuMzEyYTEuMzE3LDEuMzE3LDAsMCwwLDEuODYyLTEuODYyWm0tNy41LDBhNS42MjUsNS42MjUsMCwxLDEsNS42MjUtNS42MjVBNS42MTcsNS42MTcsMCwwLDEsMTEuMTI1LDE2Ljc1WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC43NSAwLjc1KSIgZmlsbD0iIzM4MjYwMCIvPgogICAgPC9nPgogIDwvZz4KPC9zdmc+Cg==);
                        height: var(--size);
                        width: var(--size);
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;

                        @media (max-width: 1000px) {
                            --size: 60px;
                        }
                    }

                    .message,
                    .desc {
                        text-align: center;
                        line-height: 1.5;
                    }

                    .message {
                        margin: 0;
                        margin-top: 20px;

                        @media (max-width: 1000px) {
                            font-size: 14px;
                        }
                    }

                    .desc {
                        font-size: 14px;
                        color: var(--bs-gray-600);
                        margin: 0;

                        @media (max-width: 1000px) {
                            font-size: 12px;
                        }
                    }

                    .more-links {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        .more-results-link {
                            color: inherit;
                            padding: 10px 15px;
                            padding-right: 40px;
                            text-decoration: none;
                            background-color: var(--bs-gray-200);
                            border-radius: 500px;
                            position: relative;
                            display: flex;
                            align-items: center;
                            transition: transform 1s cubic-bezier(0, -0.01, 0, 1.31);
                            will-change: transform;
                            font-size: 14px;

                            &:hover,
                            &:focus {
                                background-color: var(--bs-gray-300);
                                outline: none;
                            }

                            &:focus-visible {
                                box-shadow: 0 0 0 2px #fff, 0 0 0 4px var(--bs-gray-400);
                            }

                            &:active {
                                background-color: var(--bs-gray-300);
                                transform: scale(0.975);
                            }

                            &::after {
                                --size: 20px;
                                content: "";
                                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNOS4yOSA2LjcxYy0uMzkuMzktLjM5IDEuMDIgMCAxLjQxTDEzLjE3IDEybC0zLjg4IDMuODhjLS4zOS4zOS0uMzkgMS4wMiAwIDEuNDEuMzkuMzkgMS4wMi4zOSAxLjQxIDBsNC41OS00LjU5Yy4zOS0uMzkuMzktMS4wMiAwLTEuNDFMMTAuNyA2LjdjLS4zOC0uMzgtMS4wMi0uMzgtMS40MS4wMXoiLz48L3N2Zz4=);
                                height: var(--size);
                                width: var(--size);
                                background-position: center;
                                background-size: contain;
                                position: absolute;
                                right: 15px;
                            }
                        }
                    }
                }
            }

            .search-result {
                display: grid;
                align-items: flex-start;
                grid-template-columns: 1fr 5fr 1fr 1fr;
                gap: 20px;
                padding: 12px 10px;
                color: inherit;
                text-decoration: none;
                border-radius: 5px;

                &:nth-child(odd) {
                    background-color: #f9f9f9;

                    @media (max-width: 1000px) {
                        background-color: #fff;
                    }
                }

                &:hover,
                &:focus {
                    background-color: var(--bs-gray-200);
                    outline: none;
                }

                &:focus-visible {
                    box-shadow: 0 0 0 2px #fff, 0 0 0 4px var(--bs-gray-400);
                    z-index: 1;
                    position: relative;
                }

                &:active {
                    background-color: var(--bs-gray-300);
                    transform: scale(0.995);
                }

                @media (max-width: 1000px) {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    padding: 20px 0;
                    border-radius: 0;
                    position: relative;
                    border-bottom: 1px solid var(--bs-gray-200);
                }

                p {
                    font-size: 14px;
                    line-height: 1.25;
                    color: var(--bs-gray-800);
                    margin: 0;
                    padding: 0;
                }

                .date {
                    @media (max-width: 1000px) {
                        font-size: 12px;
                    }
                }

                .name {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;

                    @media (max-width: 1000px) {
                        overflow: hidden;
                        max-width: 100%;
                        order: -1;
                        margin-bottom: 10px;
                    }
                }

                .title,
                .description {
                    max-width: 600px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    .es-highlight {
                        background-color: lightgray;
                        font-weight: 800;
                    }
                    
                    @media (max-width: 1200px) {
                        max-width: 500px;
                    }

                    @media (max-width: 1000px) {
                        max-width: 100%;
                    }
                }

                .title {
                    font-size: 15px;
                    color: #000;
                    line-height: 1.35;

                    @media (max-width: 1000px) {
                        white-space: pre-wrap;
                    }
                }

                .description {
                    font-size: 12px;
                    color: var(--bs-gray-800);

                    &:empty {
                        display: none;
                    }

                    @media (max-width: 1000px) {
                        font-size: 14px;
                    }
                }

                .type,
                .status {
                    @media (max-width: 1000px) {
                        font-size: 12px;
                        letter-spacing: 0.5px;
                    }
                }
            }
        }
    }
}

.close-search-btn {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNMTkgMTFINy44M2w0Ljg4LTQuODhjLjM5LS4zOS4zOS0xLjAzIDAtMS40Mi0uMzktLjM5LTEuMDItLjM5LTEuNDEgMGwtNi41OSA2LjU5Yy0uMzkuMzktLjM5IDEuMDIgMCAxLjQxbDYuNTkgNi41OWMuMzkuMzkgMS4wMi4zOSAxLjQxIDAgLjM5LS4zOS4zOS0xLjAyIDAtMS40MUw3LjgzIDEzSDE5Yy41NSAwIDEtLjQ1IDEtMXMtLjQ1LTEtMS0xeiIvPjwvc3ZnPg==);
    --size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 500px;
    height: var(--size);
    width: var(--size);
    cursor: pointer;
    padding: 0;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    font-size: 0;
    user-select: none;
    position: absolute;
    left: 10px;
    background-color: #fff;
    transition: transform 1s cubic-bezier(0, -0.01, 0, 1.31);
    will-change: transform;
    background-position: 100%;
    background-size: 25%;
    animation: close-search-btn-anim 0.2s cubic-bezier(0, -0.01, 0, 1.1) forwards;

    @keyframes close-search-btn-anim {
        to {
            background-position: 50%;
            background-size: 65%;
        }
    }

    &:hover,
    &:focus {
        background-color: var(--bs-gray-100);
        outline: none;
    }

    &:focus-visible {
        box-shadow: 0 0 0 2px #fff, 0 0 0 4px var(--bs-gray-400);
    }

    &:active {
        background-color: var(--bs-gray-300);
        transform: scale(0.9);
    }

    &.hidden {
        display: none;
    }
}

.target_scroll_search {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 120vh;
    position: relative;
    top: -100vh;
    margin-bottom: -100vh;
    pointer-events: none;
}