.edit-purchase-order-field{
  input.MuiInput-input{
    font-size: 1.25rem;
    font-weight: 600;
  }  
}
.edit-purchase-discard-btn{
  width: 20.313rem;
  min-width: 20.313rem;
  max-width: 20.313rem;
  height: 3.75rem;
}