@import "../../assets/css/variable.scss";

.quote-model-main-container {
  .MuiDialog-container {
    .MuiDialog-paper {
      width: 100%;
      min-width: 70vw;
      height: 100%;
      max-height: 46.875rem;
      border-radius: 1.25rem;
      .model-header-main-section {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: $white-color;
        .header-container {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          .header-title-div {
            display: flex;
            flex-direction: column;
            gap: 0.125rem;
            width: 100%;
            max-width: 15.625rem;
            padding: 1.5625rem 0px 0px 1.5625rem;
            .main-title {
              color: $black-color;
              opacity: 1;
              font-size: 1.25rem;
              font-weight: bold;
            }
            .description {
              color: $black-color;
              opacity: 1;
              font: 0.875rem normal;
            }
          }
          .quote-search-input {
            width: 100%;
            max-width: 26.25rem;
            .MuiInputBase-root {
              padding: 0.625rem 0.875rem;
              .MuiInputAdornment-root {
                .MuiSvgIcon-root {
                  fill: $black-color;
                  opacity: 1;
                }
              }
            }
            .MuiOutlinedInput-input {
              color: $black-color;
              opacity: 1;
              font-size: 1rem;
              font-weight: normal;
              letter-spacing: 0.16px;
            }
          }
          .model-header-close-button {
            padding: 0.3125rem;
            margin-right: 0.625rem;
            margin-left: 10.625rem;
            svg {
              fill: $gray;
              width: 1.5rem;
              height: 1.5rem;
            }
            &:hover {
              background-color: $bright-Gray;
              svg {
                fill: $black-color;
              }
            }
          }
        }
        
        .quote-list-header {
          margin-top: 0.625rem;
        }
      }
      .select-item-loading {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: unset !important;
        margin-left: unset !important;
        max-height: 500px;
      }
      .MuiDialogContent-root {
        padding: 0;
        .item-list {
          width: 100% !important;
        }
        .import-quote-main-table-section {
          padding: 0 1.5625rem;
           height: auto !important;
          .item-import-table-body-section {
            .item-isSelected-values {
              background: $border-color 0% 0% no-repeat padding-box;
              opacity: 1;
              border-radius: 0.625rem;
              &:hover, &:focus {
                background-color: $border-color !important;
              }
            }
            .item-import-body-container {
              display: flex;
              width: 100%;
              height: 100%;
              align-items: center;
              min-height: 70px;
              cursor: pointer;
              &:hover, &:focus {
                  background-color: $gray-50 !important;
                  border-radius: 0.625rem;
                  opacity: 1;
                  cursor: pointer;
                }
                &::after {
                  content: "";
                  position: absolute;
                  bottom: 0;
                  left: 6.5625rem;
                  width: calc(100% - 140px);
                  height: 0.063rem;
                  background-color: var(--bs-gray-200);
                }
              .item-import-wrapper-main {
                font: 1rem normal;
                color: $black-color;
                opacity: 1;
                letter-spacing: 0px;
                &:nth-child(1)  {
                  .title {
                    padding-left: 0.3125rem;
                  }
                }
                  .title {
                    .MuiButtonBase-root {
                      .MuiSvgIcon-root  {
                        fill: $border-color;
                      width: 20px;
                      height: 20px;
                      }
                      .check-icon { 
                        color: $black-color;
                        fill: unset !important;
                      }
                    }
                  }
                  .image-with-title {
                    display: flex;
                    flex-direction: column;
                    .title-id {
                      font: 1rem normal;
                      color: $black-color;
                      opacity: 1;
                      letter-spacing: 0px;
                    }
                    .title-description {
                      font: 0.875rem normal;
                      color: $description-color;
                      opacity: 1;
                      letter-spacing: 0px;
                    }
                    .product-title-div {
                      display: flex;
                      flex-direction: column;
                      margin-left: 10px;
                    }
                  }
              }
              
            }
          }
      }
        .import-no-data-found {
          height: 100%;
          width: 100%;
          max-height: 32.8125rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
          .model-footer-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 100%;
            max-height: 104px;
            position: absolute;
            bottom: 0;
            z-index: 1;
            background-color: $white-color;
            padding: 0 1.5625rem;
            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 1.5625rem;
              width: calc(100% - 53px);
              height: 0.063rem;
              background-color: $border-color;
            }
            .title {
              color: $black-color;
              font-size: 1rem;
              opacity: 1;
              font-weight: 500;
              letter-spacing: 0.16px;
            }
            .select-item-btn {
              padding: 0.75rem 1.25rem;
              .MuiButton-label {
                font-size: 1rem;
                font-weight: 500;
                opacity: 1;
                letter-spacing: -0.32px;
              }
            }
          }
      }
    }
  }
}
.item-import-header-section {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content:space-between;
  margin-top: 0.5rem;
  align-items: center;
  background: $white-color;
  z-index: 99;
  padding: 0 1.5625rem;
  min-height: 2.5rem;
  .item-import-wrapper-main {
      color: $black-color;
      opacity: 1;
      font-size: 1rem;
      .title {
        .MuiButtonBase-root {
          .MuiSvgIcon-root  {
            fill: $border-color;
          width: 1.25rem;
          height: 1.25rem;
          }
          .check-icon { 
            color: $black-color;
            fill: unset !important;
          }
        }
      }
  }
}
.item-list > div:first-child {
  .import-quote-main-table-section:first-child .item-import-table-body-section  {
    .quote-sec-tille{
      display: block;
      padding-bottom: 0.625rem;
    }}
}
.quote-sec-tille{
  display: none;
}
.import-quote-title {
  display: flex;
  padding: 1.5rem 1.5625rem 0.188rem;
  .item-import-wrapper-main {
    text-transform: uppercase;
    font-size: 0.625rem;
  }
}
.first-quote-info {
  width: 5%;
  @media (min-width: 600px) {
    width: 15%;
  }
  @media (min-width: 900px) {
    width: 8%;
  }
  @media (min-width: 1200px) {
    width: 5%;
  }
}
.second-quote-info {
  width: 45%;
  @media (min-width: 600px) {
    width: 35%;
  }
  @media (min-width: 900px) {
    width: 42%;
  }
  @media (min-width: 1200px) {
    width: 45%;
  }
}
.third-quote-info {
  width: 12.5%;
}
.fourth {
  width: 12.5%;
}
.fifth {
  width: 12.5%;
}
.sixth {
  width: 12.5%;
}