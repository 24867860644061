.product-detail-outlined-input .MuiInputBase-root.MuiOutlinedInput-root {
    padding: 0.75rem;
  }
  .product-detail-input-spacing.product-detail-outlined-input {
    margin-top: 0.625rem;
  }
  
  .accessories-main-info-sec {
    position: relative;
    padding-bottom: 0.625rem;
    margin-bottom: 0.625rem;
    .accessories-left-info {
      flex-direction: column !important;
      @media (min-width: 600px) {
        flex-direction: row !important;
        width: calc(100% - 2.5rem);
      }
      .accessories-icon {
        margin-bottom: 6px;
        @media (min-width: 600px) {
          margin-bottom: 0rem;
        }
        margin-right: 0.625rem;
        image {
          height: 2.5rem;
          width: 2.5rem;
        }
      }
    }
    .remove-accessories-icon {
      position: absolute;
      right: 0;
    }
  }
  .accessories-name {
    font-size: 1rem;
  }
  .accessories-number {
    font-size: 0.875rem;
    color: #555555;
  }
  