.first-counter-sec,
.second-counter-sec,
.third-counter-sec {
  width: 100%;
}
.product-detail-search-input {
  @media (min-width: 600px) {
    width: 31%;
  }
}
.product-detail-stock-main-div {
  flex-direction: column !important;
  .product-detail-search-input{
    flex: 1 1 auto;
  }
  > div{
    &:last-of-type{
      .MuiInputBase-formControl{
        border-top-right-radius: 0.625rem !important;
        border-bottom-right-radius: 0.625rem !important;
      }
    }
  }
  &:not(:has(.product-detail-stock-delete-sec)){
    > div{
      &:last-of-type{
        .MuiInputBase-root.MuiFilledInput-root.MuiInputBase-formControl::after{
          display: none;
        }
      }
    }
  }
  @media (min-width: 600px) {
    flex-direction: row !important;
  }
}
.first-counter-sec {
  .MuiInputBase-root.MuiFilledInput-root.MuiInputBase-formControl {
    @media (min-width: 600px) {
      border-top-right-radius: 0rem;
      border-bottom-right-radius: 0rem;
      &::after {
        content: "";
        position: absolute;
        right: 0;
        height: 1.5rem;
        width: 0.063rem;
        background-color: var(--bs-gray-500);
      }
    }
  }
}
.second-counter-sec
  .MuiInputBase-root.MuiFilledInput-root.MuiInputBase-formControl {
  @media (min-width: 600px) {
    border-radius: 0;
    &::after {
      content: "";
      position: absolute;
      right: 0;
      height: 1.5rem;
      width: 0.063rem;
      background-color: var(--bs-gray-500);
    }
  }
}
.third-counter-sec
  .MuiInputBase-root.MuiFilledInput-root.MuiInputBase-formControl {
  @media (min-width: 600px) {
    border-radius: 0;
    &::after {
      content: "";
      position: absolute;
      right: 0;
      height: 1.5rem;
      width: 0.063rem;
      background-color: var(--bs-gray-500);
    }
  }
}
.product-detail-partial-sec {
  font-size: 0.625rem;
  min-height: 0.75rem;
  padding-left: 0.813rem;
  padding-bottom: 0.313rem;
}
.product-detail-stock-delete-sec {
  @media (min-width: 600px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7%;
    height: 2.813rem;
    align-self: end;
    border-top-right-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    border-top-left-radius: 0rem;
    border-bottom-left-radius: 0rem;
    background-color: #f1f1f1;
  }
}
.no-pricing-schedule-sec {
  margin: 0.625rem 0 0.625rem 0;
  img {
    height: 1.375rem;
    width: 1.375rem;
    margin-right: 0.625rem;
  }
}
.product-detail-schedule-btn {
  button.MuiButtonBase-root {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0.75rem !important;
    border-radius: 0.625rem !important;
    .MuiButton-label {
      font-size: 0.875rem;
    }
    .MuiButton-endIcon {
      margin-left: 0.25rem;
    }
    img {
      height: 0.938rem;
      width: 0.938rem;
    }
  }
}

.hover-with-delete-productDetail {
  .delet-order-img {
    display: block;
  }
  .delet-order-hover-img {
    display: none;
  }
  &:hover {
    cursor: pointer;
    .delet-order-img {
      display: none;
    }
    .delet-order-hover-img {
      display: block;
    }
  }
}

.hover-with-switch-productDetail {
  &:hover {
    cursor: pointer;
  }
}