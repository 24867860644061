.icon {
  --color: inherit;
  --size: 24;
  --icon-size: calc(var(--size) * 1px);
  font-family: "Material Icons Rounded" !important;
  font-size: var(--icon-size);
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  max-width: var(--icon-size);
  max-height: var(--icon-size);
  overflow: hidden;
  color: var(--color);
}
