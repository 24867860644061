.button-primary {
    --bgcolor: var(--bs-gray-200);
    height: 3.125rem;
    width: fit-content;
    border: none;
    background-color: var(--bgcolor);
    color: var(--bs-black);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 1.25rem;
    border-radius: 0.625rem;
    transition: transform 1s cubic-bezier(0, -0.01, 0, 1.31);
    will-change: transform;
    outline: none;

    @media (max-width: 75rem) {
        height: 2.5rem;
        padding: 0 0.625rem;
    }

    &:disabled {
        opacity: 0.5;
        cursor: default;

        .button-label,
        .button-icon {
            opacity: 0.75;
        }
    }

    &:focus-visible:not(:disabled) {
        outline: none;
        box-shadow: 0 0 0 0.125rem var(--bs-white), 0 0 0 0.25rem var(--bs-gray-800);
    }

    &:active:not(:disabled) {
        transform: scale(0.95);
    }

    &.button-with-icon {
        padding-left: 0.9375rem;

        @media (max-width: 75rem) {
            padding-left: 0.625rem;
        }
    }

    &.button-wide {
        width: 100%;

        &:active:not(:disabled) {
            transform: scale(0.985);
        }
    }

    &.button-accent {
        --bgcolor: var(--bs-primary);
        color: var(--bs-white);
    }

    &.button-secondary {
        --bgcolor: rgba(255, 255, 255, 0);
        color: var(--bs-black);

        &:focus-visible:not(:disabled),
        &:hover:not(:disabled) {
            --bgcolor: var(--bs-gray-50);
        }

        &:active:not(:disabled) {
            --bgcolor: var(--bs-gray-100);
        }
    }

    &.button-danger {
        color: var(--bs-danger);

        &:focus-visible:not(:disabled) {
            box-shadow: 0 0 0 0.25rem var(--bs-white), 0 0 0 0.375rem var(--bs-danger);
        }
    }

    &.button-is-loading {
        position: relative;
        overflow: hidden;
        pointer-events: none;

        .button-label,
        .button-icon {
            opacity: 0;
        }

        &::before {
            content: "";
            position: absolute;
            background-color: var(--bgcolor);
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            border-radius: 0.625rem;
        }

        &::after {
            content: "";
            position: absolute;
            height: 1.5rem;
            width: 1.5rem;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNNiAxMGMtMS4xIDAtMiAuOS0yIDJzLjkgMiAyIDIgMi0uOSAyLTItLjktMi0yLTJ6bTEyIDBjLTEuMSAwLTIgLjktMiAycy45IDIgMiAyIDItLjkgMi0yLS45LTItMi0yem0tNiAwYy0xLjEgMC0yIC45LTIgMnMuOSAyIDIgMiAyLS45IDItMi0uOS0yLTItMnoiLz48L3N2Zz4=");
            background-position: center;
            background-repeat: no-repeat;
            opacity: 0;
            animation: button-loading-anim 0.3s ease-out alternate infinite;

            @keyframes button-loading-anim {
                to {
                    opacity: 1;
                    transform: scale(1.1);
                }
            }
        }
    }

    &.button-accent {
        &.button-is-loading {
            &::after {
                filter: invert(1);
            }
        }
    }

    .button-label {
        user-select: none;
        font-family: "Inter", sans-serif;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1;
        text-transform: uppercase;
        letter-spacing: 0.0219rem;
        color: currentColor;
        white-space: nowrap;
    }

    .button-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.625rem;
        margin-left: 0.3125rem;
        user-select: none;

        @media (max-width: 75rem) {
            margin-left: 0;
            margin-right: 0.5rem;
        }
    }
}
