.custom--datepicker{
  input{
    width: 100%;
    padding: 10px;
    padding: 0.7rem !important;
    border-radius: 0.625rem !important;
    background-color: #f1f1f1;
    border: 0;
    font-weight: 600;
    &:focus{
      background-color: var(--bs-white) !important;
      outline: 0.13rem solid var(--bs-black) !important;  
      border-radius: 0.625rem;
    }
  }
}