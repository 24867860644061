.location-picker-wrapper {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 25rem;
  border-radius: 1.25rem;
  box-shadow: 0 0.625rem 2.5rem 0 rgba(0, 0, 0, 0.1);
  border: 0.063rem solid var(--bs-gray-200);
  overflow: hidden;
  background-color: #fff;
  z-index: 11;
  position: fixed;
  top: 15vh;

  .location-picker-header {
    padding: 1.25rem;
    padding-bottom: 0.625rem;
    border-bottom: 0.063rem solid var(--bs-gray-200);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 0.625rem;
    height: 7.5rem;

    .location-picker-header-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      gap: 0.625rem;

      .location-picker-search-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .location-picker-search-icon {
          position: absolute;
          left: 0.5rem;
          color: var(--bs-gray-600);
          pointer-events: none;
        }

        .location-picker-search {
          border: none;
          width: 100%;
          font-size: 0.875rem;
          padding: 0.5rem 1.875rem;
          padding-right: 2.5rem;
          background-color: var(--bs-gray-100);
          border-radius: 0.313rem;
          outline: none;
        }

        .location-search-clear-btn {
          position: absolute;
          right: 0;
        }
      }
    }

    .selection-title {
      font-family: var(--inter-font-family);
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 1;
      width: 100%;
      text-transform: capitalize;
      margin-bottom: 0;
    }

    .location-back-btn {
      user-select: none;
      display: flex;
      align-items: center;
      cursor: pointer;
      border: none;
      background-color: #fff;
      color: var(--bs-gray-500);
      font-size: 0.875rem;
      gap: 0.25rem;
      line-height: 1;
      max-width: fit-content;
      padding: 0;
      margin: 0;
      outline: none;
      border-radius: 0.313rem;
      will-change: transform;
      transition: all 1s cubic-bezier(0, -0.01, 0, 1.31), box-shadow 0s;

      &:hover,
      &:focus-visible {
        color: #000;
      }

      &:active {
        transform: scale(0.95);
      }

      &:focus-visible {
        box-shadow: 0 0 0 2px #000;
      }
    }
  }

  .location-picker-list {
    max-height: 65vh;
    overflow-y: scroll;
    padding-bottom: 1.25rem;
    overscroll-behavior: contain;

    .location-picker-error-message {
      padding: 1.25rem;
      padding-bottom: 0;
      display: flex;
      line-height: 1.35;
      font-size: 0.875rem;
      color: var(--bs-gray-600);
    }

    .list-header {
      position: sticky;
      top: 0;
      z-index: 1;
      display: flex;
      padding: 0.625rem 2.5rem;
      font-size: 0.625rem;
      color: var(--bs-gray-600);
      letter-spacing: 0.063rem;
      background-color: #fff;
      text-transform: uppercase;

      &:not(:first-of-type) {
        margin-top: 0.625rem;
      }
    }
  }

  .location-picker-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0.625rem 0.5rem 2.5rem;
    cursor: pointer;
    width: 100%;
    border: none;
    border-bottom: 0.063rem solid var(--bs-gray-100);
    background-color: #fff;
    position: relative;
    outline: none;
    user-select: none;
    will-change: transform;
    transition: all 1s cubic-bezier(0, -0.01, 0, 1.31), box-shadow 0s;

    .location-picker-item-label {
      font-size: 0.875rem;
      font-family: var(--inter-font-family);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 1.25;
    }

    &:hover,
    &:focus-visible {
      background-color: var(--bs-gray-100);

      .arrow-icon {
        color: var(--bs-gray-500);
      }
    }

    &:focus-visible {
      box-shadow: inset 0 0 0 0.125rem #000;
    }

    &:active {
      background-color: var(--bs-gray-300);

      .arrow-icon {
        transform: translateX(15%);
        color: #000;
      }
    }

    .checked-icon {
      left: 0.625rem;
      position: absolute;
    }

    .arrow-icon {
      color: var(--bs-gray-300);
    }
  }
}

.location-picker-empty-state {
  padding: 1.25rem;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  & > div {
    height: 0.625rem;
    width: 90%;
    border-radius: 31.25rem;
    background-color: var(--bs-gray-50);
    animation: location-picker-loading-anim 0.6s alternate infinite;

    @keyframes location-picker-loading-anim {
      to {
        background-color: var(--bs-gray-200);
      }
    }

    &:nth-child(2) {
      width: 50%;
      animation-delay: 0.1s;
    }
  }
}
