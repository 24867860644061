.page-tip-item-pos {
    font-family: var(--inter-font-family);
    font-weight: 400;
    line-height: 1.45;
    position: relative;
    padding-bottom: 1.5em;
    margin-top: 1.5em;
    border-bottom: 0.0625rem solid var(--gray-2);
    counter-increment: tipsCounter;
    .tip-icon{
        margin-right: 0.625rem;
        margin-bottom: 0.25rem;
    }
}
.page-tip-item-pos:nth-child(1) {
    margin-top: 0;
}
.page-tip-item-pos::before {
    display: flex;
    margin-bottom: 0.5em;
}
.page-tip-item-pos::after {
    font-size: 0.875rem;
    font-weight: 500;
    font-family: var(--inter-font-family);
    position: absolute;
    top: 0;
    line-height: 1;
    height: 1.5rem;
    display: flex;
    align-items: center;
    left: calc(1.5rem + 0.625rem);
    text-align: center;
}
.page-tip-items-pos:empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: var(--inter-font-family);
}
.page-tip-items-pos:empty::before {
    content: url("/Image/no_tips_found.svg");
}
.page-tip-items-pos:empty::after {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    text-align: center;
    content: attr(data-empty-message);
}
.tips-footer {
    margin-top: 1.5em;
    font-size: 14px;
    background-color: var(--gray-1);
    font-family: var(--inter-font-family);
    padding: 1em;
    text-align: center;
    border-radius: 10px;
    display: flex;
    align-items: center;
}
.tips-footer a:visited {
    font-weight: 600;
    color: #0061f2 !important;
}
.tips-footer span {
    margin-left: 0.75em;
}
.tips-visit{
    font-weight: 600;
    color: #0061f2 !important;
}
