/*  Uncomment if we need old pagination styles
.square-i a {
  margin: auto 5px;
  color: #000;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400 !important;
}

.pagination {
  padding: 10px 0px 20px 0px;
}

.pagination ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.pagination a {
  cursor: pointer;
  display: inline-block;
  padding: 5px 10px;
  color: #222;
}

.p1 a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0;
  text-align: center;
}
.p1 a.is-active {
  border-radius: 100%;
  color: #fff;
}

.bottom-border .is-active li {
  font-weight: 700;
  border-bottom: 3px solid #000;
}

.p3 .is-active {
  color: #fff;
}
.p4 a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100%;
  padding: 0;
  text-align: center;
  position: relative;
}

.p4 .is-active:before {
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #000;
  position: absolute;
  top: 5px;
  left: 5px;
}

.p5 a {
  width: 30px;
  height: 5px;
  padding: 0;
  margin: auto 5px;
  background-color: rgba(46, 204, 113, 0.4);
}

.p5 .is-active {
  background-color: #000;
}

.p6 a {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  padding: 0;
  margin: auto 5px;
  text-align: center;
  position: relative;
  background-color: rgba(46, 204, 113, 0.4);
}

.p6 .is-active {
  background-color: #000;
}
.square-i a {
  margin: auto 5px;
  color: #000;
  font-weight: 700;
}

.p8 a,
.square-i .is-active {
  background-color: white !important;
  color: black !important;
  font-weight: 900 !important;
}

.p8 a {
  margin: auto 5px;
  font-weight: 700;
  border: 1px solid #000;
}

.p8 .is-active {
  background-color: #fff;
  color: #000;
}

.circle a {
  width: 30px;
  height: 30px;
  line-height: 30px;
  padding: 0;
  text-align: center;
  margin: auto 5px;
}

.circle a.is-active {
  border: 1px solid #000;
  border-radius: 100%;
}

.square a {
  width: 30px;
  height: 30px;
  line-height: 30px;
  padding: 0;
  text-align: center;
  margin: auto 5px;
}

.p11 a,
.square a.is-active {
}

.p11 a {
  background-color: #000;
  margin: auto 5px;
  color: #fff;
  position: relative;
}

.p11 a:first-of-type:before {
  left: -25px;
  border-right: 22px solid #000;
}

.p11 a:first-of-type:before,
.p11 a:last-of-type:after {
  content: "";
  position: absolute;
  top: -3px;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}

.p11 a:last-of-type:after {
  right: -25px;
  border-left: 22px solid #000;
}

.p11 .is-active {
  font-weight: 700;
}

.square-fill .is-active,
.square-fill a:first-of-type,
.square-fill a:last-of-type {
  background-color: #000;
  color: #fff;
  font-weight: 700;
}
*/
.pagination {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;

    a {
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5em 1em !important;
      user-select: none;
      cursor: pointer;
      border-radius: 10px;
      text-decoration: none;
      transition: all 0.1s ease;

      &:hover:not(.is-disabled),
      &:focus-visible:not(.is-disabled) {
        background-color: #f5f5f5;
      }

      &:active {
        background-color: #ddd;
        transform: scale(0.95);
      }

      &:focus-visible:not(.is-disabled) {
        outline: none;
        box-shadow: 0 0 0 2px #fff, 0 0 0 4px #aaa;
        transform: scale(1.1);
      }

      li {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        color: #000;
      }

      &.is-active {
        background-color: #eee !important;

        li {
          font-weight: 700 !important;
        }
      }

      &.is-disabled {
        opacity: 0.25;
        cursor: default;

        &:focus-visible {
          outline: none;
        }
      }

      &:first-child,
      &:last-child {
        padding: 0;
        height: 40px;
        width: 40px;
      }
    }
  }
}