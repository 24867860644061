.storage-text-alignment div{
    text-align: left;
    justify-content: flex-start;
    @media only screen and (min-width: 1200px) {
        text-align: right;
        justify-content: flex-end;
    }
}
.table-header-styling.MuiGrid-root {
    display: none;
    @media only screen and (min-width: 1200px) {
        display: flex;
        justify-content: space-between;
    }
}
.storage-data-info.MuiGrid-root {
    margin-top: 1.25rem;
    padding-right: 1.25rem;
    @media only screen and (min-width: 1200px) {
        margin-top: 0;
        padding-right: 0;
    }
}