
.adjust-quantity-selector {
  display: flex;
  padding: 0.3125rem 0.625rem;
  border-radius: 10px;
  background: #f1f1f1;
  .label-name {
    padding-left: 0.2rem;
    margin-bottom: unset;
  }
  .adjust-stock-select {
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.5rem 0;
    &:focus {
      border: 1px solid transparent;
    }
  }
  .adjust-form-control {
    width: 100%;
    width: 33.33%;
    border-right: 1px solid #aaaaaa;
    padding: 0 0.938rem;
    .adjust-stock-location-info-title {
      font-size: 10px;
      color: #000;
      font-weight: 500;
      letter-spacing: 0.8px;
    }
    &:last-child {
      border-right: unset !important;
    }
    .MuiInputBase-root {
      margin-top: 0;
      padding: 7px 0 4px;
      .MuiFilledInput-input{
        padding: 0;
      }
    }
    .MuiFormLabel-root {
      font-size: 10px;
      color: #000;
      font-weight: 700;
      margin-bottom: 4px;
      left: 10px;
      letter-spacing: 0.8px;
    }
    .MuiInput-underline {
      &::before,
      &::after {
        border-bottom: unset;
      }
      .MuiNativeSelect-root {
        padding: 0px;
        .Mui-focused {
          background: unset;
        }
      }
      .MuiNativeSelect-select {
        &:focus {
          background-color: unset;
        }
      }
    }
    .MuiFormLabel-root.Mui-focused {
      color: unset;
    }
    .MuiSvgIcon-root.MuiNativeSelect-icon {
      color: #000;
      width: 20px;
    }
  }
}
.error-message-adjust-stock {
  margin-bottom: 10px;
  .validation-error-text {
    flex: 1;
  }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
}