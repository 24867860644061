@import "apps/pos/assets/css/variable.scss";

.draft-data-row {
    .customer-table-row-hover-data {
        position: unset;
        right: 0;
    }
}
.customer-info-table-title {
    &-text {
        text-transform: uppercase;
    }
    font-size: 0.625rem !important;
    letter-spacing: 0;
    color: #000000;
    opacity: 1;
    width: 12%;
}

.customer-info-table-container {
    box-shadow: none !important;
    thead,
    .thead,
    .thead-sticky {
        top: 0;
        background-color: #fff;
    }
    th,
    tr,
    td {
        border: none !important;
    }
    thead {
        tr {
            border: none !important;
        }
    }
    tr {
        border-bottom: 0.063rem solid $bright-Gray !important;
    }

    .border-remove {
        border: none !important;
    }
}

.customer-table-row-data-styling {
    vertical-align: initial;
    padding: 0.625rem !important;
    span {
        font-size: 1rem;
        color: $black-color;
        font-weight: 500;
    }
}

.customer-table-row-data-styling-for-order {
    vertical-align: initial;
    span {
        font-size: 0.875rem;
        color: $black-color;
        font-weight: 500;
    }
}

.selectedRow {
    td {
        &:first-of-type {
            border-top-left-radius: 0.625rem;
            border-bottom-left-radius: 0.625rem;
        }
        &:last-of-type {
            border-top-right-radius: 0.625rem;
            border-bottom-right-radius: 0.625rem;
        }
    }
}

.customer-table-data-row:hover,
.item-list-wrapper:hover {
    background-color: $bright-Gray !important;
    border-radius: 0.625rem !important;

    td:first-child {
        border-top-left-radius: 0.625rem;
        border-bottom-left-radius: 0.625rem;
        border: 0.063rem solid $bright-Gray;
    }

    td:last-child {
        border-top-right-radius: 0.625rem;
        border-bottom-right-radius: 0.625rem;
        border: 0.063rem solid $bright-Gray;
    }

    .customer-table-row-remove {
        visibility:visible
    }
    .customer-table-row-remove {
        img {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
}

.hover-with-delete {
    .delet-order-img {
        display: block;
    }
    .delet-order-hover-img {
        display: none;
    }
    &:hover {
        .delet-order-img {
            display: none;
        }
        .delet-order-hover-img {
            display: block;
        }
    }
}

.customer-table-row-remove {
    visibility:hidden;
}