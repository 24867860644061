.main-packing-slip {

    #packing-Slip {
        margin-top: 2.438rem;
    }


    .cancel-packing-slip-btn {
        border-radius: 20px !important;
        background-color: #f1f1f1 !important;
        border: none;
        width: 3.125rem;
        height: 3.125rem;
    }

    .gap-6 {
        gap: 3.125rem;
    }

    .title {
        color: #000;
        line-height: 1.1;
        font-size: 1.75rem;
        font-weight: 600;
        letter-spacing: -0.025rem;
    }

    .packing-slip-header {
        max-width: 94rem;
        margin-right: auto;
        margin-left: 5.625rem;
        position: sticky;
        top: 0;
        z-index: 1;
        padding-top: 40px;
        padding-bottom: 20px;
        background: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(20px) saturate(1.8);

    }


    .packing-slip-divider {
        margin-top: 79px !important;
        max-width: 1420px !important;
        width: 100% !important;
        margin-right: auto !important;
        margin-left: 10.875rem !important;
        background-color: #DDD;
    }

    #packing-Slip table {
        page-break-inside: avoid;
        break-inside: avoid;
    }

    @media print {
        #packing-Slip table tr {
            break-inside: avoid;
        }
    }


    .button-loading-save-packing-slip {

        &.button-accent {
            color: var(--bs-white);
        }

        &.button-is-loading-black {
            position: relative;
            overflow: hidden;
            pointer-events: none;

            .button-label,
            .button-icon {
                opacity: 0;
            }

            &::before {
                content: "";
                position: absolute;
                background-color: var(--bs-black);
                height: 100%;
                width: 100%;
                left: 0;
                top: 0;
                border-radius: 0.625rem;
            }

            &::after {
                content: "";
                position: absolute;
                height: 1.5rem;
                width: 1.5rem;
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNNiAxMGMtMS4xIDAtMiAuOS0yIDJzLjkgMiAyIDIgMi0uOSAyLTItLjktMi0yLTJ6bTEyIDBjLTEuMSAwLTIgLjktMiAycy45IDIgMiAyIDItLjkgMi0yLS45LTItMi0yem0tNiAwYy0xLjEgMC0yIC45LTIgMnMuOSAyIDIgMiAyLS45IDItMi0uOS0yLTItMnoiLz48L3N2Zz4=");
                background-position: center;
                background-repeat: no-repeat;
                opacity: 0;
                animation: button-loading-anim 0.3s ease-out alternate infinite;
                left: 39%;

                @keyframes button-loading-anim {
                    to {
                        opacity: 1;
                        transform: scale(1.1);
                    }
                }
            }
        }

        &.button-accent {
            &.button-is-loading-black {
                &::after {
                    filter: invert(1);
                }
            }
        }

    }

    .button-loading-save-packing-slip {
        &.button-accent {
            color: var(--bs-white);
        }
    }

    .text-red {
        color: red;
    }
}