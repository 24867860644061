@import "apps/pos/assets/css/variable.scss";

.customer-info-table-title {
  font-size: 0.625rem !important;
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
}

.customer-info-table-container {
  box-shadow: none !important;

  thead,
  .thead,
  .thead-sticky {
    top: 0;
    background-color: #fff;
  }

  th,
  tr,
  td {
    border: none !important;
  }

  thead {
    tr {
      border: none !important;
    }
  }

  tr {
    border-bottom: 0.063rem solid $bright-Gray !important;
  }

  .border-remove {
    border: none !important;
  }

  .pt-6 {
    padding-top: 6rem !important;
  }

}

.fifth-table-row {
  vertical-align: middle;
  width: 22%;
}

.sixth-table-row {
  vertical-align: middle;
  width: 13%;
}

.seventh-table-row,
.first-table-row,
.second-table-row,
.third-table-row,
.sixth-new-table-row,
.seventh-new-table-row,
.eigth-new-table-row,
.ninth-new-table-row,
.tenth-new-table-row,
.first-added-new-table-row,
.second-added-new-table-row,
.third-added-new-table-row,
.fourth-added-new-table-row,
.sixth-added-new-table-row,
.seventh-added-new-table-row {
  vertical-align: middle;
  width: 10%;
}

.fourth-table-row {
  vertical-align: middle;
  width: 28%;
}

.fifth-new-table-row {
  vertical-align: middle;
  width: 12%;
}

.fifth-added-new-table-row {
  vertical-align: middle;
  width: 15%;
}

.eigth-added-new-table-row {
  vertical-align: middle;
  width: 25%;
}

.draft-customer-table-row-data-styling {
  vertical-align: initial;

  span {
    font-size: 1rem;
    color: $black-color;
    font-weight: 500;
  }
}

.draft-customer-table-row-data-styling-for-order {
  vertical-align: initial;

  span {
    font-size: 0.875rem;
    color: $black-color;
    font-weight: 500;
  }
}

.orderer-name span {
  color: $description-color;
  font-size: 0.875rem;
}

.selectedRow {
  td {
    &:first-of-type {
      border-top-left-radius: 0.625rem;
      border-bottom-left-radius: 0.625rem;
    }

    &:last-of-type {
      border-top-right-radius: 0.625rem;
      border-bottom-right-radius: 0.625rem;
    }
  }
}

.selectedDivInList {
  border-radius: 0.625rem;
}


.customer-table-data-row:hover {
  background-color: $bright-Gray;
  border-radius: 0.625rem !important;

  .customer-table-row-remove {
    display: block;
  }

  .customer-table-row-remove {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: flex-end;

    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .put-storage-section {
    opacity: 0.8;

    & button:not(:disabled) {
      span:hover {
        color: $black-color;
        font-weight: 600;
        opacity: 1;
      }
    }
  }

  .put-storage-hover-content {
    opacity: 1;
  }

  .draft-sec-last-cell {
    border-top-right-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    border: 0.063rem solid $bright-Gray;
  }
}

.hover-with-delete {
  .delet-order-img {
    display: block;
  }

  .delet-order-hover-img {
    display: none;
  }

  &:hover {
    .delet-order-img {
      display: none;
    }

    .delet-order-hover-img {
      display: block;
    }
  }
}

.customer-table-row-remove {
  display: none;
}

.customer-table-row-remove {
  margin-left: auto;

  span {
    font-size: 1.25rem !important;
    margin: 0 auto;
  }
}

.customer-sec-delete-draft-drawer {
  .MuiDrawer-paper {
    border-top-left-radius: 1.875rem;
    border-bottom-left-radius: 1.875rem;
  }
}

.customer-table-styling {
  border-collapse: separate;
  border-spacing: 0.063rem;

  th {
    padding: 0.5rem;
  }
}

.customer-table-data-row:hover,
.item-list-wrapper:hover {
  background-color: $bright-Gray !important;
  border-radius: 0.625rem !important;

  td:first-child {
    border-top-left-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    border: 0.063rem solid $bright-Gray;
  }

  td:last-child {
    border-top-right-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    border: 0.063rem solid $bright-Gray;
  }

  .customer-table-row-remove {
    display: block;
  }

  .customer-table-row-remove {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: flex-end;

    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.draft-data-row {
  position: relative;

  &:focus-within {
    border: 0.13rem solid var(--bs-gray-600) !important;

    .customer-table-row-remove {
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: flex-end;
    }
  }

  &:has(.button-focus-delete-draft:focus-visible) {
    border: none !important;
  }

  .customer-table-row-hover-data {
    position: absolute;
    right: 0;
  }
}

.button-focus-delete-draft {
  &:focus-visible {
    border-radius: 1.563rem !important;
    outline: 0.13rem solid var(--bs-gray-600) !important;
    outline-offset: 0.0625rem;
  }
}

.customer-draft-header {
  z-index: 1;
}

.sort-dropdown-index {
  z-index: 11;
}