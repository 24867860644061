@import "apps/pos/assets/css/variable.scss";

.return-cart-info-modal-main {
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  .MuiDialog-container {
    .MuiDialog-paper {
      width: 80vw;
      @media (min-width: 768px) {
        width: 80vw;
      }
      @media (min-width: 1200px) {
        width: 80vw;
      }
      max-width: 61.875rem;
      height: 100%;
      max-height: 75vh;
      border-radius: 1.25rem;
      min-height: 50.438rem;
    }
    
    .MuiDialogContent-root {
      .return-cart-info-modal-section {
        .MuiGrid-root {
          .return-cart-product-section {
            padding-top: 0;
            .return-cart-product-img {
              width: 100%;
              max-width: 5rem;
              height: 100%;
              max-height: 5rem;
            }
            .return-manufacturer-name {
              font-size: 1.125rem;
              margin-top: 0.188rem;
              margin-bottom: 0;
            }
            .return-product-name {
              margin-bottom: 0;
            }
            .return-manufacturer-code {
              margin-bottom: 1.563rem;
              position: relative;
            }
            .return-returnable-qty-name {
              padding-top: 0.625rem;
              font-size: 0.625rem;
              letter-spacing: 0.063rem;
              margin-bottom: 0;
            }
            .return-returnable-qty {
              font-size: 1.5rem;
            }
            .return-avl-qty-name {
              padding-top: 0.625rem;
              font-size: 0.625rem;
              letter-spacing: 0.063rem;
              margin-bottom: 0;
            }
          }
          .return-cart-info-margin-bottom {
            margin-bottom: 0.525rem;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              top: 5.125rem;
              left: 0px;
              width: calc(100% - 1.825rem);
              height: 0.063rem;
              background-color: var(--bs-gray-200);
            }
          }
          .return-cart-qty-section {
            padding-top: 0;
            .search-customer-header {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              .model-header-close-button {
                padding: 0;
                width: 1.25rem;
                max-width: 1.25rem;
                min-width: 1.25rem;
              }
            }
              .return-qty-label {
                font-size: 1rem;
                font-weight: 500;
                padding-top: 1.875rem;
              }
              .return-qty-foot-note{
                display: flex;
                color: #000000;
                font-size: 0.75rem;
                width: auto;
                align-items: center;
                padding-top: 0.8125rem;
                img{ 
                  width: 1rem;
                  height: 1rem;
                }
              }
              .return-qty-input {
                .MuiInputBase-root {
                  padding: 0.75rem 0.938rem;
                  border-radius: 0.625rem;
                }
              }
              .return-cannot-description {
                font-size: 0.75rem;
                margin-top: 0.25rem;
              }
              .return-reason-select {
                width: 100%;
                border-radius: 0.625rem;
                .MuiSelect-root {
                  padding: 0.75rem 0.938rem;
                }
              }
              .return-to-cart-label {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                align-items: center;
                padding: 0rem 0rem 0.313rem 0rem;
                span{
                  text-transform: uppercase;
                  font-size: 0.5rem;
                  font-weight: 600;
                  padding: 0 0.938rem;
                }
              }
              .return-cart-info-rows-container {
                display: flex;
                align-items: center;
                width: 100%;
                .MuiFilledInput-input{
                  border-radius: 0;
                }

                .return-cart-info-rows {
                  padding: 0;
                  width: 100%;
                  min-width: 34.375rem;
                  label {
                    display: none;
                  }
                  .adjust-form-control {
                    flex-direction:unset;
                    border-right: unset;
                    position: relative;
                    &::after {
                      content: "";
                      position: absolute;
                      top: 0.625rem;
                      left: 0rem;
                      width: calc(100% - 8.563rem);
                      height: 1.5rem;
                      background-color: $darkGray ;
                    }
                    &:first-child {
                        &::after { 
                          display: none;
                        }
                    }
                  }
                }
              }
              .return-qty-plus-button {
                width: 100%;
                border-radius: 0.625rem;
              }
              .return-qty-remove-button {
                margin-right: 0.625rem;
                padding: 0 0.625rem;
                min-width: auto;
                height: fit-content;
                min-height: 2.813rem;
                position: absolute;
                left: -3.125rem;
                
                span{
                  width: fit-content;
                  margin: 0;
                }
              }
          }
        }
      }
    }
    .MuiDialogActions-root {
      padding: 1.5rem;
      justify-content: space-between;
      .add-to-return-cart-button-error{
        opacity: 0.3;
        cursor: not-allowed;
      }
      .add-to-return-cart-button {
        padding: 0.75rem 1.25rem;
        margin-left: 0.625rem;
        .MuiButton-label {
          letter-spacing: -0.02rem;
        }
      }
    }
  }
}
.return-add-cart-main-modal{
  .MuiDialogActions-root {
    justify-content: flex-end !important;
  }
}

.auto-resize-bg-text-field{
  background-color: $bgColor;
  width: 100%;
  padding: 0.75rem 0.938rem;
  border-radius: 0.625rem;
  border: none;
  min-height: 2.875rem;
}
.custome-error-color {
  color: $red-color;
  font-size: 0.75rem;
}

.return-cart-info {
  display: flex;
  .return-cart-info-section {
    width: 50%;
  }
}

.return-cart-info-deliver-btn {
  display: flex;
  align-items: center;
}

.indicator-dot{
  height: 12px;
  width: 12px;
  display: inline-block;
  border-radius: 50%;
  background-color: red;
}
.activeFlag-status{
  margin-bottom: 0.75rem;
}
.available-replacement-block{
  margin-bottom: 1.563rem;
              position: relative;
  &::before {
                content: "";
                position: absolute;
                top: 0rem;
                left: 0px;
                width: calc(100% - 1.825rem);
                height: 0.063rem;
                background-color: var(--bs-gray-200);
              }
}
.w-39 {
  width: 39%;
}

.return-save-changes-modal-btn{
  width: 11.75rem;
  min-width: 11.75rem;
  max-width: 11.75rem;
  height: 2.75rem;
  
}

.add-return-cart-btn{
  width: 14rem;
  min-width: 14rem;
  max-width: 14rem;
  height: 2.75rem;
}

.order-notes-section{
  border-bottom: 1px solid var(--bs-gray-200);
  width: calc(100% - 1.825rem);
}