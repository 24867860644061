@import "apps/pos/assets/css/variable.scss";

.put-storage-container{
  .order-items{
    width: 100%;
    margin: 1.25rem 0 1.25rem 0;
    align-items: center;
    .MuiGrid-item{
      padding-left: 0;
      padding-top: 0;
    }
    .qty-input{
      width: 12.5rem;
    }
  }
  .j-content-space-between {
    justify-content: space-between !important;
  }
  .text-right{
    text-align: right !important;
  }
}
.customer-first-info-col {
  display: flex;
  align-items: center;
  img {
    height: 2.5rem;
    width: 2.5rem;
  }
  .customer-name {
    padding-left: 1.25rem;
  }
}
.storage-qty-title {
  width: 12.5rem;
  margin-left: auto;
}
.storage-first-sec-title {
  padding-left: 3.75rem !important;
}
.input-out-focus-light-text input {
  color: $black-color !important;
}

.ship-fee-paid {
  position: relative;

  &::before {
      position: absolute;
      top: 0.5625rem;
      left: 1.25rem;
      width: 0.625rem;
      height: 0.625rem;
      content: "$";
      display: inline-block;
      font-size: 1.0625rem;
      z-index: 1;
      color: $black-color;
  }

  input {
      width: 100% !important;
      background-color: $bright-Gray !important;
      padding: 0.625rem 2.0625rem !important;
      border-radius: 0.625rem !important;

      &:focus {
           outline: 0.13rem solid var(--bs-black) !important;
          background-color: $white-color !important;
      }
  }
}

.deliver-all-btn{
  min-width: 9.3rem;
  max-width: 9.3rem;
  height: 2.75rem;
}

.deliver-btn{
  min-width: 7.5rem;
  max-width: 7.5rem;
  height: 2.75rem;
}

.put-in-storage-btn{
  min-width: 11.5rem;
  max-width: 11.5rem;
  height: 2.75rem;
}

.put-in-storage-table {
  max-height: 65vh;
  overflow-y: scroll;
  padding-bottom: 1.25rem;
  overscroll-behavior: contain;
}