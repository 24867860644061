@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&family=Roboto+Slab:wght@100;200;300;400;500;600;700&display=swap");
@import 'ui/scss/general.scss';

/* font-family: 'Roboto Slab', serif; */

body {
  font-family: "Inter", sans-serif;
}

.pt-0 {
  padding-top: 0 !important;
}

.MuiPopover-root {
  .MuiMenu-paper {
    transform-origin: unset !important;
    transform: unset !important;
  }
}

.addBtn {
  width: 40px;
  height: 40px;

  border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
}

.inboxLeftbar {
  height: 100%;
  padding-top: 30px;
  padding-left: 20px;
}

.searchBtn {
  background: transparent !important;
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  margin-right: 10px !important;
}

.searchBtn:hover {
  background: #f1f1f1 !important;
}

.inboxlists {
  max-height: calc(100% - 106px);
  /* overflow: auto; */
}

.inboxlistsHdr .nav-tabs .nav-item .nav-link {
  font-size: 14px;
  color: #999999;
}

.inboxlistsHdr .nav-tabs .nav-item .nav-link.active {
  color: #000 !important;
  border-bottom: 2px solid;
  border-bottom-color: #000000 !important;
}

.inboxlistsItem {
  padding: 0 5px;
}

.inboxlistsItem:hover {
  background: #f9f9f9;
}

.inboxlistsItem.active {
  background: #f1f1f1;
}

.fs-8 {
  font-size: 8px;
}

.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 0.813rem;
}

.fs-14 {
  font-size: 14px;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.color-black {
  color: black;
}

.h-custom1 {
  height: 100%;
}


.inboxlistsHdr ul {
  margin-left: 0;
}

.f28-slab-500 {
  font-size: 28px;
  line-height: 37px;
  margin: 0;
  font-family: "Roboto Slab", serif;
  font-weight: 500;
}

.highlightednote {
  font-size: 12px;
  line-height: 12px;
  border: 1px solid #f1f1f1;
  padding: 1px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  max-width: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  transition: all 0.3s ease-in-out;
  background: #fff;
}

.highlightednote:hover {
  font-size: 12px;
  line-height: 12px;
  background: #f1f1f1;
  border: 1px solid #f1f1f1;
  padding: 5px 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  max-width: 180px;
  display: block;
}

.highlightednote img {
  width: 20px;
  height: 20px;
  margin-right: 4px;
  transition: all 0.3s ease-in-out;
}

.highlightednote:hover img {
  width: 12px;
  height: 12px;
}

.btnOutSec {
  border: 1px solid #EEEEEE;
  border-radius: 8px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  background: transparent;
}

.btnOutSec:hover {
  background: #f1f1f1;
}

.SubquoteDropdown {
  box-shadow: 0px 4px 8px #0000001a;
  -webkit-box-shadow: 0px 4px 8px #0000001a;
  -moz-box-shadow: 0px 4px 8px #0000001a;
  border: 1px solid #dddddd;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  width: 100%;
  height: 370px;
  min-width: 453px;
  padding: 10px;
}

.btn:focus {
  box-shadow: none;
  text-decoration: none;
}

.selectList {
  border: 1px solid #dddddd;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  box-shadow: 0px 4px 8px #0000001a;
  -webkit-box-shadow: 0px 4px 8px #0000001a;
  -moz-box-shadow: 0px 4px 8px #0000001a;
  padding: 0.625rem !important;
  min-width: 220px !important;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Roboto+Slab:wght@300;400;500;600;700&display=swap");

/* font-family: 'Roboto Slab', serif; */

body {
  font-family: "Inter", sans-serif;
}

.addBtn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  background-color: #000 !important;
  border-color: #000 !important;
}

.inboxLeftbar {
  height: 100%;
  padding-top: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}

.inboxLeftbarTitle {
  margin: 0;
  font-size: 1.2rem;
  line-height: 21px;
  font-weight: 500;
  color: #000000;
}

.edit-hvr-bg>button {
  &:hover {
    background-color: #F1F1F1 !important;
  }
}

.searchBtn {
  background: transparent;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  margin-right: 10px;
}

.searchBtn:hover {
  background: #f1f1f1;
}

.inboxLeftbar .inboxlists {
  max-height: calc(100% - 106px);
  overflow: auto;
}

.inboxlistsHdr .nav-tabs .nav-item .nav-link {
  font-size: 14px;
  color: #999999;
}

.inboxlistsHdr .nav-tabs .nav-item .nav-link.active {
  color: #000;
  border-bottom: 2px solid #000;
}

.inboxlistsItem {
  padding: 0 5px;
}

// .inboxlistsItem.active {
//   background: #f1f1f1;
// }

.fs-8 {
  font-size: 8px;
}

.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 0.813rem;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 1rem;
}

.fs-24 {
  font-size: 24px;
}

.fs-48 {
  font-size: 3rem;
}

.fs-40 {
  font-size: 2.5rem;
}

.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.flex-align-item-center {
  align-items: center !important;
}

.h-custom1 {
  height: 100%;
}

.inbox-detailRightside {
  position: relative;
  width: calc(100% - 430px);
  padding: 30px;
  overflow: auto;
  padding-top: 0;
}

.inboxlistsHdr ul {
  margin-left: 0;
}

.desc-for-role {
  margin-top: -13px;
  font-size: 12px;
  font-family: "Inter", Regular;
  color: #000;
  margin-bottom: 30px;
}

.f24-slab-500 {
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  font-family: "Roboto Slab";
  font-weight: 400;
}

.f28-slab-500 {
  font-size: 28px;
  line-height: 37px;
  margin: 0;
  font-family: "Roboto Slab", Medium;
  font-weight: 500;
}

.f14-slab-500 {
  font-size: 14px;
  font-family: "Roboto Slab", Medium;
  font-weight: 500;
}

.sidenav-ht {
  height: 100vh;
}

.textfield-notes {
  height: 150px;
}

.options-style {
  padding: 10px 70px 10px 10px !important;
}

.f14-inter-med {
  font-size: 14px;
  font-family: "Inter", Medium;
}

.name_field_manageuser_form {
  color: red !important;
  font-size: 14px !important;
}

.permission_div_manageuser_form {
  height: 21.25rem !important;
}

.user_name_manageuser_form {
  color: white !important;
}

.cancel_button_manageuser_form {
  margin-right: 15px !important;
  border: none !important;
  width: 118px !important;
  height: 3.125rem !important;
  background: #FFFFFF00 !important;
  font-weight: 700 !important;
}

.lock_button_manageuser_form {
  background-color: #000;
  color: #fff;
  border-radius: 3.125rem;
  display: inline-flex;
  border: none;
  align-items: center;
  padding: 0.3125rem 0.5rem 0.3125rem 0.625rem;
  gap: 0.3125rem;

  .lock_button_manageuser_form_p {
    font-size: 0.875rem;
    margin: 0;
    padding-left: 0;
  }

  .lock_button_manageuser_form_div {
    display: flex;
    height: 1rem;
    width: 1rem;
  }
}

.scrollBarRole {
  overflow: hidden;

  .innerScrollBarRole {
    overflow: auto;
    margin-right: -10px;
  }

  .innerScrollBarApproveRole {
    overflow: auto;
  }
}

.scrollBarRoleButton {
  background-color: black;
  color: white;
  border-radius: 50px;
  display: inline-flex;
  height: 40px;
}

.f16-inter-med {
  font-size: 1rem;
  font-family: "Inter", Medium;
}

.modal-btns {
  position: fixed;
  bottom: 0;
  padding: 20px;
  left: 0;
  width: 100%;
  display: flex;
  padding-top: 0;
  align-items: center;
  background: #fff;
  justify-content: flex-end;
  border-top: 1px solid #ddd;
}

.remove-btn-text {
  color: #ff0000 !important;
  margin-right: -25px !important;
}

.table-btn-container {
  display: flex;
  flex-direction: row;
}

.hover-btn {
  z-index: 999 !important;
}

.hover-user {
  z-index: 0 !important;
}

.f12-inter-med {
  font-size: 12px;
  font-family: "Inter", Medium;
  color: #999999;
}

.f12-inter-med-black {
  font-size: 12px;
  font-family: "Inter", Medium;
  color: #000000 !important;
}

.f14-inter-reg {
  font-size: 14px;
  font-family: "Inter", Regular;
}

.f14-inter-reg-black {
  font-size: 14px;
  font-family: "Inter", Regular;
  color: black !important;
}

.approve-user-reject-btn {
  font-size: 14px;
  letter-spacing: 0.16px;
  font-family: "Inter", Regular;
  color: black;
  border: none !important;
  padding: 6px 24px;
  border-radius: 10px;
}

.nav-link {
  display: flex;
  flex-direction: row;
}

.radio-options-text {
  font-size: 14px;
  font-family: "Inter", Regular;
  color: black !important;
  line-height: 17px;
  display: flex !important;
  align-items: center;
  margin-bottom: 7px;
  accent-color: black;
}

.user-manage-form {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.f16-inter-reg-black {
  font-size: 1rem;
  font-family: "Inter", Regular;
  color: black;
}

Input.custom-control-input {
  background-color: black !important;
  color: black !important;
  height: 18px;
  width: 18px;
  accent-color: var(--bs-primary);
}

Input.custom-control-input :checked {
  background-color: black !important;
  color: black !important;
  height: 18px;
  width: 18px;
}

.f10-inter-reg-gray {
  font-size: 10px;
  font-family: "Inter", Regular;
  color: #aaaaaa;
}

.f14-inters-500 {
  font-size: 14px;
  font-weight: 500;
  color: #353535;
}

.hw-icon-16 {
  height: 16px !important;
  width: 16px !important;
}

.hw-icon-14 {
  height: 14px !important;
  width: 14px !important;
}

.hw-icon-12 {
  height: 12px !important;
  width: 12px !important;
}

.black_text {
  color: #000 !important;
}

.gray_text {
  color: #999999 !important
}

.f20-slab-500 {
  font-size: 1.25rem;
  line-height: 30px;
  font-family: "Roboto Slab", Medium;
  font-weight: 500;
}

.f16-slab-500 {
  font-size: 1rem;
  line-height: 20px;
  font-family: "Roboto Slab", Medium;
  font-weight: 500;
}

.email-quote-btn {
  background-color: #fff;
  margin-right: 2px;
  width: 130px;
}

.awaiting-badge-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.symbol-price-percentage {
  color: #000;
  font-size: xx-small;
  vertical-align: text-top;
}

.approval-count {
  background-color: #000000;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.313rem;
}

.highlightednote {
  font-size: 12px;
  line-height: 12px;
  border: 1px solid #f1f1f1;
  padding: 1px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  max-width: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  transition: all 0.3s ease-in-out;
  background: #fff;
}

.highlightednote:hover {
  font-size: 12px;
  line-height: 12px;
  background: #f1f1f1;
  border: 1px solid #f1f1f1;
  padding: 5px 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  max-width: 180px;
  display: block;
}

.highlightednote img {
  width: 20px;
  height: 20px;
  margin-right: 4px;
  transition: all 0.3s ease-in-out;
}

.highlightednote:hover img {
  width: 12px;
  height: 12px;
}

.btnOutSec {
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  background: transparent;
}

.btnOutSec:hover {
  background: #f1f1f1;
}

.SubquoteDropdown {
  box-shadow: 0px 4px 8px #0000001a;
  -webkit-box-shadow: 0px 4px 8px #0000001a;
  -moz-box-shadow: 0px 4px 8px #0000001a;
  border: 1px solid #dddddd;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  width: 100%;
  height: 370px;
  min-width: 453px;
  padding: 10px;
}

.btn:focus {
  box-shadow: none;
  text-decoration: none;
}

.send_rfi_right .btn:focus {
  box-shadow: none;
  background-color: #1c222b;
}

.selectList {
  border: 1px solid #dddddd;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  box-shadow: 0px 4px 8px #0000001a;
  -webkit-box-shadow: 0px 4px 8px #0000001a;
  -moz-box-shadow: 0px 4px 8px #0000001a;
  padding: 10px;
  min-width: 220px;
}

.selectList button {
  color: #000;
  font-size: 14px;
  width: 100%;
  text-align: left;
  display: block;
  padding: 8px 0;
  border-bottom: 1px solid #eeeeee;
}

.selectList button.active {
  background: url("./apps//springboard//images/icons/check.svg") no-repeat scroll 95% 50% / 11px;
}


.hrText {
  position: relative;
}

.hrText .text {
  position: absolute;
  top: -6px;
  z-index: 1;
  display: inline-block;
}

.userImg {
  width: 30px;
  height: 30px;
  min-width: 30px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.userImgmain {
  position: relative;
  min-width: 50px;
}

.userImgmain img {
  position: absolute;
  top: 17px;
  left: 17px;
}

.cardCustum {
  border: 1px solid #dddddd;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  overflow: hidden;
  margin-top: 10px;
}

.imgleft {
  width: 60px;
  min-width: 60px;
  height: 60px;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.ellips {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.messagetext {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.show.messagesRow .messagetext {
  display: -webkit-box;
  -webkit-line-clamp: unset;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.show.messagesRow .searchBtn {
  transform: rotate(180deg);
}

.messagesRow {
  padding: 10px 0;
  overflow-x: hidden;
}

.messagesRow:hover {
  background-color: #f9f9f9;
}

button.btn.btn-transparent-dark.close {
  padding: 0;
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 30px;
  z-index: 1;
}

/* .btn:focus,
.btn-transparent-dark:focus {
outline: none;
box-shadow: none !important;
background: none !important;
} */

.form-control:focus {
  outline: none;
  box-shadow: none !important;
  border-color: #000 !important;
}

.send_rfi_right button:focus {
  border-color: #000 !important;
}

select {
  background-image: url(./apps/springboard/images/icons/arrow_drop_down.svg);
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: calc(100% - 10px) 50%;
  padding-right: 20px;
}

.form-group {
  margin-bottom: 1rem;
}

.label {
  font-size: 14px;
  color: #000;
}

.form-control {
  font-size: 16px !important;
  color: #000 !important;
  font-family: "Inter", "Regular";
}

.optionaltext {
  font-size: 14px;
  margin-left: 0.4rem;
}

.btn {
  font-size: 14px;
  font-weight: 500;
}

.btn-link {
  color: #000;
}

.history_content {
  margin: 0 auto;
  text-align: center;
  width: 50%;
  padding-top: 126px;
}

.innder-padd-right {
  height: 100%;
}

.history_content>p {
  text-align: center;
  font-size: 1rem;
  letter-spacing: 0.16px;
  color: #000000;
  opacity: 1;
  font-weight: normal;
  padding-top: 24px;
}

.history-text {
  color: #fff;
  text-decoration: none;
}

.spantext::before {
  content: "";
  position: absolute;
  background: #aaaaaa;
  height: 2px;
  width: 2px;
  border-radius: 50%;
  top: 8px;
  left: 2px;
}

.spantext {
  color: #999999;
  font-weight: 400;
  padding-left: 12px;
  position: relative;
}

.price_recd:nth-child(2) {
  margin-left: 45px;
}

.dam_sales p {
  font-weight: 400;
  color: #999999;
}

.dam_sales_img p {
  border-radius: 50%;
  height: 41px;
  width: 41px;
  background-color: #fac88e;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  text-align: center;
}

.edit_send_sec {
  position: relative;
  padding-top: 33px;
}

.edit_send_sec::before {
  content: "";
  background-color: #eeeeee;
  height: 1px;
  width: 100%;
  top: 15px;
  left: 0;
  position: absolute;
}

.edit_items a,
.send_req.price_recd a {
  letter-spacing: 0.18px;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;
  text-decoration: none;
  padding: 10px;
}

.edit_items a img {
  height: 20px;
  width: 20px;
  margin-right: 7px;
}

.send_req a img {
  width: 18px;
  height: 16px;
  margin-right: 7px;
}

.edit_items {
  height: 31px;
}

.edit_items a {
  text-decoration: none;
}

.send_req {
  background-color: #fff !important;
  height: 31px;
}

.edit_items:hover,
.send_req :hover {
  background-color: #f1f1f1 !important;
  height: 31px;
}

.float_left {
  float: left;
}

.border_body {
  border: 1px solid var(--bs-gray-400);
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 0px;
}

.assign_new_rap_btn button {
  text-align: center;
  border-radius: 50% !important;
  border: none;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 50px;
  height: 50px;
  align-items: center;
}

.assign_new_rap_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.btn-link:hover {
  text-decoration: none !important;
}

.devider_border {
  background-color: #dddddd;
  height: 1px;
  width: 100%;
}

.tick_main button {
  font-weight: 400;
  font-size: 14px !important;
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
}

.tick_lower p {
  color: #aaaaaa;
}

.light-grey {
  color: #aaaaaa;
}

.tick_dropdown {
  left: -168px !important;
  min-width: 400px;
  position: absolute !important;
  width: 100%;
}

.tick_dropdown button:hover {
  background-color: #f1f1f1;
  color: #000;
}

.tick_dropdown {
  padding-left: 10px;
}

.tick_main {
  position: relative;
}

.tick_main button:first-child {
  height: auto !important;
}

.emptystate_clm p {
  color: #000;
  font-weight: 400;
  padding-top: 20px;
  padding-bottom: 30px;
}

.emptystate_img_row {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.emptystate_clm a {
  background-color: #000;
  padding: 9px 14px 9px 11px;
  border-radius: 8px;
  color: #fff;
  font-weight: 400;
  text-decoration: none;
  display: inline-block;
}

.emptystate_main img.emptystateimgs {
  min-height: 96px;
  width: 69px;
}

.emptyadd img {
  max-width: 13px;
  min-height: auto;
  vertical-align: middle;
  margin-right: 1px;
}

.emptystate_main {
  display: flex;
  align-items: center;
  height: 100vh;
}

.emptystate_clm {
  width: 20%;
  margin-right: 51px;
}

form#linkQuoteRecipientForm label {
  color: #999999;
  font-size: 14px !important;
  text-transform: uppercase;
}

input#quotedTo::placeholder {
  color: #000;
  font-size: 1.25rem;
  line-height: 30px;
  font-family: "Roboto Slab", serif;
  font-weight: 500;
}

.div_bottom_border {
  border-bottom: 1px solid #eee !important;
  border: none;
  padding-left: 0px;
}

.edit_custos_info input#receipt::placeholder {
  color: #000;
  font-weight: 400;
  font-size: 1rem;
}

.edit_custos_info input#cname::placeholder {
  color: #000;
  font-weight: 400;
  font-size: 1rem;
}

.edit_custos_info input#coname::placeholder {
  color: #000;
  font-weight: 400;
  font-size: 1rem;
}

.edit_custos_info input#emails::placeholder {
  color: #000;
  font-weight: 400;
  font-size: 1rem;
}

.edit_custos_info input#call::placeholder {
  color: #000;
  font-weight: 400;
  font-size: 1rem;
}

input#email::placeholder {
  color: #000;
  font-weight: 500;
  font-family: "Roboto Slab", serif;
}

input#phone::placeholder {
  color: #000;
  font-weight: 500;
  font-family: "Roboto Slab", serif;
}

.text-gray-400 {
  color: #999999;
}

.pagination-title-color {
  color: "#999999" !important;
}

input#fax::placeholder {
  color: #000;
  font-weight: 500;
  font-family: "Roboto Slab", serif;
}

input#address::placeholder,
input#score::placeholder {
  color: #000;
  font-weight: 500;
  font-family: "Roboto Slab", serif;
}

.send_rfi_row p {
  font-weight: 500;
  color: #000;
}

.send_rfi_right button {
  width: 144px !important;
  margin-left: auto;
  background-image: url(./apps/springboard/images/icons/mail_outline.svg);
  background-repeat: no-repeat;
  background-position: 13px 10px;
  padding-left: 57px;
  height: 50px;
  border-radius: 10px;
}

.send_rfi_right button {
  width: 144px !important;
  margin-left: auto;
  background-image: url(./apps/springboard/images/icons/mail_outline.svg);
  background-repeat: no-repeat;
  background-position: 13px 10px;
  padding-left: 57px;
  height: 50px;
  border-radius: 10px;
}

.send_rfi_right button {
  width: 144px;
}

.send_rfi_right {
  float: right;
  width: 50%;
}

.send_rfi_left {
  float: left;
  width: 50%;
}

.edit_custos_info {
  padding-top: 41px;
}

.edit_custos_info label {
  text-transform: capitalize !important;
  padding-bottom: 8px;
}

.edit_custos_info .send_rfi_right button {
  background-image: none !important;
  width: 168px;
  padding-left: 15px;
  text-align: center;
}

.edit_custos_info .send_rfi_left button {
  width: 168px;
  height: 50px;
  border-radius: 10px;
}

.edit_custos_info .btn:focus {
  box-shadow: none;
  background-color: #1c222b !important;
}

.edit_cust_btn {
  display: flex;
  justify-content: flex-end;
}

.edit_cust_btn .cacel_btn button {
  background: #fff;
  color: #000;
  border: none;
  margin-right: 1.875rem;

}

.cancel_button_lock {
  height: 3.125rem;
}

.save_button_lock {
  height: 3.125rem;
  width: 196px !important;
  opacity: 1;
  background-color: #000;
  color: #fff;
  border-radius: 0.625rem;
}

.save_button_userform {
  height: 3.125rem;
  width: 11rem !important;
  border-radius: 0.625rem;
}

.search_product_modal_mainDiv {
  display: flex;
  flex-direction: column;
}

.search_product_modal_text {
  font-weight: bold !important;
  color: #000 !important;
}

.search_product_modal_close_icon {
  font-size: 1.875rem !important;
  cursor: pointer !important;
  float: right !important;
  margin-top: 1% !important;
}

.search_product_modal_content {
  padding: 0.938rem !important;
}

.tick_lower button img {
  max-width: 1.25rem;
  height: 1.25rem;
  opacity: 0;
}

.tick_lower button:hover img {
  opacity: 1;
}

.show_erp_togglebtn .switch {
  position: relative;
  display: inline-block;
  width: 3.375rem;
  height: 1.75rem;
  margin-left: 0.625rem;
}

.show_erp_togglebtn .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.show_erp_togglebtn .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dddddd;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.show_erp_togglebtn .slider:before {
  position: absolute;
  content: "";
  height: 1.25rem;
  width: 1.25rem;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.show_erp_togglebtn input:checked+.slider {
  background-color: #000;
}

.show_erp_togglebtn input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.show_erp_togglebtn span {
  font-size: 14px;
  vertical-align: middle;
  font-weight: 400;
  padding-top: 3px;
}

.items_length_dropdown button#dropdownMenuButton {
  margin-left: 25px;
  font-weight: 500;
  font-size: 14px;
}

.show_erp_togglebtn {
  display: flex;
  justify-content: space-around;
}

.table_bg {
  background: #e3ece0;
  margin-top: 60px;
  position: relative;
}

.table_bg::before {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: #000;
}

table .total_table_row tr th strong {}

table.sc-dlMDgC.eJuoOi.table_bg.total_table_row tbody tr {
  font-weight: 600 !important;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #000;
}

td.text-nowrap.fw-semibold.green_dropdown {
  background: #062122;
  /* color: #fff!important; */
}

td .green_dropdown button {
  color: #fff !important;
}

td.text-nowrap.fw-semibold.green_dropdown {
  background: #062122;
}

table.sc-dlMDgC.eJuoOi.total_table_row {
  border-bottom: 1px solid #fff;
  margin-bottom: 12px;
}

img.notes_add_hover {
  opacity: 0;
  transition: 0.4s ease-in-out;
  cursor: pointer;
}

.notes_card .card-header:hover img.notes_add_hover {
  opacity: 1;
  cursor: pointer;
}

.f12_inter_regular {
  font-weight: 400;
  color: #aaaaaa;
  font-size: 12px;
}

.f14_inter_regular {
  font-weight: 400;
  color: #000;
  font-size: 14px;
}

.f14_inter_medium {
  font-weight: 500;
  color: #000;
  font-size: 14px;
}

.notes_card .one_notes {
  width: 32% !important;
}

.initial_notes_model .card-header {
  margin-left: 0;
  padding: 0px !important;
  height: 50px !important;
}

.initial_notes_model .start_type_note {
  width: 100%;
  border: none;
  border-bottom: 1px solid #dddddd;
  padding: 29px 0;
}

.initial_notes_model form#addNoteForm input::placeholder {
  font-size: 14px;
  color: #aaaaaa;
  font-weight: 400;
}

.initial_notes_model .card-header {
  border-bottom: 1px solid #eeeeee !important;
  width: 100%;
  margin: 0;
}

form#addNoteForm textarea#noteText {
  border: none;
  border-bottom: 1px solid #dddddd;
  height: 160px;
}

.form-group.text_area_field textarea#noteText {
  position: relative;
}

.redo_undo_textarea {
  position: absolute;
  top: 274px;
  width: 90%;
}

form#addNoteForm .form-group.text_area_field {
  border-bottom: 1px solid #dddddd;
  height: 246px;
}

textarea#noteText {
  resize: none;
  border-bottom: none !important;
  height: 160px;
}

.initial_notes_model img {
  border-radius: 50%;
  height: 45px;
  width: 45px;
  padding: 12px;
  z-index: 9999;
  background-color: #fff;
}

.start_type_note img:hover {
  background-color: #f1f1f1;
}

div#jumper_modal .sc-jSFjdj.efWple.modal-content .btn.btn-transparent-dark.close {
  background-color: #fff;
  border-radius: 50%;
  height: 34px;
  width: 34px;
  top: 25px;
  display: flex;
  justify-content: center;
  font-size: 31px;
  align-items: center;
}

.doc_type_badge strong {
  background-color: #eeeeee;
  padding: 4px 13px;
  margin-left: 10px;
  border-radius: 8px;
}

.doc_action_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.doc_action_btn a.doc_download {}

.doc_action_btn a.btn.btn-dark.doc_download {
  width: 100px;
  height: 52px;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 10px;
}

.doc_action_right {
  gap: 0.938rem;

  span:first-child {
    font-size: 1.25rem;
    font-weight: 500;
    margin-left: 6px;
    font-family: "Inter", sans-serif;
  }

  .image-download-btn {
    height: 2.813rem;
    border-radius: 0.85rem !important;
  }

  .image-open-btn {
    height: 2.813rem;
    border-radius: 0.85rem !important;

    span {
      color: #fff;
    }
  }
}

.dd_download_arrow {
  position: absolute;
  top: 27%;
  right: 133px;
}

.doc_action_btn img {
  width: 25px;
  height: 25px;
}

form#addNoteForm input:focus {
  outline: none;
}

.text-area-close {
  position: absolute;
  top: 120px;
  right: 0;
}

.initial_notes_model .start_type_note img {
  height: 42px;
  width: 42px;
  margin-top: 50px;
}

.right_round button img {
  background-color: #000 !important;
  margin-top: 50px;
}

.doc_card_img img {
  height: 100%;
  object-fit: contain;
  opacity: 1;
  display: block;
  width: 100%;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.doc_head_img {
  position: relative;
  background: $gray-200;
  height: 25vh;
  margin: -20px;
  margin-bottom: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.doc_head_img:hover img {
  opacity: 0.7;
}

.doc_head_img:hover .middle {
  opacity: 1;
}

.doc_action_btn {
  position: sticky;
  bottom: 0;
  z-index: 9999;
  background-color: #fff;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

ul.list-upload li {
  display: inline-block;
}

ul.list-upload {
  list-style: none;
}

ul.list-upload li button img {
  width: 20px;
  height: 21px;
}

.inboxlistsHdr {
  margin-left: 0px !important;
}

#estimatorForm button .send_btn {
  width: 168px !important;
}

.filter-sub-header button,
.filter-sub-header span.font-size12 {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

.inboxlistsHdr ul li a {
  color: #aaaaaa !important;
  font-weight: 400;
  font-size: 14px;
  background-color: transparent !important;
}

.doc_action_left button {
  position: relative;
}


.doc_action_left button:hover::after {
  content: "";
  opacity: 1;
}

.doc_action_left button:hover img {
  opacity: 0;
}

.doc_action_left {
  background-color: transparent;
  padding: 7px;
}

.doc_action_left:hover {
  background-color: #ffe5e5;
  border-radius: 50%;
  padding: 7px;
}

.up_body_sec_div {
  height: 332px;
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  border: 1px dotted #ddd;
  border-radius: 10px;
  font-size: 1rem;
  text-align: center;
  color: #000;
  text-decoration: none;
  letter-spacing: 0.16px;
  cursor: pointer;
}

.up_model_img img {
  background: transparent linear-gradient(180deg, #f1f1f1 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
  border-radius: 500px;
  opacity: 1;
  padding: 12px;
  height: 50px;
  width: 50px;
}

.upload_doc_main {
  min-width: 600px;
  height: auto;
}

.card_border_wrap {
  border: 1px solid;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ddd;
  padding: 29px 15px;
  border-radius: 10px;
  position: relative;
}

.upload_doc_main select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  width: 100%;
  border: 1px solid #dddddd;
  border-radius: 10px;
  height: 50px;
  font-size: 14px;
  font-weight: 400;
  padding-left: 15px;
}

.up_file_name {
  display: flex;
}

.up_file_tile {
  display: inline-flex;
  justify-content: unsafe;
}

.up_file_tile p {
  padding-left: 24px;
}

.uplod_file_dropdown {
  margin-top: 19px;
}

.upload_doc_dis input::placeholder {
  color: #aaa;
  font-size: 14px;
  font-weight: 400;
}

.upload_doc_dis {
  border: 1px solid #dddddd !important;
  border-radius: 10px;
  height: 85px !important;
  width: 100% !important;
}

.upload_doc_dis input {
  border: none;
  padding: 14px;
  border-radius: 10px;
  outline: none !important;
}

.up_file_tile.checks_img img {
  background: #eeeeee 0% 0% no-repeat padding-box;
  border-radius: 500px;
  padding: 5px;
  width: 15px;
  height: 15px;
}

.up_file_tile img {
  width: 16px;
  height: 16px;
}

.check_circle_img {
  background: #eeeeee 0% 0% no-repeat padding-box;
  border-radius: 500px;
  height: 20px !important;
  width: 23px !important;
  padding: 3px;
}

.up_doc_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upoading_count .cacle_btn {
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-weight: 500;
  width: 140px;
  text-transform: uppercase;
  height: 50px;
}

.upoading_count .up_btn_dark.fs-14 {
  background-color: #000;
  border: none;
  font: normal normal normal 14px/17px Inter;
  font-weight: 500;
  width: 140px;
  text-transform: uppercase;
  color: #fff;
  height: 50px;
  border-radius: 10px;
}

.close-icon-doc button {
  position: absolute;
  right: 9px;
  top: 12px;
  background-color: transparent;
  border: none;
}

.upoading_count p {
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 0px;
  line-height: 17px;
  margin-bottom: 0px;
}

.upload_doc_head.sticky-top {
  background: #fff;
  z-index: 99;
  height: 52px;
  display: flex;
  align-content: safe;
  align-items: center;
}

.form-control:focus {
  outline: none;
  box-shadow: none !important;
  border-color: #000 !important;
}

.send_rfi_right button:focus {
  border-color: #000 !important;
}

select {
  background-image: url(./apps/springboard/images/icons/arrow_drop_down.svg);
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: calc(100% - 10px) 50%;
  padding-right: 20px;
}

.form-group {
  margin-bottom: 1rem;
}

.custom-input-style {
  margin-bottom: 0 !important;
}

.label {
  font-size: 14px;
  color: #000;
}

.form-control {
  font-size: 1rem;
}

.btn {
  font-size: 14px;
  font-weight: 500;
}

.btn-link {
  color: #000;
}

.history_content {
  margin: 0 auto;
  text-align: center;
  width: 50%;
  padding-top: 126px;
}

.vire_history {
  padding-top: 30px;
  position: relative;
}

.vire_history a::before {
  content: "";
  position: absolute;
  background-image: url(./apps/springboard/images/icons/opennewtab.svg);
  background-repeat: no-repeat;
  background-size: contain;
  padding-right: 20px;
  left: 8%;
  height: 24px;
  width: 24px;
  z-index: 99999;
}

.innder-padd-right {
  height: 100%;
}

.history_content>p {
  text-align: center;
  font-size: 1rem;
  letter-spacing: 0.16px;
  color: #000000;
  opacity: 1;
  font-weight: normal;
  padding-top: 24px;
}

.history-text {
  color: #fff;
  text-decoration: none;
}

.vire_history a {
  color: #fff !important;
  border-radius: 10px;
  opacity: 1;
  padding: 17px 20px 17px 54px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 247px;
  margin: 0 auto;
  height: 50px;
  position: relative;
}

.vire_history small {
  color: #999999;
  letter-spacing: 0.12px;
  font-weight: 400;
}

.spantext::before {
  content: "";
  position: absolute;
  background: #aaaaaa;
  height: 2px;
  width: 2px;
  border-radius: 50%;
  top: 8px;
  left: 2px;
}

.spantext {
  color: #999999;
  font-weight: 400;
  padding-left: 12px;
  position: relative;
}

.price_recd:nth-child(2) {
  margin-left: 45px;
}

.dam_sales p {
  font-weight: 400;
  color: #999999;
}

.dam_sales_img p {
  border-radius: 50%;
  height: 41px;
  width: 41px;
  background-color: #fac88e;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  text-align: center;
}

.edit_send_sec {
  position: relative;
  padding-top: 33px;
}

.edit_send_sec::before {
  content: "";
  background-color: #eeeeee;
  height: 1px;
  width: 100%;
  top: 15px;
  left: 0;
  position: absolute;
}

.edit_items a,
.send_req.price_recd a {
  letter-spacing: 0.18px;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;
  text-decoration: none;
  padding: 10px;
}

.edit_items a img {
  height: 20px;
  width: 20px;
  margin-right: 7px;
}

.send_req a img {
  width: 18px;
  height: 16px;
  margin-right: 7px;
}

.edit_items {
  height: 31px;
}

.edit_items a {
  text-decoration: none !important;
}

.send_req {
  background-color: #fff;
  height: 31px;
}

.edit_items:hover,
.send_req :hover {
  background-color: #f1f1f1;
  height: 31px;
}

.float_left {
  float: left;
}

.send_req_top button {
  border: none;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background: none;
  margin-top: -6px;
}

.send_req_top button:hover {
  background: #f1f1f1;
}

.send_req_top {
  display: flex;
  align-items: center;
}

.assign_new_rap_btn button {
  text-align: center;
  border-radius: 50% !important;
  border: none;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 50px;
  height: 50px;
  align-items: center;
}

.assign_new_rap_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.btn-link:hover {
  text-decoration: none !important;
}

.devider_border {
  background-color: #dddddd;
  height: 1px;
  width: 100%;
}

.tick_main button {
  font-weight: 400;
  font-size: 14px !important;
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
}

.tick_lower p {
  color: #aaaaaa;
}

.tick_dropdown {
  left: -168px !important;
  min-width: 400px;
  position: absolute !important;
  width: 100%;
}

.tick_dropdown button:hover {
  background-color: #f1f1f1;
  color: #000;
}

.tick_dropdown {
  padding-left: 10px;
}

.tick_main {
  position: relative;
}

.tick_main button:first-child {
  height: auto !important;
}

.emptystate_clm p {
  color: #000;
  font-weight: 400;
  padding-top: 20px;
  padding-bottom: 30px;
}

.emptystate_img_row {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.emptystate_clm a {
  background-color: #000;
  padding: 9px 14px 9px 11px;
  border-radius: 8px;
  color: #fff;
  font-weight: 400;
  text-decoration: none;
  display: inline-block;
}

.emptystate_main img.emptystateimgs {
  min-height: 96px;
  width: 69px;
}

.emptyadd img {
  max-width: 13px;
  min-height: auto;
  vertical-align: middle;
  margin-right: 1px;
}

.emptystate_main {
  display: flex;
  align-items: center;
  height: 100vh;
}

.emptystate_clm {
  width: 20%;
  margin-right: 51px;
}

form#linkQuoteRecipientForm label {
  color: #999999;
  font-size: 14px !important;
  text-transform: uppercase;
}

input#quotedTo::placeholder {
  color: #000;
  font-size: 1.25rem;
  line-height: 30px;
  font-family: "Roboto Slab", serif;
  font-weight: 500;
}

.div_bottom_border {
  border-bottom: 1px solid #eee !important;
  border: none;
  padding-left: 0px;
}

.edit_custos_info input#receipt::placeholder {
  color: #000;
  font-weight: 400;
  font-size: 1rem;
}

.edit_custos_info input#cname::placeholder {
  color: #000;
  font-weight: 400;
  font-size: 1rem;
}

.edit_custos_info input#coname::placeholder {
  color: #000;
  font-weight: 400;
  font-size: 1rem;
}

.edit_custos_info input#emails::placeholder {
  color: #000;
  font-weight: 400;
  font-size: 1rem;
}

.edit_custos_info input#call::placeholder {
  color: #000;
  font-weight: 400;
  font-size: 1rem;
}

input#email::placeholder {
  color: #000;
  font-weight: 500;
  font-family: "Roboto Slab", serif;
}

input#phone::placeholder {
  color: #000;
  font-weight: 500;
  font-family: "Roboto Slab", serif;
}

.text-gray-400 {
  color: #999999;
}

input#fax::placeholder {
  color: #000;
  font-weight: 500;
  font-family: "Roboto Slab", serif;
}

input#address::placeholder,
input#score::placeholder {
  color: #000;
  font-weight: 500;
  font-family: "Roboto Slab", serif;
}

.send_rfi_right button {
  width: 144px !important;
  margin-left: auto;
  background-image: url(./apps/springboard/images/icons/mail_outline.svg);
  background-repeat: no-repeat;
  background-position: 13px 10px;
  padding-left: 57px;
  height: 50px;
  border-radius: 10px;
}

.send_rfi_right button {
  width: 144px;
}

.send_rfi_right {
  float: right;
  width: 50%;
}

.send_link_right button {
  width: 114px !important;
  margin-left: auto;
  background-image: url(./apps/springboard/images/icons/supervisor_account_black_24dp.svg);
  background-repeat: no-repeat;
  background-position: 13px 10px;
  padding-left: 57px;
  height: 50px;
  border-radius: 10px;
}

.send_link_right button {
  width: 144px;
}

.send_link_right {
  float: right;
  width: 50%;
}

.send_rfi_left {
  float: left;
  width: 50%;
}

.edit_custos_info {
  padding-top: 20px;
}

.edit_custos_info label {
  text-transform: capitalize !important;
  padding-bottom: 8px;
}

.edit_custos_info .send_rfi_right button {
  background-image: none !important;
  width: 168px;
  padding-left: 15px;
  text-align: center;
}

.edit_custos_info .send_rfi_left button {
  width: 168px;
  height: 50px;
  border-radius: 10px;
}

.edit_custos_info .btn:focus {
  box-shadow: none;
  background-color: #1c222b !important;
}

.edit_cust_btn {
  display: flex;
  justify-content: flex-end;
  background-color: white;
  position: sticky;
  bottom: -20px;
}

.divider-grey {
  height: 1px !important;
  background-color: #e0e5ec;
  width: 100%;
  border-top: 1px solid #eeeeee;
}

.edit_cust_btn .cacel_btn button {
  background: #fff;
  color: #000;
  border: none;
  margin-right: 30px;
}

.tick_lower button img {
  max-width: 20px;
  height: 20px;
  opacity: 0;
}

.tick_lower button:hover img {
  opacity: 1;
}

.show_erp_togglebtn .switch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;
  margin-left: 10px;
}

.show_erp_togglebtn .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.show_erp_togglebtn .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dddddd;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.show_erp_togglebtn .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.show_erp_togglebtn input:checked+.slider {
  background-color: #000;
}

.show_erp_togglebtn input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.show_erp_togglebtn span {
  font-size: 14px;
  vertical-align: middle;
  font-weight: 400;
  padding-top: 3px;
}

.items_length_dropdown button#dropdownMenuButton {
  margin-left: 25px;
  font-weight: 500;
  font-size: 14px;
}

.show_erp_togglebtn {
  display: flex;
  justify-content: space-around;
}

.table_bg {
  background: #e3ece0;
  margin-top: 60px;
  position: relative;
}

.table_bg::before {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: #000;
}

table .total_table_row tr th strong {}

table.sc-dlMDgC.eJuoOi.table_bg.total_table_row tbody tr {
  font-weight: 600 !important;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #000;
}

td.text-nowrap.fw-semibold.green_dropdown {
  background: #062122;
  /* color: #fff!important; */
}

td .green_dropdown button {
  color: #fff !important;
}

td.text-nowrap.fw-semibold.green_dropdown {
  background: #062122;
}

table.sc-dlMDgC.eJuoOi.total_table_row {
  border-bottom: 1px solid #fff;
  margin-bottom: 12px;
}

img.notes_add_hover {
  opacity: 0;
  transition: 0.4s ease-in-out;
  cursor: pointer;
}

.notes_card .card-header:hover img.notes_add_hover {
  opacity: 1;
  cursor: pointer;
}

.f12_inter_regular {
  font-weight: 400;
  color: #aaaaaa;
  font-size: 12px;
}

.f14_inter_regular {
  font-weight: 400;
  color: #000;
  font-size: 14px;
}

.f14_inter_medium {
  font-weight: 500;
  color: #000;
  font-size: 14px;
}

.notes_card .one_notes {
  width: 32% !important;
}

.initial_notes_model .card-header {
  margin-left: 0;
}

.initial_notes_model .start_type_note {
  width: 100%;
  border: none;
  border-bottom: 1px solid #dddddd;
  padding: 29px 0;
}

.initial_notes_model form#addNoteForm input::placeholder {
  font-size: 14px;
  color: #aaaaaa;
  font-weight: 400;
}

.initial_notes_model .card-header {
  border-bottom: 1px solid #eeeeee !important;
  width: 100%;
  margin: 0;
}

form#addNoteForm textarea#noteText {
  border: none;
  border-bottom: 1px solid #dddddd;
}

.form-group.text_area_field textarea#noteText {
  position: relative;
}

.redo_undo_textarea {
  position: absolute;
  top: 274px;
  width: 90%;
}

form#addNoteForm .form-group.text_area_field {
  border-bottom: 1px solid #dddddd;
  height: 246px;
}

textarea#noteText {
  resize: none;
  border-bottom: none !important;
}

.initial_notes_model img {
  border-radius: 50%;
  height: 45px;
  width: 45px;
  padding: 12px;
  z-index: 9999;
  background-color: #fff;
}

.start_type_note img:hover {
  background-color: #f1f1f1;
}

div#jumper_modal .sc-jSFjdj.efWple.modal-content .btn.btn-transparent-dark.close {
  background-color: #fff;
  border-radius: 50%;
  height: 34px;
  width: 34px;
  top: 25px;
  display: flex;
  justify-content: center;
  font-size: 31px;
  align-items: center;
}

.optional_des {
  margin-top: 16px;
}

.doc_action_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0rem !important;
}

.doc_action_btn a.doc_download {
  margin-left: 17px;
}

.dd_btn .sc-bdnxRM.vRiCy {
  margin-bottom: 0px;
}

.doc_action_btn a.btn.btn-dark.doc_download {
  width: 166px;
  height: 52px;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 10px;
}

.dd_btn {
  position: relative;
}

.dd_download_arrow {
  position: absolute;
  top: 27%;
  right: 133px;
}

.doc_action_btn img {
  width: 25px;
  height: 25px;
}

form#addNoteForm input:focus {
  outline: none;
}

// .text-area-close {
//   position: absolute;
//   top: 95px;
//   right: 0;
// }

.initial_notes_model .start_type_note img {
  height: 42px;
  width: 42px;
  margin-top: 50px;
}

.optional_des p span {
  border-bottom: 1px solid #000;
}

.right_round button img {
  background-color: #000 !important;
  margin-top: 50px;
}

.doc_action_btn {
  position: sticky;
  bottom: 0;
  z-index: 9999;
  background-color: #fff;
  padding: 1rem 0rem !important;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

ul.list-upload li {
  display: inline-block;
}

ul.list-upload {
  list-style: none;
}

ul.list-upload li button img {
  width: 20px;
  height: 21px;
}

.inboxlistsHdr {
  margin-left: 0px !important;
  display: block !important;
  cursor: pointer;
}

#estimatorForm button .send_btn {
  width: 168px !important;
}

.filter-sub-header button,
.filter-sub-header span.font-size12 {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

.inboxlistsHdr ul li a {
  color: #aaaaaa !important;
  font-weight: 400;
  font-size: 14px;
  background-color: transparent !important;
}

.doc_action_left button {
  position: relative;
}


.doc_action_left button:hover::after {
  content: "";
  opacity: 1;
}

.doc_action_left button:hover img {
  opacity: 0;
}

.doc_action_left {
  background-color: transparent;
  padding: 7px;
}

.doc_action_left:hover {
  background-color: #ffe5e5;
  border-radius: 50%;
  padding: 7px;
}

.label-modal-role {
  margin-top: -100px;
}

.css-yk16xz-control {
  background-color: red;
  height: 56px;
}

.css-1hb7zxy-IndicatorsContainer {
  height: 56px;
}

.up_body_sec {
  height: 31.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  font-size: 1rem;
  text-align: center;
  color: #000;
  text-decoration: none;
  letter-spacing: 0.01rem;
}

.up_body_sec a {
  text-decoration: none !important;
  color: #000 !important;
  border-bottom: 1px solid #000;
}

.up_body_sec a:hover {
  color: #000 !important;
  cursor: pointer;
}

.up_model_img img {
  background: transparent linear-gradient(180deg, #f1f1f1 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
  border-radius: 500px;
  opacity: 1;
  padding: 12px;
  height: 50px;
  width: 50px;
}

.upload_doc_main {
  height: auto;
}

.card_border_wrap {
  border: 1px solid;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ddd;
  padding: 29px 15px;
  border-radius: 10px;
  position: relative;
}

.upload_doc_main select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  width: 100%;
  border: 1px solid #dddddd;
  border-radius: 10px;
  height: 50px;
  font-size: 14px;
  font-weight: 400;
}

.up_file_name {
  display: flex;
}

.up_file_tile {
  display: inline-flex;
  justify-content: unsafe;
}

.up_file_tile p {
  padding-left: 24px;
}

.uplod_file_dropdown {
  margin-top: 19px;
}

.upload_doc_dis input::placeholder {
  color: #aaa;
  font-size: 14px;
  font-weight: 400;
}

.upload_doc_dis {
  border: 1px solid #dddddd !important;
  border-radius: 10px;
  height: 85px !important;
  width: 100% !important;
}

.upload_doc_dis input {
  border: none;
  padding: 14px;
  border-radius: 10px;
  outline: none !important;
}

.up_file_tile.checks_img img {
  background: #eeeeee 0% 0% no-repeat padding-box;
  border-radius: 500px;
  padding: 5px;
  width: 15px;
  height: 15px;
}

.up_file_tile img {
  width: 16px;
  height: 16px;
}

.check_circle_img {
  background: #eeeeee 0% 0% no-repeat padding-box;
  border-radius: 500px;
  height: 20px !important;
  width: 23px !important;
  padding: 3px;
}

.up_doc_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upoading_count .cacle_btn {
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-weight: 500;
  width: 140px;
  text-transform: uppercase;
  height: 50px;
}

.upoading_count .up_btn_dark.fs-14 {
  background-color: #000;
  border: none;
  font: normal normal normal 14px/17px Inter;
  font-weight: 500;
  width: 140px;
  text-transform: uppercase;
  color: #fff;
  height: 50px;
  border-radius: 10px;
}

.close-icon-doc button img {
  width: 16px;
  height: auto;
  opacity: 0.5;
}

.modal-button-style {
  position: relative;
}

.modal-close-button {
  position: fixed;
  top: 15px;
  right: 10px;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: #fff;
  border: none;
  z-index: 32323233;
}

.modal-close-button:hover {
  height: 30px;
  width: 30px;
  border-radius: 100%;
}

button .p-0.searchBtn:hover img {
  opacity: 1;
}

.upoading_count p {
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 0px;
  line-height: 17px;
  margin-bottom: 0px;
}

.upload_doc_head.sticky-top {
  background: #fff;
  z-index: 99;
  height: 52px;
  display: flex;
  align-content: safe;
  align-items: center;
}

.inboxlists tr.docs_grid_tab td {
  border-bottom: 1px solid #eee !important;
}

.inboxlists tr.docs_grid_tab th {
  border-bottom: 1px solid #eee !important;
}

.cates_headers h3 {
  background-color: #f1f1f1;
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 14px;
}

.btn-check:focus+.btn,
.btn:focus {
  box-shadow: none !important;
}

.message_downarrow {
  margin-top: -8px;
}

.light_dark {
  color: #353535;
}

@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("https://rsms.me/inter/font-files/Inter-Thin.woff2?v=3.19") format("woff2");
}

@font-face {
  font-family: "Metropolis";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("https://rsms.me/inter/font-files/Inter-ThinItalic.woff2?v=3.19") format("woff2");
}

@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("https://rsms.me/inter/font-files/Inter-ExtraLight.woff2?v=3.19") format("woff2");
}

@font-face {
  font-family: "Metropolis";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("https://rsms.me/inter/font-files/Inter-ExtraLightItalic.woff2?v=3.19") format("woff2");
}

@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://rsms.me/inter/font-files/Inter-Light.woff2?v=3.19") format("woff2");
}

@font-face {
  font-family: "Metropolis";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("https://rsms.me/inter/font-files/Inter-LightItalic.woff2?v=3.19") format("woff2");
}

@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://rsms.me/inter/font-files/Inter-Regular.woff2?v=3.19") format("woff2");
}

@font-face {
  font-family: "Metropolis";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("https://rsms.me/inter/font-files/Inter-Italic.woff2?v=3.19") format("woff2");
}

@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://rsms.me/inter/font-files/Inter-Medium.woff2?v=3.19") format("woff2");
}

@font-face {
  font-family: "Metropolis";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("https://rsms.me/inter/font-files/Inter-MediumItalic.woff2?v=3.19") format("woff2");
}

@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://rsms.me/inter/font-files/Inter-SemiBold.woff2?v=3.19") format("woff2");
}

@font-face {
  font-family: "Metropolis";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("https://rsms.me/inter/font-files/Inter-SemiBoldItalic.woff2?v=3.19") format("woff2");
}

@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://rsms.me/inter/font-files/Inter-Bold.woff2?v=3.19") format("woff2");
}

@font-face {
  font-family: "Metropolis";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("https://rsms.me/inter/font-files/Inter-BoldItalic.woff2?v=3.19") format("woff2");
}

@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("https://rsms.me/inter/font-files/Inter-ExtraBold.woff2?v=3.19") format("woff2");
}

@font-face {
  font-family: "Metropolis";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("https://rsms.me/inter/font-files/Inter-ExtraBoldItalic.woff2?v=3.19") format("woff2");
}

@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("https://rsms.me/inter/font-files/Inter-Black.woff2?v=3.19") format("woff2");
}

@font-face {
  font-family: "Metropolis";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("https://rsms.me/inter/font-files/Inter-BlackItalic.woff2?v=3.19") format("woff2");
}

.parentDiv-settingAdmin {
  display: flex;

  &:hover {
    background-color: #F9F9F9;
  }
}

.settingAdmin {
  height: 50px;
  padding: 25px;
  cursor: pointer;
  width: 100%;
  border-bottom: solid 2px;
  border-color: #F9F9F9;
  margin-left: 10px;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  display: flex;

  &:hover {
    background-color: #F9F9F9;

    .icn-btn-admin {
      fill: black !important;
    }
  }
}

.deActivateForm {
  height: 29.125rem;

  .lineBreak {
    margin-top: 1rem;
  }

  .backgroundColor {
    background-color: #EC3E371A;
    padding: 10px;
    width: 493px;
    margin-left: -18px;
    margin-top: 20px;
  }

  .f24-deActivate {
    font-size: 24px;
    color: #000;
  }

  .icn-btn-deactivate {
    margin-top: -105px;
  }

  .leftMargin {
    margin-left: 20px;
  }

  .deactivate-margintop {
    margin-top: 20px;

    .deactivate-link {
      color: black !important;
      font-weight: 420;
      text-decoration: underline;

      &:hover {
        color: #000000 !important;
      }
    }
  }

  .checkboxdeactivate {
    position: fixed;
    bottom: 90px;
    margin-left: 10px
  }

  .lastbuttons {
    width: 92%;
    position: fixed;
    bottom: 20px;
    display: flex;
    justify-content: space-between;

    .cancelbutton {
      width: 118px;
      height: 3.125rem;
      border-radius: 10px;
      border: none;
      font-weight: 700;
    }

    .deactivebutton {
      width: 225px;
      color: #FF0000;
      border: none;
      background-color: transparent;
    }
  }
}

.changePasswordModal {
  height: 18.625rem;
  width: 450px;

  .f24-changePasswordBackButton {
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  .f24-changePassword {
    font-size: 24px;
    color: #000000;
    margin-top: 5px;
    font-family: "Roboto Slab", Medium !important;
  }

  .boder-bottom {
    border-bottom: solid 1px;
    border-color: #DDDDDD;
    margin-top: 20px;
    width: 107%;
    margin-left: -18px;
  }

  .settingAdmin-change {
    cursor: pointer;
    width: 100%;
    border-bottom: solid 1px;
    border-color: #DDDDDD;
    margin-left: 20px;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    display: flex;
    font-size: 1rem;
    height: 50px;

    &:hover {
      background-color: #F9F9F9;
    }
  }

  .reset-link-height {
    height: 70px;
  }
}

.newPasswordModal {
  height: 350px;

  .f24-changePassword {
    font-size: 24px;
    color: #000;
    font-family: "Roboto Slab", Medium;
  }

  .set-new-pass-buttons {
    width: 92%;
    position: fixed;
    justify-content: flex-end;
    display: flex;
    bottom: 20px;

    .back-button {
      height: 50px;
      width: 118px;
      font-size: 14px;
      font-family: "Inter", Medium !important;
      margin-right: 10px;
      background: transparent;
      border: none;
    }

    .change-pass-button {
      width: 180px;
      height: 50px;
      font-size: 14px;
      font-family: "Inter", Medium !important;
      border-radius: 5px;
      background-color: black;
      color: white;
    }
  }

  .icn-btn-pass-admin {
    position: absolute;
    left: 90%;
    bottom: 11px;
  }
}

.admin-image-center {
  margin-top: auto;
}


.button-is-loadings {
  opacity: 0;
  animation: loading-icon-anim 0.5s ease-out alternate infinite;

  @keyframes loading-icon-anim {
    to {
      opacity: 1;
      transform: scale(1.1);
    }
  }
}

// Start Admin Button new style 
.button-primary {
  --bgcolor: var(--bs-gray-200);
  height: 50px;
  width: fit-content;
  border: none;
  background-color: var(--bgcolor);
  color: var(--bs-black);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 20px;
  border-radius: 10px;
  transition: transform 1s cubic-bezier(0, -0.01, 0, 1.31);
  will-change: transform;
  outline: none;

  &:disabled {
    opacity: 0.5;
    cursor: default;
    background-color: var(--bgcolor);

    .button-label,
    .button-icon {
      opacity: 0.75;
    }
  }

  &:focus-visible:not(:disabled) {
    outline: none;
    box-shadow: 0 0 0 2px var(--bs-white), 0 0 0 4px var(--bs-gray-800);
  }

  &:active:not(:disabled) {
    transform: scale(0.95);
  }

  &.button-with-icon {
    padding-left: 15px;
  }

  &.button-wide {
    width: 100%;

    &:active:not(:disabled) {
      transform: scale(0.985);
    }
  }

  &.button-accent {
    --bgcolor: var(--bs-primary);
    color: var(--bs-white);
  }

  &.button-secondary {
    --bgcolor: rgba(255, 255, 255, 0);
    color: var(--bs-black);

    &:focus-visible:not(:disabled),
    &:hover:not(:disabled) {
      --bgcolor: var(--bs-gray-50);
    }

    &:active:not(:disabled) {
      --bgcolor: var(--bs-gray-100);
    }
  }

  &.button-danger {
    color: var(--bs-danger);

    &:focus-visible:not(:disabled) {
      box-shadow: 0 0 0 4px var(--bs-white), 0 0 0 6px var(--bs-danger);
    }
  }

  &.button-is-loading {
    position: relative;
    overflow: hidden;
    pointer-events: none;

    .button-label,
    .button-icon {
      opacity: 0;
    }

    &::before {
      content: "";
      position: absolute;
      background-color: var(--bgcolor);
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      border-radius: 10px;
    }

    &::after {
      content: "";
      position: absolute;
      height: 24px;
      width: 24px;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNNiAxMGMtMS4xIDAtMiAuOS0yIDJzLjkgMiAyIDIgMi0uOSAyLTItLjktMi0yLTJ6bTEyIDBjLTEuMSAwLTIgLjktMiAycy45IDIgMiAyIDItLjkgMi0yLS45LTItMi0yem0tNiAwYy0xLjEgMC0yIC45LTIgMnMuOSAyIDIgMiAyLS45IDItMi0uOS0yLTItMnoiLz48L3N2Zz4=");
      background-position: center;
      background-repeat: no-repeat;
      opacity: 0;
      // filter: invert(1);
      animation: button-loading-anim 0.3s ease-out alternate infinite;

      @keyframes button-loading-anim {
        to {
          opacity: 1;
          transform: scale(1.1);
        }
      }
    }
  }

  &.button-accent {
    &.button-is-loading {
      &::after {
        filter: invert(1);
      }
    }
  }

  .button-label {
    user-select: none;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 0.35px;
    color: currentColor;
    white-space: nowrap;
  }

  .button-icon {
    --size: 24px;
    height: var(--size);
    width: var(--size);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    margin-left: 0.313rem;

    svg {
      fill: currentColor;
    }
  }
}

.icon-button-primary-main {
  --size: 40px;
  height: var(--size);
  width: var(--size);
  background-color: var(--bs-gray-200);
  border: none;
  border-radius: 500px;
  font-size: 0;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform 1s cubic-bezier(0, -0.01, 0, 1.31);
  will-change: transform;
  user-select: none;

  &:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.35;
    cursor: default;
  }

  &:hover:not(:disabled) {
    background-color: var(--bs-gray-300);
  }

  &:focus-visible:not(:disabled) {
    outline: none;
    box-shadow: 0 0 0 2px var(--bs-white) 0 0 0 4px var(--bs-gray-800);
  }

  &:active:not(:disabled) {
    transform: scale(0.9);
    background-color: var(--bs-gray-400);
  }

  &.icon-button-secondary:not(:disabled) {
    opacity: 0.75;
    background-color: rgba(255, 255, 255, 0);

    &:hover:not(:disabled),
    &:focus-visible:not(:disabled) {
      background-color: var(--bs-gray-200);
      opacity: 1;
    }

    &:active:not(:disabled) {
      background-color: var(--bs-gray-400);
    }
  }

  &.icon-button-close {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNMTguMyA1LjcxYy0uMzktLjM5LTEuMDItLjM5LTEuNDEgMEwxMiAxMC41OSA3LjExIDUuN2MtLjM5LS4zOS0xLjAyLS4zOS0xLjQxIDAtLjM5LjM5LS4zOSAxLjAyIDAgMS40MUwxMC41OSAxMiA1LjcgMTYuODljLS4zOS4zOS0uMzkgMS4wMiAwIDEuNDEuMzkuMzkgMS4wMi4zOSAxLjQxIDBMMTIgMTMuNDFsNC44OSA0Ljg5Yy4zOS4zOSAxLjAyLjM5IDEuNDEgMCAuMzktLjM5LjM5LTEuMDIgMC0xLjQxTDEzLjQxIDEybDQuODktNC44OWMuMzgtLjM4LjM4LTEuMDIgMC0xLjR6Ii8+PC9zdmc+);
  }

  &.icon-button-back {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNMTkgMTFINy44M2w0Ljg4LTQuODhjLjM5LS4zOS4zOS0xLjAzIDAtMS40Mi0uMzktLjM5LTEuMDItLjM5LTEuNDEgMGwtNi41OSA2LjU5Yy0uMzkuMzktLjM5IDEuMDIgMCAxLjQxbDYuNTkgNi41OWMuMzkuMzkgMS4wMi4zOSAxLjQxIDAgLjM5LS4zOS4zOS0xLjAyIDAtMS40MUw3LjgzIDEzSDE5Yy41NSAwIDEtLjQ1IDEtMXMtLjQ1LTEtMS0xeiIvPjwvc3ZnPg==);
  }
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 500px;
  border: 1px solid var(--bs-gray-200);
  box-shadow: 0 20px 60px 0 rgba(0, 0, 0, 0.05);
  padding: 20px;
  border-radius: 20px;
}

.divider {
  height: 1px;
  width: 100%;
  display: flex;
  background-color: var(--bs-gray-200);
}

// End Admin Button new style 

.pd-25 {
  padding-bottom: 25vh
}

.pd-top-15 {
  padding-top: 0.9375rem;
}

.deactive-icon-color {
  color: var(--bs-orange);
}

.enter-search-text-admin {
  display: flex;
  position: relative;
  background: #fff;
  padding-left: 10px;
  font-size: 14px;
  right: 0;
  align-items: center;
  z-index: 1;
  bottom: 1px;
  right: 10px;

  span {
    line-height: normal;
    color: #aaaaaa;
    font-size: 12px;
  }

  .press-enter-image {
    margin-left: 4px;
    margin-right: 4px;
  }
}

.selectList-drop {
  border: 1px solid #dddddd;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  box-shadow: 0px 4px 8px #0000001a;
  -webkit-box-shadow: 0px 4px 8px #0000001a;
  -moz-box-shadow: 0px 4px 8px #0000001a;
  padding: 0.625rem !important;
}

.selectList-drop button {
  color: #000;
  font-size: 14px;
  width: 100%;
  text-align: left;
  display: block;
  padding: 8px 0;
  border-bottom: 1px solid #eeeeee;
}

.selectList-drop button.active {
  background: url("./apps//springboard//images/icons/check.svg") no-repeat scroll 95% 50% / 11px;
}

.arrow-black {
  .MuiSelect-selectMenu {
    &:before {
      content: "";
      position: absolute;
      width: 1.875rem;
      height: 1.875rem;
      right: 0.9375rem;
      top: 1.1875rem;
      border: 0;
      background-image: url("./apps/springboard/images/icons/arrow-up.svg");
      background-repeat: no-repeat;
    }

    &.Mui-disabled {
      &:before {
        opacity: 0.3;
      }
    }
  }

  .MuiSelect-icon {
    display: none;
  }
}

.multiSnackbar {
  color: var(--bs-white) !important;
  opacity: 0.95 !important;
  padding: 10.6px 20px !important;
  font-size: 1rem !important;
  min-width: 500px !important;
  box-shadow: 0px 20px 40px #0000004D !important;
  border-radius: 15px !important;
  background-color: var(--bs-black) !important;
}

.letter-space-0-14 {
  letter-spacing: 0.0088rem;
}

.f20-Inter-500 {
  line-height: 1.875rem;
  font: normal normal 600 1.25rem/1.5rem Inter;
  letter-spacing: 0rem;
  color: #AAAAAA;
}


.f20-Inter-500-sab {
  text-align: left;
  font: normal normal 600 1.25rem/1.5rem Inter;
  letter-spacing: 0rem;
  color: #000000;
}

.send_rfi_row_new {
  font-weight: 500;
}

.f16-inters-500 {
  font-size: 1rem;
  font-weight: 500;
}

.file-size-mp {
  text-align: left;
  font: normal normal normal 0.875rem/1.5rem Inter;
  letter-spacing: 0rem;
  color: #AAAAAA;
}

.file-upload-group {
  transform: translateY(-31px);

  a {
    color: #000000 !important;
    font-weight: 500;
    padding: 0;
    margin-left: 0.3125rem;
  }
}

.btn-group-sm {
  button.MuiButton-root {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    line-height: 1.25rem;
    height: fit-content;
    min-height: auto;
    border-radius: 0.625rem !important;
    font-weight: 500;
  }
}

.add_rep_tag {
  height: 32px;
  background: #F1F1F1 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 10px;
  margin-right: 5px;

  & a:hover {
    text-decoration: none;
  }

  & .micon-set {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    &:hover {
      color: #000 !important
    }
  }

  .icon-color {
    color: #ffffff !important;
  }

}

.font-w-500 {
  font-weight: 500 !important;
}

.cust-edit {
  width: 3.75rem;
  background: #F1F1F1 0% 0% no-repeat padding-box;
  border-radius: 0.625rem;
  padding-bottom: 0.313rem;
  padding-left: 0.313rem;
}

.spr-dropdwn {
  margin-left: 1rem;
  padding: 0.25rem 1rem 0.25rem 1rem;

  &:hover {
    background: #F9F9F9 0% 0% no-repeat padding-box;
    border-radius: 0.625rem;
  }
}

.open_document {
  height: 44px;
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
  padding: 2px;
}

.downloard_document {
  height: 44px;
  background: #F1F1F1 0% 0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
  padding: 2px;
  margin-right: 10px;
}

.tickets-list-modal {
  width: max-content;
  position: absolute;
  inset: 0rem auto auto 0rem;
  margin: 0.625rem 0rem;
  transform: translate(-16.875rem, 1.4375rem);
}

.gap-tickets {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 21.875rem;
}

.btn {
  &.MuiButton-root {
    &:focus-visible {
      outline: 0.13rem solid var(--bs-gray-600) !important;
    }
  }
}

.search-input-focus {
  .MuiFilledInput-root {
    &:focus-within {
      outline: 0.13rem solid var(--bs-black) !important;
      background-color: #ffffff !important
    }
  }

  .MuiOutlinedInput-root {
    &:focus-within {
      outline: 0.0625rem solid var(--bs-black) !important;
      background-color: #ffffff !important
    }
  }

  .MuiInput-root {
    &:focus-within {
      input {
        outline: 0.0625rem solid var(--bs-black) !important;
        background-color: #ffffff !important
      }
    }
  }

}

.toggle-focus {
  .MuiSwitch-root {
    &:focus-within {
      outline: 0.13rem solid var(--bs-gray-600) !important;
      outline-offset: 0.0625rem;
      border-radius: 0.8125rem;
    }
  }
}

.tabDropdown {
  .MuiSelect-root {
    &:focus {
      background-color: var(--bs-white) !important;
      outline: 0.13rem solid var(--bs-black) !important;
    }
  }
}

.add-document-btn {
  &.MuiButton-root {
    &:focus-visible {
      outline: 0.13rem solid var(--bs-gray-600) !important;
      outline-offset: 0.0625rem;
    }
  }
}

.button-focus-add-document {
  &:focus-visible {
    border-radius: 1.563rem !important;
    border: 0 !important;
    outline: 0.13rem solid var(--bs-gray-600) !important;
    width: 50%;
    height: 100%;
    min-height: 2.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.button-focus-edit-doc {
  background-color: #DDDDDD;
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.563rem;
  cursor: pointer;

  &:focus {
    outline: 0.13rem solid var(--bs-gray-600) !important;
  }

}

.storage-date-picker {
  .MuiTextField-root {
    &:focus-within {
      background-color: var(--bs-white) !important;
      outline: 0.13rem solid var(--bs-black) !important;
      border-radius: 0.625rem;
    }
  }

  .MuiTextField-root {
    &:has(button:focus-visible) {
      outline: none !important;
    }
  }
}

.dropzone:focus-within .file-upload-main-input {
  background-color: var(--bs-white) !important;
  outline: 0.13rem solid var(--bs-black) !important;
  border-radius: 0.875rem !important
}

.focus-link-navigation {
  * {
    color: #999999 !important;

    img {
      margin-top: -0.3125rem;
      margin-left: 0.25rem;
    }
  }

  &:hover,
  &:focus-within {
    span * {
      color: var(--bs-black) !important;
      text-decoration: underline var(--bs-black);
      fill: var(--bs-black);
      filter: invert(1) brightness(0);
    }
  }
}

.text-area-focus:has(textarea:focus-visible) {
  .pos-auto-size-text-area-container {
    outline: 0.13rem solid var(--bs-black) !important;
    background-color: #ffffff !important;

    textarea {
      background-color: #ffffff !important;
      border: none;
    }
  }
}

.button-focus-class {
  &:focus-visible {
    outline: 0.13rem solid var(--bs-gray-600) !important;
    outline-offset: 0.0625rem;
  }
}

.checkbox-focus {
  &:focus-within {
    outline: 0.13rem solid var(--bs-gray-600) !important;
    border-radius: 0.0625rem;
    margin: 0.5625rem;
    padding: 0rem;
  }
}

.btn-datepicker-focus {
  &:focus-visible {
    outline: 0.13rem solid var(--bs-gray-600) !important;

    span {
      display: none;
    }
  }
}

.pagination-button-focus {
  &:focus-visible {
    outline: 0.13rem solid var(--bs-gray-600) !important;
    outline-offset: 0.1px;
  }
}

.pd-top-20 {
  padding-top: 1.25rem;
}

.MuiDialog-container,
.MuiBackdrop-root {
  transition-duration: 0s !important;
}