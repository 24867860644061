.material-icon {
    --size: 24;
    font-family: "Material Icons Rounded" !important;
    display: flex;
    padding: 0;
    line-height: 1;
    width: calc(var(--size) * 1px);
    height: calc(var(--size) * 1px);
    font-size: calc(var(--size) * 1px);
    color: inherit;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
}
