.add-product {
  &-heading {
    color: #000;
    font-weight: 600;
  }
  &-input-title {
    color: #000;
    font-weight: 500;
  }
  &-description {
    .default-facility-name {
      padding-left: 0;
      height: auto;
      textarea {
        background-color: #f1f1f1;
        border-radius: 20px;
        border: 0px solid;
      }
    }
  }
  &-select-section {
    select {
      background-color: #f1f1f1;
      border-radius: 20px !important;
      border: 0px solid !important;
      font-size: 16px !important;
      &:focus {
        background-color: #f1f1f1;
        border-radius: 20px !important;
        border: 0px solid !important;
      }
      option {
        font-size: 16px !important;
      }
    }
  }
  &-vendor-price {
    margin-bottom: 20px;
    input {
      margin-bottom: 6px;
    }
    .vendor-price-must-be-value {
      color: #999999;
      font-size: 0.813rem;
    }
  }
  &-save-btn {
    background-color: #000 !important;
    color: #fff !important;
    border-radius: 15px !important;
    width: 60% !important;
    display: flex;
    justify-content: flex-start !important;
    padding: 1.25rem !important;
    margin-top: 1.25rem !important;
    &:hover {
      color: #fff;
    }
  }
  &-form {
    padding-bottom: 20px;
  }
  .vendor-price-validation-error-text {
    margin-bottom: 20px;
  }
  &-location {
    margin-top: 10px;
    display: flex;
    &-row {
      .ws-select {
        // border-right: 1px solid #000 !important;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        &:focus {
          background-color: #f1f1f1;
        }
      }
    }
    &-unit {
      .ws-select {
        border-radius: 0 !important;
      }
    }
    &-shelf {
      .ws-select {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
      }
    }
    &-row .ws-select,
    &-unit .ws-select,
    &-shelf .ws-select {
      width: 100% !important;
      margin-top: 0;
      &:focus {
        background-color: #f1f1f1;
      }
    }
    &-shelf{
      width: 33%;
      position: relative;
      display: flex;
      align-items: center;
    }
    &-row,
    &-unit {
      width: 33%;
      position: relative;
      display: flex;
      align-items: center;
      &:before {
        content: "";
        position: absolute;
        right: 0;
        top: auto;
        bottom: auto;
        height: 70%;
        width: 0.25rem;
        border-right: 0.063rem solid #cdc3c3;
        margin: auto;
      }
    }
  }
}
.location-inner-label {
  transform: translate(0, 1.5px) scale(0.75) !important;
  transform-origin: top left !important;
}
.location-error-div{
  display: flex;
  .location-error {
    width: 33.33%;
    padding: 0 0.625rem;
  }
}

.arrow-black {
  .MuiSelect-icon{
    color : rgba(0, 0, 0, 1)
  }
  .MuiSelect-iconFilled{
    right: 1.25rem;
  }
  svg {
    min-width: 1.875rem;
    margin-right: 0.9375rem;
   }
}
.drawer-container-spacing.modal-content-style {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.big-form-field-title {
  font-size: 1.25rem !important;
}
.manudactureLink{
  display: inline-block;
  float: left;
}

a:is(.addManufacture:not(.nav-link, .dropdown-item, .page-tips-footer a, .btn)){
  color: black !important;
  border-bottom: 1px solid black;
  width: fit-content;
  display: inline-block;
  float: right;
  text-decoration: none;
  &:visited,&:link,&:hover,&:active{
    color: black !important;
    }
  &:focus-visible{
    outline: 0.13rem solid var(--bs-gray-600) !important;
  }  
}
.refresh{
  cursor: pointer;
}
.action--block{
  display: flex;
  align-items: center;
  gap: 15px;
  .manudactureLink{
    margin-right: auto;
  }
}

.refresh-btn-focus{
  &:focus-visible{
    .refresh{
      border-radius: 0.0625rem !important;
      outline: 0.13rem solid var(--bs-gray-600) !important;
    }
  }
}