@import "src/apps/pos/assets/css/variable.scss";

.return-complete-title-section{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .title{
        font-size: 1.75rem;
        font-weight: bold;
    }
    .return-successfull-back-btn{
        position: absolute;
        background-color: $bgColor;
        border-radius: 1.25rem;
        padding: 1.25rem;
        left: -5rem;
    }
}
.return-complete-top-title-section{
    border-bottom: 2px solid #DDDDDD;
    padding-bottom: 20px;
}
.return-complete-second-title-section{
    padding-top: 1.563rem;
    padding-bottom: 1.563rem;
}
.return-complete-second-section{
    border-bottom: 0.188rem solid $black-color;
}
.return-successfull-container{
    width: 100%;
    max-width: 95rem;
    display: flex;
    margin: auto;
    position: relative;
    padding-top:6.25rem ;
    height: 100%;
    main{
        height: inherit;
        .main-successfull-div{
            height: inherit;
            width: 100%;
            .scroll-styling{
                height: inherit;
                .return-draft-tooltip{
                    margin-top: 1.25rem ;
                    padding: 0.938rem !important;
                    background: $bright-Gray 0% 0% no-repeat padding-box;
                    border-radius: 0.625rem;
                    opacity: 1;
                    img{
                        height: 1.5rem;
                        width: 1.625rem;
                    }
                }
                .MuiTableContainer-root{
                    width: 80%;
                    height: inherit;
                    overflow-x: unset;
                }
            }
        }
    }
}
.table-total-data{
    td{
        border-top: 0.063rem solid $black-color;
        border-radius: 0 !important;
    }
}
.total-data-row{
    background-color: $gray-50;
    border-top: 0.125rem solid $light-gray-border;
    td span{
        font-weight: 600;
    }
}

.success-table-data-row{
    border-bottom: 0.063rem solid $border-color;
}
.success-main-data-table {
    border-collapse: collapse !important;
}

.complete-return-table-header{
    th {
        font-size: 0.625rem;
    }
}

.draft-order-info{
    max-width: 640px;
    display: flex;
    padding: 0.938rem !important;
    img{
        height: fit-content;
        margin-top: 3px;
    }
}

.table-column-draft-order-tooltip-icon {
    display: inline-flex !important;
}

.tooltip-icon {
    background: transparent url('../../../springboard/images/icons/group_2950.svg') 0% 0% no-repeat padding-box;
    opacity: 1;
}
.tooltip-icon {
    margin-right: 0.625rem;
}
.return-draft-note-icon {
    margin-right: 0.625rem;
}
.tooltip-header{
    font: normal normal bold 1.125rem/1.5rem Inter;
    letter-spacing: -0.4px;
}
.tooltip-description{
    font: normal normal normal 1.125rem/1.5rem Inter;
    letter-spacing: 0.01rem;
    margin-top: 0.75rem;
}
.return-draft-tooltip-main-div {
    margin-left: 0.3rem;
}
.failed-retry-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FEF4E5 0% 0% no-repeat padding-box;
    border-radius: 0.625rem;
    padding: 0.625rem;
    margin-bottom: 2.5rem;
    margin-top: 1.25rem;
    .failed-retry-left-sec{
        span{
            font-size: 1.125rem;
            font-weight: 500;
        }
    }
    .failed-retry-right-sec{
        span{
            font-size: 1rem;
            font-weight: 500;
        }
    }
}
.rma-info-col{
    position: relative;
    display: flex;
    align-items: center;
    .rma-partially-failed-icon{
        position: absolute;
    }
}
.return-successful-main-table{
    padding-right: 1.25rem;
    display: flex;
    align-items: center;
}
.print-main-button{
    position: relative;
    .print-dropdown{
        position: absolute;
        border: 1px solid #000;
        width: 100%;
        min-width: 240px;
    }
}

.print-btn {
    width: 7rem;
    background-color: #f1f1f1 !important;
    color: #000000 !important;
  }
.print-btn:hover {
    background-color: #DDDDDD !important;
  }

  .print-all-dropdown-content, .product-list-dropdown {
    padding: 1rem;
    margin-top: 0.9rem;
    left: 0rem;
    display: none; //none
    position: absolute;
    background-color: #ffffff;
    min-width: 13.5rem;
    border-radius: 1.25rem;
    box-shadow: 0rem 0.5rem 1rem 0rem rgba(0, 0, 0, 0.2);
    z-index: 11;
  }
  
  .print-all-dropdown-content div, .product-list-dropdown div {
    color: black;
    padding: 0.75rem 1rem;
    text-decoration: none;
    display: block;
  }
  .print-dropdown-content {
    text-align: left;
    letter-spacing: 0rem;
    opacity: 1;
    border-bottom: 1px solid #DDDDDD;
    border-radius: 0;
    &:last-child{
        border-bottom: none;
    }&:hover{
        border-radius: 0.625rem;
    }
}

.all-print-btn {
    margin-right: 0.938rem;
}
  
.print-all-dropdown-content div:hover, .product-list-dropdown div:hover {background-color: #EEEEEE;}
  
.print-btn:hover{
      .print-all-dropdown-content {
       display: block;
    }
  } 

  .return-successful-btn.MuiButtonBase-root {
    min-width: 11.563rem;
    min-height: 3.125rem;
  }
  .return-successful-all-print-btn{
    min-height: 3.125rem;
  }

.draft-orderId-text{
    padding: 0.5rem;
}

.draft-order-note-close {
    cursor: pointer;
}