@import "apps/pos/assets/css/variable.scss";

.set-customer-code-modal-container {
  .MuiOutlinedInput-root {
    padding: 0.625rem !important;
  }
  .modal-header{
    display: flex;
    justify-content: space-between;
    padding: 0.938rem 1.7rem !important;
    border-bottom: none;
    .clear-icon {
      font-size: 2.5rem;
      padding: 0.625rem;
      cursor: pointer;
      &:hover{
        background-color: $bright-Gray;
        border-radius: 1.563rem ;
      }
    }
    .button-focus{
      &:focus-visible {
         border-radius: 1.563rem !important;
         outline: 0.13rem solid var(--bs-gray-600) !important;
      }
    }
  }
  .MuiDialog-container {
    .MuiDialog-paper {
      width: 34.375rem;
      height: auto;
      border-radius: 1.25rem;
    }
    .MuiDialogActions-root {
      padding: 1.5rem;
      .add-to-return-cart-button-error {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }
  }
}

.modal-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.modal-form {
  .modal-input-title {
    font-size: 1rem;
    font-weight: 500;
  }
  .modal-input-error {
    font-size: 0.813rem;
  }
}
.check-availability-icon {
  width: 0.875rem;
}
.availability-text {
  font-size: 0.75rem;
  color: #000;
  font-weight: 600;
}
.change-code-cancel-btn{
  width: 7.5rem;
  min-width: 7.5rem;
  max-width: 7.5rem;
  height: 2.75rem;
}
.change-cust-code-save-btn{
  width: 12rem;
  min-width: 12rem;
  max-width: 12rem;
  height: 2.75rem;
}
.change-cust-code-set-btn{
  width: 9rem;
  min-width: 9rem;
  max-width: 9rem;
  height: 2.75rem;
}