@import 'ui/scss/general.scss';

@mixin threeColumns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: flex-start;
  gap: 0.625rem;
}

.item-line {
  display: grid;
  grid-template-columns: var(--header-columns);
  gap: 1.25rem;
  padding: 0.5rem 0;
  border-bottom: 1px solid #eee;
  font-size: 0.875rem;

  .item-line-product {
    display: grid;
    grid-template-columns: var(--item-columns);
    align-items: flex-start;
    gap: 0.525rem;

    .product-details {
      display: flex;
      align-items: flex-start;
      gap: 1.25rem;

      .item-details-group {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        p {
          line-height: 1.25;

          &.manufacturer-name{
            color: $light-grey;
          }

          &.item-code,
          &.item-name {
            color: $black;
          }

          &:empty {
            display: none;
          }
        }

        .inline-note {
          margin-top: 0.25rem;
        }
      }
    }

    .item-qty {
      text-align: right;

      &.item-qty-empty {
        color: #999;
      }
    }
  }

  .item-line-prices,
  .item-line-metadata {
    @include threeColumns();
  }

  .item-line-prices {
    text-align: right;

    p {
      display: flex;
      gap: 0.25rem;
      flex-direction: column;

      span {
        display: block;
        min-height: 2.5ch;
        
        &:nth-child(2) {
          color: #aaa;
        }

        &:empty {
          visibility: hidden;
        }
      }
    }
  }

  .item-line-metadata {
    grid-template-columns: repeat(2, 1fr);
    p {
      font-size: 0.75rem;
      line-height: 1.35;
    }
  }
}
