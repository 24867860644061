div {
    .icon-btn {
        --size: 32px;
        background-color: rgba(255, 255, 255, 0);
        display: flex;
        align-items: center;
        justify-content: center;
        height: var(--size);
        width: var(--size);
        background-position: center;
        background-size: 80%;
        background-repeat: no-repeat;
        border: none;
        cursor: pointer;
        border-radius: 500px;
        opacity: 0.25;
        will-change: transform;
        transition: all 1s cubic-bezier(0, -0.01, 0, 1.31);

        &:hover,
        &:focus-visible {
            background-color: #eee;
            opacity: 1;
            outline: none;
        }

        &:focus-visible {
            box-shadow: 0 0 0 2px #fff, 0 0 0 4px var(--bs-gray-400);
        }

        &:active {
            background-color: #ddd;
            opacity: 1;
            transform: scale(0.85);
        }

        span {
            font-size: 0;
            color: rgba(255, 255, 255, 0);
            visibility: hidden;
        }
    }

    button {
        user-select: none;
        cursor: pointer;
        font-family: var(--inter-font-family);
    }

    .btn-loading,
    .btn-success {
        position: relative;
        overflow: hidden;
        pointer-events: none;

        &::before {
            content: "";
            position: absolute;
            inset: 0;
            background-color: var(--bs-secondary);
        }

        &::after {
            content: "";
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNNiAxMGMtMS4xIDAtMiAuOS0yIDJzLjkgMiAyIDIgMi0uOSAyLTItLjktMi0yLTJ6bTEyIDBjLTEuMSAwLTIgLjktMiAycy45IDIgMiAyIDItLjkgMi0yLS45LTItMi0yem0tNiAwYy0xLjEgMC0yIC45LTIgMnMuOSAyIDIgMiAyLS45IDItMi0uOS0yLTItMnoiLz48L3N2Zz4=");
            position: absolute;
            inset: 0;
            background-color: var(--bs-secondary);
            display: flex;
            align-items: center;
            justify-content: center;
            background-position: center;
            background-repeat: no-repeat;
            opacity: 0;
            animation: btn-loading-anim 0.5s ease-out alternate infinite;

            @keyframes btn-loading-anim {
                to {
                    opacity: 1;
                    transform: scale(1.1);
                }
            }
        }
    }

    .btn-success {
        &::before {
            background-color: var(--bs-primary);
        }

        &::after {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNOSAxNi4ybC0zLjUtMy41Yy0uMzktLjM5LTEuMDEtLjM5LTEuNCAwLS4zOS4zOS0uMzkgMS4wMSAwIDEuNGw0LjE5IDQuMTljLjM5LjM5IDEuMDIuMzkgMS40MSAwTDIwLjMgNy43Yy4zOS0uMzkuMzktMS4wMSAwLTEuNC0uMzktLjM5LTEuMDEtLjM5LTEuNCAwTDkgMTYuMnoiIGZpbGw9IiNmZmYiLz48L3N2Zz4=");
            background-color: var(--bs-primary);
            opacity: 0;
            transform: scale(0.5);
            animation: btn-success-anim 1s cubic-bezier(0, -0.01, 0, 1.31) forwards;

            @keyframes btn-success-anim {
                to {
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }
    }

    &.bookmarks-dialog {
        border: none;
        z-index: 12;
        box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
            inset 0 0 0 1px rgba(255, 255, 255, 0.5);
        position: fixed;
        top: 10vh;
        border-radius: 20px;
        flex-direction: column;
        padding: 20px;
        min-width: 400px;
        font-family: var(--inter-font-family);
        font-size: 14px;
        will-change: transform, opacity;
        opacity: 0;
        transform: scale(0.95);
        background-color: #fff;
        left: 0;
        right: 0;
        max-width: 400px;
        margin: 0 auto;

        &[open] {
            display: flex;
            animation: dialog-intro-anim 0.4s cubic-bezier(0, -0.01, 0, 1.31) forwards;
            
            @keyframes dialog-intro-anim {
                to {
                    transform: scale(1);
                    opacity: 1;
                }
            }
        }

        @media (max-width: 800px) {
            position: fixed;
            top: auto;
            bottom: env(safe-area-inset-bottom, 0);
            padding-bottom: calc(env(safe-area-inset-bottom, 0) + 40px);
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            margin: 0;
            width: 100%;
            transform: scale(1) translateY(5%);

            &[open] {
                @keyframes dialog-intro-anim {
                    to {
                        transform: scale(1) translateY(0%);
                        opacity: 1;
                    }
                }
            }
        }

        .dialog-title {
            font-size: 1rem;
            font-weight: 600;
            user-select: none;
            margin: 0;
            line-height: 1;
        }

        .dialog-close-btn {
            position: absolute;
            right: 10px;
            top: 10px;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNMTguMyA1LjcxYy0uMzktLjM5LTEuMDItLjM5LTEuNDEgMEwxMiAxMC41OSA3LjExIDUuN2MtLjM5LS4zOS0xLjAyLS4zOS0xLjQxIDAtLjM5LjM5LS4zOSAxLjAyIDAgMS40MUwxMC41OSAxMiA1LjcgMTYuODljLS4zOS4zOS0uMzkgMS4wMiAwIDEuNDEuMzkuMzkgMS4wMi4zOSAxLjQxIDBMMTIgMTMuNDFsNC44OSA0Ljg5Yy4zOS4zOSAxLjAyLjM5IDEuNDEgMCAuMzktLjM5LjM5LTEuMDIgMC0xLjQxTDEzLjQxIDEybDQuODktNC44OWMuMzgtLjM4LjM4LTEuMDIgMC0xLjR6Ii8+PC9zdmc+);
        }

        header {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: flex-start;
            padding-bottom: 10px;
            margin-bottom: 15px;
        }
    }

    &.add-bookmark-dialog,
    &.edit-bookmark-dialog {
        .add-bookmark-form,
        .edit-bookmark-form {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }

        .actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 20px;
            gap: 10px;
        }

        .add-bookmark-btn,
        .edit-bookmark-save-btn,
        .edit-bookmark-cancel-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--bs-primary);
            color: #fff;
            font-weight: 600;
            font-size: 14px;
            border: none;
            border-radius: 10px;
            cursor: pointer;
            padding: 10px 20px;
            will-change: transform;
            transition: transform 1s cubic-bezier(0, -0.01, 0, 1.31);

            &:focus-visible {
                outline-offset: 4px;
            }
            &:active {
                transform: scale(0.95);
            }

            @media (max-width: 800px) {
                width: 100%;

                &:active {
                    transform: scale(0.985);
                }
            }

            
        }

        .form-input-group {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .form-input-message {
                font-size: 12px;
                color: var(--bs-red);
                will-change: transform, opacity;
                animation: form-input-message-intro 0.3s cubic-bezier(0.35, 0.02, 0, 1.2);

                @keyframes form-input-message-intro {
                    from {
                        transform: translateY(-50%);
                        opacity: 0;
                    }
                }

                &:empty {
                    display: none;
                }
            }

            label {
                font-weight: 500;
            }

            input {
                background-color: #f5f5f5;
                border: none;
                outline: none;
                font-size: 14px;
                padding: 10px;
                border-radius: 5px;
                transition: all 0.2s ease-out;
                appearance: none;
                line-height: 1;

                &:hover {
                    box-shadow: 0 0 0 1px var(--bs-gray-600);
                }

                &:focus-visible {
                    box-shadow: 0 0 0 2px var(--bs-primary);
                    background-color: #fff;
                }

                &.error {
                    box-shadow: 0 0 0 1px var(--bs-red);

                    &:focus-visible {
                        box-shadow: 0 0 0 2px var(--bs-red);
                    }
                }
            }
        }

        .form-checkbox-group {
            display: flex;
            align-items: center;
            cursor: pointer;
            border-radius: 5px;
            padding-left: 4px;

            &:hover {
                background-color: #f5f5f5;
            }

            input[type="checkbox"] {
                --size: 16px;
                height: var(--size);
                width: var(--size);
                border-radius: 500px;
            }

            input[type="checkbox"],
            label {
                cursor: pointer;
                user-select: none;
            }

            label {
                padding: 10px;
                padding-left: 5px;
                display: flex;
                width: 100%;
                border-radius: 10px;
                font-weight: 500;
                line-height: 1;
            }
        }
    }

    &.edit-bookmark-dialog {
        .actions {
            justify-content: space-between;

            .edit-bookmark-cancel-btn {
                order: -1;
                background-color: var(--bs-gray-100);
                color: #000;
            }
        }
    }

    &.manage-bookmarks-dialog {
        min-width: 500px;
        max-height: 65vh;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        padding: 0;

        &::-webkit-scrollbar {
            display: none;
        }

        @media (max-width: 800px) {
            min-width: auto;
            max-height: 70vh;
        }

        header {
            margin-bottom: 0;
            padding: 20px;
            padding-bottom: 0;
            position: sticky;
            top: 0;
            background-color: #fff;
            z-index: 1;
        }

        .manage-bookmarks-controls-group {
            width: 100%;
            user-select: none;
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;
            font-weight: 400;
            padding: 8px 0;
            margin-top: 10px;
            border-bottom: 1px solid #ddd;

            .actions {
                gap: 4px;
                display: flex;
                align-items: center;
            }

            button {
                padding: 6px 12px;
                border-radius: 8px;
                background-color: #fff;
                border: none;
                margin: 0;
                font-weight: 500;
                outline: none;
                font-size: 0.813rem;
                transition: transform 1s cubic-bezier(0, -0.01, 0, 1.31);

                &:focus-visible {
                    box-shadow: 0 0 0 2px #fff, 0 0 0 4px var(--bs-gray-400);
                }

                &:active {
                    transform: scale(0.9);
                }
            }

            .delete-all-bookmarks-btn {
                background-color: #fff;
                color: #888;

                &:hover,
                &:focus-visible {
                    color: var(--bs-red);
                    background-color: var(--bs-secondary);
                }
            }

            .add-bookmark-btn {
                color: #fff;
                background-color: var(--bs-primary);
            }
        }

        .manage-bookmarks-list {
            display: flex;
            flex-direction: column;
            list-style-type: none;
            margin: 0;
            padding: 0;
            max-width: 100%;
            padding: 10px;

            @media (max-width: 800px) {
                padding-top: 0;
                padding-bottom: 25vh;
            }

            .manage-bookmarks-list-item {
                display: flex;
                width: 100%;
                border-radius: 10px;
                align-items: center;
                color: inherit;
                text-decoration: none;
                will-change: transform;
                transition: transform 1s cubic-bezier(0, -0.01, 0, 1);
                position: relative;

                @media (max-width: 800px) {
                    border-bottom: 1px solid var(--bs-gray-100);
                }

                &:hover,
                &:focus-visible,
                &:focus-within {
                    background-color: #f5f5f5;

                    .link {
                        .details {
                            max-width: calc(100% - 120px);

                            @media (max-width: 800px) {
                                max-width: calc(100% - 150px);
                            }
                        }
                    }

                    .actions {
                        opacity: 1;
                    }
                }

                &:active {
                    transform: scale(0.975);
                }

                &:focus-visible {
                    outline: none;
                    box-shadow: 0 0 0 2px #fff, 0 0 0 4px var(--bs-gray-400);
                }

                .link {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    width: 100%;
                    padding: 10px 12px;
                    padding-left: 10px;

                    @media (max-width: 800px) {
                        padding: 14px;
                        padding-left: 10px;
                    }

                    .details {
                        display: flex;
                        flex-direction: column;
                        gap: 6px;
                        overflow: hidden;
                        max-width: 100%;

                        @media (max-width: 800px) {
                            max-width: calc(100% - 150px);
                        }

                        .title {
                            font-size: 0.813rem;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            line-height: 1.1;
                            color: #000;
                            margin: 0;
                        }

                        .url {
                            font-size: 10px;
                            color: #555;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            line-height: 1.1;
                        }
                    }
                }

                .icon {
                    --size: 24px;
                    min-height: var(--size);
                    min-width: var(--size);
                    max-height: var(--size);
                    max-width: var(--size);

                    img {
                        max-width: 100%;
                        width: 100%;
                    }
                }

                .actions {
                    display: flex;
                    opacity: 0;
                    position: absolute;
                    right: 10px;

                    button {
                        cursor: pointer;
                        --size: 36px;
                        opacity: 0.35;
                        background-size: 60%;

                        &:hover,
                        &:focus-visible {
                            background-color: #ddd;
                            opacity: 1;
                        }

                        @media (max-width: 800px) {
                            --size: 40px;
                        }
                    }

                    .edit-btn {
                        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNMyAxNy40NnYzLjA0YzAgLjI4LjIyLjUuNS41aDMuMDRjLjEzIDAgLjI2LS4wNS4zNS0uMTVMMTcuODEgOS45NGwtMy43NS0zLjc1TDMuMTUgMTcuMWMtLjEuMS0uMTUuMjItLjE1LjM2ek0yMC43MSA3LjA0Yy4zOS0uMzkuMzktMS4wMiAwLTEuNDFsLTIuMzQtMi4zNGMtLjM5LS4zOS0xLjAyLS4zOS0xLjQxIDBsLTEuODMgMS44MyAzLjc1IDMuNzUgMS44My0xLjgzeiIvPjwvc3ZnPg==");
                    }

                    .delete-btn {
                        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNNiAxOWMwIDEuMS45IDIgMiAyaDhjMS4xIDAgMi0uOSAyLTJWOWMwLTEuMS0uOS0yLTItMkg4Yy0xLjEgMC0yIC45LTIgMnYxMHpNMTggNGgtMi41bC0uNzEtLjcxYy0uMTgtLjE4LS40NC0uMjktLjctLjI5SDkuOTFjLS4yNiAwLS41Mi4xMS0uNy4yOUw4LjUgNEg2Yy0uNTUgMC0xIC40NS0xIDFzLjQ1IDEgMSAxaDEyYy41NSAwIDEtLjQ1IDEtMXMtLjQ1LTEtMS0xeiIvPjwvc3ZnPg==");
                    }

                    @media (max-width: 800px) {
                        opacity: 1;
                        gap: 10px;
                    }
                }
            }
        }
    }
}
.dialog-skrim {
    background-color: rgba(245, 245, 245, 0.95);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 11;
    display: none;

    &.visible {
        display: flex;
        opacity: 0;
        animation: dialog-skrim-intro-anim 0.4s cubic-bezier(0, -0.01, 0, 1.31) forwards;

        @keyframes dialog-skrim-intro-anim {
            to {
                opacity: 1;
            }
        }
    }
}
