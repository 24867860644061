.user-list {
    --avatar-size: 3.125rem;
    display: flex;
    flex-direction: column;
    margin-top: 0.625rem;

    .header {
        display: grid;
        grid-template-columns: var(--avatar-size) 4fr repeat(4, 1fr);
        gap: 0.938rem;
        position: sticky;
        top: 0;
        background-color: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(1.25rem) saturate(1.5);
        padding: 0.625rem 1.25rem;
        z-index: 1;
        margin-bottom: 0.25rem;

        @media (max-width: 75rem) {
            grid-template-columns: var(--avatar-size) 3fr repeat(4, 1fr);
        }

        @media (max-width: 62.5rem) {
            grid-template-columns: var(--avatar-size) 2fr repeat(4, 1fr);
        }

        @media (max-width: 50rem) {
            display: none;
        }

        .header-label {
            display: flex;
            font-family: "Inter", sans-serif;
            font-size: 0.625rem;
            font-weight: 400;
            color: var(--bs-gray-700);
            text-transform: uppercase;
            letter-spacing: 0.031rem;
            padding: 0.5rem 0;
            border-radius: 0.313rem;
        }
    }
}