$green-color: #36c759;
$red-color: #fc6100;
$gray-color: #f9f9f9;

.cart-page-product-units {
  display: flex;
  align-items: left;
  justify-content: flex-start;
  background-color: $gray-color;
  width: 100%;
  padding: 5px 5px 5px 0 !important;
}

.stock-accordion-summary {
  margin-top: 30px;
}

.available-units {
  flex: 1;
  display: flex;
  align-items: left;
  flex-direction: column;

  .dot {
    margin-left: 0.313rem;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
  }

  .dot-green {
    background-color: $green-color;
  }

  .dot-red {
    background-color: $red-color;
  }
}

.available-stock-info {
  font-size: 1.25rem;
  font-weight: 600;
}

.discontinue-product {
  font-size: 0.75rem;
  background-color: #FFCF6E;
  color: #000;
  width: fit-content;
  padding: 0.25rem 0.8rem;
  line-height: 1.15;
  border-radius: 1.25rem;
  margin-left: 0.625rem;
  font-weight: 600;
  text-transform: uppercase;
}

.inactive-product {
  font-size: 0.75rem;
  background-color: #FC6100;
  color: #fff;
  width: fit-content;
  padding: 0.25rem 0.8rem;
  line-height: 1.15;
  border-radius: 1.25rem;
  margin-left: 0.625rem;
  font-weight: 600;
  text-transform: uppercase;
}

.available-stock-city-info {
  font-size: 1rem;
  font-weight: 500;
}

.total-locations {
  flex: 1;
  display: flex;
  align-items: left;
  flex-direction: column;
  border-left: 1px solid #ddd;
  padding-left: 10px;
}

.total-text {
  font-size: 1.25rem;
  font-family: "Inter", Regular;
  font-weight: 600;
  color: #000000;
}

.location-wise-stock {
  width: 100%;
  display: flex;
  align-items: left;
  justify-content: space-between;
  flex-direction: row;
  border-bottom: 1px solid #dddddd;
  padding: 5px 0;

  &:last-child {
    border-bottom: none;
  }
}

.location-name {
  flex: 1;
}

.location-stock {
  flex: 1;
}

.stock-accordion {
  .units-available-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .units-available-section {
      display: flex;
      flex-direction: column;
    }

    .units-available-title {
      font: 600 20px bold;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;

      .units-available-badge-dot {
        margin-left: 15px;
        height: 18px;
        min-width: 18px;
        border-radius: 9px;
        background-color: var(--bs-green);
      }

      .units-available-badge-dot-orange {
        margin-left: 15px;
        height: 18px;
        min-width: 18px;
        border-radius: 9px;
        background-color: #fc6100;
      }
    }

    .units-available-in-warehouse {
      font-size: 14px;
      color: #999999;
    }

    .adjust-button {
      background: #f1f1f1;
      display: flex;
      align-items: center;
      font-size: 1rem;
      font-family: "inter";
      color: #000000;
      border: none;
      justify-content: flex-start;
      border-radius: 10px;
      opacity: 1;
      padding: 10px 13px;
      text-transform: capitalize;
      font-weight: 600;

      &:hover {
        filter: unset !important;
      }
    }
  }

  .units-available-location {
    font: 600 16px;
    margin-top: 10px;
    display: flex;
    gap: 14px;
    color: #000000;
    align-items: center;
    flex-wrap: wrap;

    .location-dot {
      .MuiBadge-badge {
        min-width: 4px;
        height: 4px;
        background-color: #aaaaaa;
      }
    }

    .units-available-see-all {
      font-weight: bold;
      text-decoration: underline;

      &:hover {
        color: unset !important;
      }

      &:focus-visible {
        outline: 0.13rem solid var(--bs-gray-600) !important;
        outline-offset: 0.125rem;
        transition: all 0.05s ease;
      }
    }

    .units-available-see-all-grey {
      font-weight: bold;
      text-decoration: underline;
      opacity: 0.3;
      cursor: not-allowed;

      &:hover {
        color: unset !important;
      }
    }
  }
}

.adjust-stock-dialog-box {
  .MuiDialog-paper {
    max-width: 54.438rem;
    width: 90%;
    border-radius: 20px;
    position: absolute;
    padding: 30px;

    .MuiDialogTitle-root {
      position: absolute;
      right: 25px;
      top: 25px;
      padding: 0;

      p {
        display: none;
      }

      .adjust-stock-close {
        height: 18px;
        width: 18px;
        min-width: unset;
        opacity: 0.5;

        &:focus-visible {
          .MuiTouchRipple-root {
            display: none !important;
          }
        }

        span {
          width: 100%;
          height: 100%;

          svg {
            margin: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .MuiDialogContent-root {
      padding: 0;
    }
  }
}

.available-stock-dialog-box {
  .MuiDialog-paper {
    border-radius: 20px;
    width: 730px;
    height: 70vh;
    max-height: 800px;
    position: relative;
    padding: 30px;

    .MuiDialogTitle-root {
      position: absolute;
      right: 25px;
      top: 25px;
      padding: 0;

      p {
        display: none;
      }

      button {
        height: 18px;
        width: 18px;
        min-width: unset;
        opacity: 0.5;

        span {
          width: 100%;
          height: 100%;

          svg {
            margin: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .MuiDialogContent-root {
    padding: 0;
  }
}

.stock-accordion {
  .MuiAccordionDetails-root {
    padding: 0;
    flex-direction: column;
  }
}

.clearance-product {
  font-size: 0.75rem;
  background-color: #5856D6;
  width: fit-content;
  padding: 0.25rem 0.5rem;
  line-height: 1;
  border-radius: 1.25rem;
  margin-left: 0.625rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
}

.unitQtyAvailable-list {
  flex-direction: column;
  padding: 0.625rem;
  font-family: "Inter", sans-serif;
  border-radius: 0.625rem;
  position: relative;
}

.skeleton-item {
  user-select: none;
  cursor: wait;
  width: 15.625rem;
  margin-bottom: 0.5em;
  display: flex;
  background-color: var(--bs-gray-200);
  animation: skeleton-loop-anim 0.6s ease alternate infinite;
  animation-delay: var(--initial-delay);

  &::after {
    display: none;
  }

  @keyframes skeleton-loop-anim {
    to {
      opacity: 0.25;
    }
  }
}

.stock-close-btn {
  &:focus-visible {
    .MuiTouchRipple-root {
      display: none !important;
    }
  }
}