@import "../pos/assets/css/variable.scss";

.cart-product-responsive-list {
  border-bottom: 0.063rem solid var(--bs-gray-200);

  &:focus-visible {
    .cart-data-header-sec {
      outline: 0.13rem solid var(--bs-gray-600) !important;
      background-color: var(--bs-gray-200);
      border-radius: 0.625rem;
    }
  }

  .cart-product-grid {
    margin: 0.938rem 0;
  }

  .cart-product-all-data {
    display: flex;

    .title {
      font-size: 0.625rem;
      padding-right: 0.625rem;

      @media only screen and (min-width: 960px) {
        display: none;
      }
    }

    span {
      font-size: 0.9rem;
      font-weight: 500;
    }

    .cart-product-info {
      display: flex;

      .product-data-text {
        padding-left: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media only screen and (min-width: 600px) {
          padding-left: 1.875rem;
        }
      }

      .show-display-content {
        display: contents;
      }

      .product-name-cart {
        font-weight: bold;
        font-size: 0.938rem;
      }
    }

    .indicator {
      margin-right: 0.625rem;

      .indicator-style {
        width: 0.625rem;
        height: 0.625rem;
        border-radius: 0.75rem;
        display: block;
      }
    }
  }

  .direction-col {
    flex-direction: column;
    text-align: left !important;
  }

  .cart-product-item-info,
  .cart-product-line-id {
    span {
      font-weight: 500 !important;
      font-size: 0.9rem !important;
    }
  }
}

.cart-product-line-id.MuiGrid-grid-lg-1 {
  @media only screen and (min-width: 1280px) {
    max-width: 7.333333%;
    flex-basis: 7.333333%;
  }
}

@media only screen and (min-width: 600px) {

  .product-ordered-qty,
  .product-ext-price {
    justify-content: flex-end;
  }

  .product-net-price {
    justify-content: center;

    span {
      overflow-wrap: anywhere;
      word-break: break-all;
      padding: 0 0.313rem;
    }
  }
}

@media only screen and (min-width: 960px) {

  .product-picked-qty,
  .product-ordered-qty {
    justify-content: center;
  }

  .product-net-price,
  .product-ext-price {
    justify-content: flex-end;
  }
}

.cart-product-info {
  .indicator {
    display: flex;
    margin-right: 0.625rem;

    .indicator-style {
      width: 0.625rem;
      height: 0.625rem;
      border-radius: 0.75rem;
    }
  }

  .cart-indicator {
    margin-right: 5px;
  }
}

.cart-items-responsive-header.cart-product-responsive-list.cart-product-responsive-header-list {
  z-index: 1;
  background: $white-color;
  padding-top: 1.25rem;
  z-index: 11;
  max-width: 95rem;
  width: 100%;

  @media only screen and (min-width: 1200px) {
    width: calc(80% - 58px);
  }

  justify-self: center;
  display: none;

  @media only screen and (min-width: 960px) {
    display: block;
  }
}

.cart-product-responsive-list.cart-product-responsive-header-list {
  border-bottom: 0;

  .main-sec-titles {
    font-size: 0.875rem;
    color: #000000;

    @media only screen and (min-width: 960px) {
      display: block;
    }
  }

  .cart-product-item-info {
    @media only screen and (min-width: 960px) {
      padding-left: 6.25rem;
    }
  }
}

.cart-data-header-sec {
  cursor: pointer;

  &:hover {
    background-color: var(--bs-gray-200);
    border-radius: 0.625rem;
  }
}

.cart-only-title-header:hover {
  background-color: transparent;
  cursor: default;
}

.gpm-details-modal-container {
  .MuiDialog-container {
    .MuiDialog-paper {
      width: 100%;
      min-width: 34.375rem;
      border-radius: 1.25rem;

      .MuiDialogTitle-root {
        padding: 0.9375rem 0.375rem 0.3125rem 1.5rem;

        .MuiTypography-root {
          .MuiBox-root {
            .MuiTypography-root {
              justify-content: flex-start;
              font-size: 1.25rem;
              font-weight: 600;
              letter-spacing: 0rem;
              color: $black-color;
              opacity: 1;
            }

            .MuiButtonBase-root {
              padding: 0.25rem 0.3125rem;

              &:hover {
                background-color: $bgColor !important;

                .MuiSvgIcon-root {
                  fill: $black-color;
                  opacity: 1;
                }
              }

              &:focus-visible {
                background-color: $bgColor !important;

                .MuiSvgIcon-root {
                  fill: $black-color;
                  opacity: 1;
                }

                outline: 0.13rem solid var(--bs-gray-600) !important;
              }

              .MuiSvgIcon-root {
                fill: $gray;
                opacity: 1;
                width: 1.25rem;
                height: 1.25rem;
              }
            }
          }
        }
      }
    }
  }
}

.pick-info-btn {
  width: 13.125rem;
  height: 3.875rem;
}

.continue-payment-btn {
  width: 15rem;
  height: 3.875rem;
}