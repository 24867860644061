@import "../../../pos/assets/css/variable.scss";

.item-ledger-main-section {
  padding: 1.875rem 0;
  .item-ledger-section {
    padding: 2rem;
    .item-ledger-container {
      display: flex;
      justify-items: flex-start;
      width: 100%;
      align-items: center;
      height: 100%;
      gap: 1.875rem;
      .back-button {
        border-radius: 1.25rem;
        background: $bgColor 0% 0% no-repeat padding-box;
        padding: 1.125rem;
        .back-icon {
          fill: $black-color;
        }
      }
      .item-ledger-title-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        .item-ledger-products-container {
          width: 100%;
          border: 0.065rem solid $border-color;
          max-width: 40rem;
          border-radius: 0.9375rem;
          padding: 0.625rem;
          .product-ledger-image {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 0.8rem;
            .product-ledger-name{
              .name{
                font-weight: 500;
              }
              .key{
                font-size: 12px;
              }
            }
          }
        }
        .download-csv-btn {
          padding: 0.75rem 10.9375rem;
        }
        .title{
          letter-spacing: -0.035rem;
        }
      }
    }
  }
  .table-main-container {
    padding-left: 7.625rem;
    padding-right: 7.625rem;
    margin-top:1rem;
    .divider-div {
      background: $black-color 0% 0% no-repeat padding-box;
      opacity: 1;
    }
    .second-divider-div {
      background: #EEEEEE 0% 0% no-repeat padding-box;
      opacity: 1;
      margin: 0.9375rem 0;
    }
    .show-price-quantity-container {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: repeat(8,1fr);
      margin-top: 0.5rem;
      .price-key {
        color: $black-color;
        opacity: 1;
        text-align: left;
        font-size: 1.125rem;
      }
      .price-title {
        color: $black-color;
        opacity: 0.8;
        text-align: left;
        font-size: 0.625rem;
        letter-spacing: 0.0625rem;
      }
    }
    .item-ledger-data-grid-container {
      border: 1px solid $border-color;
      border-radius: 0.9375rem;
      opacity: 1;
      margin-top: 2rem;
      .MuiDataGrid-main {
        .MuiDataGrid-row {  
          color: #000000;
        }
        .MuiDataGrid-columnHeaders {
          .MuiDataGrid-columnHeadersInner {
          border-bottom: 1px solid $border-color;
          border-top-right-radius: 0.9375rem;
          border-top-left-radius: 0.9375rem;
            .MuiDataGrid-columnHeader {
              .MuiDataGrid-columnSeparator {
                visibility: hidden;
                display: none;
              }
              .MuiDataGrid-columnHeaderTitleContainer {
                .MuiDataGrid-columnHeaderTitle {
                  font-size: 0.625rem;
                  font-weight: 500;
                  letter-spacing: 0.0313rem;
                  opacity: 1;
                }
              }
            }
            .MuiDataGrid-columnHeader:last-child {
              .MuiDataGrid-columnHeaderTitleContainer {
                justify-content: center;
              }
            }
        }
        }
        .MuiDataGrid-virtualScroller {
          .MuiDataGrid-virtualScrollerContent {
            .MuiDataGrid-virtualScrollerRenderZone {
              .MuiDataGrid-row:nth-child(odd) {
                background-color: $gray-50;
              }
            }
          }
        }
      }
      .pagination-next-container {
        flex-shrink: 0;
        margin-left: 1.25rem;
        .next-button-icon {
          color: $black-color;
        }
        .Mui-disabled {
          color: $black-color;
          opacity: 0.3;
        }
      }
    }
  }
  
  .outgoing-call-made{
   color: $darkGray ; 
  }
}

