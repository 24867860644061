@import "apps/pos/assets/css/variable.scss";

.add-document-modal {

  .MuiDialogContent-root {
    width: 37.5rem;
    height: 45.875rem;
    border-radius: 1.25rem !important;
    padding: 1.875rem !important;
  }

  .MuiDialog-paper {
    border-radius: 1.25rem;
  }

  .MuiSelect-select {
    padding: 0.9375rem !important;
  }

  .form-scrollbar {
    max-height: calc(50rem - 14rem);
    overflow-y: auto;
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
  }
}

.add-document-container {
  .clear-icon {
    width: auto;
    display: flex;
    justify-content: flex-end;
    padding: 0;

    svg {
      fill: $gray;
    }

    margin-left: auto;
  }

  .title-modal {
    font-size: 1.25rem;
    font-weight: 600;
  }

  .description-data {
    .MuiTextField-root {
      .MuiInputBase-input {
        height: 6.875rem !important;
      }
    }
  }

  .title-text {
    margin: 1.25rem 0 0.5rem 0;

    .title-message {
      margin: 0 0 0 0.625rem;
    }
  }

  .location-details {
    display: flex;
    background-color: $bgColor;
    border-radius: 1rem;
    height: 100%;
    min-height: 2.75rem;
    align-items: center;
    text-align: center;
    cursor: pointer;

    .location-div {
      width: 50%;
    }

    .current-location {
      width: 50%;
      height: 100%;
      min-height: 2.75rem;
      border-radius: 1rem;
      background-color: $white-color;
      border: solid 0.0625rem black;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
    }
  }

  .add-button {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 1.25rem;
  }

  .choose-file-btn {
    width: 100% !important;
    height: 2.75rem;
    justify-content: flex-start !important;
  }
}

.file-upload-main-input {
  padding: 0.75rem;
  border-radius: 0.875rem;
  background-color: $bgColor;

  span {
    font-weight: 500;
  }
}

.delivery-proof-upload-btn {
  width: 7.25rem;
  min-width: 7.25rem;
  max-width: 7.25rem;
  height: 2.75rem;
}

.delivery-proof-add-doc-btn {
  width: 10.1rem;
  min-width: 10.1rem;
  max-width: 10.1rem;
  height: 2.75rem;
}

.button-focus {
  &:focus-visible {
    border-radius: 1.563rem !important;
    outline: 0.13rem solid var(--bs-gray-600) !important;
  }
}

.add-document-list-select-dropdown {
  margin-right: 0.625rem;

  .MuiFormControl-root {
    border-radius: 0.625rem;
    background-color: #EEEEEE;
    min-width: 100%;

    .MuiFormLabel-root {
      font-weight: 500;
      font-family: "Inter", sans-serif;
      color: #000;
      left: 0.6875rem;
      transform: translate(0, 1rem) scale(1);
    }

    .MuiSelect-multiple {
      padding-top: 0.6875rem;
      padding-bottom: 0.6875rem;

      .MuiCheckbox-root,
      .MuiListItemText-root,
      .MuiChip-root {
        display: none;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    .MuiSelect-icon {
      display: none;
    }

    .MuiSelect-select {
      &:before {
        content: "";
        position: absolute;
        width: 1.875rem;
        height: 1.875rem;
        right: 0.4375rem;
        border: 0;
        background-image: url("../../../../../../apps/springboard/images/icons/arrow-up.svg");
        background-repeat: no-repeat;
      }

      &.Mui-disabled {
        &:before {
          opacity: 0.3;
        }
      }

    }
  }

}

.main-label-select-location {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-top: 0.3125rem;
  margin-left: 0.9375rem;
  margin-right: 1.25rem;

  p {
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: 0rem;
  }
}

.selection {
  cursor: pointer;
  text-decoration: underline;
}

.add-document-list-filter-options.MuiButtonBase-root {
  padding: 0.3125rem !important;

  .PrivateSwitchBase-root-26 {
    padding: 0rem 0.5625rem 0.5625rem 0.5625rem !important;
  }

  .MuiIconButton-colorSecondary {
    &:hover {
      background-color: transparent;
    }
  }

  &.Mui-selected {
    .MuiIconButton-label {
      background-size: 0.9375rem;
      background-position: center;
    }

  }

  .MuiIconButton-label {
    width: 1em;
    height: 1em;
  }

  .MuiListItemText-root {

    .MuiTypography-body1 {
      font-size: 0.875rem;
      font-weight: 500;
      color: black;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .MuiCircularProgress-root {
    margin: 0 auto !important;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: black;
  }
}

.upload-document-progress-container {
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0rem;
  justify-content: center;
  margin-top: 1.5625rem;
}

.show-remaining-time {
  color: #AAAAAA;
}

.title-upload-progress {
  color: #000000;
  overflow: 'hidden';
  text-overflow: 'ellipsis';
  white-space: 'nowrap';
  max-width: 37.5rem;
}

.uploading-gif {
  width: 18.75rem;
}

.show-exempt-label {
  background: #F8A903;
  border-radius: 0.75rem;
  width: 4.375rem;
  height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    text-align: left;
    letter-spacing: 0.0375rem;
    color: #000000;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 500;
  }
}

.file-upload-progress {
  justify-content: center;
  display: flex;
  margin-bottom: 1.25rem;
  margin-top: 1.25rem !important;

  .MuiLinearProgress-root {
    height: 0.375rem;
    border-radius: 31.25rem;
    width: 16.25rem;
    background-color: #EEEEEE;
  }

  .MuiLinearProgress-bar {
    background-color: #000;
  }
}

.document-selection {
  background-color: #F1F1F1;
  border-radius: 0.625rem;
  padding: 0.1875rem;

  .option {
    display: flex;
    align-items: center;
    padding: 0.625rem;
    background-color: #F1F1F1;
    border-radius: 0.5rem;
    margin-bottom: 0.1875rem;
    transition: background-color 0.3s ease;

    &.selected {
      background-color: #FFFFFF;
    }


    .MuiFormControlLabel-root {
      flex-grow: 1;
      margin: 0;
      justify-content: space-between;

      .MuiTypography-body1 {
        font-weight: 500;
        font-size: 1rem;
        letter-spacing: 0rem;
      }

      .MuiTypography-caption {
        color: #555555;
        font-size: 0.875rem;
        letter-spacing: 0rem;
      }
    }

    .MuiRadio-root {
      color: transparent;

      &.Mui-checked {
        color: black;
      }
    }
  }
}