.text-input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .text-input-label {
        font-size: 14px;

        .text-input-label-optional {
            color: var(--bs-gray-500);
            padding-left: 0.35em;
        }
    }

    .text-input-group {
        position: relative;
        display: flex;
        align-items: center;

        .icon-button-primary {
            position: absolute;
            right: 5px;
        }

        .show-password-button-inactive {
            opacity: 0.25;
        }

        .show-password-button-active {
            opacity: 1;
        }

        .text-input {
            --outline-color: var(--bs-gray-300);
            --outline-width: 1px;
            padding: 0.938rem !important;
            padding-right: 60px;
            font-size: 1rem;
            border-radius: 10px;
            border: none;
            outline: none;
            box-shadow: 0 0 0 var(--outline-width) var(--outline-color);
            transition: all 0.2s ease-out;

            &.text-input-has-error {
                --outline-color: var(--bs-danger);
            }

            &:not(:disabled):hover {
                --outline-color: var(--bs-gray-400);

                &.text-input-has-error {
                    --outline-color: var(--bs-danger);
                }
            }

            &:not(:disabled):focus-visible {
                --outline-color: var(--bs-primary);
                --outline-width: 2px;

                &.text-input-has-error {
                    --outline-color: var(--bs-danger);
                }
            }

            &.text-input-wide {
                width: 100%;
            }
        }
    }

    .text-input-description {
        font-size: 12px;
        line-height: 1.35;

        &.text-input-description-error {
            color: var(--bs-danger);
        }
    }
}
