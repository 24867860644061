.user-list-header {
  text-align: left;
  font-size: 1rem;
  color: #212832;
  letter-spacing: 0px;
  color: #000000;
}

#avatar1 {
  background-color: #eeeeee !important;
}

.user-list-header-container {
  height: 50px;
  position: fixed;
  background-color: #ffffff;
  top: 60px;
  align-items: center;
  display: flex;
  z-index: 50;
  width: 100%;
  border-bottom: 1px solid #e0e5ec;
  left: 0px;
  padding-left: 19rem;
}

.add-user-btn {
  height: 50px;
  border-radius: 10px !important;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 15px;
  padding-left: 20px;
  padding-right: 20px;
  border: 0px !important;
}

.add-user-txt {
  margin-left: 15px;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  opacity: 1;
  color: #fff;
  letter-spacing: 0.21px;
}

.tab-style {
  justify-content: center !important;
}

.user-list-container {
  height: 93%;
  width: 99%;
  flex: 1;
}

.active-tab-txt {
  font-weight: 400;
  font-size: 14px;
}

.inactive-tab-txt {
  font-size: 14px;
  color: #999999;
}

.search-text-input {
  height: 50px;
  border-radius: 10px;
  border: 1px solid #dddddd;
  justify-content: center;
  align-items: center;
}

.text-input-style {
  font-weight: 300;
  font-size: 14px;
  color: #aaaaaa;
  padding-left: 0px !important;
}

.justify-content-space-b {
  justify-content: space-between;
}

.username-text-style {
  font-size: 1rem;
  color: #000000;
}

.username-sub-text-style {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
}

.username-created-on-style {
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.optionText {
  font-size: 14px;
  font-family: "Inter", Regular;
  color: black;
  line-height: 17px;
  margin-left: 0.313rem;
  text-align: justify;
  color: #000000;
}
.user-list-main {
  display: flex;
}

.list-box-2 {
  flex: 0.4;
}

.list-box-1 {
  flex: 0.1;
}

.icon-color {
  color: white;
}

.users-tab-div .nav .nav-item .nav-link.active {
  border-bottom: 3px solid transparent;
}

/* .btn-primary{
  background-color: #000000 !important;
  border-color: #000000 !important;
} */

.saveButton{
  background-color: var(--bs-primary);
  color: white !important;
  border: none;
  font-size: 14px;
  font-weight: 500;
}
