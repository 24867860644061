@import "../../../../constants/colors.scss";

.item {
  border: 0.063rem solid $border-color;
  border-radius: 0.313rem;
  padding: 0.625rem;
  margin-bottom: 0.625rem;
}

.product-detail-section-bottom-border {
  border-bottom: 0.063rem solid $bright-Gray;
}

.product-detail-sidebar-menu {
  font-size: 1rem;
  margin-bottom: 0.125rem;
  color: $description-color;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  gap: 0.625rem;
  align-items: center;
  padding: 0.375rem 0.5rem;
  border-radius: 0.625rem;

  &:hover {
    background-color: #f5f5f5;
  }


  &.first-title {
    color: $black-color;
    background-color: #eee;
  }
}

.main-detail-product-title {
  color: $darkGray;
  font-size: 1.5rem;
  font-weight: 600;
}

.main-detail-product-desc {
  font-size: 1.5rem;
  font-weight: 600;
  color: $black-color;
  margin-bottom: 1.25rem;
}

.main-detail-product-code {
  color: $dusty-gray-color;
  font-size: 0.875rem;
  font-weight: 500;
  // margin-bottom: 1.25rem;
}

.main-detail-product-price {
  margin: 1.25rem 0 0;

  @media (min-width: 600px) {
    margin-bottom: 1.25rem;
  }

  @media (min-width: 960px) {
    margin-bottom: 2.5rem;
  }

  .price {
    font-size: 1.375rem;
    font-weight: 500;
  }

  .per-info {
    font-size: 0.875rem;
    font-weight: 500;
  }
}

.product-detail-medium-btn.MuiButtonBase-root {
  height: fit-content;
  width: fit-content;
  margin-right: 0.625rem;

  span {
    font-size: 0.875rem;
    font-weight: 500;
  }

  svg {
    height: 0.938rem;
    width: 0.938rem;
  }

  .product-top-btns {
    width: fit-content;
  }

  &.button-is-loading-black {
    position: relative;
    overflow: hidden;
    pointer-events: none;

    .button-label,
    .button-icon {
      opacity: 0;
    }

    &::before {
      content: "";
      position: absolute;
      background-color: var(--bs-black);
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      border-radius: 0.625rem;
    }

    &::after {
      content: "";
      position: absolute;
      height: 1.5rem;
      width: 1.5rem;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNNiAxMGMtMS4xIDAtMiAuOS0yIDJzLjkgMiAyIDIgMi0uOSAyLTItLjktMi0yLTJ6bTEyIDBjLTEuMSAwLTIgLjktMiAycy45IDIgMiAyIDItLjkgMi0yLS45LTItMi0yem0tNiAwYy0xLjEgMC0yIC45LTIgMnMuOSAyIDIgMiAyLS45IDItMi0uOS0yLTItMnoiLz48L3N2Zz4=");
      background-position: center;
      background-repeat: no-repeat;
      opacity: 0;
      animation: button-loading-anim 0.3s ease-out alternate infinite;

      @keyframes button-loading-anim {
        to {
          opacity: 1;
          transform: scale(1.1);
        }
      }
    }
  }

  &.button-accent {
    &.button-is-loading-black {
      &::after {
        filter: invert(1);
      }
    }
  }
}

.history.makeStyles-root-2.MuiButton-root {
  padding: 0.40rem 0.40rem 0.40rem 0.40rem;
  font-size: 0.25rem !important;
}

.product-detail-search-input {
  .MuiFilledInput-root.MuiInputBase-root {
    padding: 0.813rem;
    border-radius: 0.625rem;

    input.MuiFilledInput-input {
      font-size: 1rem !important;

      &::placeholder {
        color: $black-color;
        opacity: 0.9;
      }
    }

    svg {
      color: $black-color;
    }
  }
}

.product-detail-top-info-sec {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between !important;
  flex-direction: column !important;

  @media (min-width: 600px) {
    flex-direction: row !important;
  }

  .product-detail-top-left-info-sec {
    width: 100%;
    margin-bottom: 2.5rem;

    @media (min-width: 600px) {
      width: calc(100% - 10.625rem);
      margin-bottom: 0;
    }
  }

  .product-detail-top-right-img {
    img {
      height: 8.75rem;
      width: 8.75rem;
    }
  }
}

.display-row {
  display: flex;
  flex-direction: row;
}

.product-detail-container.new-container.MuiContainer-root {
  padding-top: 6.25rem !important;
  padding-bottom: 50vh !important;
  //back button removal : added margin-left after removing back button 
  margin-left: inherit !important;
  max-width: 100%;
  width: 100%;
  padding-right: 3.75rem !important;
  padding-left: 3.75rem !important;

  @media (min-width: 960px) {
    padding-top: 3.75rem !important;
  }

  position: relative;

  .main-title-section {
    margin-top: 2.5rem;
    margin-bottom: 0.625rem;
    padding-right: 0.625rem;

    .sec-main-title {
      font-size: 1.375rem;
      font-weight: 600;
    }

    .up-downb-arrow img {
      opacity: 0.8;
      width: 0.75rem;
    }
  }

  .overview-tab {
    margin-top: 0.5rem;
  }

  .product-detail-first-border {
    margin: 2.5rem 0 1.875rem;
  }

  .product-detail-second-border {
    margin: 2.5rem 0 1.875rem;
  }

  .product-detail-overview-border {
    margin: 0 0 2.75rem;
  }

  .title-edit-section {
    height: fit-content;
    width: fit-content;
    padding: 0.313rem 0.313rem;
    margin: 0;
    border-radius: 0.5rem;
    min-width: 3.125rem;

    span {
      font-size: 0.875rem !important;
      width: fit-content;
    }
  }

  .title-edit-section-margin {
    margin-right: 0.625rem;
  }

  .product-detail-info-row {
    padding: 0.938rem 2.5rem;

    .star-icon {
      display: none;
    }

    .large-text {
      font-weight: bold;
      font-size: large;
    }

    .product-detail-color-view {
      &:hover {
        position: absolute;
        left: -2.188rem;
        top: 1.063rem;
        border-radius: 50%;
        background-color: $bright-Gray;
        width: 1.875rem;
        height: 1.875rem;
      }
    }

    &:hover {
      .product-detail-color-view {
        position: absolute;
        left: -2.188rem;
        top: -0.125rem;
        border-radius: 50%;
        width: 1.875rem;
        height: 1.875rem;
      }

      .star-icon {
        position: absolute;
        display: block;
        border-radius: 50%;
        height: 1.063rem;
        width: 1.063rem;
        left: 0.41rem;
        top: 0.38rem;
      }
    }

    &:nth-child(even) {
      background: $gray-50;
    }

    span {
      font-size: 1rem;
    }

    .product-details-image-des.MuiGrid-grid-sm-6 {
      @media (min-width: 600px) {
        flex-grow: 0;
        max-width: 67%;
        flex-basis: 49%;
      }
    }
  }

  .product-detail-location-stock-title span {
    font-size: 0.625rem;
  }

  .product-detail-location-stock-title,
  .product-detail-location-stock-info {
    margin-bottom: 0.625rem;
  }

  .product-detail-default-large-img {
    img {
      width: 6.25rem;
      height: 6.25rem;
    }
  }

  .product-detail-default-thumbnail {
    img {
      width: 3.75rem;
      height: 3.75rem;
    }
  }

  .product-img {
    img {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  .dark-mint-green-text {
    color: $dartmouth-green;
  }

  .dark-red-color-text {
    color: $dark-red-color;
  }
}

.product-status-Active .status-icon {
  background: $dark-mint-green;
}

.product-status-Pending .status-icon {
  background: $arylide-yellow;
}

.product-status-Inactive .status-icon {
  background: $shocking-orange;
}

.status-icon {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 1.563rem;
  display: inline-block;
  // margin-left: 0.5rem;
  margin-right: 0.2rem;
}

.small-text span {
  font-size: 0.875rem !important;
  margin-left: 2rem;
}

.small-text-without-margin span {
  font-size: 0.875rem !important;
}

.small-text.hyperlink-text-black {
  .link-color {
    color: black !important;
    /* Change "red" to the desired color */
  }
}

.wholesale-des-text span {
  font-size: 0.938rem !important;
}

.gray-text {
  color: $description-color;
}

.gren-text {
  color: $dartmouth-green;
}

.product-detail-main-content {
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: auto !important;
  }

  &::-webkit-scrollbar-track {
    background: #F1F1F1 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #B9B9B9 !important;
    border-radius: 0 !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #A5A1A1 !important;
  }
}

.product-detail-back-btn {
  left: 1.875rem;
  top: 0 !important;
  display: none;
  margin-right: 1rem;

  @media (min-width: 960px) {
    display: block;
  }
}

.product-detail-small-back-btn {
  display: block;

  @media (min-width: 960px) {
    display: none !important;
  }
}

.is-sticky {
  position: relative;
  display: none;

  @media (min-width: 960px) {
    max-width: 20.625rem;
    display: block;
    position: fixed;
    z-index: 999;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  }
}

.product-detail-status {
  box-shadow: 0rem 0.625rem 1.25rem $lightBlack-color;

  @media (min-width: 600px) {
    width: 23.125rem;
  }

  padding: 0.625rem;
  position: absolute;
  z-index: 99;
  background-color: $white-color;
  top: 0.25rem;
  border-radius: 0.313rem;

  a {
    text-decoration: none;
    color: inherit !important;
  }

  a:hover {
    text-decoration: none;
    color: inherit !important;
  }
}

.product-detail-status-sec {
  cursor: pointer;
  margin: 0.313rem 0 0.313rem;
}

.product-detail-status-item {
  position: relative;
  padding-left: 1.875rem;

  &:hover {
    background-color: $bright-Gray;
    border-radius: 0.313rem;
  }

  .product-detail-status-checked-icon {
    margin-right: 0.625rem;
    position: absolute;
    left: 0.438rem;
    margin-top: 1.125rem;
  }

  .product-detail-active-link-icon {
    margin-right: 0.625rem;
    position: absolute;
    right: 0.438rem;
    margin-top: 0.875rem;
  }

  .status-name {
    color: #000 !important;
  }

  .status-name:hover {
    color: #000 !important;
  }

  .status-name,
  .status-subtitle {
    font-size: 0.875rem;
  }

  .status-subtitle {
    color: $description-color;
  }
}

.divider-margin-left {
  margin-left: 1.875rem !important;
  color: #DDD !important;
}


.selected-status-label {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
    font-weight: 500;
  }
}

.product-detail-main-status-div {
  position: relative;
}

.product-detail-image-common-style {
  height: 2.5rem;
  width: 2.5rem;
}

.cost-title-info {
  padding: 0.938rem 2.5rem 0.3rem;
  border-bottom: 0.063rem solid $bright-Gray;

  span {
    font-size: 0.75rem;
    font-weight: 600;
  }
}

.meta-data-label {
  padding: 0.938rem 2.5rem 0.3rem;
  border-bottom: 0.063rem solid $bright-Gray;

  span {
    font-size: 1.375rem;
    font-weight: 600;
    color: $darkGray;
    letter-spacing: -0.0275rem;
  }
}

.up-arrow {
  transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
}

.product-detail-sidebar {
  margin-left: 2rem;

  .span-text {
    font-size: 0.75rem;
    letter-spacing: 0.075rem;
    color: black;
    font-weight: 500;
  }
}

.arrowForwardIosIcon-icon {
  transform: scale(0.6);
  margin-bottom: 0.25rem;
}

.restore-icon {
  transform: scale(0.8);
  margin-right: 0.25rem;
  margin-bottom: 0.125rem;
}

.mirrored-chart {
  transform: scaleX(-1);
}

.product-info-pill-sec {
  .product-info-pill {
    padding: 0.25rem 0.5rem;
    width: fit-content;
    height: fit-content;
    border-radius: 1.563rem;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 0.75rem;
      line-height: normal;
      letter-spacing: 0.0375rem;
    }
  }

  .clearance-product-pill {
    background-color: $purple-color;
  }

  .discontinued-product-pill {
    background-color: $yellow-color;
  }

  .out-stock-product-pill {
    background-color: $orange-color;
  }
}

.line-height-22 {
  line-height: 1.375rem;
}

.mui-starred-icon {
  display: inline-block;
  vertical-align: top;
}

.title-edit-section-sp {
  height: fit-content;
  width: fit-content;
  padding: 0.313rem 0.313rem !important;
  margin-top: 0.3125rem !important;
  border-radius: 0.5rem !important;
  min-width: 3.125rem !important;
  background-color: $btn-back-black !important;

  span {
    font-size: 1rem !important;
    width: fit-content;
    font-weight: 500;
  }
}

.hover--btn {
  height: 1.875rem;

  svg {
    height: 1.125rem;
  }
}

.search-sticky {
  position: sticky;
  top: 0.625rem;
  z-index: 1;
}

.product-detail-warning-color {
  color: $warning-yellow-color;
}