.notes-item {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 30px 0;
  border-top: 1px solid var(--bs-gray-200);

  &:nth-of-type(2) {
    border-top: none;
  }

  .notes-item-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding-bottom: 8px;

    .notes-item-created-by,
    .notes-item-created-at {
      line-height: 1;
      padding: 0;
      margin: 0;
    }

    .notes-item-created-by {
      font-size: 14px;
      font-weight: 500;
    }

    .notes-item-created-at {
      font-size: 12px;
      color: var(--bs-gray-800);
      letter-spacing: 0.1px;

      @media (max-width: 1000px) {
        font-size: 12px;
      }
    }
  }

  .notes-item-controls {
    display: grid;
    align-items: center;
    justify-items: flex-end;
    grid-template-columns: 1fr auto;
    padding-top: 4px;

    .notes-item-last-modified {
      margin: 0;
      padding: 0;
      line-height: 1.25;
      width: 100%;
      font-size: 12px;
      color: var(--bs-gray-800);
      letter-spacing: 0.1px;
    }
  }
}
