.tabs {
  --border-radius: 10px;
  --separator-color: var(--bs-gray-300);
  border-radius: var(--border-radius);
  border: 1px solid var(--separator-color);
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;

  &:focus-within {
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #000;
  }

  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .tab-radio-button:checked + .tab {
    background-color: #000;
    color: #fff;
  }

  .tab-radio-button:checked {
    &:focus-visible {
      & + .tab {
        z-index: 1;
      }
    }
  }

  .tab-button {
    color: #000;
    background-color: #fff;
    border-right: 1px solid var(--separator-color);

    &:first-child {
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
    }

    &:last-child {
      border-top-right-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
      border-right: none;
    }
  }

  .tab {
    width: 100%;
    padding: 14px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: var(--bs-gray-300);
    }
  }
  max-width: fit-content;
}
