@import "../../assets/css/variable.scss";

.restocking-fee-modal {
    .input-title {
        font-size: 1rem;
        font-weight: 500;
        letter-spacing: 0px;
        color: $black-color;
        opacity: 1;
    }
    .restocking-fee-amount-input-div {
        background: #ffffff 0% 0% no-repeat padding-box;
        height: 100%;
        border-radius: 0.625rem;
        padding: 0 1.25rem 0 1.25rem;
        margin: 0.625rem 0 0 0;
        border: 1px solid $black-color;
        opacity: 1;
        .fee-amount-input-div {
            display: flex;
            align-items: center;
            position: relative;
              &::after {
                content: "";
                position: absolute;
                bottom: 0;
                width:100%;
                height: 0.063rem;
                background-color: var(--bs-gray-200);
              }
            .fee-amt-title {
                width: 100%;
                max-width: 5rem;
                font-size: 1rem;
                font-weight: 500;
                letter-spacing: 0px;
                color: $black-color;
                opacity: 1;
            }
            .fee-amt-input-div {
                width: 100%;
                .fee-amt-input {
                    .MuiInputBase-root {
                        .MuiInputBase-input {
                          text-align:right;
                          cursor: text;
                          padding: 0.7rem;
                        }
                    }
                }
                .rate-input {
                  .MuiInputBase-root {
                    .MuiInputBase-input {
                      text-align:right;
                      cursor: text;
                      padding: 0.7rem 0;
                    }
                    .rate-icon {
                      color: $black-color;
                      opacity: 1;
                      font-size: 1rem;
                      font-weight: 500;
                    }
                }
                }
            }
        }
    }
    .cancel-button-section {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        gap: 10px;
        margin: 50px 0 15px;
        .use-default-btn {
            font-weight: 500;
            letter-spacing: -0.32px;
        } 
    }
}