.coupons-dotted-border-sec {
    border: 2px dashed #dddddd;
    border-radius: 0.625rem;
    margin-bottom: 0.625rem;
    padding: 1.25rem;
    .coupon-main-info {
      margin-bottom: 1.25rem;
      .coupon-name {
        font-size: 1rem;
        font-weight: 600;
      }
      .coupon-discount {
        font-size: 1rem;
        font-weight: 400;
        margin-left: 0.625rem;
      }
    }
    .coupon-timing-info {
      .coupon-timing::after {
          border-radius: 3.125rem;
          content: "•";
          color: #DDDDDD;
          margin: 0 0.5rem;
      }
    }
  }
  .coupon-common-styling {
    font-size: 0.875rem;
    font-weight: 500;
    color: #555555;
  }
  .listgroup-search-input{
    > svg{
      position: absolute;
      top: 14px;
      left: 12px;
    }
    input{
      padding: 0.75rem 2.875rem;
      border-radius: 0.625rem;
      width: 100%;
    }
  }

  .list-group-listbox{
    min-width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 1.875rem 2.5rem #00000033;
    border: 0.0625rem solid #DDDDDD !important;
    border-radius: 0.625rem;
    padding: 1.25rem !important;
    max-height: 580px !important;
    > li{
      padding: 20px 0;
      border-bottom: 1px solid #EEEEEE;
      &.Mui-focused{
        background-color: transparent !important;
        color: inherit !important;
      }
    }
  }

  .coupons-list-items{
  pointer-events:none;
  }
  
  .coupons-list-add-btn{
  pointer-events:auto;
  }

  .small--btn.btn{
    font: normal normal bold 14px/17px Inter;
    letter-spacing: 0px;
    color: #FFFFFF;
    height: 40px;
    width: max-content;
    padding: 8px 17px;
  }
  .coupon-dot-other{
    border-radius: 3.125rem;
    background: #DDDDDD;
    margin: 0 0.5rem !important;
    height: 4px;
    width: 5px;
    display: inline-block;
    vertical-align: middle;
  }