@import "../../assets/css/variable.scss";

.return-cart-search-input-container {
  display: flex;
  border-radius: 15px;
  .return-cart-search-product-input.return-cart-input-product {   
    @media only screen and (min-width: 900px) {
    &::before {
      content: "";
      position: absolute;
      top: 0.625rem;
      right: 1;
      width: 100%;
      max-width: 0.125rem;
      z-index: 1;
      height: 2.5rem;
      background-color: $darkGray;
      opacity: 1;
    }}
    .MuiFormLabel-root {
      margin: 6px 0 0 52px;
      transform: unset;
      font-size: 12px;
      transition: unset;
      letter-spacing: -0.12px;
      color: #000000;
      opacity: 1;
    }
    .MuiInputBase-root {
      padding: 1.25rem 3.125rem 1.25rem 1.25rem;
      background-color: #eeeeee;
      
      .MuiInputAdornment-root {
        color: $black-color;
        opacity: 1;
      }
      .MuiInputBase-input {
        font-weight: 400;
        font-size: 1rem;
        &::placeholder {
          color: $black-color;
          opacity: 1;
        }
      }
    }
  }
  .return-cart-search-order-input {
    .MuiInputBase-root {
      padding: 1.2rem 0.7rem;
      background-color: #eeeeee;
      cursor: pointer;
      .search-order-input-first-adornment {
        color: $black-color;
        opacity: 1;
      }
      .search-order-input-last-adornment {
        margin-right: 0;
        width: 1.125rem;
        height: 1.3125rem;
        display: flex;
        justify-content: center;
        background: $white-color 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 0px $box-shadow-color;
        border-radius: 0.25rem;
        opacity: 1;
        .MuiTypography-root {
          color: $black-color;
          opacity: 1;
          font-weight: bold;
          font-size: 0.875rem;
        }
      }
      .MuiInputBase-input {
        font-weight: 400;
        font-size: 1rem;
        &::placeholder {
          color: $black-color;
          opacity: 1;
        }
      }
    }
  }
  .return-cart-search-order-input-focused {
    .MuiFormLabel-root {
      margin: 6px 0 0 52px;
      transform: unset;
      font-size: 12px;
      transition: unset;
      letter-spacing: -0.12px;
      color: #000000;
      opacity: 1;
    }
    .MuiInputBase-root {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      padding: 1.2rem 0.7rem;
      background-color: white;
      border: 0.063rem solid #000000;
      border-radius: 15px;
      cursor: pointer;
      .search-order-input-first-adornment {
        color: $black-color;
        opacity: 1;
      }
      .search-order-input-last-adornment {
        .to-enter-search-div {
          color: #AAAAAA;
          opacity: 1;
          font-size: 0.75rem;
          .press-enter-image {
            margin: 0 5px;
          }
          .MuiSvgIcon-root {
            margin-left: 0.313rem; 
            width: 18px;
            height: 18px;
            color: $black-color;
          }
        }
        .MuiTypography-root {
          color: $black-color;
          opacity: 1;
          font-weight: bold;
          font-size: 0.875rem;
        }
      }
      .MuiInputBase-input {
        font-weight: 400;
        font-size: 1rem;
        &::placeholder {
          color: $black-color;
          opacity: 1;
        }
      }
    }
  }
}

// start
.return-cart-search-new-product-input{
  position: relative;
  display: flex;
  align-items: center;
  .return-cart-search-product-input .MuiInputBase-root {
    padding-right: 50px;
  }
}
.product-remove-close-icon{  
  position: absolute;
  right: 15px;
  cursor: pointer;
  svg{
    color: #777777;
  }
}
.return-cart-search-product-input {
  .MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(52px, 7px) scale(0.75);
  }
}
// end