@import "../../assets/css/variable.scss";

.customer-info-container {
  position: relative;
  .customer-info-back-btn {
    position: absolute;
    left: -5rem;
    top: 0;
  }
  .customer-info-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .customer-info-loader {
    display: flex;
    height: 50vh;
    align-items: center;
    justify-content: center;
  }
  .MuiTabs-root {
    .MuiTabs-flexContainer {
      display: -webkit-box !important;
      color: $dusty-gray-color;
    }
    .Mui-selected {
      color: black;
    }
    .MuiTabs-indicator {
      background-color: black !important;
    }
    .MuiButtonBase-root {
      text-transform: unset !important;
    }
  }
  .customer-tab{
    overflow: auto;
    height: 100vh;
  }
}
.customer-modal-container {
  max-width: 74.375rem;
  width: 100%;
  margin-top: 1.25rem;
  height: 100vh;
  overflow: auto;
  margin-bottom: 5rem;
  .tax-exempted-section {
    margin: 1.25rem 0;
    .money-off-icon {
      width: 1.25rem !important;
      height: 1.25rem !important;
    }
    .tax-text {
      margin-left: 0.55rem;
      font-weight: 500;
      font-size: 1.125rem;
    }
  }
  .divider-customer {
    margin: 1rem 0 !important;
    color: inherit;
    background-color: $bgColor !important;
    opacity: 0.25;
  }
  .credit-balance {
    width: 30%;
    display: flex;
    flex-direction: column;
    margin: 0.938rem 0rem;
    border-left: 0.063rem solid $border-color;
    padding-left: 1.25rem;

    .credit-unpaid {
      font-size: 0.625rem;
      color: $gray;
    }
    .balance-amount {
      font-weight: 500;
      font-size: 1.375rem;
    }
  }
  .customer-details-section {
    .customer-schedule-list {
      font-weight: 500;
    }
    .sub-heading {
      color: $description-color;
      letter-spacing: 0.0088rem;
      font: normal normal normal 0.875rem/1.0625rem Inter;
    }
    .unset-color {
      font: normal normal normal 1rem/1.25rem Inter;
      color: #000000;
      opacity: 0.3;
    }
    .modal-subheading {
      color: $description-color;
    }
    .code-contaier {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .code-set-update-button {
        display: flex;
        align-items: center;
        button,
        svg {
          margin-left: 0.313rem;
          font-size: 0.875rem;
          color: $description-color !important;
          text-decoration: underline;
        }
      }
      .customer-used-schedule {
        border: 0;
      }
    }
  }
}
.customer-used-schedule {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0;
}
.customer-schedule-list {
  font-size: 1.125rem;
  font-weight: 600;
  height: 1.25rem !important;
}
.credit-balance-grid-section {
  border: 1px solid #dddddd;
  padding: 1.25rem;
  border-radius: 0.625rem;
  .balance-credit-sec {
    display: flex;
    .unpaid-balance-info {
      flex-direction: column;
      margin-right: 3.75rem;
      .balance-title {
        line-height: normal;
        margin-bottom: 0.625rem;
        span {
          color: #555555;
          font-size: 0.625rem;
          font-weight: 600;
        }
      }
      .balance-data {
        line-height: normal;
        span {
          font-size: 1.125rem;
          font-weight: 600;
        }
        .no-balance-data{
          opacity: 0.3;
        }
      }
    }
  }
  .tex-credit-sec {
    display: flex;
    align-items: center;
    border-left: 0.031rem solid #dddddd;
    padding-left: 1.875rem;
    .credit-section {
      display: flex;
      align-items: center;
      margin-right: 1.875rem;
      span {
        font-weight: 600;
      }
      svg {
        height: 1.25rem;
        width: 1.25rem;
        margin-right: 0.5rem;
      }
      .credit-info {
        svg {
          fill: #fc6100;
        }
      }
    }
  }
}
.customer-info-title-left-section {
  min-width: 12.5rem;
  @media only screen and (min-width: 600px) {
  min-width: 18.75rem;
  }
}
.customer-account-row {
  padding: 0 0.938rem  0.938rem;
  border-radius: 0.313rem;
  .customer-info-title-left-section, .customer-used-schedule, .customr-account-sec-btn {
    padding-top: 1rem;
  }
}
.customer-account-row-style:nth-child(even) {
  background-color: #f9f9f9 !important;
}
.customer-info-change-btn.btn {
  padding: 0.25rem 0.75rem;
  width: fit-content;
  background-color: #f1f1f1;
  span {
    font-size: 0.875rem;
    width: fit-content;
    display: flex;
    color: #000;
  }
}
.customer-tab-header {
  min-height: auto !important;
  .MuiTabs-flexContainer {
    padding-bottom: 0.5rem;
    margin-top: 1.875rem;
    button {
      padding: 0.625rem !important;
      min-width: fit-content;
      min-height: fit-content;
      border-radius: 0.375rem;
      &:hover {
        background-color: #eeeeee;
        color: #000;
      }
    }
  }
}
.customer-info-change-btn.btn.MuiButtonBase-root {
  padding: 0.375rem 0.625rem;
  border-radius: 0.5rem;
}
.customer-section-info {
  .customer-info-back-btn.MuiButtonBase-root {
    position: relative;
    left: unset;
    margin-bottom: 10px;    
    @media only screen and (min-width: 600px) {
      position: absolute;
      left: -5rem;
    }
  }
}
.customer-notification-sec {
  .customer-notification-sec-title {
    border-bottom: 0.063rem solid $border-color;
    padding: 0.625rem 0;
    font-size: 1.375rem;
    font-weight: 600;
  }
  .customer-notification-toggle
    .css-11s4ykm-MuiSwitch-root
    .MuiSwitch-switchBase.Mui-checked
    + .MuiSwitch-track {
    background-color: $black-color !important;
  }
}
.MuiButtonBase-root.customer-main-sec-back-btn {
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  .btn-back-customer {
    min-width: 3.75rem !important;
    max-width: 3.75rem !important;
    height: 3.75rem;    
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.start-new-order-btn{
  width : 14.276rem;
  min-width : 14.276rem;
  max-width : 14.276rem;
  height: 2.875rem;
}

.switch-customer-btn{
  width: 13rem;
  min-width: 13rem;
  max-width: 13rem;
  height: 2.875rem;
}

.customer-details-back-btn{
  height: 3.75rem;
  min-width: 3.75rem;
  max-width: 3.75rem;
  width: 3.75rem;
}

.cust-code-change-btn{
  width: 5rem;
  min-width: 5rem;
  max-width: 5rem;
  height: 2rem; 
}

.tabButton-focus{
 &.MuiButton-root{
        &:focus{
            outline: 0.13rem solid var(--bs-black) !important;
        }
  }
}
