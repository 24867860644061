.product-image-sb {
  --size: 40;
  --img-size: calc(var(--size) * 1px);
  background-color: #eee;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  overflow: hidden;
  height: var(--img-size);
  width: var(--img-size);
  min-height: var(--img-size);
  min-width: var(--img-size);
  aspect-ratio: 1;
  position: relative;
  animation: product-image-loading-anim 1s ease alternate infinite;

  @keyframes product-image-loading-anim {
    to {
      background-color: #fff;
    }
  }

  img,
  svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    pointer-events: none;
    opacity: 0;

    &.product-image-loaded {
      animation: product-image-intro 1s ease-in forwards;

      @keyframes product-image-intro {
        to {
          opacity: 1;
        }
      }
    }
  }
}
