.delivery-proof-section {
    background-color: #FEF4E5;
    padding: 0.313rem 0.625rem 0.313rem 1.25rem;
    border-radius: 0.625rem;
    margin: 1.25rem 0 1.875rem;
    span {
        font-weight: 500;
    }
    button {        
    margin: 0.313rem 0;
    }
}
.delivery-proof-add-doc-modal {
    .MuiDialogContent-root {
        height: fit-content !important;
    }
}

.icon-align-center {
    align-items: center;
}

.print-slip-btn{
    width: 13rem;
    height: 2.75rem;
}

.undo-delivery-btn{
    width: 12rem ;
    height: 2.75rem;
}

.edit-storage-btn{
    width: 13rem ;
    height: 2.75rem;
}