@import "../../apps/pos/assets/css/variable.scss";

.pick-quantity-main-section {
  max-width: 1520px;
  margin: auto;
}

.pick-quantity-heading {
  position: relative;
}

.pick-replace-quantity-container {
  .pick-replace-quantity-back-icon {
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 1.25rem;
    left: 8.125rem;
    top: 9.6875rem;
    background-color: $bgColor !important;

    .MuiSvgIcon-root {
      fill: $black-color;
      opacity: 1;
    }
  }

  .pick-replace-quantity-main-section {
    .static-picking-replace-header {
      width: 100%;
      padding-top: 3.75rem;

      @media only screen and (min-width: 1001px) {
        left: 0;
        padding-left: 3.875rem;
      }

      // might need later
      // @media only screen and (min-width: 960px) {
      //   position: fixed;
      //   width: 100%;
      //   right: 0;
      //   margin: auto;
      // }
      top: 0;
      background-color: #ffffff;
      z-index: 1;
      padding-bottom: 0;
      max-width: 95rem;
    }

    .pick-replace-quantity-heading {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 0.063rem;
        background-color: var(--bs-gray-200);
      }

      .page-heading {
        .heading-black-text {
          letter-spacing: -0.035rem;
        }
      }

      .pick-replace-quantity-right-btn-div {
        display: flex;
        flex-wrap: wrap;
        gap: 0.625rem;
      }

      .pick-replace-quantity-btn {
        padding: 0.625rem 1.1875rem;
        font-weight: 500;
        letter-spacing: -0.32px;
        display: flex;
        justify-content: space-between;

        &.button-is-loading {
          position: relative;
          overflow: hidden;
          pointer-events: none;

          .button-label,
          .button-icon {
            opacity: 0;
          }

          &::before {
            content: "";
            position: absolute;
            background-color: var(--bs-gray-200);
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            border-radius: 0.625rem;
          }

          &::after {
            content: "";
            position: absolute;
            height: 1.5rem;
            width: 1.5rem;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNNiAxMGMtMS4xIDAtMiAuOS0yIDJzLjkgMiAyIDIgMi0uOSAyLTItLjktMi0yLTJ6bTEyIDBjLTEuMSAwLTIgLjktMiAycy45IDIgMiAyIDItLjkgMi0yLS45LTItMi0yem0tNiAwYy0xLjEgMC0yIC45LTIgMnMuOSAyIDIgMiAyLS45IDItMi0uOS0yLTItMnoiLz48L3N2Zz4=");
            background-position: center;
            background-repeat: no-repeat;
            opacity: 0;
            animation: button-loading-anim 0.3s ease-out alternate infinite;

            @keyframes button-loading-anim {
              to {
                opacity: 1;
                transform: scale(1.1);
              }
            }
          }
        }

        &.button-accent {
          color: var(--bs-white);
        }

        &.button-is-loading-black {
          position: relative;
          overflow: hidden;
          pointer-events: none;

          .button-label,
          .button-icon {
            opacity: 0;
          }

          &::before {
            content: "";
            position: absolute;
            background-color: var(--bs-black);
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            border-radius: 0.625rem;
          }

          &::after {
            content: "";
            position: absolute;
            height: 1.5rem;
            width: 1.5rem;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNNiAxMGMtMS4xIDAtMiAuOS0yIDJzLjkgMiAyIDIgMi0uOSAyLTItLjktMi0yLTJ6bTEyIDBjLTEuMSAwLTIgLjktMiAycy45IDIgMiAyIDItLjkgMi0yLS45LTItMi0yem0tNiAwYy0xLjEgMC0yIC45LTIgMnMuOSAyIDIgMiAyLS45IDItMi0uOS0yLTItMnoiLz48L3N2Zz4=");
            background-position: center;
            background-repeat: no-repeat;
            opacity: 0;
            animation: button-loading-anim 0.3s ease-out alternate infinite;

            @keyframes button-loading-anim {
              to {
                opacity: 1;
                transform: scale(1.1);
              }
            }
          }
        }

        &.button-accent {
          &.button-is-loading-black {
            &::after {
              filter: invert(1);
            }
          }
        }
      }
    }
  }
}

.btn.return-picking-qty-back-btn {
  position: absolute;
  left: 0;
  top: 1.375rem;

  @media only screen and (min-width: 960px) {
    left: -4.875rem;
    top: 0;
  }
}

.pick-info-table-header {
  padding-left: 1.5rem;
}

.pick-replace-table-header {
  .title {
    font-size: 0.625rem;
    font-weight: 600;
  }
}

.pick-replace-item-name {
  display: flex;
  position: relative;
  padding-left: 3.75rem;

  .pick-replace-product-img {
    position: absolute;
    left: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .image-bottom-vertical-line {
      display: none;

      @media only screen and (min-width: 960px) {
        border-left: 1px solid $bright-Gray;
        height: calc(100% - 1.25rem);
        margin-top: 0.625rem;
        display: block;
      }
    }

    img {
      height: 2.5rem;
      width: 2.5rem;
    }
  }
}

.replace-pick-qty {
  input {
    text-align: center;
  }
}

.pick-replace-line-id {
  font-size: 0.875rem;
  font-weight: 600;
}

.replace-qty,
.picked-replace-qty {
  font-size: 1.125rem;
  font-weight: 600;
}

.pick-replace-content {
  border-bottom: 0.063rem solid $bright-Gray;
  margin-bottom: 1.25rem;
  padding-bottom: 0;
}

.pick-content-spacing {
  padding-bottom: 1.5rem;
}

.light-text {
  color: $darkGray;
}

.icon-btn-right-spacing {
  padding-left: 10px;
}

.pick-quantity-heading-with-text {
  display: flex;
  flex-direction: column;

  .pick-heading-sub-text {
    max-width: 650px;
    display: flex;
    flex-direction: column;

    .back-order-counter {
      padding: 0.625rem;
      border-radius: 0.625rem;
      background-color: $bright-Gray;
      display: flex;
      gap: 0.625rem;
      align-items: center;
      letter-spacing: 0.01rem;
    }
  }
}

.pick-quantity-right-btn-div {
  height: fit-content;
  padding-bottom: 3.125rem;
}

.picking-qty-bottom-btn {
  justify-content: flex-end !important;
  position: sticky;
  bottom: 0;
  background: #fff;

  &::after {
    display: none;
  }
}

.pick-location-responsive-title.pick-responsive-title {
  margin-right: 0;
  margin-bottom: 0.625rem;
}

.pick-id-responsive-title.line-id {
  justify-content: flex-start !important;
  text-align: left !important;
}

.pick-responsive-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.625rem;
  font-size: 0.625rem;
  font-weight: 600;

  @media only screen and (min-width: 960px) {
    display: none;
    margin-right: 0;
  }
}

.pick-table-title-section {
  display: none;

  @media only screen and (min-width: 960px) {
    display: block;
  }
}

.available-qty-title {
  padding-right: 2rem;
}

.pick-product-line-id .pick-replace-table-header span {
  font-size: 1rem;

  @media only screen and (min-width: 960px) {
    font-size: 0.625rem;
  }
}

.available-qty-title {
  padding-right: 2rem;
}

.left-padding {
  padding-left: 3rem;
}

.pick-order-content-spacing {
  justify-content: flex-start;
}

.pick-ordered-qty-content-spacing {
  justify-content: flex-end;
}

.pick-ordered-qty-content-spacing,
.pick-order-content-spacing {
  @media only screen and (min-width: 960px) {
    justify-content: center;
  }
}

.pick-qty-container.new-container.MuiContainer-root {
  width: 80%;

  @media only screen and (min-width: 960px) {
    width: 80%;
  }
}

.pick-qty-container.new-container.MuiContainer-root.pick-qty-header-container {
  width: 80%;

  @media only screen and (min-width: 960px) {
    width: 84%;
  }
}

.pick-replace-quantity-complete-btn-div {
  padding: 1.25rem 0rem;
  border-top: 0.125rem solid black;
  justify-content: flex-end;
}

//might need later
// #pick-replace-qty-final{
//   padding-bottom: 6.25rem;
// }

.pick-qty-put-storage-section.put-storage-section {
  opacity: 0.7;
  padding: 1.25rem 0;

  &:hover {
    opacity: 1;
  }
}

.disable-put-in-storage {
  cursor: not-allowed;
}

.disable-storage {
  pointer-events: none;
}

.storage-change-spacing {
  &:before {
    content: "•";
    color: $darkGray;
    margin: 0 0.5rem;
  }
}

.qty-storage-item-title {
  font-size: 0.625rem;
}

.in-storage-data-sec {
  max-width: 19.375rem;
  width: 100%;
  border: 0.0625rem solid $border-color;
  border-radius: 0.625rem;
  padding: 1.25rem;

  .qty-storage-item {
    width: 40.33%;
  }

  .qty-storage-item-data-hover {
    &:hover {
      text-decoration: underline;
    }

    span {
      font-size: 0.875rem;
    }
  }

  .qty-storage-item-data {
    span {
      font-size: 0.875rem;
    }
  }
}

.set-text-item-data {
  opacity: 0.5;
}

.set-storage-data {
  opacity: 0.3;
}

.custom-size-13 {
  width: 13.75rem;
  min-width: 13.75rem;
  max-width: 13.75rem;
  height: 3.125rem;
}

.qty-str-focus-class {
  &:focus-visible {
    .storage-span {
      outline: 0.13rem solid var(--bs-gray-600) !important;
      outline-offset: 0.1875rem;
    }
  }
}