.product-list-select-dropdown {
  select {
    border-radius: 0.438rem !important;
    padding: 0.313rem 0.313rem !important;
    font-size: 0.875rem !important;
  }

  .option-list-view {
    display: flex;
    margin-top: 2.5rem;
  }
}

.hide-extra-text {
  height: 4.375rem;
}

.filters {
  .product-list-select-dropdown {
    margin-right: 0.625rem;

    .MuiFormControl-root {
      border-radius: 0.625rem;
      background-color: #EEEEEE;
      min-width: 8.75rem;

      .MuiFormLabel-root {
        font-size: 0.875rem;
        font-weight: 500;
        font-family: "Inter", sans-serif;
        color: #000;
        left: 0.6875rem;
        transform: translate(0, 1rem) scale(1);
      }

      .MuiSelect-multiple {
        padding-top: 0.6875rem;
        padding-bottom: 0.6875rem;

        .MuiCheckbox-root,
        .MuiListItemText-root,
        .MuiChip-root {
          display: none;
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border: none;
      }

      .MuiSelect-icon {
        display: none;
      }

      .MuiSelect-select {
        &:before {
          content: "";
          position: absolute;
          width: 1.875rem;
          height: 1.875rem;
          right: 0.4375rem;
          border: 0;
          background-image: url("../../../../apps/springboard/images/icons/arrow-up.svg");
          background-repeat: no-repeat;
        }

        &.Mui-disabled {
          &:before {
            opacity: 0.3;
          }
        }
      }
    }
  }
}

.product-list-filter-options.MuiButtonBase-root {
  padding: 0.3125rem 0.3125rem 0.3125rem 0.3125rem !important;

  .MuiIconButton-colorSecondary {
    &:hover {
      background-color: transparent;
    }
  }

  &.Mui-selected {
    .MuiIconButton-label {
      background: url(../../../../Image/tick.png) no-repeat 0 0;
      background-size: 0.9375rem;
      background-position: center;
    }
  }

  .MuiIconButton-label {
    width: 1em;
    height: 1em;

    .MuiSvgIcon-root {
      display: none;
    }
  }

  .MuiListItemText-root {

    .MuiTypography-body1 {
      font-size: 0.875rem;
      font-weight: 500;
      color: #000;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .MuiCircularProgress-root {
    margin: 0 auto !important;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: transparent;
  }
}

li.label-border-styling:nth-of-type(3) {
  border-bottom: 1px solid #EEEEEE;
}

.search-container {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.search-container .MuiInputBase-root {
  margin-left: 15px;

  .MuiInputBase-input {
    margin-left: 5px;
  }

  .search-input {
    overflow: hidden;
  }
}

.loading-item .MuiCircularProgress-root {
  margin: 0 auto;
}