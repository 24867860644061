.loading-container {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.fixed-loader{
  position: unset;
}

.loading-container .progress-bar-wrapper {
  height: 2px;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.loading-container .progress-bar-wrapper .progress-track {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bs-gray-200);
  position: absolute;
}

.loading-container .progress-bar-wrapper .progress-bar-1,
.loading-container .progress-bar-wrapper .progress-bar-2 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bs-primary);
  position: absolute;
  z-index: 1;
  -webkit-transform-origin: left;
          transform-origin: left;
  -webkit-transition: -webkit-transform 0.2s linear;
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  transition: transform 0.2s linear, -webkit-transform 0.2s linear;
}

.loading-container .progress-bar-wrapper .progress-bar-1::after,
.loading-container .progress-bar-wrapper .progress-bar-2::after {
  content: '';
  position: absolute;
  right: 0;
  height: 100%;
  width: 100%;
}

.loading-container .progress-bar-wrapper .progress-bar-1.indeterminate,
.loading-container .progress-bar-wrapper .progress-bar-2.indeterminate {
  will-change: transform;
  -webkit-animation: loading-progress-anim 2s infinite;
          animation: loading-progress-anim 2s infinite;
}

.loading-container .progress-bar-wrapper .progress-bar-2.indeterminate {
  -webkit-animation-delay: 0.65s;
          animation-delay: 0.65s;
}

@-webkit-keyframes loading-progress-anim {
  from {
    -webkit-transform: scaleX(0) translateX(-150%);
            transform: scaleX(0) translateX(-150%);
  }
  to {
    -webkit-transform: scaleX(1) translateX(130%);
            transform: scaleX(1) translateX(130%);
  }
}

@keyframes loading-progress-anim {
  from {
    -webkit-transform: scaleX(0) translateX(-150%);
            transform: scaleX(0) translateX(-150%);
  }
  to {
    -webkit-transform: scaleX(1) translateX(130%);
            transform: scaleX(1) translateX(130%);
  }
}
/*# sourceMappingURL=index.css.map */