.group-wrapper {
  grid-column: 5 / 16;
  display: flex;
  flex-direction: column;

  .group-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;

    .group-title {
      h3 {
        font-size: 22px;
        font-weight: 600;
        letter-spacing: -0.2px;
      }
    }

    .group-actions {
      button {
        font-size: 14px;
        font-weight: 600;
        padding: 4px 12px;
        border-radius: 8px;
        border: none;
        background-color: #f1f1f1;
        outline: none;
        transition: trasform 0.1s ease-out;

        &:hover,
        &:focus-visible {
          background-color: #ddd;
        }

        &:focus-visible {
          box-shadow: 0 0 0 2px #fff, 0 0 0 4px #999;
        }

        &:active {
          transform: scale(0.975);
        }
      }
    }
  }

  .group-content {
    padding-top: 10px;

    .logs-empty-state,
    .schedules-empty-state {
      color: #555;
      font-size: 14px;
      padding-top: 10px;
    }

    .log-group {
      .log-group-header {
        margin-bottom: 10px;
        border-bottom: 1px solid #eee;
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #fff;

        .log-group-date {
          h5 {
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
          }
        }
      }

      .log-group-content {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .log-item {
          position: relative;
          display: grid;
          gap: 30px;
          grid-template-columns: auto 1fr auto;
          align-items: flex-start;

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 70px;
            width: calc(100% - 70px);
            height: 1px;
            background-color: #eee;
          }

          .log-item-avatar {
            position: relative;

            .log-type-indicator {
              position: absolute;
              height: 24px;
              width: 24px;
              background-color: #000;
              border-radius: 500px;
              display: flex;
              align-items: center;
              justify-content: center;
              right: -10px;
              bottom: -10px;
              outline: 2px solid #fff;

              .log-type-icon {
                color: #fff;
                user-select: none;
                pointer-events: none;
              }
            }
          }

          .log-item-info {
            padding-bottom: 15px;

            .log-item-text {
              font-weight: 400;
              font-size: 16px;
              line-height: 1.25;
              margin-bottom: 3px;

              .log-person-name {
                font-weight: 500;
              }

              .log-item-entity {
                text-transform: capitalize;
              }
            }

            .log-item-subtext {
              margin-top: 6px;
              margin-bottom: 1px;

              &.log-item-subtext-product-change {
                font-size: 14px;
                letter-spacing: 0.1px;
                color: #555;
                line-height: 1.5;
              }

              &.log-item-subtext-price-change {
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 0.1px;
                color: #aaa;
                display: flex;
                align-items: center;
                gap: 10px;
                font-feature-settings: "tnum", "calt", "ss01", "ss02", "zero";

                .log-item-new-price {
                  color: #000;
                }
              }
            }
          }

          .log-item-time {
            font-size: 12px;
            text-transform: uppercase;
            color: #555;
          }
        }
      }
    }

    .more-logs {
      margin-top: 10px;
      display: flex;
      align-items: center;
      gap: 20px;
      padding-left: 70px;

      .more-logs-label {
        font-size: 16px;
        color: #555;
      }

      button {
        padding: 8px 12px;
        border-radius: 10px;
        background-color: #eee;
        font-size: 14px;
        font-weight: 500;
        border: none;
        outline: none;
        transition: trasform 0.1s ease-out;

        &:hover,
        &:focus-visible {
          background-color: #ddd;
        }

        &:focus-visible {
          box-shadow: 0 0 0 2px #fff, 0 0 0 4px #999;
        }

        &:active {
          transform: scale(0.975);
        }
      }
    }

    .more-items-group {
      margin-top: 10px;
      display: flex;
      align-items: center;
      gap: 20px;

      .more-items-label {
        font-size: 16px;
        color: #555;
      }

      .more-items-view-btn {
        padding: 8px 12px;
        border-radius: 10px;
        background-color: #eee;
        font-size: 14px;
        font-weight: 500;
        border: none;
        outline: none;
        transition: trasform 0.1s ease-out;

        &:hover,
        &:focus-visible {
          background-color: #ddd;
        }

        &:focus-visible {
          box-shadow: 0 0 0 2px #fff, 0 0 0 4px #999;
        }

        &:active {
          transform: scale(0.975);
        }
      }
    }

    .schedules-group {
      .schedules-header,
      .schedule-item {
        display: grid;
        grid-template-columns: repeat(11, 1fr);
        column-gap: 20px;
        row-gap: 6px;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .schedules-header {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #fff;
        padding: 10px 0;

        p {
          display: flex;
          width: 100%;
          font-size: 10px;
          letter-spacing: 1px;
          font-weight: 400;
          text-transform: uppercase;
          color: #555;
          line-height: 1;

          &:first-child {
            grid-column: 1 / 4;
          }

          &:nth-child(2),
          &:nth-child(4) {
            grid-column: span 2;
          }

          &:nth-child(5) {
            grid-column: span 3;
          }
        }

        .spacer {
          grid-column: span 1;
          grid-column-end: -1;
        }
      }

      .schedule-item {
        font-size: 16px;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #eee;

        .schedule-name {
          grid-column: 1 / 4;
          line-height: 1;
        }

        .schedule-volume-break {
          grid-column: 4 / span 8;
          line-height: 1;
          display: grid;
          grid-template-columns: repeat(8, 1fr);
          gap: 20px;

          p {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            line-height: 1.25;

            &:nth-child(1),
            &:nth-child(2) {
              font-feature-settings: "tnum", "calt", "ss01";
            }

            &:nth-child(1) {
              grid-column: span 2;
            }

            &:nth-child(3) {
              grid-column: span 2;
            }

            &:nth-child(4) {
              grid-column: span 3;
            }
          }
        }
      }
    }
  }
}

.btn-small {
  font-size: 14px;
  font-weight: 600;
  padding: 4px 12px;
  border-radius: 8px;
  border: none;
  background-color: #f1f1f1;
  outline: none;
  transition: trasform 0.1s ease-out;

  &:hover,
  &:focus-visible {
    background-color: #ddd;
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #999;
  }

  &:active {
    transform: scale(0.975);
  }
}

// Manage Retial price
.product-detail-notes-modal {
  .product-detail-notes-paper-div {
    &.manage-retaile-price {
      width: 81.25rem;
    }
  }
  .product-detail-noted-modal-content {
    padding-bottom: 6.25rem;
  }
  .input-with-add-button {
    .document-search-input {
      .MuiFilledInput-root {
        padding: 0.75rem;
        border-radius: 0.625rem;
      }
      .MuiInputBase-input {
        font-size: 1rem;
        line-height: 1.5rem;
        letter-spacing: 0.01rem;
        color: #000;
        &::placeholder {
          color: #000 !important;
        }
      }
      .MuiSvgIcon-root {
        width: 1.25rem;
      }
    }
    .MuiInputAdornment-root {
      margin-top: 0 !important;
    }
  }
  .manage-detail-section {
    margin-top: 1.25rem;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 15px;
    .left-block {
      width: 25.9375rem;
      padding-top: 2rem;
      .title-label {
        font-weight: 500;
        line-height: normal;
        &-1,
        &-2 {
          font-size: 0.75rem;
          line-height: 0.9375rem;
          letter-spacing: 0.075rem;
          text-transform: uppercase;
          font-weight: 500;
        }
        &-1 {
          color: #000000;
        }
        &-2 {
          color: #999999;
        }
      }
      .c--dot {
        height: 0.1875rem;
        width: 0.1875rem;
        background-color: #aaaaaa;
        border-radius: 50%;
        margin: 0 0.5rem;
      }
      .w-sm {
        width: 0.9375rem;
      }
    }
    .right-block {
      flex: 1 1 auto;
      .manage-detail-section-header {
        padding: 0.625rem 0;
        font-size: 0.625rem;
        color: #000;
        span {
          padding-left: 0.625rem;
        }
      }
      .manage-detail-section-action-items,
      .manage-detail-section-header {
        max-width: 100%;
        display: grid;
        grid-template-columns: 8.125rem repeat(3, 1fr) 3.125rem;
        grid-gap: 0.0625rem;
        gap: 0.0625rem;
      }
      .manage-detail-section-action-items {
        margin-bottom: 0.3125rem;
        .return-to-input-group-sec {
          &:last-of-type {
            &::after {
              content: "";
              position: absolute;
              width: 1px;
              height: 20px;
              background: #aaaaaa;
              left: 0;
            }
          }
          .return-qty-input {
            padding: 0.625rem;
          }
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      .product-detail-select {
        .MuiSelect-root.MuiFilledInput-input {
          padding: 0.625rem;
        }
      }
    }
    &:last-of-type {
      border-bottom: 0;
    }
  }
}

  .notes-model-footer {
   
    .int-msg {
      font-size: 0.875rem;
      line-height: 1.0625rem;
      letter-spacing: 0rem;
      color: #000000;
      max-width: 21.1875rem;
      margin: 0.3125rem 1.25rem 0;
    }
    hr {
      &.top--border {
        display: block;
        border-top: 0.0625rem solid #dddddd;
        width: 100%;
        margin: 0 0 2.1875rem;
      }
    }
}