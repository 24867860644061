.MuiDrawer-root.default-drawer-style.no-cart-drawer {
    .MuiDrawer-paper {
      .MuiBox-root {
        max-width: 46.875rem;
        width: 100%;
        margin: 0 auto;
        .responsive-back-btn {
          left: unset;
          right: 0.938rem;
          top: 0.625rem;
          z-index: 11;
          width: 2.813rem;
          height: 2.813rem;
          background-color: #F1F1F1 !important;
          border-radius: 1rem;
          color: #000;
          @media only screen and (min-width: 900px) {
          right: 48.625rem;
          top: 3.438rem;
          width: 3.75rem;
          height: 3.75rem;
          border-radius: 1.25rem;
          }
        }
      }
    }
  }
  
  .new-container.MuiContainer-root {
    max-width: 95rem;
    width: 70%;
    @media only screen and (min-width: 768px) {
      width: 80%;
    }
    padding: 0 !important;
    @media only screen and (min-width: 1200px) {
      max-width: 95rem;
      width: 80%;
      padding: 0 !important;
    }
  }
  @media only screen and (min-width: 1200px) {
    aside.sidenav-collapsed:hover + .cart-main-layout .new-container.MuiContainer-root {
      max-width: 95rem;
      width: 92%;
      padding: 0 !important;
    }
  }
  @media only screen and (min-width: 1200px) {
    aside.sidenav-open + .cart-main-layout .new-container.MuiContainer-root {
      max-width: 95rem;
      width: 92%;
      padding: 0 !important;
    }
  }
  @media only screen and (min-width: 1200px) {
    aside.sidenav-open + .cart-main-layout .new-container.MuiContainer-root {
      max-width: 95rem;
      width: 92%;
      padding: 0 !important;
    }
  }
  .large-screen-container-styling.MuiContainer-root {
    @media only screen and (min-width: 1200px) {
      width: 80%;
    }
  }

  .small-screen-title {
    display: block;
    @media only screen and (min-width: 1200px) {
      display: none;
    }
  }
