.deactivate-account-dialog {
    .dialog-header {
        padding: 20px;

        .dialog-title {
            font-size: 24px;
            font-family: "Roboto Slab", serif !important;
            font-weight: 400;

            @media (max-width: 800px) {
                font-size: 1.25rem;
            }
        }
    }

    .dialog-body {
        display: flex;
        flex-direction: column;

        .deactivate-alert-icon {
            color: var(--bs-red);
        }

        .deactivate-alert-message {
            display: flex;
            padding: 20px;
            gap: 20px;
            background-color: rgba(#ec3e37, 0.1);

            @media (max-width: 800px) {
                flex-direction: column;
            }

            .deactivate-alert-message-group {
                display: flex;
                flex-direction: column;
                gap: 20px;

                p {
                    line-height: 1.35;
                    font-size: 1rem;
                    font-family: var(--inter-font-family) !important;
                    margin-bottom: 0;
                }
            }
        }

        .support-link {
            padding: 20px;
            font-size: 1rem;
            line-height: 1.35;
            padding-left: 64px;

            @media (max-width: 800px) {
                padding-left: 20px;
            }

            a {
                color: inherit !important;
                font-weight: 500;
            }
        }

        .deactivation-confirmation {
            display: flex;
            align-items: center;
            cursor: pointer;
            user-select: none;
            padding: 0 10px;
            padding-left: 24px;
            margin-top: 40px;
            gap: 20px;
            border-top: 1px solid var(--bs-gray-100);
            border-bottom: 1px solid var(--bs-gray-100);

            @media (max-width: 800px) {
                margin-top: 20px;
                padding-left: 20px;
            }

            &:hover {
                background-color: var(--bs-gray-100);
            }

            &:active {
                background-color: var(--bs-gray-300);
            }

            input[type="checkbox"] {
                --size: 18px;
                height: var(--size);
                width: var(--size);
                accent-color: var(--bs-primary);
            }

            label {
                cursor: pointer;
                height: 100%;
                width: 100%;
                display: flex;
                padding: 10px 0;
                line-height: 1.34;
            }
        }
    }

    .dialog-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        padding: 20px;
    }

    @media (max-width: 800px) {
        padding-bottom: 20px;
    }
}
