.user-list-item,
.skeleton {
    display: grid;
    grid-template-columns: var(--avatar-size) 4fr repeat(4, 1fr);
    gap: 0.938rem;
    align-items: center;
    border-radius: 0.625rem;
    padding: 0.625rem 1.25rem;
    color: inherit;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    &::after {
        content: "";
        position: absolute;
        width: calc(100% - var(--avatar-size) - 2.1875rem);
        height: 0.0625rem;
        right: 0;
        bottom: 0;
        background-color: var(--bs-gray-200);
    }

    &:hover,
    &:focus-visible {
        background-color: var(--bs-gray-50);
    }

    &:focus-visible {
        outline: none;
        box-shadow: 0 0 0 0.125rem #fff, 0 0 0 0.25rem #000;
    }

    &:hover,
    &:active,
    &:focus-visible {
        &::after {
            display: none;
        }
    }

    @media (max-width: 75rem) {
        grid-template-columns: var(--avatar-size) 3fr repeat(4, 1fr);
    }

    @media (max-width: 62.5rem) {
        grid-template-columns: var(--avatar-size) 2fr repeat(4, 1fr);
        padding: 0.625rem 1.25rem;
    }

    @media (max-width: 50rem) {
        display: grid;
        grid-template-columns: auto 1fr;
        column-gap: 0.625rem;
        row-gap: 0.5rem;
        padding: 0.625rem 0;
        padding-right: 6.25rem;
        padding-bottom: 1.25rem;

        &::after {
            width: calc(100% - var(--avatar-size) - 0.625rem);
        }
    }

    .avatar {
        height: var(--avatar-size);
        width: var(--avatar-size);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0% !important;
        background-color: transparent !important;

        // patch: avatar changes z-index on hover
        &>div {
            &:hover {
                z-index: initial !important;
            }
        }
    }

    .skeleton-icon {
        height: var(--avatar-size);
        width: var(--avatar-size);
        display: flex;
        align-items: center;
        justify-content: center;

        // patch: avatar changes z-index on hover
        &>div {
            &:hover {
                z-index: initial !important;
            }
        }
    }

    .name-group,
    .created-at,
    .role,
    .status,
    .name,
    .email {
        font-size: 0.875rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.25;


        @media (max-width: 50rem) {
            grid-column: 2 / -1;
            font-size: 0.8125rem;
        }
    }

    .created-at {
        @media (max-width: 50rem) {
            display: none;
        }
    }

    .name-group {
        display: flex;
        flex-direction: column;
        gap: 0.3125rem;

        .name {
            font-size: 1rem;

            @media (max-width: 50rem) {
                font-weight: 500;
            }
        }

        .email {
            letter-spacing: 0.0.938rem;
            color: var(--bs-gray-600);
        }

        .name,
        .email {
            max-width: 30ch;
        }
    }

    .status {
        display: flex;
        align-items: center;
        gap: 0.3125rem;
        padding: 0.25rem 0.5rem;
        background-color: var(--bs-gray-100);
        max-width: fit-content;
        border-radius: 31.25rem;

        &.active {
            background-color: var(--bs-gray-100);
        }

        &.invited {
            background-color: var(--bs-gray-100);
            color: #000;
        }

        &.awaiting {
            background-color: var(--bs-gray-800);
            color: #fff;
            padding-bottom: 0.3125rem;
        }

        &.locked {
            background-color: var(--bs-yellow);
            color: #000;
        }

        &:empty {
            width: 100%;
            background-color: unset;
        }
    }

    .actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0.625rem;

        @media (max-width: 50rem) {
            gap: 0.625rem;
            position: absolute;
            right: 0;
            top: 0.625rem;
        }

        .menu-wrapper {
            position: relative;
        }

        .menu-skrim {
            position: fixed;
            height: 100vh;
            width: 100%;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.1);
            z-index: 10;
            opacity: 0;
            will-change: opacity;
            animation: menu-skrim-intro 0.3s cubic-bezier(0.35, 0.45, 0, 1) forwards;

            @keyframes menu-skrim-intro {
                to {
                    opacity: 1;
                }
            }

            @media (max-width: 50rem) {
                background-color: rgba(0, 0, 0, 0.4);
            }
        }

        .menu {
            position: absolute;
            right: 0;
            top: 75%;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            z-index: 11;
            border-radius: 0.938rem;
            border: 0.0625rem solid var(--bs-gray-300);
            box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.1);
            padding: 0.625rem;
            transform: translate3d(5%, -25%, 0) scale(0.9);
            transform-origin: top right;
            border-radius: 6.25rem;
            opacity: 0;
            will-change: transform, opacity;
            animation: menu-intro 0.4s cubic-bezier(0, 1, 0, 1) forwards;

            @keyframes menu-intro {
                to {
                    border-radius: 0.938rem;
                    opacity: 1;
                    transform: translate3d(0, 0, 0) scale(1);
                }
            }

            &:empty {
                display: none;
            }

            &.bottom-edge {
                top: auto;
                bottom: 75%;
                transform: translate3d(5%, 25%, 0) scale(0.9);
                transform-origin: bottom right;
                flex-direction: column-reverse;

                @media (max-width: 50rem) {
                    flex-direction: column;
                    bottom: 0;
                    transform-origin: center;
                    transform: translate3d(0, 100%, 0);
                }

                .menu-item {
                    &:first-child {
                        border-bottom-color: #fff;
                    }

                    &:last-child {
                        border-bottom-color: var(--bs-gray-100);
                    }
                }
            }

            @media (max-width: 50rem) {
                width: 100%;
                position: fixed;
                top: auto;
                bottom: 0;
                left: 0;
                right: 0;
                border-radius: 1.25rem;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                opacity: 1;
                transform-origin: center;
                transform: translate3d(0, 100%, 0);
                will-change: transform;
                animation: menu-intro 0.4s cubic-bezier(0.35, 0.45, 0, 1) forwards;
                padding: 0.938rem;
                padding-bottom: 2.5rem;
                border: none;
                background-color: var(--bs-gray-100);

                @keyframes menu-intro {
                    to {
                        transform: translate3d(0, 0, 0);
                    }
                }
            }

            .menu-item {
                margin: 0;
                color: #000;
                padding: 0.625rem;
                border-radius: 0.3125rem;
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 0.625rem;
                width: 12.5rem;
                max-width: 12.5rem;
                border: none;
                background-color: #fff;
                overflow: hidden;
                transition: transform 1s cubic-bezier(0, -0.01, 0, 1);
                will-change: transform;
                border-bottom: 0.0625rem solid var(--bs-gray-100);
                user-select: none;

                &.menu-item-danger {
                    color: var(--bs-danger);
                }

                @media (max-width: 50rem) {
                    width: 100%;
                    max-width: 100%;
                    padding: 1rem 1.25rem;
                    border-radius: 0;
                    border-bottom: none;
                    background-color: #fff;
                    justify-content: space-between;
                    flex-direction: row-reverse;

                    &:first-child {
                        border-top-left-radius: 0.938rem;
                        border-top-right-radius: 0.938rem;
                    }

                    &:last-child {
                        border-bottom-left-radius: 0.938rem;
                        border-bottom-right-radius: 0.938rem;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        height: 0.0625rem;
                        width: 100%;
                        bottom: 0;
                        left: 0;
                        background-color: var(--bs-gray-100);
                    }
                }

                &:last-child {
                    border-bottom-color: #fff;

                    @media (max-width: 50rem) {
                        border-bottom: none;
                    }
                }

                &:focus-visible {
                    outline-color: black;
                    z-index: 1;
                }

                &:hover,
                &:focus-visible {
                    background-color: var(--bs-gray-100);
                    border-bottom: 0.0625rem solid #fff;

                    @media (max-width: 50rem) {
                        background-color: var(--bs-gray-300);
                        border-bottom: none;
                    }
                }

                &:active {
                    background-color: var(--bs-gray-100);
                    transform: scale(0.975);

                    @media (max-width: 50rem) {
                        background-color: var(--bs-gray-300);
                        transform: scale(1);
                    }
                }

                .label {
                    font-family: var(--inter-font-family), "Inter", sans-serif;
                    white-space: nowrap;
                    font-size: 0.875rem;
                    font-weight: 500;
                    text-overflow: ellipsis;
                    letter-spacing: 0.0063rem;
                    text-rendering: geometricPrecision;
                    color: currentColor;

                    @media (max-width: 50rem) {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}

.mb0 {
    margin-bottom: 0rem !important;
}

.skeleton {
    cursor: wait;

    &:hover {
        background-color: #fff;
    }

    .skeleton-icon,
    .name-group .name,
    .name-group .email,
    .created-at,
    .role,
    .status {
        background-color: var(--bs-gray-200) !important;
        border-radius: 31.25rem;
        animation: skeleton-loop-anim 0.6s ease-out alternate infinite;

        @keyframes skeleton-loop-anim {
            to {
                opacity: 0.5;
            }
        }
    }

    .created-at,
    .role,
    .status {
        height: 0.625rem;
        width: 4.375rem;
        min-width: 2.5rem;

        &:empty {
            background-color: var(--bs-gray-200);
        }
    }

    .name-group {

        .name,
        .email {
            height: 0.625rem;
        }

        .name {
            width: 6.25rem;
        }

        .email {
            width: 15.625rem;
        }
    }
}

.user-list-empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 9.375rem 0;
    gap: 0.625rem;

    .empty-state-message {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.2;
        display: flex;
        margin-top: 1.25rem;
    }
}

.target_scroll {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 120vh;
    position: relative;
    top: -100vh;
    margin-bottom: -100vh;
    pointer-events: none;
}