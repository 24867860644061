@import "../../pos/assets/css/variable.scss";
@import "../../../constants/colors.scss";

.free-ship-info {
    margin: 0.625rem 0;

    .free-ship-title-style {
        width: 85%;

        &>span {
            display: flex;
            align-items: center;

            .shipping-free-label {
                line-height: normal;
                font-size: 0.625rem;
                padding: 0.125rem 0.313rem;
                margin-left: 0.625rem;
                letter-spacing: 0.063rem;
            }
        }
    }
}

.payment-complete-order-btn {
    width: 21.875rem;
    min-width: 21.875rem;
    max-width: 21.875rem;
    height: 3.75rem;
}

.card-main-container {
    width: 43.875rem;
    height: 268px;

    .card-container {
        height: 60px;
        width: 43.875rem;
        border: 1px solid #f1f1f1;
        border-radius: 15px;

        .card-text {
            margin-left: 32px;
            margin-top: 30px;
        }

        .card-text-lower {
            margin-left: 20px;
            margin-top: 15px;
            display: flex;
            flex-direction: row;

            .card-loader {
                margin-right: 15px;

                .MuiSvgIcon-root {
                    fill: #34C759;
                }
            }

            .card-lower-text {
                display: flex;
                flex-direction: column;

                .upper-text {
                    font-weight: 500;
                }
            }
        }
    }
}

.card-manual {
    cursor: pointer;
    align-items: center;
    background: $bgColor;
    border-radius: 0px 0px 15px 15px;
    width: 43.875rem;
    height: 5.25rem;
    display: flex;
    flex-direction: row;

    .card-image {
        margin-left: 32px;
    }

    .card-text-manual {
        margin-left: 15px;
        display: flex;
        flex-direction: column;

        .upper-text {
            font-weight: 500;
        }
    }
}

.manual-card-input-container {
    background-color: #ffffff;
    border: 1px solid #000;
    border-radius: 10px;
    margin-right: 0.313rem;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    width: 14rem;
    min-width: 43rem;
    max-width: 45rem;
    height: 4rem;
}

.tender-amount-options {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border-radius: 3.125rem;
    padding: 0.3125rem 0.3125rem;
    font-size: 0.875rem !important;
    font-family: "Inter", Medium;
    height: 2.0625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}

.new-tender-options {
    gap: 0.3125rem;
    display: flex;
    margin-top: 0.75rem;
    justify-content: flex-start;
}

.process-via-tabs {
    margin-right: 1.25rem;
    font-weight: 500;
    font-size: 1.25rem;
}

.process-via {
    display: flex;
    align-items: center;
    margin-bottom: 1.875rem;
}

.manual-card-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.manual-card-last-4-digit {
    width: 48%;
}

.manual-card-last-4-digit {
    .form-field-space {
        margin-top: 0;
    }
}

.manual-card-card-type {
    .form-field-space {
        margin-top: 0;
    }
}

.manual-card-card-type {
    width: 50%;
}

.paypal-section-continue-btn {
    margin-top: 2.5rem;
}

.marginright-6 {
    margin-right: 6rem;
}

.marginright-4 {
    margin-right: 4rem;
}

.billing-details-form {
    .MuiContainer-root {
        padding-left: 0;
        padding-right: 0;
    }
}

.address-sub-text {
    height: 1.25rem;
    text-align: left;
    font-size: 1rem;
    letter-spacing: 0px;
    color: $dusty-gray-color;
    opacity: 1;
}

.billing-form-field-title {
    font-size: 1.25rem;
}

.phone-number-ext {
    margin-right: 7.188rem;
}

.applied-store-credit {
    color: $fern-green-color;
}

.payment-info-layout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2.5rem;

    .grid-container {
        padding-right: 0.625rem;

    }

    .grid-value {
        text-align: end;
        font-feature-settings: "cv01", "cv02", "cv03", "cv04", "cv05", "cv06", "cv08", "cv10", "cv11", "cpsp", "calt", "ccmp", "locl", "kern", "tnum", "salt";
    }

    .label-font {
        text-transform: uppercase;
        font-size: 0.625rem !important;
        color: $black-color;
        font-weight: 500;
        letter-spacing: 0.0625rem;
        font: normal normal normal 0.625rem/1rem Inter;
    }
}

.allow-pickup-text {
    font-size: 0.875rem;
    letter-spacing: 0.0088rem;
    color: $description-color;
    padding-left: 1.875rem;
}

.start-dollar-icon {
    p {
        color: black
    }
}