@import "apps/pos/assets/css/variable.scss";

.edit-document-info-modal {

    .MuiDialogContent-root {
        width: 37.5rem;
        height: 45.875rem;
        border-radius: 1.25rem !important;
        padding: 1.875rem !important;
    }
    .MuiSelect-select {
          padding: 0.9375rem !important;
    }

    .MuiDialog-paper {
        height: auto !important;
        border-radius: 1.25rem !important;

        .MuiDialogContent-root {
            height: auto;
            position: relative;
            padding: 1.875rem !important;

            .close-modal-icon {
                position: absolute;
                width: 100%;
                display: flex;
                justify-content: flex-end;
                right: 0.625rem;
                top: 0.625rem;
                // background-color: $bright-Gray; // important
                width: 2.5rem;
                height: 2.5rem;

                svg {
                    fill: $gray;
                }
            }

            .edit-document-container {
                .edit-document-title-section {
                    .small-title-modal {
                        font-size: 0.875rem;
                        font-weight: 500;
                        color: $black-color;
                    }

                    .edit-document-title {
                        font-size: 1.25rem;
                        font-weight: 600;
                        color: $black-color;
                    }
                }

                .edit-document-type-section {
                    margin-top: 1.875rem;

                    .edit-document-type-text {
                        font-size: 1rem;
                        font-weight: 500;
                        color: $black-color;
                        margin-bottom: 0.625rem;
                    }
                }

                .edit-document-description-section {
                    margin-top: 1.875rem;

                    .title-text {
                        font-size: 1rem;
                        font-weight: 500;
                        color: $black-color;
                        margin-bottom: 0.625rem;

                        .document-optional-head {
                            font-size: 1rem;
                            font-weight: 500;
                            color: $darkGray;
                        }
                    }
                }

                .edit-document-bottom-section {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 1.875rem;

                    .new-tab-open-btn {
                        margin-left: 0.625rem;
                        padding: 0.75rem 0.625rem !important;

                        img {
                            height: 1.125rem;
                            width: 1.25rem;
                        }
                    }

                    .document-edit-section {
                        background-color: $border-color;
                        height: 2.5rem;
                        width: 2.5rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 1.563rem;
                    }
                }
            }
        }
    }
}

.doc-customer-name {
    font-weight: 600;
}

.delete-document-modal {
    .MuiDialogContent-root {
        max-width: 34.375rem !important;

        .delete-confirmation-btn {
            color: $orange-color;
            margin-left: 0.625rem;
        }
    }
}