@import "src/apps/pos/assets/css/variable.scss";

// .review-item-page-wrapper{
//  .review-item-page-main-sec{
//   .info-header-wrapper{
//       .info-header{
//           .picking-qty-back-btn{
//               margin-left: 20px;
//               margin-top: 71px;
//               top: 24px;
//           }

//           .review-item-header{
//            margin-top: 34px;
//           }
//       }

//       }
//  }
// }

.review-item-container {
  overflow-y: auto;
  height: 100%;
  padding: 0 5rem;
  margin: auto;
  @media only screen and (min-width: 960px) {
    width: 90% !important;
  }
  @media only screen and (min-width: 1536px) {
    width: 80% !important;
  }

  .review-item-heading {
    display: flex;
    justify-content: start;
    position: relative;
    padding-top: 2.5rem;
  }
  .review-title-spacing{
    padding-top: 1.5rem;
  }

  .review-item-description {
    font-size: 1rem;
    text-align: left;
    margin-top: 0.7rem;
    color: #000000;
  }

  .review-item-heading-details {
    .review-stock-table {
      .sticky-table-header {
        position: unset;

        .MuiTableRow-root {
          .MuiTableCell-root {
            font-size: 10px;
          }
        }
      }
    }
  }

  .review-item-price-change {
    margin-top: 55px;

    .review-item-price-change-details {
      font-size: 20px !important;
      font-weight: 500 !important;
      border-bottom: 1px solid #dddddd52;
      padding-bottom: 15px;
      margin-bottom: 5px;
    }
  }

  .review-item-span {
    font-size: 0.875rem;
    color: #000000;
    font-weight: 400;
    line-height: 1.063rem;
  }

  .review-item-all-price {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .review-item-price-container {
      img {
        padding-top: 1rem;
      }

      .review-item-price-span {
        display: inline-grid;
        margin-left: 0.8rem;
        line-height: 2.3rem;
      }
    }



    p {
      padding: 11px;
      font-size: 15px;
      font-weight: 500;
    }
  }

  .old-price-apply {
    margin-left: 0.8rem;
    width: 6.688rem;
    height: 2.75rem;
    background: #f1f1f1;
    border-radius: 0.938rem;
    text-align: center;
    cursor: pointer;
    p {
      font-weight: 600;
    }
  }
  .new-price-apply {
    width: 6.688rem;
    height: 2.75rem;
    background: #f1f1f1;
    border-radius: 0.938rem;
    text-align: center;
    cursor: pointer;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    p {
      font-weight: 600;
    }
  }
  .review-item-not-in-stock {
    margin-top: 55px;

    .review-item-out-of-stock {
      font-size: 1.25rem;
      font-weight: 500;
      margin-top: 4.75rem;

      img {
        margin-top: 1rem !important;
        padding-right: 0.8rem !important;
      }
    }

    .review-out-stock-description {
      margin-left: 3.6rem;
      font-size: 0.875rem;
      display: flex;
      line-height: 0rem;
      margin-bottom: 1rem;
      color: #000000;
      font-weight: 400;
    }

    .review-out-stock-table {
      .sticky-table-header {
        position: unset;

        .product-search-table-header-row {
          .MuiTableCell-root {
            font-size: 10px;
          }
        }
      }
    }
  }

  .review-stock-table-avl-qty {
    text-align: center;
  }

  .review-item-save-and-go-to-cart {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    margin-bottom: 50px;
    .MuiButton-label{
      justify-content: space-between;
      .MuiButton-endIcon{
        background-color: $white-color;
        color: black;
        padding:0px 0.1875rem;
        border-radius: 0.25rem;
      }
    }
  }

  .review-items-footer {
    border: 1px solid;
    margin: 2rem 0rem 0rem 3rem;
  }

  .old-price-btn-contain {
    padding: 10px;
    border: 1px solid #000000;
    opacity: 1;
    border-radius: 5px 0px 0px 5px;
    width: fit-content;
    text-align: left;
    background: #eeeeee 0% 0% no-repeat padding-box;
    cursor: pointer;
    height: 100%;
    width: 100%;
  }

  .old-price-btn-contain-blank {
    padding: 10px;
    border: 1px solid #DDDDDD;
    opacity: 1;
    border-radius: 5px 0px 0px 5px;
    width: fit-content;
    text-align: left;
    cursor: pointer;
    height: 100%;
    width: 100%;
  }

  .new-price-btn-contain {
    padding: 10px;
    border: 1px solid #000000;
    opacity: 1;
    border-radius: 0px 5px 5px 0px;
    width: fit-content;
    background: #f1f1f1;
    text-align: left;
    cursor: pointer;
    height: 100%;
    width: 100%;
  }

  .new-price-btn-contain-blank {
    padding: 10px;
    opacity: 1;
    border-radius: 0px 5px 5px 0px;
    border: 1px solid #DDDDDD;
    width: fit-content;
    text-align: left;
    cursor: pointer;
    height: 100%;
    width: 100%;
  }

  .tab-body-old-price {
    padding-right: 0px !important;
    @media only screen and (min-width: 960px) {
    max-width: 9.375rem;
    }
  }

  .tab-body-new-price {
    padding-left: 0px !important;
    @media only screen and (min-width: 960px) {
    max-width: 9.375rem;
    }
  }

  .check-icon {
    height: 16px;
    width: 16px;
  }

  .old-price-apply {
    width: 107px;
    height: 44px;
    background: #f1f1f1;
    border-radius: 15px;
  }

  .new-price-apply {
    width: 107px;
    height: 44px;
    background: #f1f1f1;
    border-radius: 15px;
  }

  .adjust-stock-button-out-stock-item {
    height: 2.25rem;
    border-radius: 10px;
    background: #f1f1f1;
    color: black;
    font-size: 1rem;
  }
}
.review-item-container::-webkit-scrollbar-thumb{
  background-color: transparent
}

.review-table-item {
  display: flex;
  padding: 16px 10px;

  &-header {
    font-size: 10px;
  }

  &-left-info {
    width: 45%;

    &-cell {
      height: 100%;
      width: 33.33%;
    }
  }

  &-right-info {
    width: 55%;

    &-cell {
      width: 100%;
    }

    .price-break {
      overflow-wrap: break-word;
      text-align: right;
    }
  }

  &-icon {
    width: 40px;
    margin-right: 10px;
  }

  &-name {
    width: calc(100% - 40px);

    &-manufacturer {
      color: #000;
      font-size: 1rem;
      font-weight: 600;
    }

    &-product {
      color: #000;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .old-price-text,
  .new-price-text {
    font-size: 1rem;
    color: #000;
    font-weight: 600;
    line-height: normal;
    .profit-percentage{
    font-weight: 400;
    }
  }
}

.review-table-item-data {
  border-bottom: 1px solid #dddddd52;
}

.review-back-btn.btn {
  position: absolute;
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 20px !important;
  background-color: #f1f1f1 !important;
  padding: 20px;
  left: -80px;

  img {
    height: 100%;
    width: 100%;
  }
}
.review-responsive-title{
  width: fit-content;
  margin-right: 0.625rem;
  font-size: 0.625rem;
}
.review-responsive-title, .price-responsive-title {
@media only screen and (min-width: 960px) {
  display: none !important;
}}
.review-normal-title {
  display: none !important;
  @media only screen and (min-width: 960px) {
display: flex !important;
  }
}
.price-sec-grid.d-flex {
  flex-direction: column !important;
  .price-responsive-title{
  display: flex;
    width: 100%;
    .old-price-responsive-title, .new-price-responsive-title{
      width: 50%;
      text-align: center;
      margin-bottom: 0.313rem;
    }
}}
.price-main-title{
  @media only screen and (min-width: 960px) {
    justify-content: center;
  }
}
.review-ext-price-value{
  justify-content: flex-start;
  @media only screen and (min-width: 960px) {
    justify-content: flex-end;
  }
}
.review-responsive-data{
  text-align: left;
  padding-left: 0.438rem;
  @media only screen and (min-width: 960px) {
    text-align: center;
    padding-left: 0;
  }
}
.review-data-grid.MuiGrid-item{
  margin: 0.938rem 0;
  padding-left: 0.625rem;
  justify-content: flex-start;
  border-bottom: 0.063rem solid $bright-Gray;
  @media only screen and (min-width: 960px) {
    margin: 0;
    padding-left: 0;
    align-items: center;
    justify-content: center;
  }
}
.review-data, .review-responsive-data{
  width: 100%;
}
.main-apply-to-all{
  width: 100%;
  @media only screen and (min-width: 600px) {
    width: auto;
  }
  .review-apply-all{
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: normal;
  }
}
@media only screen and (max-width: 600px) {
  .d-flex {
    display: flex !important;
    justify-content: unset !important;
    flex-direction: row !important;
  }
} 
.main-review-data-grid .review-data-grid{
padding: 0.938rem 0;
}
.save-go-to-cart-btn{
  width:     19rem;
  min-width: 19rem;
  max-width: 19rem;
  height: 4rem;
}