/* workstation page */
@import "../../apps/pos/assets/css/variable.scss";

.table-header {
  th {
    color: #000000;
  }
}

.cart-product-listing-data {
  max-height: 46.875rem;
}

.order-user-list-table,
.cart-product-data-styling {
  th {
    background-color: #fff;
  }

  .table-divider {
    cursor: pointer;
  }
}

// start
.main-wrapper {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  -webkit-column-gap: 1rem;
  column-gap: 1rem;
  padding: 0 5.625rem;
}

.main-wrapper main {
  -ms-grid-column: 2;
  -ms-grid-column-span: 14;
  grid-column: 1 / 16;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  -webkit-column-gap: 1rem;
  column-gap: 1rem;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-grid-rows: 1fr auto 1fr;
  grid-template-rows: 1fr auto 1fr;
  padding: 0;
}

.main-wrapper main .info-header-wrapper {
  grid-column: 2 / -2;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  -webkit-column-gap: 1rem;
  column-gap: 1rem;
  position: -webkit-sticky;
  position: sticky;
  z-index: 11;
  background: $white-color;
}

.main-wrapper main .info-header-wrapper::before,
.main-wrapper main .info-header-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  background-color: #fff;
}

.main-wrapper main .info-header-wrapper .info-header {
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    grid-column: 1 / -1;
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    -webkit-column-gap: 1rem;
    column-gap: 1rem;
  }
}

.main-wrapper main .info-header-wrapper .info-header .titles-wrapper {
  -ms-grid-column: 1;
  -ms-grid-column-span: 5;
  grid-column: 1 / 7;

  @media (min-width: 768px) {
    grid-column: 1 / 6;
  }

  @media (min-width: 1200px) {
    grid-column: 1 / 4;
  }

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 0.313rem;
}

.main-wrapper main .info-header-wrapper .info-header .search-title-wrapper.titles-wrapper {
  grid-column: 1 / 17;
}

.main-wrapper main .info-header-wrapper .info-header .titles-wrapper .title,
.main-wrapper main .info-header-wrapper .info-header .titles-wrapper .subtitle {
  color: #000;
  line-height: 1.1;
  font-size: 1.75rem;
  font-weight: 600;
  letter-spacing: -0.025rem;
}

.main-wrapper main .info-header-wrapper .info-header .titles-wrapper .subtitle {
  color: var(--bs-gray-600);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.625rem;
}

.main-wrapper main .info-header-wrapper .info-header .info-blocks {
  grid-column: 7 / 17;
  margin-top: 1rem;

  @media (min-width: 768px) {
    grid-column: 6 / 17;
    padding-left: 0.5rem;
    margin-top: 0;
  }

  @media (min-width: 1200px) {
    grid-column: 4 / 17;
  }

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  flex-wrap: wrap;
  justify-content: flex-end;

  .info-block {
    border-radius: 0.625rem;
    width: 8.75rem;
    padding: 0.625rem;
    cursor: pointer;

    span,
    .primary-text {
      cursor: inherit;
    }

    display: flex;
    flex-direction: column;
  }
}

.cart-searchbar-wrapper {
  grid-column: 1 / -1;
}

.main-wrapper main .info-header-wrapper .info-header .searchbar-wrapper {
  margin-top: 1.875rem;
}

.main-wrapper main .info-header-wrapper .info-header .searchbar-wrapper-credit {
  margin-top: 3.125rem;
}

.cart-items-header {
  grid-column: 1 / -1;
  display: -ms-grid;
  display: flex;
  justify-content: space-between;
  grid-template-columns: repeat(14, 1fr);
  -webkit-column-gap: 1rem;
  column-gap: 0.563rem;
  font-size: 0.625rem;
  letter-spacing: 0.063rem;
  font-weight: 500;
  color: #000;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-transform: uppercase;
  padding: 0.625rem;
  padding-bottom: 0.313rem;
}

.cart-items-header .code {
  grid-column: 6 / 9;
}

.cart-items-header .indicator {
  padding-left: 3.438rem;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 0.75rem;
  background-color: #34c759;
}

.cart-items-header .name {
  grid-column: 3 / 6;
  width: 65%;
  max-width: 50rem;
  padding-left: 6.25rem;
}

.cart-items-header .line-id {
  width: 5%;
  max-width: 3.125rem;
}

.cart-items-header .qty,
.cart-items-header .price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 0.313rem;
  width: 15%;
  max-width: 15.625rem;
}

.cart-items-header .qty span,
.main-wrapper main .info-header-wrapper .info-header .cart-items-header .price span {
  width: 100%;
}

.cart-items-header .qty {
  -ms-grid-column: 10;
  -ms-grid-column-span: 2;
  grid-column: 10 / 12;
}

.main-wrapper main .info-header-wrapper .info-header .cart-items-header .qty span {
  text-align: center;
}

.cart-items-header .price {
  grid-column: 12 / -1;
}

.cart-items-header {

  .net-price,
  .ext-price {
    width: 50%;
    text-align: end;
  }
}

.cart-items-header .price {
  text-align: left !important;

  .ext-price {
    text-align: right !important;
  }
}

// .main-wrapper main
.cart-wrapper {
  grid-column: 1 / -1;
  display: -ms-grid;
  display: grid;
  -webkit-column-gap: 1rem;
  column-gap: 1rem;
}

.empty-cart-wrapper {
  justify-content: center;
}

.empty-cart-wrapper.cart-wrapper {
  display: flex;
  max-width: 38.375rem;

  @media (min-width: 600px) {
    display: grid;
  }
}

.product-search-suggestions {
  -ms-grid-column: 5;
  -ms-grid-column-span: 6;
  grid-column: 1 / 11;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 2.9375rem;
  will-change: transform, opacity;
  width: 100%;
}

.product-search-suggestions .title {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.product-search-suggestions .title span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.product-search-suggestions .title span::after {
  height: 0.063rem;
  width: 100%;
  margin-left: 1.25rem;
  content: "";
  background-color: var(--bs-gray-200);
}

.product-search-suggestions .suggestions-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 0.625rem;
}

.product-search-suggestions .hint {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 0.313rem;
  grid-column: 1 / -1;
  margin-top: 2.813rem;
  color: var(--bs-gray-900);
}

.product-search-suggestions .hint .hint-copy {
  font-size: 0.75rem;
  color: #333;
}

// .main-wrapper main
.cart-wrapper .cart-empty-state {
  -ms-grid-column: 6;
  -ms-grid-column-span: 4;
  grid-column: 6 / 10;
  margin-top: 6.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 1.25rem;
  will-change: transform, opacity;
}

// .main-wrapper main
.cart-wrapper .cart-empty-state .art {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// .main-wrapper main
.cart-wrapper .cart-empty-state .art img {
  pointer-events: none;
}

// .main-wrapper main
.cart-wrapper .cart-empty-state .copy {
  font-size: 1.125rem;
  letter-spacing: 0.063rem;
}

// .main-wrapper main
.cart-wrapper .cart-list {
  grid-column: 1 / -1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  // margin-bottom: 10vh;
  padding-top: 0.313rem;
  overflow: auto;
  height: 34vh;
}

// .main-wrapper main
.cart-wrapper .cart-footer-wrapper {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  -webkit-column-gap: 1rem;
  column-gap: 1rem;
  // padding: 0 5.625rem 0 138px;
  padding: 0 7.813rem 0 10.625rem;
  position: relative;

  @media (min-width: 1200px) {
    position: fixed;
  }

  z-index: 11;
  width: 100%;
  bottom: 0;
  will-change: transform,
  opacity;
  background: white;
}

// .main-wrapper main
.cart-wrapper .cart-footer-wrapper .cart-footer {
  border-top: 0.125rem solid #000;
  padding-top: 0.5rem;
  -ms-grid-column: 2;
  -ms-grid-column-span: 14;
  grid-column: 2 / 16;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  -webkit-column-gap: 1rem;
  column-gap: 1rem;
  background-color: #fff;
}

// .main-wrapper
//   main
.cart-wrapper .cart-footer-wrapper .cart-footer .footer-blocks {
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    grid-column: 1 / -1;
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(14, 1fr);
    -webkit-column-gap: 1rem;
    column-gap: 1rem;
  }

  .label {
    margin-bottom: 0.625rem;
    font-weight: bold;
  }
}

// .main-wrapper
//   main
.cart-wrapper .cart-footer-wrapper .cart-footer .footer-blocks .particulars {
  padding-left: 0.625rem;
  -ms-grid-column: 1;
  -ms-grid-column-span: 11;
  grid-column: 1 / 12;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

// .main-wrapper
//   main
.cart-wrapper .cart-footer-wrapper .cart-footer .footer-blocks .totals {
  grid-column: 12 / -1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  position: relative;
}

// .main-wrapper
//   main
.cart-wrapper .cart-footer-wrapper .cart-footer .footer-blocks .totals::before {
  content: "";
  position: absolute;
  top: 0;
  left: -1.25rem;
  height: 100%;
  width: 0.063rem;
  background-color: #000;
}

// .main-wrapper main
.cart-wrapper .cart-footer-wrapper .cart-footer .actions {
  border-top: 0.063rem solid var(--bs-gray-200);
  margin-top: 0.625rem;
  padding-bottom: 2.5rem;
  grid-column: 1 / -1;
  display: -ms-grid;
  grid-template-columns: repeat(14, 1fr);
  -webkit-column-gap: 1rem;
  column-gap: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cart-wrapper .cart-footer-wrapper .cart-footer .actions .save-for-later-btn {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1 / 3;
  margin-top: 1.25rem;
}

// .main-wrapper
//   main
.cart-wrapper .cart-footer-wrapper .cart-footer .actions .btn.print-btn {
  -ms-grid-column: 3;
  -ms-grid-column-span: 2;
  grid-column: 3 / 5;
  margin-top: 1.25rem;
}

.cart-footer-btn {
  display: flex;
  flex-wrap: wrap;

  button.btn {
    padding: 0.8rem;
    font-size: 0.9rem;

    @media (min-width: 768px) {
      padding: 1.25rem;
      font-size: 1rem;
    }
  }
}

// .main-wrapper
//   main
.cart-wrapper .cart-footer-wrapper .cart-footer .actions .set-picking-info-btn {
  -ms-grid-column: 9;
  -ms-grid-column-span: 3;
  grid-column: 9 / 12;
  display: flex !important;
  justify-content: space-between !important;
  margin-right: 0.938rem;
  margin-top: 1.25rem;

  .MuiButton-label {
    .MuiButton-endIcon {
      background-color: white;
      color: black;
      padding: 0px 0.1875rem;
      border-radius: 0.25rem;
    }
  }
}

// .main-wrapper
//   main
.cart-wrapper .cart-footer-wrapper .cart-footer .actions .payment-btn {
  grid-column: 12 / -1;
  display: flex !important;
  justify-content: space-between !important;
  margin-top: 1.25rem;

  .MuiButton-label {
    .MuiButton-endIcon {
      background-color: white;
      color: black;
      padding: 0px 0.1875rem;
      border-radius: 0.25rem;
    }
  }
}

/*# sourceMappingURL=cart.css.map */

.btn.info-block {
  width: 11.25rem !important;
  padding: 0.625rem;
  border-radius: 0.625rem;
  border: none;
  background-color: #fff;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  // height: 100%;
  will-change: transform;
  outline: 0.125rem solid rgba(255, 255, 255, 0);
  outline-offset: -0.125rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  justify-content: flex-start;
}

.info-block:not(:disabled):hover,
.info-block:not(:disabled):focus-visible {
  background-color: var(--bs-gray-100);
}

.info-block:not(:disabled):hover .label,
.info-block:not(:disabled):focus-visible .label {
  color: var(--bs-gray-900);
}

.info-block:not(:disabled):focus-visible {
  outline-color: var(--bs-gray-400);
  -webkit-transition: all 0.05s ease;
  transition: all 0.05s ease;
}

.info-block:not(:disabled):active {
  background-color: var(--bs-gray-200);
  -webkit-transform: scale(0.975);
  transform: scale(0.975);
}

.info-block:disabled {
  opacity: 0.35;
  cursor: default;
}

.info-block .label,
.info-block .secondary-text {
  color: var(--bs-gray-800);
}

.info-block .label {
  font-size: 0.625rem;
  font-weight: 400;
  letter-spacing: 0.063rem;
  line-height: 1;
  text-transform: uppercase;
  font-family: var(--inter-font-family);
  text-align: left;
}

.info-block .primary-text,
.info-block .primary-text-ship,
.info-block .secondary-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  line-height: 1.15;
  font-family: var(--inter-font-family);
  text-align: left;
  max-width: 8.75rem;
  font-size: 0.875rem;
  cursor: default;
}

.info-block .one-line {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.info-block .primary-text {
  color: #000;
  cursor: default;
}

.info-block .primary-text-ship {
  color: #000;
  cursor: default;
  white-space: normal;
  word-break: break-word;
}

.info-block .secondary-text {
  margin-top: 0.125rem;
}

.info-block .icon {
  font-size: 1rem;
  margin-left: 0.313rem;
}

.money-off-icon {
  width: 1rem !important;
  height: 1rem !important;
  background-color: #000000;
  color: #eeeeee;
  border-radius: 0.625rem;
  margin-left: 0.375rem;
}

.label-with-icon {
  min-height: 1rem;
}

.credit-hold-icon {
  width: 1.25rem !important;
  height: 1.25rem !important;
}

.btn.suggestion-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.625rem;
  padding-right: 1.25rem;
  border-radius: 0.938rem;
  background-color: var(--bs-gray-50);
  cursor: pointer;
  border: none;
  user-select: none;
  will-change: transform, opacity;
  outline: 0.125rem solid rgba(255, 255, 255, 0);
  outline-offset: -0.125rem;
  text-align: start;

  .label-group {
    display: none;
  }

  &:hover,
  &:focus-visible {
    background-color: var(--bs-gray-100);

    .image {
      transform: scale(1.1);
    }
  }

  &:focus-visible {
    outline-color: var(--bs-gray-400);
    outline-offset: 0.125rem;
    transition: all 0.05s ease;
  }

  &:active,
  &.active {
    background-color: var(--bs-gray-200);
    transform: scale(0.995);
  }

  .suggestion-info {
    display: flex;
    align-items: center;
    gap: 0.938rem;
    max-width: calc(100% - 3em);

    .name {
      white-space: nowrap;
      font-family: var(--inter-font-family);
      font-size: 0.875rem;
      letter-spacing: 0.063rem;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #000;
      font-weight: 500;
      max-width: 31.875rem;
    }
  }
}

.keyboard-shortcut-block {
  line-height: 1;
  padding: 0.125rem 0.375rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.063rem 0 0 var(--bs-gray-400);
  background-color: #fff;
  color: #000;
  font-family: var(--inter-font-family);
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: fit-content;
  user-select: none;
  pointer-events: none;
}

@mixin grid($columns) {
  display: grid;
  grid-template-columns: repeat($columns, 1fr);
  column-gap: 1rem;
}

.cart-item-line {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0.063rem;
    background-color: var(--bs-gray-200);
  }
}

.btn.cart-list-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  grid-template-columns: repeat(14, 1fr);
  padding: 0.625rem;
  border-radius: 0.625rem;
  cursor: pointer;
  position: relative;
  will-change: transform, opacity;
  min-height: 4.375rem;
  align-items: flex-start;
  outline: 0.125rem solid rgba(255, 255, 255, 0);
  outline-offset: -0.125rem;
  background-color: #fff;
  border: none;
  animation: cart-list-item-intro 0.8s ease-in;

  @keyframes cart-list-item-intro {
    from {
      background-color: #ddd;
    }

    to {
      background-color: #fff;
    }
  }

  .label-group {
    display: none;
  }

  &:hover,
  &:focus-visible {
    background-color: var(--bs-gray-200);

    .image {
      transform: scale(1.1);
    }
  }

  &:focus-visible {
    outline-color: var(--bs-gray-400);
    transition: all 0.05s ease;
    outline-offset: 0;
    z-index: 1;

    &::after {
      display: none;
    }
  }

  &:active,
  &.active {
    background-color: var(--bs-gray-300);
    transform: none !important;
  }

  span {
    width: 100%;
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: 0.006rem;
    font-family: var(--inter-font-family);
    color: #000;
  }

  .line-id {
    width: 5%;
    max-width: 3.125rem;
  }

  .code {
    grid-column: 6 / 9;
    text-align: left;
    width: auto;
  }

  .name {
    width: 65%;
    max-width: 50rem;
    position: relative;
    grid-column: 1 / 6;
    text-align: left;
    display: flex;

    .name-with-indicator {
      margin-right: 0.4rem;
    }

    .name-cart-list {
      display: flex;
      justify-content: space-between;
      width: 80%;
      max-width: 46.25rem;

      .name-text {
        padding-left: 1.875rem;
        display: flex;
        flex-direction: column;

        .clearance-product {
          display: flex;
        }
      }
    }
  }

  .indicator-header {
    grid-column: 1;
  }

  .indicator {
    display: flex;
    margin-right: 0.625rem;

    &-style {
      width: 0.625rem;
      height: 0.625rem;
      border-radius: 0.75rem;
    }
  }

  .qty,
  .price {
    display: flex;
    justify-content: space-between;
    gap: 0.313rem;
    width: 15%;
    max-width: 15.625rem;

    span {
      width: 100%;
    }
  }

  .qty {
    grid-column: 10 / 12;

    span {
      text-align: center;
    }
  }

  .price {
    grid-column: 12 / 15;

    span {
      text-align: right;
      font-weight: 500;
    }
  }

  .cart-list-item-image {
    top: 0.625rem;
    grid-column: 1 / 2;
    min-width: 4.375rem;
    width: 20%;
    max-width: 3.125rem;
    height: 3.125rem;

    .user-avatar {
      position: relative;

      .popular-img-icon {
        margin-top: 0;
        margin-left: 0;
        right: -0.625rem;
        bottom: -0.625rem;
        background-color: #fff;
      }
    }
  }
}

.btn.footer-block,
.footer-block {
  min-width: 10rem;
  padding: 0.625rem;
  border-radius: 0.625rem;
  border: none;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.125rem;
  outline: 0.125rem solid rgba(255, 255, 255, 0);
  outline-offset: -0.125rem;

  &.clickable {
    cursor: pointer;
    will-change: transform;

    &:not(:disabled) {

      &:hover,
      &:focus-visible {
        background-color: var(--bs-gray-100);
      }

      &:focus-visible {
        outline-color: var(--bs-gray-400);
        transition: all 0.05s ease;
      }

      &:active {
        background-color: var(--bs-gray-200);
        transform: scale(0.975);
      }
    }
  }

  &:disabled {
    opacity: 0.35;
    cursor: default;
  }

  &.disabled-block {
    opacity: 0.35;
    cursor: default;
  }

  .label {
    font-size: 0.625rem;
    font-weight: 400;
    letter-spacing: 0.063rem;
    line-height: 1;
    margin-bottom: 1.25rem;
    text-transform: uppercase;
    font-family: var(--inter-font-family);
    text-align: left;
    color: #000;
    position: relative;
    display: flex;
    align-items: center;
    min-height: 1rem;

    .status-flag {
      position: absolute;
      background-color: #f8a903;
      color: #000;
      padding: 0.125rem 0.25rem;
      border-radius: 0.125rem;
      font-family: var(--inter-font-family);
      font-size: 0.625rem;
      font-weight: 500;
      letter-spacing: 0.047rem;
      text-transform: uppercase;
      left: 100%;
      margin-left: 0.313rem;
    }
  }

  .value {
    display: flex;
    font-weight: 500;
    line-height: 1.15;
    font-size: 1.25rem;
    font-family: var(--inter-font-family);
    text-align: left;
    color: #000;
    user-select: text;
  }

  sup {
    top: 0.5rem;
    font-size: 0.6em;
  }
}

.footer-block {
  user-select: none;

  &.hidden-footerblock {
    position: relative;
    overflow: hidden;
    background-color: var(--bs-gray-100);

    .label {
      z-index: 1;
    }

    .value {
      z-index: -1;
    }

    &::after {
      content: "Hidden";
      inset: 0;
      background-color: #fff;
      position: absolute;
      display: flex;
      align-items: flex-end;
      padding: 0.625rem;
      font-weight: 500;
      line-height: 1.15;
      font-size: 1.25rem;
      font-family: var(--inter-font-family);
      text-align: left;
      color: rgba(0, 0, 0, 0.3);
      user-select: none;
      transition: clip-path 0.4s cubic-bezier(0, 1.1, 0.49, 1.05), padding 0.6s cubic-bezier(0, 1.1, 0.49, 1.05);
      clip-path: path("m 164 80 v 66 h -3.69 c -15.0675 -34.4 -72.2215 -56.32 -91.635 -63 c -30.7807 4.04 -53.2795 11.83 -68.675 23.28 v -125.28 h 164 z"
        );
    }

    &:hover,
    &:focus-visible {
      &::after {
        padding-bottom: 1.75rem;
        clip-path: path("m 164 0 v 66 h -3.69 c -15.0675 -34.4 -72.2215 -56.32 -91.635 -63 c -30.7807 4.04 -53.2795 11.83 -68.675 23.28 v -125.28 h 164 z"
          );
      }
    }
  }
}

.close-input-icon-btn {
  border: none;
  background: transparent;
  opacity: 1 !important;
}

.no-data-cart-sec {
  grid-column: 1 / -1;
}

.cart-add-product-back-btn {
  margin-bottom: 3.375rem;
  position: absolute;
  right: 0;

  @media (min-width: 600px) {
    left: -4.625rem;
    right: unset;
  }

  button {
    position: relative;
    left: 0;
    top: 0;
  }

  .back-next-btn-small-left {
    width: 3.125rem;
    height: 3.125rem;
  }
}

.new-container.MuiContainer-root {
  max-width: 1520px;
  width: 70%;

  @media only screen and (min-width: 768px) {
    width: 80%;
  }

  .position-back-return {
    color: black;
    position: absolute;
  }

  .return-product-page-back-btn.MuiButtonBase-root {
    left: -5.625rem;
    top: 0;
    width: 3.75rem !important;
    height: 3.75rem !important;
    border-radius: 1.25rem !important;
    background-color: $bgColor !important;
  }
}

.schedule-section {
  height: auto;
  padding: 0.813rem 1.25rem;
}

.schedule-list-div {
  padding: 1.375rem 0.625rem;
  margin-top: 0;
}

.schedule-list-styling {
  font-size: 1.25rem !important;
}

.common-sup {
  font-size: 0.6em;
}

.dollar-sup {
  font-size: 0.58em;
}

.font-18 {
  font-size: 1.125rem;
}

.mt-0 {
  margin-top: 0;
}

.ml-0 {
  margin-left: 0 !important;
}

.mb-0 {
  margin-bottom: 0;
}

.mr-0 {
  margin-right: 0;
}

.drawer-heading-spacing {
  padding-top: 1.25rem;
}

.search-section-styling {
  border-color: transparent;
  background-color: #eeeeee;
}

.pl-0 {
  padding-left: 0.4rem !important;
}

span,
button,
input,
div,
p,
h1,
h2,
h3,
h4,
h5,
h6,
a {
  font-family: var(--inter-font-family) !important;
}

.search-product-suggestion-wrapper {
  max-width: 50rem;
  margin: auto;
  display: block;

  @media (min-width: 768px) {
    display: grid;
  }

  .product-search-suggestions {
    grid-column: 1 / 14;
  }
}

.main-product-page {
  z-index: 1;
}

.sidenav-group-wrapper {
  z-index: 11;
}

.print-option-section {
  border: 0.063rem solid #dddddd;
  padding: 1.25rem 0.938rem;
  border-radius: 1.563rem;
  margin-bottom: 1.25rem;

  .print-option-title,
  .print-option-subtitle {
    color: #000;
    line-height: normal;
  }

  .print-option-title {
    font-size: 1.125rem;
    font-weight: 500;
    display: flex;
    align-items: center;
  }

  .print-option-subtitle {
    font-size: 0.875rem;
    font-weight: 400;
    max-width: 18.75rem;
    margin-top: 0.5rem;
  }

  .print-btn-sec {
    button {
      color: #000000;
      border-radius: 0.938rem;
      font-size: 1rem;
    }
  }

  .print-option-info {
    margin-left: 0.625rem;
  }
}

.print-option-header {
  margin-bottom: 1.875rem;
}

.hide-price-checkbox {
  margin-top: 1.25rem;
  margin-bottom: 0.9375rem;

  .checkbox-focus {
    &:focus-within {
      outline: none !important;
      margin: 0rem !important;
    }
  }

  .MuiCheckbox-colorSecondary.Mui-checked:hover,
  .MuiIconButton-colorSecondary:hover {
    background-color: #00000012;
  }

  .MuiButtonBase-root {
    padding: 0rem 0.5625rem 0rem 0.5625rem;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #000;
  }

  label {
    span {
      color: #000;
      font-weight: 600;
    }
  }
}

.mark-quote-container {
  display: flex;
  align-items: center;
}

.mark-quote-checkbox {

  .MuiCheckbox-colorSecondary.Mui-checked:hover,
  .MuiIconButton-colorSecondary:hover {
    background-color: #00000012;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #000;
  }

  label {
    span {
      color: #000;
      font-weight: 600;
    }
  }
}

.set-expiry-btn {
  text-decoration: underline !important;
  text-transform: none !important;
  color: black !important;
  background: transparent !important;
}

.cart-with-total-sec {
  padding-bottom: 0.625rem;
}

.print-ticket-circular-progress {
  margin-left: 0.625rem;
}

.main-product-page.customer-info-page-styling {
  overflow-y: unset;
  padding-top: 0;
}

.redirect-backorder-id {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.cart-loading {
  grid-column: 1 / -1;
}

.new-pos-footer-style.cart-footer-wrapper {
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  -webkit-column-gap: 1rem;
  grid-column-gap: 1rem;
  column-gap: 1rem;
  max-width: 95rem;
  width: 100%;

  @media only screen and (min-width: 1200px) {
    width: calc(80% - 45px);
  }

  justify-self: center;
  padding: 0;

  .cart-footer {
    grid-column: 1 / 16;
  }
}

.new-container.MuiContainer-root {
  width: 100%;
  padding: 0;

  @media (min-width: 768px) {
    width: 80%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.modal-btn-content-width.common-modal-btn-style {
  .MuiButtonBase-root {
    width: fit-content !important;
    height: fit-content !important;
  }
}

.shipping-free-label {
  background-color: #eaf9ef;
  padding: 0.188rem;
  border-radius: 0.25rem;
  color: #1d8036;
  margin-left: 0.375rem;
  font-weight: 600;
}

.gpm-custom-label {
  color: $white;
  padding: 0.188rem;
  border-radius: 0.25rem;
  background-color: $dark-green;
  margin-left: 0.375rem;
  font-weight: 600;
}

.modal-container-styling.MuiContainer-root {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.pos-drawer-container {
  .modal-box {
    .modal-container-styling.MuiContainer-root {
      padding-left: 1.35rem;
      padding-right: 0.625rem;
    }
  }
}

.pos-drawer-container {
  .payment-method-model-bottom {
    padding-bottom: 50vh;
  }

  .modal-container-styling.MuiContainer-root {
    padding-left: 1.35rem;
    padding-right: 1.4rem;
  }
}

.frequently-used-schedule:hover {
  cursor: pointer;
  background-color: $bright-Gray;
  border-radius: 0.625rem;
}

.frequently-used-schedule-focus:has(.saved-jobname:focus-visible) {
  outline: none !important;
  box-shadow: 0 0 0 0.125rem #fff, 0 0 0 0.25rem #000 !important;
  background-color: var(--bs-gray-50) !important;
  border-radius: 0.625rem !important;
}

.new-container,
.cart-container,
.large-screen-container-styling,
.new-order-main-section,
.cart-wrapper,
.return-cart-product-container,
.return-cart-hide-scroll,
.search-return-customer-modal,
.search-return-product-modal,
.return-successfull-container,
.main-drawer-classes,
.set-backorder-code-modal-container {

  /* Apply visible scrollbar */
  ::-webkit-scrollbar {
    width: 0rem !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent !important;
  }
}

.input-focus-styling.MuiFormControl-root {
  .MuiInputBase-root {
    padding: 0.7rem !important;
    border-radius: 0.625rem !important;
  }

  .Mui-focused {
    background-color: #fff !important;
    outline: 0.13rem solid var(--bs-black) !important;

  }
}

.text-underline {
  text-decoration: underline;
}

.light-gray-text {
  color: #999999 !important;
}

.btn-font-600 span {
  font-weight: 600 !important;
}

.common-big-input.MuiFormControl-root .MuiInputBase-root {
  padding: 1.25rem !important;
}

.disabled-checkbox svg.MuiSvgIcon-root {
  fill: rgba(0, 0, 0, 0.38);
}

.disable-section,
.disable-section label {
  cursor: no-drop !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.black-to-white-color-change {
  -webkit-filter: invert(100%);
  /* Safari/Chrome */
  filter: invert(100%);
}

.odd-even-btn {
  .small-btn {
    max-width: 15.625rem;
  }

  .large-btn {
    max-width: 25rem;
  }
}

.masked-phone-number-outline-input {
  background-color: $white-color !important;
  border: 0.125rem solid #000000 !important;
  border-radius: 0.625rem !important;
}

.wrap-anywhere {
  overflow-wrap: anywhere;
}

.sec-with-green-bg {
  background-color: #eaf9ee;
  padding: 0.75rem;
  border-radius: 0.375rem;
  margin-top: 3.125rem;
  display: flex;
  align-items: center;
  position: relative;

  img {
    height: 1.25rem;
    width: 1.25rem;
    margin-right: 0.625rem;
  }

  .green-sec-close-icon {
    position: absolute;
    right: 0.625rem;
  }
}

.word-break-all {
  word-break: break-all;
}

.direction-column {
  flex-direction: column;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.change-amount {
  font-size: 2rem;
  margin-top: 0.9375rem;
}

.change-amount-disabled {
  opacity: 0.65;
}

.job-name-limit {
  // display: block; commenting for now as per the Xd design 
  // white-space: nowrap;
  max-width: 23.75rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.custom-order-label {
  background-color: $white;
  padding: 0.188rem;
  border-radius: 0.25rem;
  color: $light-green;
  margin-left: 0.375rem;
  font-weight: 600;
  letter-spacing: 0.0088rem;
}

.pos-icon-back-btn {
  height: 3.75rem;
  width: 3.75rem;
  min-width: 3.75rem;
  max-width: 3.75rem;
}

.ship-select-btn {
  height: 3.75rem;
  min-width: 7.5rem;
  max-width: 7.5rem;
  width: 7.5rem;

  &:disabled {
    cursor: default !important;
  }
}

.new-address-cancel-btn {
  height: 4rem;
  min-width: 18rem;
  max-width: 18rem;
  width: 18rem;
}

.add-ship-address-continue-btn {
  height: 4rem;
  min-width: 22rem;
  max-width: 22rem;
  width: 22rem;
}

.tax-file-add-doc-btn {
  height: 2.75rem;
  min-width: 12rem;
  max-width: 12rem;
  width: 12rem;
}

.new-address-go-back-btn {
  height: 4rem;
  min-width: 16rem;
  max-width: 16rem;
  width: 16rem;
}

.new-address-preview-btn {
  height: 4rem;
  min-width: 24rem;
  max-width: 24rem;
  width: 24rem;
}

.complete-return-btn {
  width: 13rem;
  min-width: 13rem;
  max-width: 13rem;
  height: 2.75rem;
}

.return-cart-new-order {
  width: 14.75rem;
  min-width: 14.75rem;
  max-width: 14.75rem;
  height: 2.75rem;
}

.modal-remove-btn {
  width: 8rem;
  min-width: 8rem;
  max-width: 8rem;
  height: 2.75rem;
}

.custom-size-5 {
  width: 5rem;
  min-width: 5rem;
  max-width: 5rem;
  height: 2.75rem;
}

.custom-size-7 {
  width: 7rem;
  min-width: 7rem;
  max-width: 7rem;
  height: 2.75rem;
}

.change-doc-btn {
  width: 6rem;
  min-width: 6rem;
  max-width: 6rem;
  height: 2.75rem;
}

.add-to-cart-modal-btn {
  width: 10rem;
  min-width: 10rem;
  max-width: 10rem;
  height: 3rem;
}

.change-price-set-btn {
  width: 10.875rem;
  min-width: 10.875rem;
  max-width: 10.875rem;
  height: 2.75rem;
}

.product-update-qty-btn {
  width: 11rem;
  min-width: 11rem;
  max-width: 11rem;
  height: 3rem;
}

.facility-drawer {
  padding-top: 22rem;
}

.disabed-cursor-not-allowed {
  cursor: not-allowed !important;
}

.card-purchase-order {
  display: flex;
  flex-direction: column;
  max-width: fit-content;
  width: 8.75rem;
  border: 0.125rem solid white;
  /* Border turns black when focused within */
  border-radius: 0.625rem;
  cursor: pointer;
  height: 100%;
  position: relative;
  padding: 0.625rem;

  &:hover {
    background-color: var(--bs-gray-100);
  }

  &:focus-within {
    border: 0.125rem solid black;
    /* Border turns black when focused within */
    background-color: white;

    /* Background turns white when focused within */
    span {
      color: black;
    }
  }

  span {
    font-size: 0.625rem;
    font-weight: 400;
    letter-spacing: 0.063rem;
    line-height: 1;
    text-transform: uppercase;
    font-family: var(--inter-font-family);
    text-align: left;
    color: var(--bs-gray-800);
    padding: 0rem;
  }

  .span-select {
    position: absolute;
    left: 10px;
    top: 10px;
  }

  input {
    outline: none;
    /* outline-none */
    background-color: transparent;
    /* bg-transparent */
    border: none;
    font-size: 0.875rem;
    padding: 0rem;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 0rem;
  }

  select {
    background-color: transparent !important;
    background: none;
    font-size: 0.875rem;
    line-height: 1rem;
    min-height: 46px;
    padding: 0.625rem;
    padding-top: 0.5rem !important;
    position: relative;
    z-index: 1;
    display: flex;
    height: 100%;

    &::-ms-expand {
      display: none;
    }
  }

}

.exempt-custom-label {
  color: $black-color;
  padding: 0.188rem;
  border-radius: 0.25rem;
  background-color: $dark-yellow;
  margin-left: 0.375rem;
  font-weight: 600;
}

.card-purchase-order-select {
  display: flex;
  flex-direction: column;
  max-width: fit-content;
  width: 8.75rem;
  border: 0.125rem solid white;
  /* Border turns black when focused within */
  border-radius: 0.625rem;
  cursor: pointer;
  height: 100%;
  position: relative;
  padding: 0;

  &:hover {
    background-color: var(--bs-gray-100);
  }

  &:focus-within {
    border: 0.125rem solid black;
    /* Border turns black when focused within */
    background-color: white;

    /* Background turns white when focused within */
    span {
      color: black;
    }
  }

  span {
    font-size: 0.625rem;
    font-weight: 400;
    letter-spacing: 0.063rem;
    line-height: 1;
    text-transform: uppercase;
    font-family: var(--inter-font-family);
    text-align: left;
    color: var(--bs-gray-800);
    padding: 0rem;
    position: absolute;
    left: 10px;
    top: 10px;
  }

  select {
    background-color: transparent !important;
    background: none;
    font-size: 0.875rem;
    line-height: 1rem;
    min-height: 46px;
    padding: 0.625rem;
    padding-top: 0.5rem !important;
    position: relative;
    z-index: 1;
    display: flex;
    height: 100%;

    &::-ms-expand {
      display: none;
    }
  }

  .warehouse-name {
    text-transform: none;
    line-height: 1rem;
    font-weight: 500;
    text-align: left;
    max-width: 10rem;
    font-size: 0.875rem;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--bs-gray-600) !important;
    letter-spacing: 0rem;
    top: 2.5rem;
  }
}

.price-show-min-width {
  @media (min-width: 1280px) {
    min-width: 7.125rem;
  }
}

.review-refund-return-btn {
  max-width: 208px;
  height: 44px;
}

.review-refund-modal-container {
  .MuiDialog-paperWidthSm {
    max-width: 34.375rem;
  }

  .MuiDialog-container {
    .MuiDialog-paper {
      width: 100%;
      min-width: 36.875rem;
      border-radius: 1.25rem;

      .MuiDialogTitle-root {
        padding: 15px 6px 0px 20px;

        .MuiTypography-root {
          .MuiBox-root {
            .MuiTypography-root {
              justify-content: flex-start;
              font-size: 1.25rem;
              font-weight: 600;
              letter-spacing: 0px;
              color: $black-color;
              opacity: 1;
            }

            .MuiButtonBase-root {
              margin-right: 1.25rem;

              &:hover {
                background-color: #f1f1f1 !important;

                .MuiSvgIcon-root {
                  fill: $black-color;
                  opacity: 1;
                }
              }

              .MuiSvgIcon-root {
                fill: $gray;
                opacity: 1;
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }
    }
  }
}