.change-password-dialog {
    display: flex;
    flex-direction: column;
    width: 500px;

    @media (max-width: 800px) {
        width: 100%;
        padding-bottom: 20px;
    }

    .dialog-header {
        padding: 20px;

        .dialog-title {
            font-size: 24px;
            font-family: "Roboto Slab" !important;
            font-weight: 400;

            @media (max-width: 800px) {
                font-size: 1.25rem;
                margin-bottom: 10px;
            }
        }
    }

    .dialog-body {
        display: flex;
        flex-direction: column;
        padding: 20px;
        padding-top: 0;
        gap: 30px;
    }

    .dialog-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        padding: 20px;

        @media (max-width: 800px) {
            justify-content: space-between;
        }
    }
}
