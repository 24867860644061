.tax-files-sec {
  margin-top: 1.25rem;
  padding-top: 1.875rem;
  border-top: 0.125rem solid #000;

  .tax-uploaded-files-info-sec {
    border: 0.0625rem solid #dddddd;
    border-radius: 1.25rem;
    margin-top: 1.5625rem;

    .tax-uploaded-files-div {
      border-bottom: 0.0625rem solid #dddddd80;
      padding: 0.8125rem 0.8125rem;

      &:last-child {
        border-bottom: none;
      }

      .tax-uploaded-file-name {
        font-size: 1.125rem;
        font-weight: 500;
        color: #000000;
      }

      .tax-uploaded-file-date {
        font-size: 0.625rem;
        font-weight: 500;
        color: #999999;
      }
    }
  }
}

// start

.btn.tax-file-upload-btn {
  font-size: 16px;
  padding: 0.75rem 0.9375rem;

  img {
    margin-right: 0.375rem;
  }
}

.tax-docs {
  border-top: 0.125rem solid var(--bs-black);
  margin-top: 1.25rem;
  padding-top: 1.875rem;

  .td-block {
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
  }

  .td-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;

    .td-header-copy {
      display: flex;
      flex-direction: column;
      gap: 0.3125rem;

      h3 {
        font-family: var(--inter-font-family);
        font-weight: 600;
        font-size: 20px;
        letter-spacing: -0.0125rem;
      }

      p {
        font-size: 0.875rem;
        font-family: var(--inter-font-family);
      }

      span {
        text-align: left;
        font: normal normal normal 0.8125rem/0.4375rem Inter !important;
        letter-spacing: 0rem;
        color: #000000;
        opacity: 1;
      }
    }

    .td-upload-btn {
      border: none;
      background: none;
      background-color: var(--bs-black);
      color: #fff;
      fill: #fff;
      cursor: pointer;
      padding: 0.75rem 0.9375rem;
      padding-right: 1.25rem;
      display: flex;
      align-items: center;
      gap: 0.625rem;
      border-radius: 0.9375rem;
      will-change: transform;

      svg {
        height: 1.125rem;
        width: 1.125rem;
      }

      span {
        font-family: var(--inter-font-family);
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.0125rem;
      }

      &:hover {
        background-color: var(--bs-gray-900);
      }

      &:focus-visible {
        outline: 0.125rem solid var(--bs-gray-500);
        outline-offset: 0.125rem;
      }

      &:active {
        transform: scale(0.975);
      }
    }
  }

  .td-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 0.0625rem solid var(--bs-gray-300);
    border-radius: 1.25rem;
    padding: 0.625rem;
    margin-bottom: 1.875rem;

    &:focus-within {
      outline: 2.08px solid var(--bs-black) !important;
    }

    &:empty {
      border: 0.125rem dashed var(--bs-gray-300);
      padding: 3.75rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      gap: 1.25rem;

      &::before {
        content: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='100' height='60' viewBox='0 0 100 60'%3E%3Cdefs%3E%3CradialGradient id='a' cx='0.887' cy='1.71' r='0.832' gradientTransform='translate(0 -0.953) scale(1 1.667)' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23ddd'/%3E%3Cstop offset='1' stop-color='%23f9f9f9'/%3E%3C/radialGradient%3E%3C/defs%3E%3Cg transform='translate(573 1159)'%3E%3Crect width='100' height='60' rx='5' transform='translate(-573 -1159)' fill='url(%23a)'/%3E%3Ccircle cx='10' cy='10' r='10' transform='translate(-563 -1149)' fill='%23aaa'/%3E%3Crect width='40' height='2' rx='1' transform='translate(-538 -1146)' fill='%23aaa'/%3E%3Crect width='30' height='2' rx='1' transform='translate(-538 -1136)' fill='%23aaa'/%3E%3C/g%3E%3C/svg%3E");
      }

      &::after {
        content: "Drop files above to Upload";
        color: var(--bs-black);
        font-size: 0.875rem;
      }
    }

    .td-item {
      width: 100%;
      padding: 0.75rem 1.25rem;
      padding-left: 0.625rem;
      color: inherit;
      text-decoration: none;
      position: relative;
      will-change: transform;
      display: flex;
      align-items: center;
      cursor: pointer;
      border-bottom: 0.0625rem solid var(--bs-gray-200);
      justify-content: start;

      &:last-child {
        border-bottom: none;
      }

      &:not(.uploading):hover {
        background-color: var(--bs-gray-200);

        .item-info {
          .item-timestamp {
            color: var(--bs-black);
          }
        }

        &::after {
          content: "";
          position: absolute;
          right: 1.25rem;
          width: 1.5rem;
          height: 1.5rem;
          background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M0,0H24V24H0Z' fill='none'/%3E%3Cpath d='M5,13H16.17l-4.88,4.88a1.008,1.008,0,0,0,0,1.42,1,1,0,0,0,1.41,0l6.59-6.59a1,1,0,0,0,0-1.41L12.71,4.7A1,1,0,1,0,11.3,6.11L16.17,11H5a1,1,0,0,0,0,2Z' transform='translate(0 0)'/%3E%3C/svg%3E");
        }
      }

      &:not(.uploading):active {
        background-color: var(--bs-gray-300);
      }

      &:not(.uploading):focus-visible {
        outline: 0.125rem solid var(--bs-gray-500);
        outline-offset: 0.125rem;
      }

      &.uploading {

        &:focus-visible {
          outline: none;
        }

        .item-info {
          .item-timestamp {
            color: var(--bs-black);
          }
        }
      }

      &:last-child::before {
        display: none;
      }

      .item-info {
        display: flex;
        flex-direction: column;
        gap: 0.3125rem;
        font-family: var(--inter-font-family);

        .item-name {
          font-size: 16px;
        }

        .item-timestamp {
          color: var(--bs-gray-600);
          font-size: 0.625rem;
          font-weight: 500;
          letter-spacing: 0.0625rem;
          text-transform: uppercase;
        }

        .item-timestamp-gray {
          text-align: left;
          font: normal normal normal 0.625rem/0.75rem Inter;
          letter-spacing: 0.0625rem;
          color: var(--bs-gray-600);
          text-transform: uppercase;
          opacity: 1;
        }
      }
    }
  }
}

.tax-modal-upload-btn {
  height: 2.75rem;
  min-width: 6.5rem;
  max-width: 6.5rem;
  width: 6.5rem;
}