@import "apps/pos/assets/css/variable.scss";

.storage-date-picker {
  .MuiFormControl-root {
    width: 100%;
    .MuiInputBase-root,
    fieldset {
      border-radius: 0.625rem;
      border-color: $black-color !important;
    }
    input {
      padding: 0;
      height: auto;
      font-weight: 600;
    }
    .MuiInputBase-root, fieldset {
      border: none !important;
    }
    .MuiInputBase-root {
      background-color: #f1f1f1; 
    }
    .MuiButtonBase-root {
      margin-right: 0.375rem;
      svg {
        color: $black-color;
      }
    }
  }
}
.storage-date-picker-new{
 width: 100%;
 border-color: $black-color !important;
 input {
   padding: 0;
   height: auto;
   font-weight: 600;
   background-color: #f1f1f1; 
   border: none !important;
   border-radius: 0.625rem;
    }
}

.modal-input-bottom-spacing {
  margin-bottom: 1.875rem;
}
.set-storage-modal-container {
  .modal-header {
    border-bottom: none;
    padding: 1.875rem 1.875rem 0.938rem !important;
    align-items: flex-start;
  }
  .MuiDialogContent-root {
    padding: 0.938rem 1.875rem 0.938rem !important;
  }
  .MuiDialogActions-root {
    padding: 0.938rem 1.875rem 1.875rem !important;
  }
}
.undo-delivery-modal-footer {
  width: 100%;
  justify-content: space-between;
}
.small-page-heading span {
  font-size: 1.375rem;
}
.storage-data {
  display: flex;
  border-bottom: 0.063rem solid #dddddd;
  padding: 0.75rem 0;
  .storage-data-left-sec {
    width: 45%;
    .storage-data-title {
      font-weight: 500;
    }
  }
  .storage-data-right-sec {
    width: 55%;
    .storage-first-data {
      font-weight: 600;
    }
    .storage-second-data {
      color: #555555;
    }
  }
}
.storage-data-modal-container .MuiDialog-paper {
  max-width: 40rem !important;
  width: 100% !important;
}

.put-storage-modal-container {
  .modal-header {
    padding-bottom: 0 !important;
  }
}
.collapse-image {
  opacity: 0.5;
  cursor: pointer;
  padding: 0.375rem;
  display: flex;
  align-items: center;
  &:hover {
    background-color: $bright-Gray;
    border-radius: 1.563rem;
  }
  img {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.set-storage-type-section-padding {
  padding-bottom: 1.25rem;
}

.put-in-storage-modal-btn{
  min-width: 12rem;
  max-width: 12rem;
  height: 2.75rem;
}

.set-storage-back-btn{
  min-width: 4.813rem;
  max-width: 4.813rem;
  height: 2.75rem;
}

.undo-delivery-modal-back-btn{
  min-width: 6.25rem;
  max-width: 6.25rem;
  height: 2.75rem;
}
