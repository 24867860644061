.return-to-header {
    display: none;

    @media only screen and (min-width: 600px) {
        display: grid;
        grid-template-columns: 200px repeat(2, 1fr) 50px;
        gap: 1px;
        padding: 10px 0;
        max-width: 34.375rem;
    }

    span {
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #000;
        font-family: var(--inter-font-family);
        padding-left: 10px;
    }
}

.return-to-line-items {
    display: flex;
    flex-direction: column;
    gap: 5px;
    // height: 2.813rem;
}

.return-to-line-add-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: var(--bs-gray-100);
    border: none;
    cursor: pointer;
    padding: 8px 10px;
    transition: transform 1s cubic-bezier(0, -0.01, 0, 1.31);
    will-change: transform;
    outline: none;

    &:hover,
    &:focus-visible {
        background-color: var(--bs-gray-300);
    }

    &:focus-visible {
        box-shadow: inset 0 0 0 2px #000;
    }

    &:active {
        transform: scale(0.975);
    }

    &:disabled {
        opacity: 0.6;
    }
}