.grid-wrapper {
  display: grid;
  grid-column-gap: 1em;
  grid-template-columns: repeat(13, 1fr);
  align-items: center;
  text-align: center;
}
.box {
  column-count: 1;
}
.sales-history-table-header {
  font-size: 0.625rem;
  font-weight: 500;
  padding: 0.9375rem 0;
  opacity: 1;
  letter-spacing: 0.0313rem;
}
.table-body-col {
  font-size: 0.875rem;
  color: #000000;
  padding: 0.9375rem 0;
  text-align: left;
}
.inv-date-col {
  padding: 15px 20px;
}
.table-body-row,
.table-head-row {
  display: grid;
  grid-row-gap: 1em;
  grid-template-columns: repeat(13, 1fr);
}
.table-head-row,
.sales-history-table-data,
.sales-history-header-filter-sec {
  border-bottom: 1px solid #dddddd;
}
.sales-history-header-filter-sec {
  display: flex;
  padding: 18px 20px;
  .filter-icon-section {
    .filter-icon {
      width: 12px;
    }
  }
  .sales-history-header-filter-info {
    background-color: #333333;
    color: #fff;
    width: fit-content;
    padding: 4px 8px;
    border-radius: 20px;
    line-height: normal;
    font-size: 14px;
    margin-left: 20px;
    img {
      height: 10px;
      width: 10px;
      margin-right: 6px;
    }
  }
}

.sales-history-user-info {
  width:100%;
  border: 0.065rem solid #dddddd;
  max-width: 40rem;
  border-radius: 0.9375rem;
  padding: 0.625rem;
  .sales-user-main-name {
    font-size: 14px;
    color: #000;
    font-weight: 600;
  }
  .sales-user-id {
    font-size: 12px;
    color: #000;
    font-weight: 500;
  }
}
.sales-history-table-section {
  border: 1px solid #dddddd;
  border-radius: 0.9375rem !important;
}
.sales-user-icon {
  margin-right: 15px;
  width: 50px;
  height: 50px;
}
.download-csv-icon {
  padding: 0.9375rem !important;
  background-color: #f1f1f1;
  border-radius: 15px;
  font-size: 1rem;
  font-weight: 500;
  color: #000;
  &:hover {
    color: #000;
  }
  img {
    height: 18px;
    width: 18px;
  }
}
.table-body-row:nth-child(even) {
  background-color: #F9F9F9;
}

.customer-col-width{
  width: 15.625rem;
}

.customer-code{
  color : #888888;
  font-size: 0.75rem;
}