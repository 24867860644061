@import "../../assets/css/variable.scss";

.handling-fee-section {
  .handling-input-main-div {
    .input-title {
      font-size: 1rem;
      font-weight: 500;
      letter-spacing: 0px;
      color: $black-color;
      opacity: 1;
    }
    .handling-input {
      margin: 0.3125rem 0 0.125rem;
      .MuiInputBase-root {
        padding: 8px 24px;
        .MuiInputAdornment-root {
          .MuiTypography-root {
            color: $black-color;
            opacity: 1;
          }
        }
      }
    }
  }
  .save-cancel-button-section {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 0.625rem;
    margin: 1.5625rem 0 0.9375rem;
    .use-default-btn {
      font-weight: 500;
      letter-spacing: -0.32px;
    }
  }
}
