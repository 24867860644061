@import "apps/pos/assets/css/variable.scss";

.set-send-message-modal-container {
    .MuiOutlinedInput-root {
        padding: 0.625rem !important;
    }
    .modal-header {
        display: flex;
        justify-content: space-between;
        padding: 0.938rem 1.7rem !important;
        border-bottom: none;
        .clear-icon {
            font-size: 2.5rem;
            padding: 0.625rem;
            cursor: pointer;
            &:hover {
                background-color: $bright-Gray;
                border-radius: 1.563rem;
            }
        }
        .button-focus {
            &:focus-visible {
                border-radius: 1.563rem !important;
                outline: 0.13rem solid var(--bs-gray-600) !important;
            }
        }
    }
    .MuiDialog-container {
        .MuiDialog-paper {
            width: 46.375rem !important;
            max-width: 700px !important;
            height: auto;
            border-radius: 1.25rem;
        }
        .MuiDialogActions-root {
            padding: 1.5rem;
            .add-to-return-cart-button-error {
                opacity: 0.3;
                cursor: not-allowed;
            }
        }
    }

    .send-message {
        display: flex;
        flex-direction: column;
        gap: 15px;
        &-email {
            display: flex;
            flex-direction: column;
            .email-input {
                div:first-child {
                    height: 2.25rem;
                    border-radius: 10px !important;
                }
                .MuiFilledInput-input {
                    padding: 0.75rem 0.75rem 0.75rem;
                }
            }
        }
        &-subject {
            display: flex;
            flex-direction: column;
            border-radius: 15px;
            .subject-input {
                .MuiFilledInput-root {
                    height: 2.25rem;
                    border-radius: 10px !important;
                }
                .MuiFilledInput-input {
                    padding: 0.75rem 0.75rem 0.75rem;
                }
            }
        }
        &-message {
            display: flex;
            flex-direction: column;
            .message-input {
                .MuiFilledInput-root {
                    border-radius: 10px !important;
                }
                .MuiFilledInput-input {
                    height: 12.5rem;
                }
            }
        }
    }
}
.new-note-input {
    font-size: 1rem;
    width: 100%;
    padding: 0.9375rem;
    min-height: 240px;
    font-family: inherit;
    border-radius: 0.625rem;
    background-color: $bright-Gray;
    transition:
        box-shadow 0.1s ease-out,
        background-color 0.1s ease-out;
}
