.order-first-table-row {
  max-width: 20%;
  vertical-align: middle;
}
.order-second-table-row {
  max-width: 20%;
  vertical-align: middle;
}
.order-third-table-row {
  max-width: 30%;
  vertical-align: middle;
}
.order-fourth-table-row {
  max-width: 30%;
  vertical-align: middle;
  align-items: center;
}
.customer-order-data-container .MuiPaper-root.MuiDialog-paper {
  height: 90vh;
  max-height: 54.5rem;
}
.put-storage-section {
  opacity: 0.3;
}
.put-storage-section {
  align-items: center;
  img {
    height: 1.125rem;
    width: 1.125rem;
  }
  .put-storage-title {
    color: #555555;
    font-size: 0.875rem;
    text-decoration: underline;
    font-weight: 600;
    white-space: nowrap;
  }
}
.order-info-table-title {
  padding: 1.4rem 0.625rem 0.3rem !important;
}
.orders-small-screen-titles {
  display: block;
  @media only screen and (min-width: 900px) {
    display: none;
  }
}

.order-info-put-in-storage-disbaled {
  cursor: not-allowed !important;
}

.put-in-storage-button {
  letter-spacing: 0.01071em;
  width: 100%;
  &:focus-visible {
    div {
      outline: 0.13rem solid var(--bs-black) !important;
      outline-offset: 0.0625rem;
      span {
        color: black !important;
      }
    }
  }
}

.no-shipments-found {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  height: 66vh;
}
