.refund-select {
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    position: relative;

    &:not(:disabled) {
        &:hover {
            cursor: pointer;
        }
    }

    .refund-select-label-icon,
    .refund-select-value-icon {
        pointer-events: none;
        user-select: none;
    }

    .refund-select-label-group {
        display: flex;
        align-items: center;
        gap: 0.125rem;

        .refund-select-label {
            pointer-events: none;
            text-transform: uppercase;
            font-size: 10px;
            letter-spacing: 0.05rem;
            line-height: 1;
        }
    }

    .refund-select-value-group {
        display: flex;
        flex-direction: row;
        gap: 0.375rem;
        align-items: center;
        line-height: 1;

        .refund-select-value-icon {
            &.cash {
                color: #217f39;
            }

            &.card {
                color: #2b62ca;
            }

            &.check {
                color: #9043b6;
            }

            &.account {
                color: #a2845e;
            }

            &.store-credit {
                color: #c1384a;
            }
        }

        .refund-select-value {
            font-weight: 500;
        }
    }

    .refund-select-dropdown {
        position: absolute;
        inset: 0;
        cursor: pointer;
        opacity: 0;
        user-select: none;
    }
}