@import "../../../../constants/colors.scss";
.reason-block {
    border-bottom: 0.063rem solid $bright-Gray;
    .reason-value {
        position: relative;
        padding: 0.625rem 2.2rem 0.625rem 0.438rem;
    }
    .reason-label {
    font-size: 0.875rem;
    color: $black-color;
    padding: 0.625rem 2.2rem 0.625rem 0.438rem;
    text-transform: uppercase;
    font: normal normal normal 0.625rem/1rem Inter;
    letter-spacing: 0.0625rem;
    opacity: 1;
}
}