.btn {
    --color: #000;
    --background: var(--bs-secondary);
    --transform-scale: 0.95;
    --border-radius: 10px;
    --icon-size: 20px;
    --padding: 8px 10px;
    --label-size: 16px;
    --disabled-opacity: 0.25;
    border: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    background-color: var(--background);
    color: var(--color);
    border-radius: var(--border-radius);
    padding: var(--padding);
    font-weight: 600;
    will-change: transform;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    outline: 2px solid rgba(255, 255, 255, 0);
  }
  
  .btn.hidden {
    visibility: hidden;
    pointer-events: none;
  }
  
  .btn .label-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  .btn .btn-label {
    font-family: var(--inter-font-family);
    font-size: var(--label-size);
    white-space: nowrap;
  }
  
  .btn.primary {
    color: #fff;
    background-color: #000;
    --disabled-opacity: 0.1;
    &:hover{
        color: #fff;
    }
  }
  
  .btn:disabled {
    opacity: var(--disabled-opacity);
  }
  
  .btn:not(:disabled) {
    cursor: pointer;
  }
  
  .btn:not(:disabled):hover, .btn:not(:disabled):focus-visible {
    -webkit-filter: brightness(0.9) contrast(1.1);
            filter: brightness(0.9) contrast(1.1);
  }
  
  .btn:not(:disabled):focus-visible {
    outline-color: var(--bs-gray-400);
    outline-offset: 2px;
    -webkit-transition: all 0.05s ease;
    transition: all 0.05s ease;
  }
  
  .btn:not(:disabled):active, .btn:not(:disabled).active {
    -webkit-transform: scale(var(--transform-scale));
            transform: scale(var(--transform-scale));
    -webkit-filter: brightness(0.8) contrast(1.2);
            filter: brightness(0.8) contrast(1.2);
  }
  
  .btn .btn-icon {
    font-size: var(--icon-size);
    margin-right: 4px;
  }
  
  .btn .keyboard-shortcut-block {
    margin-left: 10px;
  }
  
  .btn.medium {
    padding: 12px 20px 12px 15px;
    border-radius: 15px;
    icon-size: 22px;
  }
  
  .btn.medium .btn-icon {
    margin-right: 10px;
  }
  
  .btn.medium .keyboard-shortcut-block {
    margin-left: 12px;
  }
  
  .btn.large {
    padding: 20px;
    border-radius: 20px;
    icon-size: 24px;
    label-size: 18px;
    transform-scale: 0.985;
  }
  
  .btn.large .keyboard-shortcut-block {
    margin-left: 20px;
  }
  
  .btn.secondary {
    color: #000;
    background-color: #F1F1F1;
  }
  /*# sourceMappingURL=index.css.map */