.sb-icon-btn {
  --bs-danger: 209, 48, 39;
  --size: 40;
  height: calc(var(--size) * 1px);
  width: calc(var(--size) * 1px);
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 500px;
  outline: revert !important;
  border: none;

  .icon {
    user-select: none;
  }

  &[disabled] {
    opacity: 0.35;
    cursor: default;
  }

  &:link {
    text-decoration: none !important;
  }

  &.type-primary {
    background-color: #000;
    color: #fff !important;
    --hover: #222;
    --active: #555;

    &:link {
      color: #fff !important;
    }

    .icon {
      opacity: 1;
    }
  }

  &.type-secondary {
    background-color: var(--bs-gray-200);
    color: #000 !important;
    --hover: var(--bs-gray-300);
    --active: var(--bs-gray-400);

    &:link {
      color: #000 !important;
    }

    .icon {
      opacity: 1;
    }
  }

  &.type-tertiary {
    background-color: rgba(255, 255, 255, 0);
    color: #000 !important;
    --hover: var(--bs-gray-100);
    --active: var(--bs-gray-200);

    &:link {
      color: #000 !important;
    }

    .icon {
      opacity: 1;
    }
  }

  &.type-danger {
    background-color: rgba(var(--bs-danger), 0.1);
    color: rgba(var(--bs-danger), 1) !important;
    --hover: rgba(var(--bs-danger), 0.2);
    --active: rgba(var(--bs-danger), 0.25);

    &:link {
      color: rgba(var(--bs-danger), 1) !important;
    }

    .icon {
      opacity: 1;
    }
  }

  &:not(:disabled) {
    cursor: pointer;

    &:hover,
    &:focus-visible {
      background-color: var(--hover);

      .icon {
        opacity: 1;
      }
    }

    &:active {
      background-color: var(--active);
    }
  }

  &.busy {
    cursor: wait;
  }
}