.document-search-input {
    .MuiFilledInput-adornedStart {
        .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
            margin-top: 3px !important;
        }
    }
}

.allocated-stock-in-drafts-header {
    padding-top: 0.75rem;
    width: 529px;
    position: fixed;
    background-color: white;
}

.allocated-stock-in-drafts-body {
    width: 98%;
    padding-top: 13.125rem;
}

.min-width-fit-content {
    min-width: fit-content;
}