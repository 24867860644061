@import "../../assets/css/variable.scss";

.shipping-credit-modal {
    .items-return-list {
        margin-bottom: 0.9375rem;
    .shipping-credit-wrapper-main {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            position: relative;
            padding: 0.3125rem 0px;
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 0.063rem;
                background-color: var(--bs-gray-200);
            }
            .title {
                font: 1rem normal;
                color: $black-color;
                opacity: 1;
                letter-spacing: 0px;
            }
            .title-id {
                font-size: 1rem;
                font-weight: 500;
                color: $black-color;
                opacity: 1;
                letter-spacing: 0px;
            }
    }
    }
    .shipping-credit-input {
        .input-title {
            font-size: 1rem;
            font-weight: 500;
            letter-spacing: 0px;
            color: $black-color;
            opacity: 1;
        }
        .credit-input {
            margin: 0.3125rem 0 1rem;
            .MuiInputBase-root {
                padding: 0.625rem 0.875rem;
                .MuiInputAdornment-root {
                    .MuiTypography-root {
                        color: $black-color;
                        opacity: 1;
                    }
                }
            }
        }
    }
    .use-default-button-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 0.9375rem;
        .use-default-btn {
            font-weight: 500;
            letter-spacing: -0.02rem;
        }
    }
}
.no-more-shipping-credit{
    font-size: 0.75rem;
    margin-bottom: 1.875rem;
    color: $black-color;
    font-weight: 500;
}