.card-header{
    background-color: white !important;
    margin:0px 20px;
    padding:  1rem 0 !important;
}

.card{
    box-shadow: none !important;
}

.main-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.highlightedJob {
    line-height: 12px;
    border: none ;
    padding: 1px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    max-width: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    transition: all 0.3s ease-in-out;
    background: #fff;
}
.highlightedJob:hover {
    line-height: 12px;
    background: #fff;
    border: none !important;
    padding: 5px 2px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    max-width: 150px;
    display: flex;
    align-items: center;
}
.highlightedJob img {
    width: 25px;
    height: 25px;
    transition: all 0.3s ease-in-out;
}
.highlightedJob:hover img {
    width: 20px;
    height: 20px;
}