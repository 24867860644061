.stock-info-dropdown {
 .MuiInputBase-root.MuiFilledInput-root {
    width: fit-content;
    background-color: transparent;
    .MuiSelect-root {
        width: fit-content;
        background-color: transparent;
        padding: 0 38px 0 0;
    }
 }
}

.span-gap {
    gap: 1.875rem
}