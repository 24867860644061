@import "../../../constants/colors.scss";
@import 'ui/scss/general.scss';

.font-size12 {
  font-size: 12px;
  color: black;
}

.table-row-div {
  font-size: 12px;
  color: black;
  padding: 10px 0px;
  flex: 1px;
}

.table-row-bg {
  background-color: #f9f9f9;
}

.table-header-div {
  font-size: 12px;
  color: #aaaaaa;
  padding: 10px 0px;
  flex: 1px;
}

.filter-header {
  width: "100%";
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
}

.filter-sub-header {
  width: "100%";
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.rep-text {
  font-size: 12px;
  color: black;
  text-align: right;
}

.hidden-text {
  font-size: 10px;
  color: black;
  text-align: right;
}

.rep-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


.side-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
}

.side-menu:hover {
  background-color: #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  height: 40px;
  width: 40px;
}

.side-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
}

.side-menu:hover {
  background-color: #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  height: 40px;
  width: 40px;
}

.rep-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 10px;
}

.td-header {
  background-color: #eeeeee;
  font-size: 14px;
  color: black;
  padding: 5px 10px 5px 10px;
  font-weight: 600 !important;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.td-header-text {
  display: block;
  cursor: pointer;
  font-size: 12px;
  font-family: "Inter", Regular;
}

.td-header-text:hover {
  text-decoration: underline;
}

.quote-content-header {
  width: 100%;
  justify-content: space-between;
  display: flex;
}

.extend-selected {
  padding: 5px 12px;
  border: 1px solid black;
  background-color: #eeeeee;
  border-radius: 20px;
  font-size: 14px;
  font-family: Inter, Regular;
  color: #000;
  font-weight: 500;
  cursor: pointer;
}

.extend {
  padding: 0.5rem 0.75rem;
  border: 0 !important;
  background-color: #F5F5F5;
  border-radius: 1.25rem;
  font-size: 0.875rem;
  font-family: Inter, Regular;
  color: #000;
  font-weight: 500;
  cursor: pointer;
}

.extend:active {
  border: 0 !important;
  border-radius: 1.25rem;
  font-size: 0.875rem;
  font-family: Inter, Regular;
  color: #000;
  font-weight: 500;
  cursor: pointer;
}

.extend:hover {
  border: 0 !important;
  background-color: #DDDDDD;
  border-radius: 1.25rem;
  font-size: 0.875rem;
  font-family: Inter, Regular;
  color: #000;
  font-weight: 500;
  cursor: pointer;
}

.extend-by-title {
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
  font-family: Inter, Regular;
}

.main-likelihood {
  display: flex;
}

.main-likelihood>div:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.main-likelihood>div:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.likeli-hood-select {
  background-color: black;
  color: #fff;
  padding: 10px 20px;
  font-weight: 500;
}

.likeli-hood-unselect {
  border: 1px solid #eeeeee;
  padding: 10px 20px;
  background-color: white;
  color: black;
}

.empty-message {
  text-align: center;
  margin-top: 10px;
}

.edit-img {
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.img-txt {
  cursor: pointer;
}

.sub-quote-container {
  width: 28.125rem;
  max-height: 37.5rem;
}

.current-estimator {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #f1f1f1;
  width: 100%;
  padding: 0.938rem !important;
  margin-top: 60px;
  font-family: "Inter", Regular;
  border-radius: 5px;
}

.current-val1 {
  display: flex;
  flex-direction: column;
}

.current-val2 {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}

.current-option {
  font-size: 10px;
  line-height: 12px;
  font-family: "Inter", Regular;
  color: #999999;
  margin-bottom: 10px;
}

.current-option-value {
  font-size: 14px;
  line-height: 17px;
  font-family: "Inter", Medium;
  font-weight: 600;
}

.label-style {
  color: #999999 !important;
  font-family: "Inter", Regular !important;
}

.label-style-version {
  color: #000000 !important;
  font-family: "Inter", Regular !important;
}

.card-label-style {
  color: #000000 !important;
  font-family: "Inter", Regular !important;
}

.pr-space {
  border-radius: 0.625rem;
  width: auto;
  height: fit-content;
  padding: 0.5rem;
  cursor: pointer;
  background: transparent;
  display: flex;
  flex-direction: column;
  p{
    text-align: start;
  }
  outline: revert !important;
}

.is-clickable:hover {
  background-color: #f1f1f1;
}

.upload-file_btn {
  /* padding: 10px 20px; */
  border-radius: 10px;
  font-style: normal;
  width: 130px;
  line-height: 18px;
  text-transform: uppercase;
  height: 45px;
}

.add-sub-quote-btn {
  font-size: 0.75rem;
  font-family: "Inter", Medium;
  font-weight: 600;
  margin: 0.25rem 0.5rem 0rem 0rem;
  border: 0.0625rem solid $bright-Gray;
  border-radius: 0.3125rem;
  padding: 0.5rem;
  cursor: pointer;
  line-height: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.1875rem;
}

.version-btn {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #eeeeee;
  border-radius: 8px;
  width: 180px;
  height: 31px;
  cursor: pointer;
  font-size: 12px;
  font-family: "Inter", Medium;
}

hr.slim-divider {
  margin-top: 0px;
  border-bottom: 1px solid #bbb;
}

.download-icon {
  cursor: pointer;
}

.estimator-bottom-line {
  background-color: #c7c0c0;
  margin: 20px 0;
}

.quote-mr-10 {
  margin-right: 0.625rem;
}

.sub-quote-add-btn {
  font-size: 0.75rem;
  font-family: "Inter", Medium !important;
  font-weight: 600;
  border: 0.0625rem solid $bright-Gray;
  border-radius: 0.3125rem;
  cursor: pointer;
  line-height: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  letter-spacing: 0.0075rem;
  text-align: left;
  padding: 0.375rem;
}

.cust_info-main {
  position: relative;

  .cust-info-sab {
    text-align: left;
    font: normal normal normal 0.625rem/0.75rem Inter;
    letter-spacing: 0.0625rem;
    color: #000000;
    text-transform: uppercase;
    padding-left: 0.375rem;
  }

  .title-edit-section-sp {
    padding: 0.313rem 0.313rem !important;
    margin: 0;
    border-radius: 0.5rem !important;
    min-width: 3.125rem;

    span {
      font-size: 0.875rem !important;
      width: fit-content;
    }
  }
}

.document-type-info {
  .document-types {
    border-bottom: 1.008px solid #EEEEEE;
    display: flex;
    font-weight: 500;
    padding: 0.438rem 0;
    letter-spacing: 0rem;
    text-align: left;

    &-title {
      min-width: 11.25rem;
      font-weight: 400;
    }
  }
}

.span-info-sec {
  text-align: left;
  font: normal normal normal 0.875rem/1.0625rem Inter;
  letter-spacing: 0.0088rem;
  color: #555555;
}

.badge-btn {
  border-radius: 0.625rem !important;
  font-weight: 500 !important;
  padding: 0.375rem 0.9375rem !important;
}

.close-icon {
  position: absolute;
  top: 0.9375rem;
  right: 0.9375rem;
  opacity: .5;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.masked-phone-number-input {
  border-radius: 0.625rem !important;
  height: 2.5863rem;
}

.masked-phone-number-group {
  display: flex;
  gap: 0.625rem;
  align-items: flex-start;

  .ext-input {
    width: 7.5rem;
    flex: 1 1 7.5rem;

    .MuiInputBase-formControl {
      margin-top: 0.625rem !important;
    }
  }

  .masked-phone-number-input {
    flex: 1 1 auto;
  }
}

.optional-text {
  font-size: 1rem;
  font-weight: 500;
}

.subquote-report-section {
  .subquote-report-container {
    .report-div {
      position: relative;
      padding: 0.625rem 2.2rem 0.625rem 0.438rem;
      border-bottom: 0.063rem solid #EEEEEE;
    }

    .manage-sub {
      .report-div {
        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }
}

.edit_cust_btn {
  padding: 0.9375rem 0;
  gap: 0.625rem;
}

.cancel-btn,
.save-btn {
  height: 2.75rem;
  border-radius: 0.9375rem !important;

  &.button-is-loading-black {
    position: relative;
    overflow: hidden;
    pointer-events: none;
    .button-label,
    .button-icon {
      opacity: 0;
    }

    &::before {
      content: "";
      position: absolute;
      background-color: var(--bs-black);
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      border-radius: 0.625rem;
    }

    &::after {
      content: "";
      position: absolute;
      height: 1.5rem;
      width: 1.5rem;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNNiAxMGMtMS4xIDAtMiAuOS0yIDJzLjkgMiAyIDIgMi0uOSAyLTItLjktMi0yLTJ6bTEyIDBjLTEuMSAwLTIgLjktMiAycy45IDIgMiAyIDItLjkgMi0yLS45LTItMi0yem0tNiAwYy0xLjEgMC0yIC45LTIgMnMuOSAyIDIgMiAyLS45IDItMi0uOS0yLTItMnoiLz48L3N2Zz4=");
      background-position: center;
      background-repeat: no-repeat;
      opacity: 0;
      animation: button-loading-anim 0.3s ease-out alternate infinite;

      @keyframes button-loading-anim {
        to {
          opacity: 1;
          transform: scale(1.1);
        }
      }
    }
  }

  &.button-accent {
    &.button-is-loading-black {
      &::after {
        filter: invert(1);
      }
    }
  }
}

.cancel-btn {
  background: #F1F1F1 !important;
  margin-right: 0 !important;
}

.remove-btn {
  background: #FFEBEA !important;
}

.custom-select {
  &+.MuiFormControlLabel-label {
    font-weight: 500;
  }
}

.notify-customer-check {
  border-bottom: 0.0625rem solid #EEEEEE;
}

.notify-allSubQuote-check { 
    opacity: 0.35;
}

.form-field-title {
  font-weight: 500;
  margin-bottom: 0.3125rem !important;
  display: inline-block;
}

.new-product-btn {
  height: 2.5rem;

  img {
    height: 1.063rem;
  }
}

.message-date-text {
  text-align: left;
  font: normal normal medium 1rem/1.25rem Inter;
  letter-spacing: 0rem;
  color: $darkGray;
  opacity: 1;
}

.message-name-text {
  text-align: left;
  font: normal normal medium 1rem/1.25rem Inter;
  letter-spacing: 0rem;
  color: $black-color;
  opacity: 1;
}

.message-email-text {
  text-align: left;
  font: normal normal normal 1rem/1.25rem Inter;
  letter-spacing: 0rem;
  color: $description-color;
  opacity: 1;
}

.message-body-text {
  text-align: left;
  font: normal normal normal 1rem/1.625rem Inter;
  letter-spacing: 0rem;
  color: $dark-black-text-color;
}

.rfi-pdf-text {
  text-align: left;
  font: normal normal medium 0.875rem/1.25rem Inter;
  letter-spacing: 0rem;
  color: $dark-black-text-color;
}

.rfi {
  text-align: left;
  font: normal normal normal 0.75rem/0.9375rem Inter;
  letter-spacing: 0.0075rem;
  color: $dusty-gray-color;
  opacity: 1;
}

.document-preview {
  display: flex;
  border: 0.0625rem solid $border-color;
  border-radius: 0.625rem;
  overflow: hidden;
  max-width: fit-content;

  .preview {
    --size: 50;
    width: calc(0.0625rem * var(--size));
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $bright-Gray;
  }

  .details {
    display: flex;
    flex-direction: column;
    padding: 0.625rem;
    p{
      max-width: 18.75rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.message-line {
  width: 100%;
  display: grid;
  grid-template-columns: 1.875rem 1fr 1fr;
  column-gap: 1.875rem;
  border-radius: 0.625rem;
  p{
    margin: 0 !important;
  }
  .message-content {
    display: flex;
    flex-direction: column;
  }
}

.mini-notes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.25rem;
}

.mini-note {
  min-height: 18.75rem;
  max-height: 18.75rem;
  border-radius: 0.625rem;
  background-color: $white-color;
  border: 0.0625rem solid $border-color;
  cursor: pointer;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover,
  &:focus-visible {
    border: 0.0625rem solid $darkGray;
    .icon-button-notes {
        opacity: 1;
    }
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.625rem;
    padding-left: 1.25rem;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: $white-color;
    border-bottom: 0.0625rem solid $bright-Gray;

    .title {
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }

    h4 {
      font-size: 1.125rem;
      font-weight: 600;
      margin-bottom: 0rem;
    }
  }

  .note-main-content {
    padding: 0 1.25rem !important;
    height: 100%;
    text-align: left;
    font: normal normal normal 0.875rem/1.25rem Inter;
    letter-spacing: 0rem;
    color: $dark-black-text-color;
    flex-direction: none !important;
    &::after {
      content: "";
      position: sticky;
      z-index: 1;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgb(255, 255, 255)
      );
      display: flex;
      width: 100%;
      height: 2.5rem;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    padding-left: 0rem;
    list-style: none;
    margin: 0rem;
    padding: 1.25rem 0rem 1.25rem 0rem; 

    li {
      line-height: 1.5;
      font-size: 0.875rem;
      white-space: pre-wrap;
    }
  }

  button {
    cursor: pointer;
    user-select: none;
    border: none;
  
    &:focus-visible {
      outline: none;
      box-shadow: 0 0 0 3px rgba(255, 255, 255, 1), 0 0 0 5px $darkGray;
    }
  
    &:active {
      transition: box-shadow 0.1s ease-out;
      box-shadow: 0 0 0 0px rgba(255, 255, 255, 1);
    }
  
    &[disabled] {
      opacity: 0.35;
      cursor: wait;
    }
  }
}

.notes-dialog-sb {
  padding: 0;
  border-radius: 1.25rem;
  border: none;
  outline: none;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
  &::backdrop{
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.notes-dialog-wrapper-sb {
  max-width: 50rem;
  min-width: 50rem;
  max-height: 80vh;

  header {
    padding: 1.875rem;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: $white-color;

    h3 {
      font-size: 1.25rem;
      font-weight: 600;
      letter-spacing: -0.0063rem;
      margin: 0;
      color: $black-color;
    }

    .header-actions-note {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      position: absolute;
      right: 1.625rem;
      top: 0.9375rem;

      .icon{
        cursor: pointer;
      }
    }
  }

  .dialog-content {
    padding: 0 1.875rem;
    margin-top: 0.0625rem;
  }

  .dialog-content-ul {
    &::after {
      content: "";
      position: sticky;
      z-index: 1;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgb(255, 255, 255)
      );
      display: flex;
      width: 100%;
      height: 2.5rem;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  footer {
    padding: 1.875rem 0;
    gap: 0.625rem;
    display: flex;
    align-items: center;

    button {
      padding: 0.75rem 1.25rem;
      border-radius: 0.9375rem;
      font-size: 1rem;
      letter-spacing: -0.0125rem;
      font-weight: 500;

      &.primary {
        background-color: $black-color;
        color: $white-color;
      }

      &.secondary {
        background-color: $bright-Gray;
        color: $black-color;
      }

      &.tertiary {
        background-color: rgba(255, 255, 255, 0);
        color: $black-color;

        &:hover,
        &:focus-visible {
          background-color: $bright-Gray;
        }
      }

      &.button-accent {
        color: var(--bs-white);
      }

      &.button-is-loading-black{
        position: relative;
        overflow: hidden;
        pointer-events: none;
        .button-label,
        .button-icon {
          opacity: 0;
        }

        &::before {
          content: "";
          position: absolute;
          background-color: var(--bs-black);
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          border-radius: 0.625rem;
        }

        &::after {
          content: "";
          position: absolute;
          height: 1.5rem;
          width: 1.5rem;
          background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNNiAxMGMtMS4xIDAtMiAuOS0yIDJzLjkgMiAyIDIgMi0uOSAyLTItLjktMi0yLTJ6bTEyIDBjLTEuMSAwLTIgLjktMiAycy45IDIgMiAyIDItLjkgMi0yLS45LTItMi0yem0tNiAwYy0xLjEgMC0yIC45LTIgMnMuOSAyIDIgMiAyLS45IDItMi0uOS0yLTItMnoiLz48L3N2Zz4=");
          background-position: center;
          background-repeat: no-repeat;
          opacity: 0;
          animation: button-loading-anim 0.3s ease-out alternate infinite;

          @keyframes button-loading-anim {
            to {
              opacity: 1;
              transform: scale(1.1);
            }
          }
        }
      }

      &.button-accent {
        &.button-is-loading-black {
          &::after {
            filter: invert(1);
          }
        }
      }

    }
  }

  textarea {
    border-radius: 0.625rem;
    background-color: $bright-Gray;
    transition: box-shadow 0.1s ease-out, background-color 0.1s ease-out;
  
    &[disabled] {
      cursor: wait;
    }
  
    &:hover {
      box-shadow: 0 0 0 0.0625rem $border-color;
    }
  
    &:focus-visible {
      background-color: $white-color;
      outline: none;
      box-shadow: 0 0 0 0.0625rem $black-color, 0 0 0 0.375rem $bright-Gray;
    }
  }
}

.note-item-sb {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  .note-metadata {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    font-feature-settings: "tnum" 0;

    p {
      font-weight: 500;
      color: $darkGray;

      &.note-author {
        color: $black-color;
      }
    }
  }

  .note-text {
    font-size: 1rem;
    line-height: 1.5;
  }
}

.notes-list-sb {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 0rem;
  padding-left: 0rem;

  p{
    margin-bottom: 0rem;
  }
}

.new-note-input {
  font-size: 1rem;
  width: 100%;
  padding: 0.9375rem;
  min-height: 37.5rem;
  font-family: inherit;
  resize: none;
  border: none;
  border-radius: 0.625rem;
}

.note-text-limit {
  text-align: left;
  font: normal normal normal 1rem/1.25rem Inter;
  letter-spacing: 0rem;
  color: $dusty-gray-color;
  opacity: 1;
}

.skeleton-quote-details {
  cursor: wait;
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 100%;

  .quote-details {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .subquote {
      width: 12.5rem;
      padding: 0.625rem;
    }

    .quote-title {
      width: 25rem;
      padding: 1rem;
    }

    .quote-creator {
      width: 18.75rem;
      padding: 0.625rem;
    }

    .actions {
      margin-top: 1.25rem;
      display: flex;
      gap: 0.625rem;

      .action {
        padding: 1.25rem;
        width: 5rem;
      }
    }
  }

  .quote-actions {
    display: flex;
    gap: 0.625rem;
    height: fit-content;

    .action-btn {
      padding: 1.25rem 2.5rem;
    }

    .icon-action-btn {
      padding: 1.25rem;
    }
  }

  .subquote,
  .quote-title,
  .quote-creator,
  .action,
  .action-btn,
  .icon-action-btn {
    background-color: var(--bs-gray-200);
    border-radius: 0.25rem;
    animation: skeleton-loop-anim 0.6s ease-out alternate infinite;

    @keyframes skeleton-loop-anim {
      to {
        opacity: 0.5;
      }
    }
  }
}

.hoverArrow:hover {
  background: $bgColor no-repeat scroll 95% 50% / 12px;
}

.icon-button-notes {
  --size: 40;
  height: calc(var(--size) * 0.0625rem);
  width: calc(var(--size) * 0.0625rem);
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 31.25rem;

  background-color: $white-color;
  opacity: 0.5;

  &:hover,
  &:focus-visible {
    background-color: $bright-Gray;
    opacity: 1;
  }

  &:active {
    background-color: $border-color;
  }
}
.job-highlight-notes{
  background: $yellow-color-light 0% 0% no-repeat padding-box;
  border-radius: 31.25rem;

  span{
    text-align: left;
    font: normal normal normal 1rem/1.25rem Inter;
    letter-spacing: 0rem;
    color: $dark-black-text-color;
  }

  .remove-eye-icon {
    margin-left: 0.3125rem;
    padding-top: 0.188rem;
  }

  .customer-notes{
    margin-left: 0.3125rem;
    margin-right: 0.625rem;
  }
}

.quote-hover:hover{
  background: $bgColor 0% 0% no-repeat padding-box;
  border-radius: 0.625rem;
}

.quote-add-btn-hover:hover{
  background: $bgColor 0% 0% no-repeat padding-box;
  border-radius: 0.0625rem;
}
.skeleton-notes {
  cursor: wait;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  padding: 1.25rem;
  width: 100%;
  min-height: 100%;

  .note-line {
    height: 0.75rem;
    width: 100%;
    background-color: var(--bs-gray-200);
    border-radius: 0.25rem;
    animation: skeleton-loop-anim 0.6s ease-out alternate infinite;

    &:nth-child(2) {
      width: 50%;
    }

    @keyframes skeleton-loop-anim {
      to {
        opacity: 0.5;
      }
    }
  }
}

.remove-margin{
  margin: 0;
}

.quotes-list-skeleton {
  cursor: wait;
  display: flex;
  flex-direction: column;
  width: 100%;

  .quotes-list-item {
    padding: 1.875rem;
    display: grid;
    grid-template-columns: 1fr 8fr;
    align-items: flex-start;
    gap: 1.25rem;
    width: 100%;

    .quote-id,
    .quote-name,
    .quote-recipient {
      padding: 0.5rem;
    }

    .quote-details {
      display: flex;
      flex-direction: column;
      gap: 0.625rem;

      .quote-recipient {
        width: 70%;
      }
    }
  }

  .quote-id,
  .quote-name,
  .quote-recipient {
    background-color: var(--bs-gray-200);
    border-radius: 0.25rem;
    animation: skeleton-loop-anim 0.6s ease-out alternate infinite;

    @keyframes skeleton-loop-anim {
      to {
        opacity: 0.5;
      }
    }
  }
}

.selected-quote-id {
  background: $bgColor;
}

.is-not-clickable{
  cursor: default;
}
.input-focus-styling-sb.MuiFormControl-root {
  .MuiInputBase-root {
    padding: 0.7rem !important;
    border-radius: 0.625rem !important;
  }
  .Mui-focused {
    background-color: var(--bs-white) !important;
    border: 0.063rem solid var(--bs-black);
  }

  &.error {
    border-radius: 0.625rem;
    box-shadow: 0 0 0 0.0625rem var(--bs-red);    
      .Mui-focused {
        background-color: var(--bs-white) !important;
        border: 1.008px solid var(--bs-red);
      }
  }
}
.tabInput-mobile-sb {
  &:focus-visible {
    border: 0.063rem solid var(--bs-black);
    background-color: var(--bs-white)  !important;
  }
  &.error {
    &:focus-visible {
      border: 0.063rem solid var(--bs-red);
    }
  }
}

.sb-doc-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18.75rem, 1fr));
  gap: 1.25rem;
}

.sb-doc-card {
  --border-radius: 10;
  border: solid 0.0625rem var(--bs-gray-300);
  background-color: $white-color;
  display: grid;
  grid-template-rows: 9.375rem 1fr;
  border-radius: calc(0.0625rem * var(--border-radius));
  aspect-ratio: 1;
  padding: 0;
  outline: revert !important;

  @media (max-width: 50rem) {
    aspect-ratio: auto;
  }

  &:nth-child(1n) {
    --icon-color: $red-icon-color;
  }

  &:nth-child(2n) {
    --icon-color: $orange-icon-color;
  }

  &:nth-child(3n) {
    --icon-color: $pink-icon-color;
  }

  &:nth-child(4n) {
    --icon-color: $yellow-icon-color;
  }

  &:nth-child(5n) {
    --icon-color: $green-icon-color;
  }

  &:nth-child(6n) {
    --icon-color: $tron-blue-icon-color;
  }

  &:nth-child(7n) {
    --icon-color: $sky-blue-icon-color;
  }

  &:nth-child(8n) {
    --icon-color: $blue-lvy-icon-color;
  }

  &:nth-child(9n) {
    --icon-color: $blue-icon-color;
  }

  &:nth-child(10n) {
    --icon-color: $purpal-icon-color;
  }

  &:nth-child(11n) {
    --icon-color: $dark-pink-icon-color;
  }

  &:nth-child(12n) {
    --icon-color: $dark-red-icon-color;
  }

  &:nth-child(13n) {
    --icon-color: $coral-icon-color;
  }

  .icon {
    color: var(--icon-color);
  }

  &:not(:disabled) {
    cursor: pointer;

    &:hover,
    &:focus-visible {
      border-color: var(--bs-gray-400);
    }

    &:active {
      border-color: var(--bs-gray-500);
    }
  }

  .sb-doc-preview-wrapper {
    height: 100%;
    position: relative;
    --img-border-radius: calc(var(--border-radius) - 1);
    border-radius: calc(0.0625rem * var(--img-border-radius));
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    &.loading {
      background-color: var(--bs-gray-200);
      animation: preview-loading-animation 1s ease alternate infinite;

      @keyframes preview-loading-animation {
        to {
          background-color: $white-color;
        }
      }
    }

    &.error {
      background-color: var(--bs-gray-100);
    }

    .sb-doc-preview {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      user-select: none;
      pointer-events: none;
      transition: opacity 1s ease;
      opacity: 0;

      &.loading,
      &.error {
        opacity: 0;
      }

      &.complete {
        opacity: 1;
      }
    }

    .placeholder {
      transition: opacity 1s ease;
      opacity: 0;

      &.loading,
      &.complete {
        opacity: 0;
      }

      &.error {
        opacity: 1;
      }
    }
  }

  .sb-doc-info {
    display: grid;
    height: 100%;
    grid-template-rows: 1fr auto;

    .sb-doc-name-wrapper {
      padding: 1.25rem;
    }

    .sb-doc-name {
      text-align: left;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.25;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      word-break: break-all;

    }

    .sb-doc-footer {
      border-top: 0.0625rem solid var(--bs-gray-300);
      margin: 1.25rem;
      margin-top: 0;
      padding-top: 1.25rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:empty {
        display: none;
      }

      .sb-doc-type {
        font-size: 0.875rem;
        color: var(--bs-gray-600);
        margin: 0;
        &:empty {
          display: none;
        }
      }

      .sb-doc-extension {
        text-transform: uppercase;
        font: $card-font;
        letter-spacing: 0.5px;
        padding: 0.25rem;
        border-radius: 0.25rem;
        background-color: var(--bs-gray-200);
        user-select: none;
        margin: 0;
        &:empty {
          display: none;
        }
      }
    }
  }
}

.item-total-table{
  position: sticky;
  bottom: 0;
  z-index: 10;
  font-family: "Inter", sans-serif;
  font-feature-settings: "cv01", "cv02", "cv03", "cv04", "cv05", "cv06", "cv08",
    "cv10", "cv11", "cpsp", "calt", "ccmp", "locl", "kern", "tnum", "salt";
  overflow-x: unset !important;
}

.txt-align-left{
  text-align: left;
}

.arrow-black {
  .MuiSelect-icon{
    color : rgba(0, 0, 0, 1)
  }
  .MuiSelect-iconFilled{
    right: 1.25rem;
  }
  svg {
    min-width: 1.875rem;
    margin-right: 0.9375rem;
   }
}

.rfi-list-container {
  overflow-y: scroll;
  max-height: 37.5rem;
}

.text-red{
  color: $dark-red-text-color;
}

.open-new-note-btn {
  border-radius: 0.625rem;
  background-color: $white-color;
  border: 0.0625rem solid $border-color;
  width: 100%;
  font-size: 1rem;
  padding: 0.75rem 0.9375rem;
  padding-right: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.25rem;
  margin-bottom: 1.25rem;

  span {
    color: $darkGray;
  }

  &:hover,
  &:focus-visible {
    background-color: $bright-Gray;
    border-color: $bright-Gray;

    span {
      color: $black-color;
    }
  }
}

.remove-btn-hide {
  display: none;
}


.shaped-skeleton-block {
  cursor: wait;
  animation: shaped-skeleton-loop-anim 0.6s ease-out alternate infinite;

  .shaped-skeleton-block-rect {
    fill: var(--bs-gray-200);
  }

  @keyframes shaped-skeleton-loop-anim {
    to {
      opacity: 0.5;
    }
  }
}

.doc-table-status-header{
  background-color: $white-color;
  top: 0;
}
