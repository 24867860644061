.skeleton-container {
  cursor: wait;
  user-select: none;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1em;
  flex-direction: column;
  padding: 0;
  width: 100%;

  .skeleton-line {
    border-radius: 0.35em;
    height: 1em;
    width: 100%;
    background-color: var(--bs-gray-200);
    animation: skeleton-loop-anim 0.6s ease-out alternate infinite;

    &:nth-child(odd) {
      width: 60%;
    }

    &:nth-child(3n) {
      width: 80%;
    }

    &:first-of-type,
    &:nth-child(8n) {
      width: 35%;
    }

    @keyframes skeleton-loop-anim {
      to {
        opacity: 0.35;
      }
    }
  }
}
