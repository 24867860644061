.notes-dialog {
  outline: none;
  border-radius: 1.25rem;
  width: 40.625rem;
  max-width: 40.625rem;
  min-height: 85vh;
  max-height: 85vh;
  padding: 0;
  border: none;
  padding-bottom: 50vh;
  overscroll-behavior: none;
  box-shadow: 0 0.75rem 1.75rem 0 rgba(0, 0, 0, 0.2), 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 0.0625rem rgba(255, 255, 255, 0.5);
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #fff;
  scroll-padding-top: 8.125rem;

  &::-webkit-scrollbar {
    display: none;
  }

  &::backdrop {
    background: rgba(0, 0, 0, 0.4);
    animation: backdrop-intro 0.2s ease forwards;
  }

  @media (max-width: 62.5rem) {
    max-width: 100%;
    width: 100%;
    min-height: auto;
    height: 100vh;
    max-height: 100vh;
    border-radius: 0;
    inset: unset;
    top: 0;
  }

  .dialog-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    padding: 1.875rem;
    position: relative;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(1.25rem);
    -webkit-backdrop-filter: blur(1.25rem);
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
    position: sticky;
    top: 0;
    z-index: 1;

    .dialog-back-to-top {
      pointer-events: none;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .dialog-back-to-top-btn {
        pointer-events: all;
      }
    }

    h3 {
      font-size: 1.5rem;
      font-weight: 600;
      letter-spacing: -0.0125rem;
      color: black;
      @media (max-width: 62.5rem) {
        font-size: 1.125rem;
      }
    }

    .close-btn {
      position: absolute;
      right: 0.625rem;
      top: 0.625rem;

      @media (max-width: 62.5rem) {
        position: relative;
        right: auto;
        top: auto;
      }
    }
  }

  .dialog-body {
    padding: 1.875rem;
    padding-top: 0.625rem;
    display: flex;
    flex-direction: column;

    .notes-manager-controls {
      padding-top: 1.25rem;
      padding-bottom: 0.25rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .notes-manager-count,
    .notes-manager-btn {
      text-transform: uppercase;
      font-size: 0.75rem;
      letter-spacing: 0.0125rem;
      user-select: none;
      font-weight: 600;
      padding: 0;
      margin: 0;
      line-height: 1;
    }

    .notes-manager-btn {
      --outline-color: var(--bs-gray-500);
      --outline-offset: 0.25rem;
      --outline-width: 0.125rem;
      border: none;
      background-color: #fff;
      color: var(--bs-black);
      opacity: 0.35;
      padding: 0.625rem;
      border-radius: 0.625rem;
      outline: none;
      max-width: fit-content;

      &:focus-visible {
        transform: scale(1.05);
        box-shadow: 0 0 0 var(--outline-offset) #fff,
          0 0 0 calc(var(--outline-offset) + var(--outline-width))
            var(--outline-color);
      }

      &:active {
        transform: scale(0.95);
      }

      &:hover,
      &:focus-visible {
        opacity: 1;
        background-color: var(--bs-gray-200);
      }
    }
  }
}
