@import "apps/pos/assets/css/variable.scss";

.warehouse-select {
    .MuiSelect-root.MuiSelect-select {
        background-color: $white-color;
        border: 0.125rem solid $black-color;
    }
}

.tabDropdown{
   .MuiSelect-root{
   &:focus{
        background-color: var(--bs-white) !important;
        outline: 0.13rem solid var(--bs-black) !important;  
      }
  }
}

.btn-disabled-opacity{
    opacity: 0.3 !important
}
