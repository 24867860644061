
.category-picker {
  .return-to-line-item {
  display: grid;
  grid-template-columns: 200px repeat(2, 1fr) 50px;
  gap: 1px;
  align-items: center;
  max-width: 550px;
  background-color: var(--bs-gray-100);
  border-radius: 10px;

  .return-line-delete-button {
    opacity: 0.5;

    &:hover,
    &:focus-visible {
      opacity: 1;
    }
  }

  .location-button {
    border: none;
    font-size: 0.875rem;
    font-family: var(--inter-font-family);
    cursor: pointer;
    width: 12.5rem;
    padding: 0.75rem;
    padding-right: 0.3125rem;
    border-radius: 0.625rem;
    background-color: var(--bs-gray-100);
    will-change: transform;
    transition: all 1s cubic-bezier(0, -0.01, 0, 1.31), box-shadow 0.1s ease-out;
    text-align: start;
    display: flex;
    justify-content: space-between;
    align-items: center;
    outline: none;
    font-weight: 500;

    &.location-button-active {
      animation: location-button-shimmer 1s ease-out alternate infinite;

      @keyframes location-button-shimmer {
        to {
          background-color: var(--bs-gray-300);
        }
      }
    }

    .location-button-label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 1.25;
    }

    &:hover,
    &:focus-visible {
      background-color: var(--bs-gray-300);
    }

    &:active {
      background-color: var(--bs-gray-400);
      transform: scale(0.975);
    }

    &:focus-visible {
      box-shadow: inset 0 0 0 1px #000;
    }
  }

  .return-to-input-group {
    position: relative;
    display: flex;
    align-items: center;

    &::before {
      content: "";
      position: absolute;
      left: -1px;
      height: 24px;
      width: 1px;
      background-color: var(--bs-gray-500);
    }

    &:first-of-type {
      &::before {
        display: none;
      }
    }

    &:last-of-type {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .return-qty-input {
    user-select: all;
    width: 100%;
    border: none;
    padding: 12px 15px;
    font-size: 14px;
    font-family: var(--inter-font-family);
    border-radius: 10px;
    outline: none;
    background-color: var(--bs-gray-100);
    transition: all 0.1s ease-out;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:focus-visible {
      background-color: #fff;
      box-shadow: inset 0 0 0 1px #000;
    }
  }
}
}
.max-w-full{max-width: 100%;}