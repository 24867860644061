.half-width-detail-sec.d-flex {
  display: flex;
  flex-direction: column !important;
  @media (min-width: 768px) {
    flex-direction: row !important;
  }
  .half-width-left-detail-div {
    width: 100%;
    @media (min-width: 768px) {
      padding-right: 0.625rem;
      width: 50%;
    }
  }
  .half-width-right-detail-div {
    width: 100%;
    margin-top: 0.938rem;
    @media (min-width: 768px) {
      padding-left: 0.625rem;
      width: 50%;
      margin-top: 0;
    }
  }
}
.price-per-each-sec, .prof-percentage-sec {
  position: relative;
  .MuiInputBase-root.MuiInputBase-formControl {
    padding-left: 8rem !important;
    padding-right: 1.25rem !important;
    input {
      text-align: right;
    }
  }
  .price-per-each-title {
    position: absolute;
    top: 0.55rem;
    left: 0.7rem;
  }
}
.price-per-each-sec {
  .MuiInputBase-root.MuiInputBase-formControl {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom: 0.3px solid #DDDDDD !important;
  }
}
.prof-percentage-sec {
  margin-top: 0 !important;
  .MuiInputBase-root.MuiInputBase-formControl {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-top: 0.3px solid #DDDDDD !important;
  }
}
.input-set-focus .MuiInputBase-root {
  background-color: #fff !important;
  border: 0.063rem solid #000;
}
.first-input-set-focus .MuiInputBase-root {
  border-bottom: none;
}
.second-input-set-focus .MuiInputBase-root {
  border-top: none;
}
.extra-small-text{font-size: 11px;}
.text-adornment{
  p{
    color: rgba(0, 0, 0, 1);
  }
}
.img-text-adornment{
  p{
    color: gray;
    margin-right: -8px;
  }
}