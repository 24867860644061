.main-content {
  width: -webkit-fill-available;
  width: -moz-available;
  height: calc(100vh);
  overflow: auto;
  padding-top: 3.75rem !important;
  padding-right: 0rem !important;
  padding: 1.5rem;
  padding-bottom: 0rem !important;
  background-color: var(--bs-white-fff);
  scroll-padding-top: 7.5rem; 
}

.main-content-collapsed {
  width: -webkit-fill-available;
  width: -moz-available;
  height: calc(100vh);
  overflow: scroll;
  padding-top: 3.75rem !important;
  padding-bottom: 0px !important;
  background-color: var(--bs-white-fff);
  scroll-padding-top: 7.5rem;
}

.universal-shortcut-container {
  display: flex;
  justify-content: space-between;
  margin-left: -10%;
  width: 8%;
}

.universal-search {
  display: flex
}

.key-shortcut-symbol {
  margin: 0.625rem;
  font-size: 0.9375rem;
  color: var(--bs-gray-7c);
}

.keyboard-shortcut-universal {
  background-color: var(--bs-white-fff);
  padding: 0rem 0rem 0rem 0rem;
  color: var(--bs-gray-7c);
  width: 100%;
  font-size: 0.9375rem;
  font-weight: 600;
  box-shadow: 0.25rem 0.1875rem 0.375rem -0.1875rem rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0.25rem 0.1875rem 0.375rem -0.1875rem rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0.25rem 0.1875rem 0.375rem -0.1875rem rgba(0, 0, 0, 0.19);
  /* margin-left: -4%; */
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.keyboard-shortcut-letter {
  background-color: var(--bs-white-fff);
  padding: 0rem 0rem 0rem 0rem;
  color: var(--bs-gray-7c);
  width: 6.25rem !important;
  font-size: 0.9375rem;
  font-weight: 600;
  box-shadow: 0.25rem 0.1875rem 0.375rem -0.1875rem rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0.25rem 0.1875rem 0.375rem -0.1875rem rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0.25rem 0.1875rem 0.375rem -0.1875rem rgba(0, 0, 0, 0.19);
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}


.icon-button {
  --icon-button-size: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 31.25rem;
  height: var(--icon-button-size);
  width: var(--icon-button-size);
  cursor: pointer;
  padding: 0;
  overflow: hidden;
}

.icon-button:hover {
  background-color: var(--bs-gray-100);
}

.icon-button:active {
  background-color: var(--bs-gray-200);
  transform: scale(0.9);
}

.icon-button svg {
  opacity: 0.3;
}

.icon-button:hover svg {
  opacity: 1;
}


/* sidenav footer */
.master-sidenav-footer {
  height: 3.125rem;
  display: flex;
  flex-direction: column;
}

.sidenav-footer-logo {
  width: 100%;
  padding: 0 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.sidenav-footer-logo-full {
  height: 1.875rem;
  width: auto;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-bottom: 0.625rem;
}

.sidenav-footer-logo-symbol {
  display: none;
}

.sidenav-collapsed .sidenav-footer-logo {
  width: var(--sidenav-collapsed-width);
  padding: 0.625rem 0;
  justify-content: center;
}

.sidenav-collapsed .sidenav-footer-logo-full {
  display: none;
}

.sidenav-collapsed .sidenav-footer-logo-symbol {
  display: block;
  width: calc(var(--sidenav-item-height) - 0.9375rem);
  height: calc(var(--sidenav-item-height) - 0.9375rem);
  opacity: 0.5;
}

/* imports */
/* fonts */
@import url("https://rsms.me/inter/inter.css");

/* variables */
:root {
  --inter-font-family: "Inter", sans-serif;

  /* colors */
  --bs-blue: #2877ff;
  /* Primary */
  --bs-indigo: #5956d5;
  --bs-purple: #af52de;
  --bs-red: #fc6100;
  --bs-yellow: #fecc05;
  --bs-green: #36c759;
  --bs-cyan: #32aee6;
  --bs-white-fff : #ffffff;
  /* grays */
  --bs-gray-50: #f9f9f9;
  --bs-gray-88: #888888;
  --bs-gray-100: #f1f1f1;
  --bs-gray-7c : #7c7c7c;
  /* Secondary */
  --bs-gray-200: #eee;
  --bs-gray-300: #ddd;
  --bs-gray-400: #ccc;
  --bs-gray-500: #aaa;
  --bs-gray-600: #999;
  --bs-gray-700: #777;
  --bs-gray-800: #555;
  --bs-gray-900: #222;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray-999: #999999;
  --bs-gray-silver-chalice: #AAAAAA;
  --bs-primary: 'red';
  --bs-primary-dark: #1e58ff;
  --bs-primary-darker: #254fc7;
  --bs-secondary: var(--bs-gray-100);

  --bs-success: var(--bs-green);
  --bs-info: var(--bs-cyan);
  --bs-warning: var(--bs-yellow);
  --bs-danger: var(--bs-red);
  --bs-light: var(--bs-gray-100);
  --bs-dark: var(--bs-gray-800);

  --bs-orange: #ec3e37;
}

/* defaults and resets */
html {
  font-family: "Inter", sans-serif;
  scroll-behavior: smooth;
  scroll-padding: 100px;
  box-sizing: border-box;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
ul,
ol,
li,
h1,
h2,
h3,
h4,
p {
  margin: 0;
  padding: 0;
  font-family: var(--inter-font-family);
}

a[href]:empty::before {
  content: attr(href);
}

input[type="text"],
input[type="number"],
select,
textarea {
  font-size: 1rem;
}

.springboard-main-content {
  padding: 60px 0 0 !important;
}