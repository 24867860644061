.invoice-returned-table.MuiTable-root {
  border-collapse: collapse !important;
  max-width: 1520px;
  width: 100%;
  min-width: 62.5rem;

  .invoice-returned-data {
    border-bottom: 0.063rem solid #dddddd8a;
  }
}

.return-product-table {
  max-height: 33.3rem;
}

.return-product-title-header,
.cart-added-items-imfo {
  width: 50%;
}

.cart-added-items-imfo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 2px solid black;
  padding: 1rem 0rem;
  width: 100%;

  .added-products-count {
    margin-right: 1.25rem;
    font-weight: 500;
  }
}

.retrun-product-section {
  margin-top: 80px;
  padding: 0 1.5rem 1.25rem;
  position: fixed;
  background: white;
  bottom: 0;
  max-width: 1520px;
  width: 80%;
}

.retrun-table-scroll-height {
  height: calc(608px - 140px) !important;
}