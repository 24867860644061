.skeleton-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0.625rem;

  li {
    padding: 1.25rem;
    font-family: "Inter", sans-serif;
    border-radius: 0.625rem;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.0625rem;
      background-color: var(--bs-gray-400);
    }

    &.skeleton-item {
      user-select: none;
      cursor: wait;
      width: 100%;
      margin-bottom: 0.5em;
      display: flex;
      padding: calc(1.25rem + 0.25em);
      background-color: var(--bs-gray-300);
      animation: skeleton-loop-anim 0.6s ease alternate infinite;
      animation-delay: var(--initial-delay);

      &::after {
        display: none;
      }

      @keyframes skeleton-loop-anim {
        to {
          opacity: 0.25;
        }
      }
    }
  }
}