@import 'ui/scss/general.scss';

.listed-doc-name {
  display: block;
  width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card-type-tag {
  background: $bright-Gray 0% 0% no-repeat padding-box;
  border-radius: 5px;
  @include setFlexCenter;
  text-align: right;
  font: $card-font;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.unique-icon-class button::after {
  height: 25px;
  width: 25px;
  content: "";
  position: absolute;
  opacity: 0;
  background-repeat: no-repeat;
  left: 7px;
  background-repeat: no-repeat;
  background-size: 21px;
}

.unique-icon-class:hover {
  visibility: hidden;
}

.unique-icon-class:hover::before {
  content: "";
  visibility: visible;
  position: absolute;
  top: 6px;
  left: 6px;
  width: 25px;
  height: 25px;
  background-image: url("../../../Image/deleteOpen.svg");
  background-size: cover;
}

.document-description {
  line-height: 26px;
}
