@import "apps/pos/assets/css/variable.scss";

.cart-drawer-close-icon.btn {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 1.25rem;
  background-color: #f1f1f1;
  border: none;
  position: fixed;
  right: 84rem;
  top: 5rem;
}

.MuiButtonBase-root.cart-drawer-disable-btn-style.Mui-disabled {
  background-color: #c6c6c6;
  color: #424242;
}

.product-drawer-main-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;

  .cart-drawer-first-part {
    margin-bottom: 3.125rem;

    @media (min-width: 1200px) {
      width: 50%;
    }

    max-width: 41.875rem;
  }

  .cart-drawer-second-part {
    padding: 0rem;

    @media (min-width: 960px) {
      padding: 0rem 3rem;
    }

    margin-bottom: 3.125rem;

    @media (min-width: 1200px) {
      width: 50%;
    }

    .set-new-price {
      height: 100%;
      max-height: 19.75rem !important;
    }

    .cart-price-volume {
      @media (min-width: 600px) {
        min-width: 26.875rem;
      }

      width: 100%;

      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 10px 40px #0000000d;
      border: 1px solid #eeeeee;
      border-radius: 30px;
      opacity: 1;

      .cart-price-volume-box {
        padding: 0.625rem 1.25rem;

        .Set-price-label {
          font-size: 1rem;
          color: #000000;
          font-family: "Inter", Regular;
          text-align: left;
        }
      }
    }

    .cart-volume-break-opacity-low {
      opacity: 0.5;
      width: 100%;
      height: 100%;
      border-left: 1px solid rgba(0, 0, 0, 0.06);
      padding: 0 30px 0;
      position: relative;

      &::before {
        opacity: 0.5;
        z-index: -1;
        position: absolute;
      }

      .vol-break-price-list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
      }

      .vol-price-unit-list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
      }

      .cart-volume-text {
        font-size: 11px;
      }

      .cart-unit-text {
        font-size: 11px;
      }
    }

    .cart-volume-break {
      width: 100%;
      height: 100%;
      border-left: 1px solid rgba(0, 0, 0, 0.06);
      padding: 0 30px 0;
      position: relative;

      &::before {
        opacity: 0.5;
        z-index: -1;
        position: absolute;
      }

      .vol-break-price-list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
      }

      .vol-price-unit-list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
      }

      .cart-volume-text {
        padding-bottom: 0.313rem;
        font-size: 0.625rem;
        letter-spacing: 1px;
        color: #000000;
        font-family: "Inter", Regular;
      }

      .cart-unit-text {
        padding-bottom: 0.313rem;
        font-size: 0.625rem;
        color: #000000;
        letter-spacing: 1px;
        font-family: "Inter", Regular;
        text-align: right;
      }

      .cart-volume-unit-price-raw {
        background: #f9f9f9 0% 0% no-repeat padding-box;
        border-radius: 5px;
      }

      .cart-unit-price-raw {
        text-align: right;
      }
    }
  }

  .button-container {
    margin-top: 1.25rem;
    width: 100%;
    justify-content: space-between;

    .button-change-price {
      width: 100%;
      font-size: 1rem;
      height: 2.75rem;
      max-width: 10.125rem;
      padding-left: 0.875rem;
      border-radius: 0.938rem;
      font-weight: normal;
      justify-content: center;

      .MuiButton-startIcon {
        margin-right: 0.3125rem;
      }
    }

    .button-cancel {
      background: #f1f1f1 0% 0% no-repeat padding-box;
      font-size: 1rem;
      margin-right: unset;
      padding-left: 0.875rem;
      border-radius: 0.938rem;
      justify-content: center;
    }
  }

  .second-part-input-btn {
    background-color: #ffffff;
    height: 44px;
    border: 1px solid #000;
    border-radius: 10px;
    display: flex;
    align-self: end;
    align-items: center;
    justify-content: center;
    margin-right: 0.313rem;
    width: 212px;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
  }

  .second-part-search-input-results {
    margin: 0 10px;
    width: 100%;
    border: none !important;
    font-size: 18px;
    font-family: "Inter", Regular;
    background-color: #ffffff;
    font-weight: 500;

    input {
      padding: 0;
    }
  }

  .second-part-update-btn {
    height: 2.75rem !important;
    width: 100% !important;
    max-width: 9.625rem;
  }

  .button-update-cart-price {
    border-radius: 0.938rem !important;
    padding-left: unset !important;
    font-weight: normal;
    justify-content: center !important;

    .MuiButton-startIcon {
      margin-left: 0 !important;
    }
  }

  .add-to-cart-btn {
    padding-left: unset;
    font-weight: normal;
    justify-content: center;
    border-radius: 0.938rem;
    padding: 0 !important;

    &:focus-visible {
      outline: 0.13rem solid var(--bs-gray-600) !important;
      outline-offset: 0.0625rem;
    }
  }

  .add-to-cart-symbol-second-part {
    padding-left: 10px;
    font-size: 23px;
  }

  .add-to-cart-text-second {
    display: flex;
    gap: 7px;
  }

  .schedule-btn-text-second-part {
    display: none;
  }

  .unit-price-and-ext-price {
    margin: 1.1rem 0rem;
    display: flex;
    justify-content: space-between;
    font-size: 0.625rem;
    letter-spacing: 1px;

    .unit-price {
      width: 50%;
      display: flex;
      flex-direction: column;
      font-size: 0.625rem;

      .unit-price-text {
        font-size: 1rem;
        letter-spacing: 0.5px;
        font-weight: 500;
      }
    }

    .unit-ext-price {
      width: 50%;
      left: 57.56rem;
      font-size: 0.625rem;
      display: flex;
      flex-direction: column;

      .unit-ext-text {
        font-size: 1rem;
        letter-spacing: 0.5px;
        font-weight: 500;
      }
    }
  }

  .schedule-btn-second-part-text {
    display: contents;
    font-size: 17px;
  }

  .add-to-cart-second-part {
    margin-left: 0.313rem;
  }

  .cart-items-added {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    color: #000000;
    font-size: 0.875rem;
    font-family: "Inter", Regular;
    line-height: 2.25rem;

    img {
      margin-top: 0.125rem;
      width: 0.938rem;
      height: 2rem;
    }
  }

  .cart-items-back-order {
    display: flex;
    gap: 12px;
    color: #000000;
    padding-top: 0.5rem;
    font-size: 14px;
    font-family: "Inter", Regular;
    border-top: 1px solid #dddddd;

    img {
      margin-top: 2px;
      width: 15px;
      height: 16px;
    }
  }

  .remove-btn-second-part {
    text-decoration: underline;
    font-weight: 500;
    cursor: pointer;

    &:focus-visible {
      outline: 0.13rem solid var(--bs-gray-600) !important;
      outline-offset: 0.125rem;
      transition: all 0.05s ease;
      border-radius: 1px !important;
    }
  }

  .div-add-tocart-section {
    justify-content: space-between;
  }

  .qty-for-add-to-cart {
    width: 57%;
    max-width: 57%;
    height: 2.75rem;

    .input-add-to-cart {
      height: unset;

      input {
        padding: 0;
      }
    }
  }

  .cart-item-details {
    margin-top: 10px;
    // padding: 20px 30px 0;
  }

  .cart-item-details-row-images {
    display: flex;
    flex-direction: unset !important;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .price-type-product {
    letter-spacing: 0.0625rem;
    color: $description-color;
    font: normal normal bold 0.625rem/0.75rem Inter;
    text-transform: uppercase;
    opacity: 1;
  }

  .vo-break-main-div {
    position: relative;
    margin: 1rem 0rem;
  }

  .cart-btn-container-second {}

  .backorder-and-remove {}

  .product-notes-container {
    margin-top: 0.5rem;
    border-top: 1px solid #dddddd;

    .product-order-note-container {
      padding-top: 0.5rem;

      .product-order-note-header {
        font-weight: 500;
      }

      .product-order-note-input-container {
        background-color: #f1f1f1;
        padding: 1rem;
        margin: 0.5rem 0;
        border-radius: 1.25rem;

        &:focus-within {
          background-color: var(--bs-white) !important;
          outline: 0.13rem solid var(--bs-black) !important;
        }

        .product-order-note-input {
          width: 100%;
          border-color: #f1f1f1;
          background-color: #f1f1f1;

          input {
            padding: 0;

            &:focus-visible {
              background-color: var(--bs-white) !important;
            }
          }
        }
      }
    }
  }

  .volume-breakbtn-using-price {
    gap: 7px;
    display: flex;
    z-index: 99999999999;
    opacity: 1;
    background: #eeeeee 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    color: black;
    padding: 8px;
  }

  .set-price-button {
    margin: 1.25rem 0rem;
  }

  .set-price-container {
    padding: 1.125rem 1.25rem;
  }

  .pricing-schedule-cart-msg {}

  .reset-btn-price-customer {
    text-decoration: underline;
    font-weight: 500;
    color: black;
    cursor: pointer;
  }

  .toast-message-remove {
    gap: 20px;
    display: flex;
    justify-content: space-between;

    .remove-item-undo {
      cursor: pointer;
    }
  }
}

.volume-break-section {
  position: relative;
  margin-bottom: 1.25rem;
  margin-top: 2rem;

  .volume-break-using-custom-price {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .volume-break-title-div {
    margin-bottom: 0.375rem;

    .volumne-break-title,
    .unit-price-title {
      width: 50%;
    }
  }

  .volume-break-info {
    display: flex;
    margin-bottom: 0.375rem;
    position: relative;
    align-items: center;

    .qty-volume-break-arrow-icon {
      position: absolute;
      left: -20px;
    }

    .volume-break-qty,
    .volume-break-price {
      width: 50%;
    }
  }
}

.custom-price-div {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;

  span {
    background: #eeeeee;
    border-radius: 0.625rem;
    padding: 0.313rem 0.625rem;
    display: flex;
  }

  .cust-price {
    background: #eeeeee;
    border-radius: 0.625rem;
    padding: 0.313rem 0.625rem;
    display: flex;
    align-items: center;
  }

  .reset-btn-link {
    text-decoration: underline;
    font-weight: 600;
    padding: 0;
    margin-left: 0.25rem;
    cursor: pointer;
    color: #000;

    .label-group {
      display: none;
    }
  }

  .set-price-divider-container {
    max-width: 100%;
    max-width: 20.75rem;
    height: 0.063rem;
    margin-bottom: 1rem;
    opacity: 1;
    background: #dddddd 0% 0% no-repeat padding-box;
  }
}

.update-quantity-container {
  margin-top: 1rem;
  padding: 1.25rem 0;
  margin-left: 20px;
  margin-right: 20px;
  border-top: 1px solid #dddddd;
}

.cart-price-details-container {
  color: #999999;
  text-align: left;
  font-size: 0.875rem;
  // position: absolute;
  padding: 1rem 0.2rem;
  margin-top: 3.5rem;
}

//return product cart

.product-drawer-main-container-returncart {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 5%;

  .cart-drawer-first-part {
    width: 100%;
    min-width: 40%;
    max-width: 40%;
  }

  .cart-drawer-second-part {
    width: 100%;
    min-width: 60%;
    max-width: 60%;
  }
}

.gray-dot {
  height: 0.4rem;
  width: 0.4rem;
  background-color: #aaaaaa;
  border-radius: 50%;
  display: inline-block;
}

.product-notes-modal {
  .MuiDialogContent-root {
    height: fit-content;
    max-height: 90vh !important;
    max-width: 40.625rem !important;
    overflow-y: scroll;
  }

  .MuiPaper-root {
    max-width: 40.625rem !important;
  }

  .product-notes-bottom-section {
    display: flex;
    justify-content: space-between;
    padding-top: 1.875rem !important;
    border-top: 0.0625rem solid #aba9a9;
  }
}

.product-add-update-btn {
  margin-top: 0.438rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media only screen and (min-width: 600px) {
    flex-wrap: unset;
  }

  button {
    margin-top: 1.25rem;

    @media only screen and (min-width: 600px) {
      margin-top: 0;
    }
  }
}

.cart-product-drawer-left-info {
  flex-wrap: wrap;
}

.cart-product-drawer-left-info-style {
  width: 100%;

  @media only screen and (min-width: 600px) {
    width: 50%;
  }
}

.cart-peice-sec {
  width: 100%;

  @media only screen and (min-width: 600px) {
    width: auto;
  }
}

.fixed-margin-info {
  background-color: #ffcf6e;
  padding: 0.625rem 0.875rem;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;

  img {
    height: 1.125rem;
    width: 1.125rem;
    margin-right: 0.5rem;
  }

  span {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: normal;
  }
}

.gray-message-dot {
  height: 0.2125rem;
  width: 0.2125rem;
  background-color: $darkGray;
  border-radius: 50%;
  display: inline-block;
  margin-top: 0.625rem;
}

.input-focus {
  &:focus-within {
    outline: 0.063rem solid var(--bs-black) !important;
  }
}

.lower-prof-applied {
  padding: 0.625rem;
  border-radius: 0.625rem;
  background-color: #FFCF6E;
  display: flex;
  gap: 0.625rem;
  align-items: center;
  letter-spacing: 0.0088rem;
  font-size: 0.875rem;
  font-weight: 500;
  height: 34px;
}

.warn-icon-size {
  font-size: 1.25rem !important;
}