@import "ui/scss/general.scss";

.table-btn-container {
  .selected-option {
    background-color: $black;
    border-color: $white !important;
    font-size: $extra-small-font-size;
  }
  .dropdown-options {
    background-color: $black;
  }
  .single-button {
    background-color: $black;
    color: $white;
    font-size: $extra-small-font-size !important;
  }
  .dropdown-popup-modal {
    width: 30ch;
    &-paper {
      border-radius: 10px;
      box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
        0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);
      &-list {
        border-bottom: 1px solid $gray-100;
      }
      &-list:last-child {
        border-bottom: none;
      }
    }

    .space-wrap {
      white-space: inherit;
    }
  }
}
