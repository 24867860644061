.available-stock-container {
    .available-stock-name {
      margin-bottom: 20px;
      .heading-black-text {
        color: #000000;
        font-size: 1.25rem;
      }
    }
    .available-stock-product {
      display: flex;
      width: 100%;
      .available-stock-product-info {
        padding: 0 30px 0 15px;
      }
      .available-stock-product-name {
        font-size: 14px;
        color: #000000;
        font-weight: 500;
      }
      .available-stock-adjust-btn {
        display: flex;
        height: 100%;
        .available-adjust-button {
          background: #F1F1F1 0% 0% no-repeat padding-box;
          display: flex;
          align-items: center;
          font-size: 1rem;
          font-family: var(--inter-font-family) !important;
          color: #000000;
          border: none;
          border-radius: 10px;
          opacity: 1;
          padding: 10px 13px;
          min-width: 150px;
          font-weight: 500;
          letter-spacing: -0.32px;
          &:hover {
            filter: unset !important;
          }
        }
      }
      .available-stock-image-and-text {
        display: flex;
        width: calc(100% - 150px);
      }
    }
    .available-search-input-box {
      background: #F1F1F1 0% 0% no-repeat padding-box;
      border-radius: 10px;
      width: 100%;
      display: flex;
      align-items: center;
      margin: 25px 0 10px;
      .search-bar-icon {
        svg {
          height: 1.25rem;
          width: 1.25rem;
          margin-left: 10px;
          fill: #AAAAAA;
        }
      }
    }
    .available-stock-table {
      thead {
        th {
          border-bottom: none;
        }
      }
      tbody {
        tr:last-child {
          th, td {
            border-bottom: none;
          }
        }
      }
      .MuiTableHead-root,
      .MuiTableBody-root {
        .MuiTableRow-root {
          .MuiTableCell-root {
            padding: 8px 0;
          }
        }
      }
      width: 100%;
      .sticky-table-header {
        .product-search-table-header-row {
          .available-stock-table-location {
            color: #000000;
            font-size: 0.625rem;
            opacity: 0.7;
            letter-spacing: 1px;
            font-weight: 600;
            font-family: "Inter";
            text-transform: uppercase;
          }
          .available-stock-table-avl-qty {
            color: #000000;
            font-size: 0.625rem;
            opacity: 0.7;
            letter-spacing: 1px;
            font-weight: 600;
            font-family: "Inter";
            text-transform: uppercase;
          }
        }
      }
      .available-warehouse-name {
        font-size: 1rem;
        color: #000000;
        font-family: "Inter", sans-serif;
        border-color: #EEEEEE;
        opacity: 1;
      }
      .available-unit-qty {
        font-size: 1rem;
        color: #000000;
        font-family: "Inter", sans-serif;
        border-color: #EEEEEE;
        opacity: 1;
      }
      .available-wh-name-grey {
        font-size: 1rem;
        color: #000000;
        font-family: "Inter", sans-serif;
        border-color: #EEEEEE;
        opacity: 0.4;
      }
      .available-qty-grey {
        font-size: 1rem;
        color: #000000;
        font-family: "Inter", sans-serif;
        border-color: #EEEEEE;
        opacity: 0.4;
      }
    }
  }
  .available-stock-adjust-btn {
    .adjust-stock-button {
      padding: 0.75rem 0.25rem !important;
    }
  }
  .available-stock-manufacturer-code {
    font-size: 12px;
    color: #000;
    font-weight: 400;
  }
  .available-stock-product-img {
    img {
      height: 50px;
      width: 50px;
    }
  }
  