@import "../../../../../../../../constants/colors.scss";

.product-search-filter-container {
  .filter-toogle-container {
    display: flex;
    justify-content: space-between;
    padding-top: 1.25rem;
    .filters {
      display: flex;
      button {
        margin-right: 0.625rem;
      }
    }
  }
}
.option-list-view-product{
    margin-top: 1.5rem;
    display: flex;
    gap: 0.625rem;
    flex-wrap: wrap;

    button{
      border: 0.063rem solid $darkGray;
      color: $black-color;
    }
}

.product-filter-search-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  input {
    width: 100%;
    padding: 0.75rem;
    padding-left: 2.875rem;
    font-size: 1rem;
    border-radius: 0.625rem;
    background-color: $bright-Gray;
    border: none;

    &:hover {
      background-color: $border-color;
    }

    &:focus-visible {
      background-color: $white-color;
      outline: auto;
      outline-color: $black-color;
    }
  }

  .clear-search-btn {
    right: 4px;
    position: absolute;
  }

  .search-icon,
  .search-tooltip {
    position: absolute;
    pointer-events: none;
  }

  .search-icon {
    left: 0.75rem;
  }
}