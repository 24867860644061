@import "apps/pos/assets/css/variable.scss";

.detail-half-table {
    max-width: 36.25rem;
    width: 100%;
}
.black-btn span{
color: $white-color;
}
.change-btn-padding{
    padding-right: 1rem;
}

.white-space-no-wrap {
    white-space: nowrap;
}

.storage-upload-btn{
    width: 8rem;
    min-width: 8rem;
    max-width: 8rem;
    height: 3rem;
}

.view-doc-btn{
    width: 5rem;
    min-width: 5rem;
    max-width: 5rem;
    height: 2.75rem;
  }