.product-detail-image-sec-border {
  border-bottom: 0.063rem solid #dddddd7d;
}
.product-detail-image-sec {
  padding-bottom: 1.875rem;
  margin-bottom: 1.875rem;
  .product-detail-large-image {
    margin: 0.625rem 0;
    img {
      height: 6.25rem;
      width: 6.25rem;
    }
  }
  .product-detail-medium-image {
    margin: 0.625rem 0;
    img {
      height: 3.75rem;
      width: 3.75rem;
    }
  }
}
.light-gray-detail-text {
  font-size: 1rem;
  opacity: 0.5;
}

.font-normal{
  font-weight: normal;
}
.font-medium{
  font-weight: 500;
}
.pb-70{padding-bottom: 10rem;}