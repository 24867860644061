@import "apps/pos/assets/css/variable.scss";

.product-search-scroll-styling {
  .MuiTableContainer-root {
    overflow-x: unset;
    .frequently-used-schedule {
      cursor: pointer;
    }
  }
}

.customer-and-cart-info {
  font-weight: 500;
  line-height: 1.15;
  font-family: var(--inter-font-family);
  text-align: left;
  max-width: 10rem;
  font-size: 0.875rem;
  cursor: default;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--bs-gray-600);
}

.cart-page-product-suggestion {
  @media (min-width: 48rem) {
    min-width: 40rem;
  }
}
.searched-product-data-styling.MuiTableBody-root {
  display: flex;
  flex-direction: column;
  @media (min-width: 0.75rem) {
    display: table-row-group;
  }
}

.searched-product-data-styling {
  .MuiTableRow-root {
    vertical-align: top !important;

    span.int-16-reg {
      font-weight: 400 !important;
    }

    .user-avatar {
      margin-right: 0.625rem;
    }
  }

  .frequently-used-schedule {
    padding: 0.625rem;
  }
}

.option-list-view{
  margin-top: auto;
  display: inline-flex;
  gap: 0.625rem;
  flex-wrap: wrap;

  button{
    border: 0.063rem solid rgba(0, 0, 0, 0.23);
    color: white;
    background-color: black;
    width: auto;
    box-sizing: content-box;
    height: 0.188rem;
  }
}

.search-tab-block {
  @media (max-width: 6.25rem) {
    padding: 0 1.875rem;
    padding-bottom: 50vh;
  }
  .more-results-product {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 120vh;
    position: relative;
    top: -100vh;
    margin-bottom: -100vh;
    pointer-events: none;
  }
}

.credit-hold-icon {
  svg {
    width: 1rem;
    height: 1rem;
    path {
      color: $black-color;
    }
  }
}