@import "../../apps/pos/assets/css/variable.scss";

.notes-model {
    &-header {
        position: sticky;
        top: 0;
        background-color: $white-color;
        font-size: 1.5rem;
        font-weight: 600;
        padding: 1rem 0.625rem 0.9375rem 1.875rem;

        &-close-icon {
            &:hover {
            svg {
                fill: $black-color;
            }
            }
        }
    }
    &-content {
        height: 100%;
        max-height: 33.75rem;
        overflow-y: auto;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;
            &::-webkit-scrollbar {
                background: transparent; /* Chrome/Safari/Webkit */
                width: 0px;
            }
    }
    &-footer {
        position: sticky;
        bottom: 0;
        background-color: $white-color;
    }

    .banned-sec-info {
        background-color: $solitaire-color;
        padding: 1.25rem 1.875rem;

        &-title {
            font-weight: 500;
            margin-bottom: 0.625rem;
            font-size: 1rem;
            color: $black-color;
            display: flex;

            .banned-danger-icon {
                height: 1.5rem;
                width: 1.5rem;
                display: flex;
                margin-right: 0.438rem;

                img {
                    height: 100%;
                    width: 100%;
                }
            }
        }

        &-data {
            margin-top: 1.25rem;
            font-weight: 400;
            font-size: 1rem;
            color: $black-color;
        }
    }

    .notes-sec-info {
        padding: 1.25rem 1.875rem;

        &-title {
            font-weight: 400;
            margin-bottom: 0.625rem;
            font-size: 0.625rem;
            color: $black-color;
            text-transform: uppercase;
            opacity: 1;
        }

        &-data {
            margin-top: 1.25rem;
            font-weight: 400;
            font-size: 1rem;
            color: $black-color;
        }
    }

    .notes-sec-border {
        border-bottom: 0.063rem solid $border-color;
        padding-bottom: 1.25rem;
    }

    .hide-price-checkbox {
        padding: 0 1.875rem 1.25rem;
        margin-top: 0;

        .MuiFormGroup-root {
            border-top: 0.063rem solid $border-color;

            .MuiFormControlLabel-root {
                margin: 0;
            }
        }
    }

    .notes-btn-div {
        padding: 0 1.875rem 1.875rem; 
        .back-button {
            letter-spacing: -0.32px;
            opacity: 1;
            .MuiButton-label {
                font-weight: 500;
            }
        }
    }
}

.imp-notes-icon {
    height: 1.25rem;
    width: 1.25rem;
    display: flex;
    margin-left: 0.438rem;
}

.notes-modal-close-btn{
    min-width: 2.75rem;
    max-width: 2.75rem;
    height: 2.75rem;
}

.customer-notes-back-btn{
    width:     4.813rem;
    min-width: 4.813rem;
    max-width: 4.813rem;
    height: 2.75rem;
}

.customer-notes-continue-btn{
    width:     6.688rem;
    min-width: 6.688rem;
    max-width: 6.688rem;
    height: 2.75rem;
}