@import "apps/pos/assets/css/variable.scss";

.storage-date-picker {
  .MuiFormControl-root {
    width: 100%;

    .MuiInputBase-root,
    fieldset {
      border-radius: 0.625rem;
      border-color: $black-color !important;
    }

    input {
      padding: 0;
      height: auto;
      font-weight: 600;
    }

    .MuiInputBase-root,
    fieldset {
      border: none !important;
    }

    .MuiInputBase-root {
      background-color: #f1f1f1;
    }

    .MuiButtonBase-root {
      margin-right: 0.375rem;

      svg {
        color: $black-color;
      }
    }
  }
}

.modal-input-bottom-spacing {
  margin-bottom: 1.875rem;
}

.set-storage-modal-container {
  .modal-header {
    border-bottom: none;
    padding: 1.875rem 1.875rem 0.938rem !important;
    align-items: flex-start;
  }

  .MuiDialogContent-root {
    padding: 0.938rem 1.875rem 0.938rem !important;
  }

  .MuiDialogActions-root {
    padding: 0.938rem 1.875rem 1.875rem !important;
  }
}

.undo-delivery-modal-footer {
  width: 100%;
  justify-content: space-between;
}

.small-page-heading span {
  font-size: 1.375rem;
}

.storage-data {
  display: flex;
  border-bottom: 0.063rem solid #dddddd;
  padding: 0.75rem 0;

  .storage-data-left-sec {
    width: 45%;

    .storage-data-title {
      font-weight: 500;
    }
  }

  .storage-data-right-sec {
    width: 55%;

    .storage-first-data {
      font-weight: 600;
    }

    .storage-second-data {
      color: #555555;
    }
  }
}

.storage-data-modal-container .MuiDialog-paper {
  max-width: 40rem !important;
  width: 100% !important;
}

.put-storage-modal-container {
  .modal-header {
    padding-bottom: 0 !important;
  }
}

.collapse-image {
  opacity: 0.5;
  cursor: pointer;
  padding: 0.375rem;
  display: flex;
  align-items: center;

  &:hover {
    background-color: $bright-Gray;
    border-radius: 1.563rem;
  }

  img {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.set-storage-type-section-padding {
  padding-bottom: 1.25rem;
}

.back-order-confirmation-modal-btn {
  max-width: 16rem;
  height: 2.75rem;
  color: $orange-color !important;
}

.message-body {
  color: $black-color;

  .table-header {
    th {
      font-size: 0.625rem;
      font-weight: 400;
      letter-spacing: 0.0625rem;
      color: #000000;
      text-transform: uppercase;
      text-align: left;
    }

  }
}

.set-storage-back-btn {
  min-width: 4.813rem;
  max-width: 4.813rem;
  height: 2.75rem;
}

.undo-delivery-modal-back-btn {
  min-width: 6.25rem;
  max-width: 6.25rem;
  height: 2.75rem;
}

.justify-end {
  justify-content: end !important;
}

.set-backorder-modal-container {

  .MuiDialogContent-root {
    padding: 0rem 1.875rem 0.938rem !important;
  }

  .MuiDialogActions-root {
    padding: 0.938rem 1.875rem 1.875rem !important;
  }
}

.set-backorder-code-modal-container {
  .MuiOutlinedInput-root {
    padding: 0.625rem !important;
  }

  .MuiDialog-paperWidthSm {
    max-width: 43.75rem;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    padding: 0.938rem 1.7rem !important;
    border-bottom: none;

    .clear-icon {
      font-size: 2.5rem;
      padding: 0.625rem;
      cursor: pointer;

      &:hover {
        background-color: $bright-Gray;
        border-radius: 1.563rem;
      }
    }

    .button-focus {
      &:focus-visible {
        border-radius: 1.563rem !important;
        outline: 0.13rem solid var(--bs-gray-600) !important;
      }
    }
  }

  .MuiDialog-container {
    .MuiDialog-paper {
      width: 50.375rem;
      height: auto;
      border-radius: 1.25rem;
    }

    .MuiDialogActions-root {
      padding: 1.5rem;

      .add-to-return-cart-button-error {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }
  }

  .MuiPaper-root {
    box-shadow: none;
  }

  .MuiTable-root {
    thead {
      top: 0;
      position: relative;
    }


  }
}

.back-order-info {
  max-width: 22.1875rem;
}

.manufacturer-code {
  text-align: left;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0rem;
  color: #555555;
}

.backorder-qty {
  text-align: right !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  letter-spacing: 0rem !important;
  vertical-align: top !important;
}

.line-id-backorder {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  letter-spacing: 0rem !important;
  vertical-align: top !important;
}