.sb-btn {
  --bs-danger: 209, 48, 39;
  font-family: var(--inter-font-family), "Inter", sans-serif;
  border: none;
  display: flex;
  align-items: center;
  text-decoration: none !important;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.0125rem;
  user-select: none;
  margin: 0;
  padding: 0;
  line-height: 1;
  outline: revert !important;
  max-width: fit-content;

  &[disabled] {
    opacity: 0.35;
    cursor: default;
  }

  .label {
    line-height: 1;
    margin: 0;
    padding: 0;
    font-weight: inherit;
    font-size: inherit;
    font-family: inherit;
    letter-spacing: inherit;
    white-space: nowrap;
    color:inherit;
  }

  &:link {
    text-decoration: none !important;
  }
  

  &.type-primary {
    background-color: #000;
    color: #fff !important;
    --hover: #222;
    --active: #555;

    &:link{
      color: #fff !important;
    }
  }

  &.type-secondary {
    background-color: var(--bs-gray-200);
    color: #000 !important;
    --hover: var(--bs-gray-300);
    --active: var(--bs-gray-400);

    &:link{
      color: #000 !important;
    }
  }

  &.type-tertiary {
    background-color: rgba(255, 255, 255, 0);
    color: #000 !important;
    --hover: var(--bs-gray-100);
    --active: var(--bs-gray-200);

    &:link{
      color: #000 !important;
    }
  }

  &.type-outline {
    background-color: rgba(255, 255, 255, 0);
    color: #000 !important;
    --hover: var(--bs-gray-100);
    --active: var(--bs-gray-200);
    box-shadow: inset 0 0 0 1px var(--active);
    &:link{
      color: #000 !important;
    }
  }
  
  &.type-danger {
    background-color: rgba(var(--bs-danger), 0.1);
    color: rgba(var(--bs-danger), 1) !important;
    --hover: rgba(var(--bs-danger), 0.2);
    --active: rgba(var(--bs-danger), 0.25);

    &:link{
      color: rgba(var(--bs-danger), 1) !important;
    }
  }

  &.size-medium {
    gap: 0.625rem;
    padding: 0.75rem 1.25rem;
    border-radius: 0.9375rem;

    &.icon-leading {
      padding-left: 1rem;
      color: inherit !important;
    }

    &.icon-trailing {
      padding-right: 1rem;
      color: inherit !important;
    }
  }

  &.size-small {
    gap: 0.375rem;
    padding: 0.375rem 0.625rem;
    border-radius: 0.625rem;
    color: inherit !important;
    &.icon-leading {
      padding-left: 0.625rem;
      color: inherit !important;
    }

    &.icon-trailing {
      padding-right: 0.625rem;
      color: inherit !important;
    }
  }

  &:not(:disabled) {
    cursor: pointer;

    &:hover,
    &:focus-visible {
      background-color: var(--hover);
    }

    &:active {
      background-color: var(--active);
    }
  }

  &.busy {
    cursor: wait;
  }
}