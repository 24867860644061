$border-color:#DDDDDD;
$gray:#919191;
$gray-50:#F9F9F9;
$white-color:#ffffff;
$darkGray : #AAAAAA;
$black-color:#000000;
$bgColor:#f1f1f1;
$bright-Gray:#EEEEEE;
$description-color:#555555;
$light-black-color:#0000007a;
$lightBlack-color: #00000029;
$orange-color:#FC6100;
$cinnamon-color:#785200;
$dusty-gray-color:#999999;
$solitaire-color:#fef4e5;
$box-shadow-color:#CCCCCC;
$red-color: #ff0000;
$light-gray: #F5F5F5;
$light-gray-color: #c1c1c1;
$light-gray-border: #797979e0;
$dark-mint-green: #36C759;
$arylide-yellow: #FFCF6E;
$shocking-orange: #EB4E3D;
$dartmouth-green: #00761C;
$gray-88: #888888;
$light-parrot-color: #EAF9EE;
$light-opacity-gray-color: #aaaaaa40;
$purple-color: #5856D6;
$yellow-color: #FFCF6E;
$dark-red-color: #910202;
$gray-option: #dddddd87;
$btn-back-black: #FFFFFF00;
$dark-black-text-color: #353535;
$yellow-color-light : #F7CE46;
$red-icon-color: #ff3b2f;
$orange-icon-color: #ff9500;
$pink-icon-color: #bf5af2;
$yellow-icon-color: #ffcc02;
$green-icon-color: #35c759;
$tron-blue-icon-color: #03c7be;
$sky-blue-icon-color: #30b0c7;
$blue-lvy-icon-color: #31ade6;
$blue-icon-color: #007aff;
$purpal-icon-color: #5856d7;
$dark-pink-icon-color: #af52de;
$dark-red-icon-color: #ff2c55;
$coral-icon-color: #a2845e;
$dark-red-text-color: #EC3E37;
$fern-green-color:#417D41;
$warning-yellow-color: #f4a100;
$dark_purple_bg :#5856d621;
