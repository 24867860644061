/* workstation page */
@import "../pos/assets/css/variable.scss";
@import "../../constants/colors.scss";

*:focus {
  outline: none;
}

.workstation-bottom {
  display: flex;
  align-items: center;
  background-color: $bright-Gray;
  border-radius: 10px;
  padding: 10px;
  bottom: -30px;

  .workstation-bottom-text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 10px;
  }
}

.border-bottom-1 {
  border-bottom: 1px solid #e4ddf7 !important;
}

.ws-continue {
  cursor: pointer;
  background-color: #000000;
  color: #fff;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  border-radius: 20px;
  width: 275px;
  height: 60px;
  margin-top: 20px;
}

.ws-select {
  line-height: 1.5 !important;
  width: 560px !important;
  height: 60px !important;
  border-radius: 15px !important;
  margin-top: 10px;
  border: 2px solid #000000 !important;
  font-size: 20px !important;
  font-family: "Inter", Medium;

  option {
    font-size: 1.25rem;
  }
}

.add-address-btn {
  border-radius: 20px;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  background-color: #f1f1f1;
  width: 250px;
  height: 60px;
  margin-bottom: 25px;
  font-size: 18px;
  font-style: "inter";
  font-weight: 500;
}

.btn-text-design {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.btn-text-design {
  display: flex;
  flex-direction: column;
}

.default-facility-name {
  border-radius: 20px;
  border: 0px solid;
  display: flex;
  align-items: center;
  padding-left: 20px;
  background-color: #f1f1f1;
  height: 60px;
  margin-bottom: 25px;
  margin-top: 10px;
  font-size: 18px;
  font-style: "inter";
  font-weight: 500;
}

.phone-number-input {
  border-radius: 20px;
  border: none;
  display: flex;
  align-items: center;
  padding-left: 20px;
  background-color: #f1f1f1;
  height: 60px;
  margin-bottom: 25px;
  margin-top: 10px;
  margin-right: 20px;
  font-size: 18px;
  font-style: "inter";
  font-weight: 500;
  width: 50%;
}

.phone-ext-input {
  border-radius: 20px;
  border: none;
  display: flex;
  align-items: center;
  padding-left: 20px;
  background-color: #f1f1f1;
  height: 3.75rem;
  margin-bottom: 25px;
  margin-top: 0.625rem !important;
  font-size: 18px;
  font-style: "inter";
  font-weight: 500;
  width: 25%;
}

.phone-ext-title {
  width: 30%;
}

.phone-number-field-title {
  width: 70%;
}

.phone-country-code-input {
  border-radius: 1.25rem;
  border: none;
  display: flex;
  align-items: center;
  padding-left: 1.25rem;
  background-color: #f1f1f1;
  height: 3.75rem;
  margin-bottom: 1.563rem;
  margin-top: 0.625rem !important;
  margin-right: 1.25rem !important;
  font-size: 1.125rem;
  font-style: "inter";
  font-weight: 500;
  width: 25%;
}

.phone-country-code-input-admin {
  border-radius: 0.35rem;
  border: 0.063rem solid #c5ccd6;
  display: flex;
  margin-bottom: 1.563rem;
  align-items: center;
  width: 25%;
  padding: 0.875rem 1.125rem;
  font-size: 14px;
  font-weight: 500;
  font-style: "inter";
  line-height: 1;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  -webkit-appearance: none;
  height: 3.125rem;
  -moz-appearance: none;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.phone-country-code-input-admin:focus {
  outline: none;
  box-shadow: none !important;
  border-color: #000 !important;
}

.phone-number-input-admin {
  border-radius: 0.35rem;
  border: 1px solid #c5ccd6;
  display: flex;
  align-items: center;
  width: 50%;
  padding: 0.875rem 1.125rem;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  -webkit-appearance: none;
  height: 3.125rem;
  margin-left: 0.625rem;
  -moz-appearance: none;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.phone-number-input-admin:focus {
  outline: none;
  box-shadow: none !important;
  border-color: #000 !important;
}

.phone-ext-input-admin {
  border-radius: 0.35rem;
  margin-left: 10px;
  border: 1px solid #c5ccd6;
  display: flex;
  align-items: center;
  width: 25%;
  padding: 0.875rem 1.125rem;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  -webkit-appearance: none;
  height: 3.125rem;
  -moz-appearance: none;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.phone-ext-input-admin:focus {
  outline: none;
  box-shadow: none !important;
  border-color: #000 !important;
}

.set-new-pass-admin {
  border-radius: 0.35rem;
  border: 1px solid #c5ccd6;
  display: flex;
  margin-bottom: 25px;
  align-items: center;
  width: 100%;
  padding: 0.875rem 1.125rem;
  font-size: 14px;
  font-weight: 500;
  font-style: "inter";
  line-height: 1;
  color: #69707a;
  background-color: #fff;
  background-clip: padding-box;
  -webkit-appearance: none;
  height: 3.125rem;
  -moz-appearance: none;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.set-new-pass-admin:focus {
  outline: none;
  box-shadow: none !important;
  border-color: #000 !important;
}

.facility-box {
  display: flex;
  flex-direction: column;

  .location-picker-wrapper {
    right: 100px;
  }

  .input {
    .MuiInputBase-input {
      height: 0rem !important;
    }
  }

  .payment-select {
    .MuiInputBase-input {
      height: 0rem !important;
    }

    .MuiFilledInput-input {
      padding: 1.0625rem !important;
    }
  }
}

.manufactureAdd {
  display: block;
  flex-direction: column;

  .location-picker-wrapper {
    right: 100px;
  }
}

.phone-number-title {
  display: flex;
  justify-content: space-between;
}

.set-new-pass-title {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.facilit-phone-number {
  display: flex;
}

.facilit-set-new-pass {
  display: flex;
  position: relative;
}

.phone-optional-head {
  color: #7d7d7d;
}

.purchase-order-text {
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: #eeeeee;
  border-radius: 10px;
  margin-top: 30px;
}

.customer-credit-hold-text {
  background-color: $solitaire-color;
  align-items: left;
  height: 5.063rem;
  display: flex;
  flex-direction: row;
  border-radius: 0.625rem;
  justify-content: space-between;

  .credit-hold-text {
    .MuiSvgIcon-root {
      fill: $orange-color;
    }
  }
}

.credit-hold-text {
  font-weight: 600;
  margin: 0.625rem 0 0 0.625rem;
  font-size: 18px;
  color: $black-color;
}

.customer-details-section {
  margin-top: 30px;
}

.customer-button-section {
  margin-top: 30px;
  display: flex;
}

.btn-change-customer {
  margin-right: 20px;
}

.header-profile-avatar-customer {
  width: 60px;
  height: 60px;
  background-color: #bcf0c9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  margin-bottom: 30px;
  font-size: 1.25rem;
  color: #000000;
}

.marginleft20 {
  margin-left: 10px;
}

.left-cart-btns {
  display: flex;
}

.order-num-input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 5px;
}

.order-num-input:focus {
  border: none;
}

.char-count {
  display: flex;
  justify-content: flex-end;
  padding: 0 0.625rem;

  span {
    font-size: 0.875rem;
    color: $dusty-gray-color;
  }
}

.btns-container-discard-save {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.btn.btn-discard {
  background-color: #f1f1f1;
  border-radius: 20px;
  display: flex;
  margin-right: 20px;
  align-items: center;
  font-size: 18px;
  font-family: "inter";
  line-height: 21px;
  color: #000000;
  padding-left: 25px;
  border: none;
  justify-content: flex-start;

  .MuiButton-label {
    justify-content: flex-start !important;
    font-weight: bold !important;
  }
}

.font-size {
  .MuiButton-label {
    font-size: large !important;
    justify-content: unset;
  }
}

.icn-btn {
  margin-right: 0.625rem;
}

.pos-divider {
  margin: 1rem 0 !important;
  color: inherit;
  background-color: currentColor !important;
  opacity: 0.25;
}

.pos-divider-balance {
  margin: 1rem 0 !important;
  color: inherit;
  background-color: currentColor !important;
  opacity: 1;
}

.pos-divider-black {
  margin: 1rem 0 !important;
  color: $black-color;
  background: $black-color 0% 0% no-repeat padding-box;
  opacity: 0.5;
}

.icn-btn-admin {
  margin-right: -10px;
}

.icn-btn-admin-svg {
  margin-right: -10px;
  margin-top: 12px;
}

.btn.btn-savechanges,
.btn-savechanges {
  background-color: #000000;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  // margin-left: 15px;
  align-items: center;
  font-weight: bold;
  font-family: "inter";
  line-height: 21px;
  color: #ffffff;
  border: none;
  justify-content: flex-start;
  font-size: 1rem;

  &:hover {
    color: #ffffff;
  }
}

.edit-purchase-input {
  height: 70px;
  border: 2px solid #000000;
  /* border-radius: 15px; */
  font-size: 1.25rem;
  font-family: "Inter", Regular;
  line-height: 24px;
  margin-top: 20px;
}

.edit-purchase-input:focus-visible {
  border: none;
  border-radius: 15px;
  font-size: 1.25rem;
  font-family: "Inter", Regular;
  line-height: 24px;
  margin-top: 20px;
}

.order-heading {
  margin-top: 25px;
  display: flex;
  margin-bottom: 1.875rem;
}

.quote-heading {
  display: flex;
  margin-bottom: 1.875rem;
}

.neworder-heading {
  font-size: 25px;
  color: #999999;
  font-family: "Inter", Semibold;
}

.pricing-modal-heading {
  font-size: 28px;
  color: #999999;
  font-family: "Inter", Semibold;
  margin-bottom: 0;
}

.workstation-subheading {
  font-family: "Inter", regular;
  margin-bottom: 0.625rem;
  color: $black-color;
  font-weight: 500;
  font-size: 1.25rem;
}

.return-items {
  display: flex;
  justify-content: flex-end !important;
  align-items: center;

  .return-items-btn {
    font-weight: 500 !important;
    opacity: 1;
    letter-spacing: -0.32px;
    padding: 0.625rem 0.6875rem !important;
    border-radius: 0.75rem !important;

    &:hover {
      .MuiButton-label {
        .MuiButton-startIcon {
          color: $black-color;
        }
      }
    }

    .MuiButton-label {
      .MuiButton-startIcon {
        color: $black-color;
      }
    }
  }

  .order-page-setting-icon {
    color: $gray !important;

    &:hover {
      color: $black-color !important;
      background-color: $bright-Gray !important;
    }
  }
}

.privacy-settings-icon {
  display: flex;
  justify-content: flex-end !important;
  padding: 20px;
  display: flex;
  justify-content: flex-end !important;
  padding: 1.5rem 1.875rem;
  position: fixed;
  width: inherit;
  background: white;
  z-index: 3;

  .icon {
    cursor: pointer;

    g {
      fill: #000000;
    }

    &:hover {
      g {
        fill: #000000;
        opacity: 1;
      }
    }
  }
}

.new-order-heading {
  font-size: 24px;
  font-weight: 500;
  color: #999999;
  font-family: "Inter", Semibold;
}

.select-customer-heading {
  font-size: 28px;
  color: #000;
  margin: 0px -20px 0px 0px;
  font-family: "Inter", Semibold;
  font-weight: 700;
}

.neworder-vendor-name {
  font-size: 22px;
  color: #999999;
  font-family: "Inter", Semibold;
}

.neworder-mfg-code {
  font-size: 14px;
  color: #999999;
  margin-top: 10px;
  letter-spacing: 0.14px;
  text-transform: uppercase;
}

.no-items-text {
  padding-right: 10px;
}

.select-heading {
  font-weight: 700;
  font-size: 28px;
  color: #000;
  margin: 0px -20px 0px 0px;
  font-family: "Inter", Semibold;
  // font-weight: bold;
}

.order-sub-heading {
  width: 100%;
}

.new-customer-line {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  .title {
    letter-spacing: -0.035rem;
  }
}

.new-customer-line-quote {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .title {
    letter-spacing: -0.035rem;
  }
}

.pricing-schedule-heading {
  font-size: 25px;
  font-weight: 700;
  color: #000;
  margin: 0px 20px 40px 0px;
  font-family: "Inter", Semibold;
  letter-spacing: -0.5px;
}

.select-product-name {
  font-weight: 700;
  font-size: 28px;
  color: #000;
  font-family: "Inter", Semibold;
}

.clear-cart-button {
  font-size: 1rem;
  font-weight: 500;
  border: none;
  text-align: center;
  width: 100px;
  padding: 8px 0;
  color: #000000;
  border-radius: 10px;
  background-color: #f1f1f1;
  cursor: pointer;

  &:hover {
    background-color: #dddddd;
  }
}

.gray-hover {
  &:hover {
    background-color: #dddddd;
  }
}

.select-heading-gray {
  font-size: 28px;
  font-weight: 700;
  color: #999;
  font-family: "Inter", Semibold;
  // font-weight: bold;
}

.horizontal-divider {
  border-right: 1px solid #ddd;
  height: 80px;
}

.order-search-border {
  background-color: #eeeeee;
  height: 60px;
  border-radius: 10px;
  display: flex;
  align-self: end;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.order-search-results {
  background-color: #ffffff;
  height: 60px;
  border: 1px solid #000000;
  border-radius: 10px;
  display: flex;
  align-self: end;
  align-items: center;
  justify-content: center;
  margin: 0px 5px;
  width: 100%;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
}

.jon-name-save-for-later {
  border: 0px;
  border-radius: 0;
  margin: 0;
  padding: 0;
  height: auto;

  input {
    margin: 0 0 0 5px;
    padding: 10px 0;
    height: auto;
  }
}

.shipping-quantity-btn {
  margin-left: 0;
}

.cases-order {
  padding: 0px 20px 0px 15px;
}

.order-search-input {
  margin: 0 10px;
  width: 100%;
  border: none !important;
  height: 50px;
  font-size: 18px;
  font-family: "Inter", Regular;
  background-color: #eeeeee;
  font-weight: 500;
  padding: 0;
  color: #000;

  &::placeholder {
    color: #000;
  }
}

.order-text {
  justify-content: center;

  input::placeholder {
    font-size: 1.25rem;
    opacity: 1;
  }
}

.order-search-input-results {
  margin: 0 10px;
  width: 100%;
  border: none !important;
  height: 50px;
  font-size: 18px;
  font-family: "Inter", Regular;
  background-color: #ffffff;
  font-weight: 500;
}

.order-search-input:focus {
  outline: none;
}

.order-search-input-results:focus {
  outline: none;
}

.customer-search-border {
  height: 3.75rem;
  background-color: var(--bs-gray-200);
  border: 0.125rem solid var(--bs-gray-200);
  border-radius: 0.938rem;
  display: flex;
  align-self: end;
  align-items: center;
  justify-content: center;
  margin: 0px 5px;
  width: 100%;
  padding: 0 10px 0 20px;
}

.direction-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.625rem;
  margin-top: 0.625rem;

  .f-btn-chips {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    border: none;
    border-radius: 0.625rem;
    padding: 0.625rem 0.75rem;
    background-color: var(--bs-gray-50);

    &:hover,
    &:focus-visible {
      background-color: var(--bs-gray-100);
    }

    &:active {
      transform: scale(0.975);
      background-color: var(--bs-gray-200);
    }

    .f-btn-chip-label {
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0;
    }
  }
}

.customer-search-results {
  height: 3.75rem;
  background-color: #ffffff;
  border: 2px solid #000;
  border-radius: 0.938rem;
  display: flex;
  align-self: end;
  align-items: center;
  justify-content: center;
  margin: 0px 5px;
  width: 100%;
  cursor: pointer;
  padding: 0 10px 0 20px;
}

.customer-search-results1 {
  background-color: #ffffff;
  min-height: 3.75rem;
  border: 2px solid #000;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  display: flex;
  align-self: end;
  align-items: center;
  justify-content: center;
  // margin: 0px 5px;
  width: 100%;
  cursor: pointer;
  padding: 0 10px 0 20px;
}

.customer-search-results2 {
  background-color: #ffffff;
  min-height: 3.75rem;
  border: 2px solid #000;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  align-self: end;
  align-items: center;
  justify-content: center;
  // margin: 0px 5px;
  width: 100%;
  cursor: pointer;
  padding: 0 10px 0 20px;
}

.keyboard-shortcut {
  background-color: #ffffff;
  padding: 0px 5px;
  font-size: 14px;
  font-weight: 600;
  box-shadow: 4px 3px 6px -3px rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 4px 3px 6px -3px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 4px 3px 6px -3px rgba(0, 0, 0, 0.19);
}

.keyboard-shortcut-pay {
  background-color: #ffffff;
  padding: 0px 5px;
  font-size: 14px;
  color: #000000;
  font-weight: 600;
  box-shadow: 4px 3px 6px -3px rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 4px 3px 6px -3px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 4px 3px 6px -3px rgba(0, 0, 0, 0.19);
}

.keyboard-shortcut-backslash {
  background-color: #ffffff;
  padding: 0px 5px;
  font-size: 14px;
  font-weight: 600;
  box-shadow: 4px 3px 6px -3px rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 4px 3px 6px -3px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 4px 3px 6px -3px rgba(0, 0, 0, 0.19);
}

hr.solid {
  margin-top: 0px;
  border-top: 3px solid #bbb;
}

hr.solid-dark {
  margin-top: 0px;
  border-top: 1px solid #bbb;
  margin-bottom: 25px;
}

.table-divider {
  border-bottom: 0.063rem solid #eee;
  vertical-align: baseline !important;
}

.table-divider:hover {
  background-color: #eee !important;
}

.swatch-customer-container {
  padding-left: 50px !important;
  padding-right: 138px !important;

  @media only screen and (max-width: 768px) {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
}

.new-order-main-section {
  .new-container-order {
    width: 100%;
    display: flex;
    gap: 29px;
    padding-left: 138px;
    padding-right: 138px;

    @media only screen and (max-width: 768px) {
      flex-wrap: wrap;
      padding-left: 70px;
      padding-right: 70px;
    }

    .back-button {
      border-radius: 1.25rem;
      background: $bgColor 0% 0% no-repeat padding-box;
      padding: 1.125rem;
      height: 100%;

      .back-icon {
        fill: $black-color;
      }

      &:hover {
        background: $bgColor 0% 0% no-repeat padding-box;
      }
    }

    .order-heading {
      width: 100%;
      margin-top: 0px;
    }
  }

  .search-with-content-section {
    width: 100%;
    padding-left: 8.625rem;
    padding-right: 8.625rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 768px) {
      padding-left: 4.375rem;
      padding-right: 4.375rem;
    }

    .search-wrapper {
      width: 100%;
    }
  }
}

.order-layout {
  display: flex;

  .loader-width {
    width: 71%;
    margin-left: 218px;
  }
}

.order-page-buttons {
  display: flex;
  justify-content: flex-end;
  margin: 20px;
  align-items: center;
}

.progress-bar-new-customer {
  margin: 50px 0 0 0;
  display: flex;

  .progress-fill {
    border-radius: 0;

    &:first-child {
      border-top-left-radius: 3.125rem;
      border-bottom-left-radius: 3.125rem;
    }

    &:last-child {
      border-top-right-radius: 3.125rem;
      border-bottom-right-radius: 3.125rem;
    }
  }
}

.order-page-new-customer {
  margin-right: 10px;
}

.hover-last-cell {
  opacity: 0;
}

.table-divider:hover .hover-last-cell {
  opacity: 1;
}

/* Pricing modal styles */

.modal-content-style {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 89vh;
}

.pricing-modal-header-address {
  margin: 45px 0 10px 0;
}

.progress-bar-new-address {
  padding: 0 0 0 1.5rem;
  margin: 3.125rem 0 0 0;
  display: flex;

  .progress-fill {
    border-radius: 0;

    &:first-child {
      border-top-left-radius: 3.125rem;
      border-bottom-left-radius: 3.125rem;
    }

    &:last-child {
      border-top-right-radius: 3.125rem;
      border-bottom-right-radius: 3.125rem;
    }
  }
}

.progress-bar-step {
  background-color: #000000;
  width: 1.875rem;
  height: 0.3125rem;
  border-radius: 3.125rem;
  opacity: 1;
}

.progress-bar-steps {
  background-color: #f1f1f1;
  width: 1.875rem;
  height: 0.3125rem;
  border-radius: 3.125rem;
  opacity: 1;
}

.fill-progress-bar {
  background-color: #000000;
  width: 1.875rem;
  height: 0.3125rem;
  border-radius: 3.125rem;
  opacity: 1;
}

.pricing-modal-header {
  margin-top: 3.438rem;
  margin-bottom: 10px;
}

.schedule-shortcut {
  background-color: #fff;
  color: #000;
  padding: 2px 4px;
  font-size: 0.813rem;
  border-radius: 3px;
}

.frequently-used-schedule {
  // cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 15px;
  margin-top: 0;
  border-bottom: 0.063rem solid #eee;
  // cursor: pointer;
  /* margin: 10px 0px; */
}

.pricing-options-section {
  .frequently-used-schedule {
    .keyboard-shortcut-pay {
      display: block;
    }

    svg.right-icon {
      display: none;
    }

    &:hover {
      .keyboard-shortcut-pay {
        display: none;
      }

      svg.right-icon {
        display: block;
      }
    }
  }
}

.select-ws-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0px;
  /* margin: 10px 0px; */
}

// .frequently-used-schedule:hover {
//   background-color: #eee;
//   border-radius: 7.5px;
// }

.frequently-used-schedule-index {
  background-color: #eee;
  border-radius: 7.5px;
}

.right-icon {
  opacity: 0;
}

.frequently-used-schedule:hover .right-icon {
  opacity: 1;
}

.modal-subheading {
  margin-bottom: 10px;
  margin-top: 20px;
  font-family: "Inter", Regular;
  font-size: 10px;
  letter-spacing: 0.5px;
  display: block;
}

.align-center {
  font-size: 0.75rem;
  text-align: center;
}

.modal-subheading-delete {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.modal-button {
  position: absolute;
  bottom: 20px;
  width: 90%;
}

.modal-select-default-btn {
  background-color: #000000 !important;
  color: #fff;
  padding: 0px 20px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  border-radius: 20px;
  margin-bottom: 20px;
  height: 60px;
  cursor: pointer;

  &.button-is-loading {
    position: relative;
    overflow: hidden;
    pointer-events: none;

    .button-label,
    .button-icon {
      opacity: 0;
    }

    &::before {
      content: "";
      position: absolute;
      background-color: var(--bs-gray-200);
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      border-radius: 0.625rem;
    }

    &::after {
      content: "";
      position: absolute;
      height: 1.5rem;
      width: 1.5rem;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNNiAxMGMtMS4xIDAtMiAuOS0yIDJzLjkgMiAyIDIgMi0uOSAyLTItLjktMi0yLTJ6bTEyIDBjLTEuMSAwLTIgLjktMiAycy45IDIgMiAyIDItLjkgMi0yLS45LTItMi0yem0tNiAwYy0xLjEgMC0yIC45LTIgMnMuOSAyIDIgMiAyLS45IDItMi0uOS0yLTItMnoiLz48L3N2Zz4=");
      background-position: center;
      background-repeat: no-repeat;
      opacity: 0;
      animation: button-loading-anim 0.3s ease-out alternate infinite;

      @keyframes button-loading-anim {
        to {
          opacity: 1;
          transform: scale(1.1);
        }
      }
    }
  }
}

.select-default-text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.default-schedule-heading {
  display: flex;
  margin-left: 15px;
  flex-direction: column;
}

.selectShipping-address {
  display: flex;
  justify-content: space-between;
}

.select-schedule-list {
  margin-bottom: 0 !important;
  font-size: 20px !important;
  font-family: "Inter", Medium !important;
  font-weight: 500 !important;
  color: #000000 !important;
  margin-right: 90px;
  cursor: pointer;
}

.all-schedules-names {
  margin-top: 2%;
}

.back-to-top-sales-person {
  width: 400px;
  margin: 30px auto 26px auto;
  display: flex;
  align-items: center;
  border: 1px solid #eeeeee;
  height: 60px;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 30px;
  letter-spacing: 0.0313rem;

  .back-to-top-text {
    font-size: 18px !important;
    font-family: "Inter", Medium !important;
    font-weight: 500 !important;
    color: #000000 !important;
    cursor: pointer;
  }

  .jump-back-text {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.all-pricing-schedules {
  margin-top: 30px;

  .warehouse-select {
    .Mui-focused {
      border-bottom: 0.125rem solid $black-color !important;
    }

    .MuiInputBase-root {
      border: 0.125rem solid $black-color;
      background-color: $white-color !important;

      .MuiFilledInput-input {
        background-color: $white-color !important;
      }
    }
  }

  .warehouse-select {
    .MuiInputBase-root {
      border: 0.125rem solid $black-color;

      .MuiFilledInput-input {
        background-color: $white-color !important;
      }
    }
  }
}

.schedule-btn-text {
  margin: 0px 16px 0px 0px;
  font-size: 18px;
  font-family: "Inter", Semibold !important;
  font-weight: 500;
}

.dollar-price {
  display: flex;
}

.dollar-symbol {
  font-weight: 20px;
}

.pricing-name {
  color: #bbbbbb;
  font-weight: bold;
  /* font-size: 1rem; */
}

.pricing-heading {
  font-weight: bolder;
}

/* Avtar user list  */

.avatar-speed-dial-list {
  margin-top: 3.125rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 100%;

  @media (min-width: 600px) {
    width: 30rem;
  }

  @media (min-width: 900px) {
    width: 40rem;
  }

  .suggestions-list {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    width: 100%;
  }
}

.saved-draft-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.9375rem 0.9375rem;
  margin-top: 0;
}

.date-title {
  width: 100%;
  max-width: fit-content;
  color: $description-color;
  font-size: 0.75rem;
}

.divider-date {
  height: 0.0625rem;
  width: 88%;
  background-color: #dedddd;
  margin-left: 0.625rem;
}

.int-12-reg-gray {
  font-size: 0.75rem;
  line-height: 1.0625rem;
  font-family: "Inter", Regular;
  color: #999999;
}

.int-16-reg-gray {
  font-size: 1rem;
  line-height: 1.0625rem;
  font-family: "Inter", Regular;
  color: #999999;
}

.order-loader {
  color: #000000 !important;
  width: 100%;
  display: flex;
  justify-content: center;
}

.user-avatar-item {
  background-color: #f9f9f9;
  width: 220px !important;
  height: fit-content;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  justify-content: space-between;
  border-radius: 30px;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
    color: #000000;
  }
}

.avatar-username {
  font-size: 0.875rem;
  font-weight: 500;
}

.avatar-username,
.avatar-username-line-2 {
  line-height: 1;
  font-family: "Inter", sans-serif;
  color: #000;
}

.avatar-username-line-2 {
  font-size: 0.875rem;
  font-weight: 400;
}

.product-suggestion-name {
  font-size: 14px !important;
  font-family: "Inter", Regular;
  margin-left: 20px;
}

.user-avatar {
  display: inline-flex;
  // height: 40px;
  // width: 40px;
  border-radius: 50%;
  position: relative;
  align-items: center;
  justify-content: center;
  font-size: 16px !important;
  font-family: "Inter", Medium;

  span {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.user-avatar-list {
  display: inline-flex;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: relative;
  align-items: center;
  justify-content: center;
  font-size: 16px !important;
  font-family: "Inter", Medium;
  margin-left: 10px;
}

.no-cart-data-found-text {
  margin-left: -30%;
}

.shortcuts-line {
  margin-top: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-data-found-class {
  display: flex;
  justify-content: center;
  padding-top: 3rem;
}

.search-data-found {
  align-self: center;
  margin-top: 3rem;
  margin-left: -6rem;
}

.search-data-found-text {
  align-self: center;
  margin-left: -8rem;
}

.no-cart-data-found {
  align-self: center;
  margin-top: 0.5rem;
}

.use-keyboard-line {
  margin-left: 10px;
  font-size: 12px;
  font-family: "Inter", Regular;
  font-weight: normal;
  color: #000000;
}

.int-16-reg {
  font-size: 1rem;
  font-family: "Inter", Regular;
  color: #000000;
}

.customer-name {
  display: grid;
  flex-direction: row;
}

.normal-content {
  font-family: "Inter", Regular;
  font-weight: 400;
}

.bold-content {
  font-family: "Inter", Medium;
  font-weight: 500;
}

.int-10-reg {
  font-size: 10px;
  line-height: 12px;
  font-family: "Inter", Regular;
  color: #000000;
}

.int-error-text {
  font-family: "Inter", Regular !important;
}

.int-14-reg {
  font-size: 14px;
  line-height: 17px;
  font-family: "Inter", Regular;
  color: #000000;
}

.int-14-reg-gray {
  font-size: 14px;
  line-height: 17px;
  font-family: "Inter", Regular;
  color: #999999;
}

.int-14-reg-white {
  font-size: 14px;
  line-height: normal;
  font-family: "Inter", Regular;
  color: #fff;
  font-weight: 100;
}

.int-18-reg {
  font-size: 1rem;
}

.int-18-reg-bold {
  font-size: 1rem;
  font-family: "Inter", Regular;
  font-weight: 600;
  color: #000000;
}

.info-span {
  font-size: 18px;
}

.int-20-reg-bold-white {
  font-size: 18px;
  font-family: "Inter", Regular;
  font-weight: 400;
  color: #ffffff;
}

.int-18-reg-bold-white {
  font-size: 18px;
  font-family: "Inter", Regular;
  font-weight: 600;
  color: #ffffff;
}

.int-18-reg-bold-white-return {
  font-size: 18px;
  font-family: "Inter", Regular;
  font-weight: 600;
  color: #ffffff;
  margin-right: 17px;
}

.int-20-reg-bold {
  font-size: 1.25rem;
  font-family: "Inter", Regular;
  font-weight: 600;
  color: #000000;
}

.int-20-reg {
  font-size: 1.25rem;
  font-family: "Inter", Regular;
  color: #000000;
}

.customer-credit-letter {
  letter-spacing: 0.0125rem;
  margin-bottom: 1.875rem;
  font-weight: 500;
}

.back-to-top-btn {
  width: 100%;
  max-width: 25rem;
  margin: 30px auto;
  // margin: 30px auto 180px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #eeeeee;
  height: 60px;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 30px;
  letter-spacing: 0.0313rem;

  .back-to-top-text {
    width: 100%;
    text-align: center;
    font-size: 18px !important;
    font-family: "Inter", Medium !important;
    font-weight: 500 !important;
    color: #000000 !important;
    cursor: pointer;
  }
}

/* Add Product Page */

.sticky-header {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
  // padding: 20px 0px;
  min-width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  z-index: 1;
  flex-direction: column;
}

.bold-liner {
  border-top: 3px solid black;
  width: 100%;
}

.bold-return-liner {
  border-top: 3px solid black;
  width: 1630%;
}

.slim-liner {
  border-top: 2px solid #eee;
  width: 100%;
}

.mtb-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.margintop-10 {
  margin-top: 15px;
}

.ptb-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.cart-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.cart-footer-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.btn.cart-bottom-buttons {
  border-radius: 20px;
  height: 60px;
  font-size: 18px;
  font-family: "Inter";
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: none;
  margin-top: 20px;
  padding-left: 20px;
  justify-content: space-between;

  .label-group {
    display: none;
  }

  :disabled {
    cursor: text !important;
    background-color: #e5e5e5;
  }
}

.cart-buttons {
  font-size: 14px;
  height: auto;
  padding-top: 15px;
  padding-bottom: 15px;

  @media (min-width: 1200px) {
    padding-top: 20px;
    padding-bottom: 20px;
    min-width: 18px;
  }
}

.btn.print-btn {
  display: flex !important;
  justify-content: space-between !important;
  background-color: #f1f1f1;
  margin-right: 10px;
  padding-right: 15px;
  width: 200px;
}

.print-btn.cart-buttons {
  width: 170px;

  @media (min-width: 1200px) {
    width: 200px;
  }
}

.pay-btn {
  width: 250px;
  background-color: #000000;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
}

.pay-btn:disabled {
  cursor: not-allowed;
  background-color: #e5e5e5;
  width: 250px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
}

.pay-btn.cart-buttons {
  width: 225px;

  @media (min-width: 1200px) {
    width: 250px;
  }
}

.cart-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.product-page-header {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
}

.product-page-header-right {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.product-subheading {
  display: flex;
  flex-direction: column;
  min-width: 250px;
}

.product-header-item {
  display: flex;
  flex-direction: column;
  width: 160px;
  height: 95px;
  padding: 15px 15px;
  border-radius: 10px;
}

.product-footer-item {
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  height: 60px;
  padding: 0px 10px;
  border-radius: 10px;
  min-width: 250px;

  @media (min-width: 768px) {
    min-width: 200px;
  }
}

.product-footer-item1 {
  cursor: pointer;
}

.product-header-item:hover {
  background-color: #eee;
  cursor: pointer;
}

.product-footer-item1:hover {
  background-color: #eee;
  cursor: pointer;
}

.sub-header-text {
  font-size: 10px;
  font-family: "Inter", Regular;
  color: #999999;
  text-transform: uppercase;
  line-height: 12px;
}

.sub-header-text-money {
  font-size: 10px;
  font-family: "Inter", Regular;
  color: #999999;
  text-transform: uppercase;
  line-height: 20px;
}

.sub-header-exempted-flag {
  display: flex;

  .exempted-box {
    margin-left: 10px;
    background: #f8a903;
    font-weight: bold;
    color: black;
    font-size: 9px;
    padding: 2px;
  }
}

.sub-header-text-type {
  font-size: 18px;
  font-family: "Inter", Medium;
  color: #000000;
  line-height: 21px;
  // font-weight: 500;
}

.sub-header-money-type {
  padding: 5px 0px 0px 0px;
  font-size: 24px;
  font-family: "Inter", Regular;
  color: #000000;
  line-height: 21px;
  font-weight: 500;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // max-width: 220px;
}

.pickup-from-text {
  white-space: nowrap;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-page-parts {
  display: flex;
  margin: 25px 0px 0px 0px;
}

.subpart {
  width: 50%;
  height: 600px;
  padding: 25px;
}

.liner {
  width: 1px !important;
  margin: 50px 0px 50px 0px;
  height: 500px !important;
  border-left: 1px solid #eeeeee;
}

.product-search-border {
  background-color: #eeeeee;
  height: 60px;
  border-radius: 15px;
  display: flex;
  align-self: end;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.product-search-border-visible {
  background-color: #ffffff;
  height: 60px;
  border-radius: 15px;
  display: flex;
  align-self: end;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 2px solid black;
}

.product-search-input {
  background-color: #eeeeee;
  margin: 2px;
  width: 90%;
  border: none !important;
  height: 50px;
  font-size: 18px;
  font-family: "Inter", Regular;
  padding-left: 10px;
}

.product-search-input::-webkit-outer-spin-button,
.product-search-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.product-search-input[type="number"] {
  -moz-appearance: textfield;
}

.product-search-input:focus {
  outline: none;
}

.h90 {
  height: 90px;
}

.h70 {
  height: 70px;
}

.product-item-list {
  background-color: #f9f9f9;
  width: 720px !important;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  margin-bottom: 20px;
  // justify-content: space-between;
  border-radius: 15px;
  cursor: pointer;
  position: relative;
  padding-right: 32px;
}

.product-item-quantity {
  position: absolute;
  right: 10px;
}

.product-item-list:hover {
  background-color: #eeeeee;
}

.product-search-icon {
  opacity: 0;
  position: absolute;
  margin-left: -20px;
  margin-top: 5px;
}

.product-item-list:hover .product-search-icon {
  opacity: 1;
}

.product-item-list:hover .avatar-shortcut {
  opacity: 0;
}

.product-search-item-list {
  width: 100% !important;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  justify-content: space-between;
  border-radius: 30px;
  cursor: pointer;
  border-bottom: 0.063rem solid #eee;
}

.product-search-item-head {
  padding: 0px 10px;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-search-item-list:hover {
  background-color: #eee;
  border-radius: 30px;
}

.products-shortcuts-line {
  margin-top: 2%;

  display: flex;
  align-items: center;
}

.prod-list-img {
  width: 40px;
  height: 40px;
}

.product-code {
  min-width: 200px;
  margin-left: 25px;
}

.products-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.line-progress-loader {
  background-color: rgb(206, 204, 204) !important;

  .MuiLinearProgress-bar {
    background-color: black !important;
  }
}

.products-suggestion {
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.recent-products-suggestion {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.product-suggestion-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.688rem 1.25rem;
  border-radius: 0.938rem;
  background-color: var(--bs-gray-50);
  cursor: pointer;
  border: none;
  user-select: none;

  &:hover,
  &:focus-visible {
    background-color: var(--bs-gray-100);
  }

  &:active {
    transform: scale(0.995);
    background-color: var(--bs-gray-200);
  }
}

.recent-product-suggestion-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.35rem;
  width: 100%;
}

.products-suggestion-sp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}

.products-suggestion-nf {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.625rem;
}

.cart-page-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.products-found {
  display: flex;
  align-items: center;
}

.product-found-info {
  padding: 2rem;
  position: relative;

  .product-found-text {
    position: absolute;
    background: #fff;
    padding-right: 10px;
    font-size: 14px;
  }

  .product-found-horizontal-line {
    max-width: 100%;
    min-width: 100%;
  }
}

.search-results-wrapper {
  width: 100%;
}

.product-found-info-cust {
  position: relative;
  padding: 1.5rem 0rem 2rem;

  .product-found-text {
    display: flex;
    gap: 0.625rem;
    align-items: center;
    position: absolute;
    background: #fff;
    padding-right: 0.625rem;
    font-size: 0.875rem;
  }

  .product-found-horizontal-line {
    max-width: 100%;
    min-width: 100%;
  }
}

.enter-search-text {
  display: flex;
  position: absolute;
  background: #fff;
  // padding-left: 10px;
  font-size: 14px;
  right: 0;
  align-items: center;
  z-index: 1;
  bottom: 22px;
  right: 50px;

  span {
    line-height: normal;
    color: #aaaaaa;
    font-size: 12px;
  }

  .press-enter-image {
    margin-left: 4px;
    margin-right: 4px;
  }
}

.padding-right-search-text {
  padding-right: 1.375rem;
  z-index: 1;
}

.icon-btn.close-input-icon-btn {
  z-index: 2;
}

.horizontal-liner {
  border-top: 1px solid #eeeeee;
  height: 1px;
  width: 600px;
}

.horizontal-liner-fp {
  border-top: 1px solid var(--bs-gray-200);
  height: 1px;
  width: 100%;
}

.horizontal-liner-sp {
  border-top: 1px solid #eeeeee;
  height: 1px;
  min-width: 85%;
  max-width: 85%;
}

.horizontal-liner-sr {
  border-top: 1px solid #eeeeee;
  height: 1px;
  min-width: 85%;
  max-width: 85%;
  margin-top: 9%;
}

.horizontal-liner-max {
  border-top: 1px solid #eeeeee;
  height: 1px;
  min-width: 80%;
  margin-left: 20px;
}

.enter-search-text-new-order {
  width: 10.625rem;
  font-size: 0.875rem;
  z-index: 1;

  span {
    line-height: normal;
    color: #aaaaaa;
    font-size: 0.75rem;
  }

  .press-enter-image {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
}

.horizontal-divider-bold {
  margin-top: 10px;
  border-right: 2px solid #000;
  height: 50px;
}

.topbar-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.suggestion-text {
  margin: 0 20px 0 0;
  font-size: 1.25rem;
  font-family: "Inter", sans-serif;
  line-height: 40px;
  font-weight: 600;
}

.back-next-btn {
  background-color: transparent;
  width: 60px;
  height: 60px;
  border-radius: 20px;
  background-color: #f1f1f1;
  border: none;
}

.btn.back-next-btn-small {
  background-color: transparent;
  width: 60px;
  height: 60px;
  border-radius: 20px;
  background-color: #f1f1f1;
  border: none;
  position: fixed;
  right: 650px;
  top: 80px;
}

.back-next-btn-small-left {
  border-radius: 20px !important;
  background-color: #f1f1f1 !important;
  border: none;
  position: fixed;
  left: 200px;
  top: 70px;
}

.back-next-btn-small-left-return {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 1.25rem !important;
  background-color: $bgColor !important;
  border: none;
  position: fixed;
  left: 5rem;
  top: 10.625rem;
  cursor: pointer;
}

.return-cancel-image {
  width: 1.875rem;
  height: 3.75rem;
  margin-left: 0.938rem;
}

.return-product-close {
  left: 0 !important;
  top: 0 !important;
}

.back-next-btn-small-left-mui {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 1.25rem !important;
  background-color: #f1f1f1 !important;
  border: none;
  position: fixed;
  left: 4.375rem !important;
  top: 9.375rem !important;
}

.back-next-btn-small-right {
  background-color: transparent;
  width: 60px;
  height: 60px;
  border-radius: 20px;
  background-color: #f1f1f1;
  border: none;
  position: fixed;
  /* left: 0; */
  right: 100px;
  top: 50px;
}

.bgWhite {
  background-color: #ffffff !important;
}

.search-list-img {
  width: 50px;
  height: 50px;
}

.return-list-img {
  width: 80px;
  height: 5rem;
}

.search-list-icon {
  position: absolute;
  margin-top: 40px;
  margin-left: 53px;
  width: 24px;
  height: 24px;
}

/* Cart page */
.product-drawer {
  padding-top: 1.25rem;

  @media only screen and (min-width: 960px) {
    padding-top: 6.25rem;
  }

  padding-left: 1.875rem !important;
  // min-width: 1200px; TODO:need this
}

.cart-page-add-product-drawer {
  // margin-bottom: 300px;
  margin-bottom: 200px;
}

.cart-page-return-product {
  // margin-bottom: 300px;
  margin-bottom: 200px;
}

.cart-header {
  display: flex;
  flex-wrap: wrap;

  @media only screen and (min-width: 600px) {
    flex-wrap: unset;
  }

  align-items: center;
  justify-content: space-between;

  .cart-sub-price {
    .title-image {
      width: 100%;
      min-width: 80px;
      height: 100%;
      min-height: 80px;
    }
  }
}

.cart-price {
  font-size: 2.25rem;
  color: #000;
  font-family: "Inter", Semibold;
  // font-weight: 500;
}

.add-to-cart-symbol {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 20px;
    width: 20px;
  }
}

.add-to-cart-text {
  display: flex;
  margin: 0px;
}

.add-to-cart {
  padding-left: 2px;
  font-size: 1rem;
}

.add-to-cart-btn {
  cursor: pointer;
  background-color: #000000;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  display: flex;
  border-radius: 15px;
}

.cart-button-container {
  .quantity-text {
    font-size: 1.25rem;
    margin: 0px 0px 10px 5px;
  }
}

.quantity-label {
  border: 2px solid #000000;
  padding: 15px 80px;
  margin: 0px 5px;
  border-radius: 15px;
}

.quantity-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.topbar-cart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 20px;
}

.addCart-unit-exit-price {
  margin-bottom: 100px;
  width: 333px;
  height: 1px;
  background: #dddddd 0% 0% no-repeat padding-box;
}

.product-id {
  font-size: 1rem;
  font-family: "Inter", Medium;
  color: #999999;
  font-weight: 500;
  line-height: 1.25rem;
}

.cart-flags {
  display: flex;
  flex-wrap: wrap;

  img {
    height: 1.25rem;
    width: 1.25rem;
  }
}

.popular-flag {
  width: 32%;
  min-width: 11.5rem;
  min-height: 2.875rem;
  display: flex;
  align-items: flex-start;
  padding: 0.313rem 0.625rem 0.313rem 0rem;
  justify-content: space-between;
  background: #ffffff 0% 0% no-repeat padding-box;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
}

.popular-item-text {
  border-right: 0.063rem solid #dddddd;
  padding: 0 0.375rem;
}

.popular-item-text.popular-flag:last-child {
  border-right: none;
}

.popular-item-text.popular-flag:first-child {
  border-left: 0.063rem solid #dddddd;
}

.flag-text {
  font-size: 0.813rem;
  font-family: "Inter", Regular;
  margin-left: 0.813rem;
}

.cart-item-details {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.cart-item-text {
  font-size: 1.25rem;
  font-family: "Inter", Regular;
  line-height: 1.5rem;
  font-weight: 400;
}

.cart-item-details-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.063rem solid #eee;
  padding: 1.25rem 0rem;
}

.cart-item-btn-change {
  background-color: #f1f1f1;
  padding: 0.5rem;
  border-radius: 0.625rem;
  padding-left: 0.625rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  // margin-left: 10px;
  height: fit-content;
}

.cart-item-btn {
  background-color: #f1f1f1;
  padding: 5px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  // margin-left: 20px;
}

.cart-item-change {
  font-size: 1rem;
  font-family: "Inter";
  line-height: 20px;
  font-weight: 600;
}

.cart-item-change.icn-btn {
  margin-left: 6px;
}

.item-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
}

.cart-item-details-row-images {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.avatar-icon {
  position: absolute;
  opacity: 0;
}

.user-avatar-item:hover .avatar-icon {
  opacity: 1;
  background-color: inherit;
}

.user-avatar-item:hover .avatar-shortcut {
  opacity: 0;
}

.keyboard-shortcut.avatar-shortcut {
  border-radius: 4px;
  box-shadow: 0px 1px 0px #cccccc;
}

.tender-amount-input {
  height: 60px;
  border-radius: 15px;
  border: 2px solid #000000;
}

.tender-amount-input:focus-visible {
  border: none;
  border-radius: 15px;
}

.tender-amount-input:focus {
  border: none;
  border-radius: 15px;
}

.payment-text-light {
  font-size: 18px;
  font-family: "Inter", Regular;
  line-height: 22px;
}

.payment-text {
  font-size: 1.25rem;
  font-family: "Inter", Medium;
  line-height: 22px;
  font-weight: 500;
}

.payment-option-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.delivery-heading {
  font-size: 28px;
  color: #999999;
  font-family: "Inter", Semibold;
}

.shipping-address-row {
  display: flex;
}

.shipping-address-exempt {
  margin-top: 80px;
  display: flex;
}

.schedule-collapsed-group {
  --schedule-collapsed-group-bg: #f5f5f5;
  transition: all 1s cubic-bezier(0.4, 0.4, 0, 1.1);
  box-shadow: 0 0 0 0 #fff, -10px 0 0 0 #fff, 10px 0 0 0 #fff;
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAASlJREFUOE+dkuFxwjAMhZMJgAmACSgTtEzQERomgA3IBm0nKGzABs0G0A2yATABvC8n5RQn/dH67p1tWXp6kpVn3VXouhGezHzWvhcOwjXxba65Gcfav0Ng6gvRWmDvLCfgYSH8CFuhMi+UfAjPQi0sUyUQFMKXBbv0NNFRhlfh0xK07xB49pVlLrXTB4KQzaLEi6mYR3YI7mbwcmjWyGyTINkTUUbbiyGCvRzeBkqqZKMXPYIh5pnJjWpTpc0bCuj6u0AG+jC0Shl3AvNQRAcIaBDBfKM3Lg6NB9/0zi/VKQF3Hgie2iNlQfJid4K5nwR+pk3gnccPJWRDov8CdmRjZ6CYBcgptSGJBFGZD1Qc3VhqS/IbQSSL5x7JXwm81EoHmn7+D0EkyR7syUPA+mzY1gAAAABJRU5ErkJggg==),
    auto;

  .schedule-collapsed-group-sub {
    transition: all 0.4s cubic-bezier(0.4, 0.4, 0, 1.1);
    height: auto;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transform: scale(0.975);
  }

  &.always-open {
    .schedule-collapsed-group-sub {
      max-height: 16rem;
      opacity: 1;
      transform: scale(1);
    }
  }

  &:hover,
  &:focus-visible {
    transition: none;
    background-color: var(--schedule-collapsed-group-bg);
    border-radius: 10px;
    box-shadow: 0 0 0 4px var(--schedule-collapsed-group-bg),
      -10px 0 0 4px var(--schedule-collapsed-group-bg),
      10px 0 0 4px var(--schedule-collapsed-group-bg);

    .schedule-collapsed-group-sub {
      max-height: 16rem;
      opacity: 1;
      transform: scale(1);
      transition: all 1s cubic-bezier(0.25, 1.5, 0, 1.5);
    }
  }
}

.toggle-schedule {
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  border: none;
  padding: 0;

  .toggle-schedule-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.875rem;
    width: 1.875rem;
    border-radius: 50%;
  }
}

.w50 {
  width: 50%;
}

.w35 {
  width: 35%;
}

.shipping-btns {
  margin-top: 0.4375rem;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.shipping-btns-no {
  margin-top: 0.4375rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.shipping-quantity-sec {
  flex-direction: column;
}

.btn.skip-shipping-btn {
  margin-top: 30px;
  display: flex;
  align-items: center;
  border: none;
  background-color: #f1f1f1;
  border-radius: 20px;
  padding: 20px;
  justify-content: flex-start;
  text-transform: unset !important;
  justify-content: flex-start !important;
  color: #69707a !important;
}

.btn.order-details-modal-continue-btn {
  width: 15rem;
  height: 3rem;
}

.btn-text {
  margin-left: 15px;
  font-size: 18px;
  font-family: "Inter", Semibold;
  // font-weight: 500;
}

.btn.set-charges {
  background-color: #000000;
  margin-left: 10px;
  color: #fff !important;
}

.btn.complete-order {
  background-color: #000000;
  width: 300px;
  color: #fff !important;
  height: 60px;
  justify-content: flex-start;

  &:hover {
    color: #fff;
  }
}

.btn.order-details-continue-btn {
  height: 4.3rem;
  min-width: 21.5rem;
  max-width: 21.5rem;
  margin-left: 0;
}

.order-complete {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.order-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.order-cell {
  display: flex;
  flex-direction: column;
}

.w300 {
  width: 300px !important;
}

.btn.black-btn {
  background-color: #000000;
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.btm-border {
  padding-bottom: 25px;
  border-bottom: 2px solid #000000;
  margin-bottom: 15px;
}

.price-options {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  width: 53px;
  border-radius: 50px;
  padding: 5px 5px;
  font-size: 0.875rem !important;
  font-family: "Inter", Medium;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.new-price-options {
  gap: 5px;
  display: flex;
  margin-top: 12px;
  justify-content: flex-start;
}

.price-options:hover {
  background-color: #eeeeee;
}

.mtb20 {
  margin: 20px 0px;
}

.w60 {
  width: 60px !important;
}

// .search-results-container {
//   min-width: 90%;
//   max-width: 90%;
// }

.ship-btn {
  width: 100%;
  max-width: 20.313rem;
  background-color: #f1f1f1 !important;
  color: #000000 !important;
}

.ship-btn-dark {
  width: 100%;
  max-width: 20.313rem;
  background-color: #000000 !important;
  color: #ffffff !important;
}

.ship-charge-continue-btn {
  min-width: 22rem;
  max-width: 22rem;
  height: 4rem;
}

.w62 {
  width: 62%;
}

.h60 {
  height: 60px;
}

.w100 {
  width: 100% !important;
  margin-left: 0 !important;
}

.pl-10 {
  padding-left: 10px;
}

.ptb-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.mtb-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.trash-icon {
  opacity: 1;
  padding: 5px;
  // position: absolute;
}

.trash-icon:hover {
  opacity: 1;
  background-color: #dddddd;
  padding: 5px;
  border-radius: 50%;
  // position: absolute;
}

.trash-open {
  opacity: 0;
  position: absolute;
}

.trash-open:hover {
  opacity: 1;
  position: absolute;
}

.delete {
  background-color: #ffe5e5 !important;
}

.h50 {
  min-height: 50vh;
}

.sticky-table-head {
  position: fixed;

  .MuiTableRow-root {
    // margin-top: -2rem;
    // position: fixed;
    // background-color: #ffff;
    // z-index: 5;
    // width: 80%;
    // .table-cell-blank{
    //   width: 4%;
    // }

    // .table-cell-name{
    //   padding-right: 48rem;
    // }
  }
}

.table-cell-code {
  font-weight: bolder;
}

.jcsb {
  justify-content: space-between !important;
}

.save-later-text {
  margin-top: 50px;
  display: flex;
  align-items: flex-start;
  background-color: #fef4e5;
  border-radius: 10px;
  padding: 10px;
  margin-right: 20px;

  .save-later-text-style {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 10px;
  }
}

.set-price-text {
  width: 430px;
  height: 74px;
  margin-bottom: 20px;
  font-size: 14px !important;
  display: flex;
  align-items: flex-start;
  background-color: #fef4e5;
  border-radius: 10px;
  padding: 10px;
  color: #000000;
  font-family: "Inter", Regular;
  letter-spacing: 0.16px;

  img {
    margin-top: 6px;
  }

  .set-price-text-style {
    width: 390px;
    height: 44px;
    display: flex;
    align-self: center;
    justify-content: flex-start;
    margin-left: 10px;
  }
}

.flex-column-display {
  display: flex;
  flex-direction: column;
  margin-top: 6px;
}

.transaction-error-title {
  font-weight: 600;
}

.title-transaction-error {
  margin-bottom: 0;
}

.set-transaction-error-text {
  margin-bottom: 20px;
  font-size: 14px !important;
  display: flex;
  align-items: flex-start;
  background-color: #fef4e5;
  border-radius: 10px;
  padding: 10px;
  color: #000000;
  font-family: "Inter", Regular;
  letter-spacing: 0.16px;

  img {
    margin-top: 6px;
  }

  .set-transaction-error-text-style {
    width: 390px;
    display: flex;
    align-self: center;
    justify-content: flex-start;
    margin-left: 10px;
  }
}


.btn.btn-saveforlater {
  margin-top: 20px;
  background-color: #000000;
  border-radius: 20px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-family: "inter";
  line-height: 21px;
  color: #ffffff;
  padding-left: 15px;
  border: none;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold !important;
  font-family: "inter";
  line-height: 21px;
  border: none;
  justify-content: flex-start;

  .MuiButton-label {
    justify-content: flex-start !important;
  }
}

.btn.save-for-later-btn {
  margin-right: 15px;
  width: 10.938rem;
  height: 3.875rem;
  display: flex !important;
  justify-content: space-between !important;
}

.btn.cart-print-btn,
.btn.save-for-later-btn {
  font-weight: bold !important;

  .MuiButton-endIcon {
    background-color: white;
    padding: 0px 0.1875rem;
    border-radius: 0.25rem;
  }
}

.cart-print-btn {
  margin-top: 1.25rem !important;
  display: flex !important;
  justify-content: space-between !important;
  background-color: #f1f1f1;
  margin-right: 10px;
  padding-right: 15px;
  min-width: 12.5rem;
  max-width: 12.5rem;
  width: 12.5rem;
  height: 3.875rem;
}

.jcs {
  align-items: center;
  justify-content: center;
}

.disable-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
  border-bottom: 0.063rem solid #eee;
  /* margin: 10px 0px; */
  opacity: 0.3;
}

.quantity-pick-header {
  display: flex;
  // align-items: center;
  justify-content: space-between;
}

.jcfe {
  justify-content: flex-end !important;
}

main.quanity-page-layout {
  display: flex;
  padding-top: 94px;
  padding-bottom: 0;
  position: relative;
  overflow-y: inherit;
}

.quanity-page-layout {
  grid-column: 1 / -1;
  padding-top: 100px;
  position: relative;
}

.pd5 {
  padding: 5px;
}

.cart-deets-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.disable-pay {
  opacity: 0.2 !important;
  cursor: text !important;
}

.pick-table-input {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  border-bottom: 1px solid #bbbbbbbf;

  &::placeholder {
    color: #bbbbbbbf;
  }

  input {
    padding: 0 !important;
  }
}

// .pick-table-input:focus-visible {
//   border: none;
// }

.product-search-item-list-pick {
  width: 100% !important;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  justify-content: space-between;
  border-radius: 30px;
  cursor: pointer;
  // border-bottom: 0.063rem solid #eee;
}

.qty-border {
  border: 1px solid #1c6ea4;
  border-radius: 10px;
}

.cart-items-button-container {
  display: flex;
}

.select-draft-sort {
  background-color: #fff;
  border: none;
  background-image: none !important;
  padding-right: 0;
  text-transform: uppercase;

  .select {
    padding-right: 0px !important;
  }
}

.select-draft-sort:focus-visible {
  border: none !important;
}

.picking-qty-location {
  display: flex;
  justify-content: flex-start;
}

.pick-cell-table {
  // height: 60px;
  font-size: 18px;
  font-family: "Inter", Regular;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding: 0px;
}

.pick-cell-table-input {
  padding-bottom: 6px;
}

.picking-qty-location {
  display: flex;
  justify-content: flex-start;
}

.picked-qty-title {
  padding-right: 3rem;
}

.picked-qty-location-title {
  padding-left: 3rem;
}

.empty-cart-search {
  margin-top: 10px;
}

.picked-qty-title {
  padding-right: 3rem;
}

.picked-qty-location-title {
  padding-left: 3rem;
}

.back-next-btn-small-pick-qty {
  background-color: transparent;
  width: 60px;
  height: 60px;
  border-radius: 20px;
  background-color: #f1f1f1;
  border: none;
  position: fixed;
  left: 80px;
  top: 70px;
}

.ship-to-top-result {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  border-bottom: 0.063rem solid #eee;
  padding-bottom: 10px;
}

// might needed
// .btn.shipto-select-btn {
//   height: 60px;
//   width: 120px;
// background-color: #000000;
// color: #fff;
// border-radius: 20px;
// margin-left: 70px;
// display: flex;
// align-items: center;
// justify-content: center;
// padding-left: 15px;
// display: flex !important;
// }

.btn.preview-shipping-btn {
  align-items: center !important;
  font-weight: bold !important;
  font-family: "inter" !important;
  line-height: 21px !important;
  color: #ffffff !important;
  justify-content: flex-start !important;
  font-size: 18px !important;
  font-weight: bold !important;
}

.credit-hold-img {
  height: 18px;
}

.saved-jobname {
  padding: 0rem 0.625rem;
  flex-grow: 1;
}

.pd0 {
  padding: 0 !important;
}

.svg-btm-text {
  margin: 32px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.price-label {
  color: #000000;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Inter", Regular;
  width: 20% !important;
}

.btm-svg-textline {
  font-size: 18px;
  font-family: "Inter", Regular;
  line-height: 21px;
  margin-top: 20px;
}

.margintop-20 {
  margin-top: 20px !important;
}

.marginleft10 {
  margin-left: 10px !important;
}

.w85 {
  width: 75% !important;
}

.Set-price-label {
  font-weight: 500;
}

.gray-text {
  font-size: 1rem;
  font-family: "Inter, Medium";
  padding-right: 5px;
  color: #999999;
  line-height: 20px;
}

.product-search-border-visible-cart-page {
  background: #ffffff 0% 0% no-repeat padding-box;
  height: 100%;
  border-radius: 10px;
  padding: 0 20px 0 20px;
  margin: 10px 0 0 0;
  border: 1px solid #000000;
  opacity: 1;
}

.price-input-section {
  border-bottom: 2px solid #eeeeee;
  display: flex;
  align-items: center;
}

.markup-input-icon {
  margin: 0 0 0 6.25rem;
  display: flex;
  align-items: center;
}

.price-Markup-section {
  display: flex;
  align-items: center;

  input {
    padding: 0 !important;
  }
}

.price-input-icon {
  margin: 0 0 0 6.25rem;
  display: flex;
  align-items: center;

  input {
    padding: 0 !important;
  }

  .product-search-price-input,
  .product-search-input {
    display: flex;
    justify-content: center;
    padding-left: 5px;
  }
}

.product-search-price-input {
  width: auto !important;
  display: flex !important;
  background-color: #eeeeee;
  margin: 2px;
  border: none !important;
  height: 50px;
  font-size: 18px;
  font-family: "Inter", Regular;
}

.markup-min-width {
  min-width: 50px;
}

.product-search-price-input::-webkit-outer-spin-button,
.product-search-price-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.product-search-price-input[type="number"] {
  -moz-appearance: textfield;
}

.product-search-price-input:focus {
  outline: none;
}

.tax-item-btn-change {
  background-color: #f1f1f1;
  padding: 7px;
  border-radius: 10px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100px;
  margin-top: 10px;
}

.margin-0 {
  margin-left: 0 !important;
}

.margin-r10 {
  margin-right: 10px !important;
}

.btn.btn-add-customer {
  background-color: #000000;
  border-radius: 15px;
  display: flex;
  // width: 180px;
  // height: 50px;
  margin-left: 0.313rem;
  align-items: center;
  font-family: "inter";
  line-height: 21px;
  color: #ffffff;
  // padding-left: 5px;
  border: none;
  justify-content: center;
  font-size: 1rem;

  &:hover {
    color: #ffffff;
  }
}

.tax-text-calc {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;

  .calc-text {
    font-weight: bold;
  }
}

.tax-exempt-text {
  background-color: #f8a903;
  color: #000000;
  margin-left: 0.313rem;
  line-height: 12px;
  font-family: "inter", Medium;
  font-size: 10px;
  padding: 2px;
  border-radius: 2px;
  letter-spacing: 1px;
}

.width120 {
  width: 113px !important;
}

.preview-address-btn {
  padding: 0;

  button.preview-shipping-btn {
    width: 100%;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    padding: 0.875rem 1.125rem !important;
  }
}

.btn.new-address-continue-btn,
.new-address-continue-btn {
  .MuiButton-label {
    justify-content: flex-start;
  }
}

.confirmation-title-text {
  font-size: 10px;
}

.complete-order-icon {
  width: 0.938rem;
  height: 0.938rem;
  margin-left: -0.313rem;
}

.complete-order-text {
  margin-left: -0.063rem;
  font-size: 0.938rem;
}

.picking-info-table {
  .MuiTableCell-root {
    padding: 0;

    .column-info {
      // padding: 16px 0;
    }

    .location-col,
    .available-qty-col,
    .picked-qty-col {
      display: flex;
      justify-content: center;
      align-items: center;
      // border: 1px solid #eee;
    }
  }

  .table-head-last-cell,
  .table-info-last-cell {
    display: flex;

    .last-head-col,
    .location-col,
    .available-qty-col,
    .picked-qty-col {
      width: 33.33%;
    }

    // .location-col,
    // .available-qty-col,
    // .picked-qty-col {
    // }
    .location-col span {
      font-size: 1rem;
    }

    .available-qty-col,
    .picked-qty-col {
      span {
        font-size: 18px;
      }
    }

    &:focus-within {
      background-color: #EEEEEE;
    }
  }

  .table-info-last-cell {
    border: 1px solid #eee;
    border-radius: 10px;
    // padding: 0.938rem !important;
    // margin: 20px 0;
  }

  .picking-info-table-section {
    border-spacing: 0px 20px;
    border-collapse: separate;

    .table-cell-col {
      padding: 10px 0 30px;
      border-bottom: 0.063rem solid #eee;
    }

    th {
      .column-info {
        font-size: 10px;
        font-weight: 600;
      }
    }
  }
}

.shipping-address-styling {
  margin-right: 10px;
}

.btn-confirm-add {
  background-color: #000000;
  justify-content: center;
  border-radius: 20px;
  display: flex;
  width: 275px;
  height: 65px;
  align-items: center;
  font-weight: bold;
  font-family: "inter";
  line-height: 21px;
  color: #ffffff;
  border: none;
}

.MuiCircularProgress-colorPrimary {
  color: #000000 !important;
}

.edit-btn {
  width: 80px;
  height: 40px;
}

.facility-items-align {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ml-3 {
  margin-left: 3px;
}

.min-width-cell {
  min-width: 120px;
  width: 10%;
}

.picking-qty-btn-sec {
  flex-wrap: wrap;

  .picking-qty-btn {
    width: auto;

    @media (min-width: 1400px) {
      width: 310px;
    }

    .schedule-shortcut {
      margin-left: 10px;
    }
  }
}

.btn.picking-qty-back-btn {
  position: relative;
  left: 0;
  top: 0;
  margin-bottom: 20px;

  @media (min-width: 1600px) {
    position: absolute;
    left: -100px;
    top: 94px;
    margin-bottom: 0;
    z-index: 11;
  }

  @media (min-width: 1800px) {
    left: -120px;
  }

  img {
    height: 20px;
    width: 20px;
  }
}

// might needed 
// .table-main-container {
//   padding-left: 7.625rem;
//   padding-right: 2rem;
//   margin-top:1rem;
// }
.newOrder-qty-back-btn {
  position: relative;
  left: 0;
  top: 20px;
  margin-bottom: 20px;

  @media (min-width: 1800px) {
    position: fixed;
    left: 120px;
    top: 150px;
  }

  img {
    height: 20px;
    width: 20px;
  }
}

// might needed 
// .btn.select-shipping-address-btn {
//   margin-left: 20px;
//   padding: 1.375rem 3.4375rem 1.375rem 1.25rem !important;
//   margin: 0;
// }
.ship-to-address-info {
  width: calc(100% - 150px);
  padding-right: 10px;

  h3 {
    word-break: break-word;
    width: 100%;
  }
}

.cart-footer-section {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  width: 100%;
  left: 30px;

  .MuiContainer-root {
    max-width: 80vw;

    @media (min-width: 1200px) {
      max-width: 1050px;
    }

    @media (min-width: 1400px) {
      max-width: 1150px;
    }

    @media (min-width: 1600px) {
      max-width: 1280px;
    }
  }
}

// #cart-product {
//   overflow: auto;
//   width: 100%;
// }

.selfadapt {
  width: 60px;
}

.product-search-table-header-row {
  th {
    background-color: #fff;
    color: #000;
  }
}

.cart-product-search {
  margin: 0;
}

.cart-product-container.MuiContainer-root {
  display: flex;
  position: relative;
  max-width: 80vw;

  // @media (min-width: 992px) {
  //   max-width: 900px;
  // }
  @media (min-width: 1200px) {
    max-width: 1050px;
  }

  @media (min-width: 1400px) {
    max-width: 1150px;
  }

  @media (min-width: 1600px) {
    max-width: 1280px;
  }

  .cart-add-product-back-btn {
    position: absolute;
    left: -70px;

    button {
      position: relative;
      left: 0;
      top: 0;
    }
  }

  .cart-product-info {
    width: 100%;
  }
}

.scroll-styling {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;

  /* IE 10+ */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 3px !important;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

.table-border-styling {

  td:first-child,
  th:first-child {
    border-radius: 10px 0 0 10px;
  }

  td:last-child,
  th:last-child {
    border-radius: 0 10px 10px 0;
  }
}

.image-cell {
  width: 60px;

  @media (min-width: 1400px) {
    width: 80px;
  }
}

.item-name-cell {
  width: 250px;

  @media (min-width: 992px) {
    width: 300px;
  }

  @media (min-width: 1200px) {
    width: 450px;
  }

  @media (min-width: 1400px) {
    width: 510px;
  }

  span {
    word-break: break-word;
  }
}

.order-qty-cell {

  // width: 120px;
  @media (min-width: 1400px) {
    width: 200px;
  }

  .column-info {
    font-size: 18px;
  }
}

.picking-product-name {
  font-size: 18px;
  font-weight: 600;
}

.picking-manufacturer-name {
  font-size: 1rem;
  font-weight: 400;
}

.picking-header-section {
  max-width: 640px;
}

.schedule-number-info {
  font-size: 18px;
  font-family: "Inter", Regular;
  font-weight: 600;
  color: #fff;
}

.delete-drawer-btn-spacing {
  margin-top: 35px;
}

.form-field-spacing {
  margin-top: 30px;
}

.form-field-spacing-admin {
  margin-bottom: unset;
}

.form-field-title {
  font-size: 1.25rem;
}

.discontinue-footer {
  margin-left: 1.25rem;
}

.discontinue-heading {
  margin-left: -0.6875rem;
}

.must-be-value {
  color: #999999bf;
  font-size: 14px;
  margin-top: 6px;
}

.select-form-field.form-control {
  border-radius: 20px !important;
  border: 0px solid !important;
  padding-left: 20px;
  background-color: #f1f1f1;
  font-size: 1.125rem !important;
  font-weight: 500;
  display: flex;
}

.payment-icon-spacing {
  margin-right: 1.5625rem;
}

.btn.payment-drawer-btn-spacing,
.payment-drawer-btn-spacing {
  margin-top: 45px;
}

.pay-later-job-input {
  input {
    margin-bottom: 0;
  }

  .facility-box {
    width: 50%;
  }

  width: 21.25rem;
}

.cart-delete-icon {
  width: 25px;
}

.cart-remove-btn {
  padding-right: 10px;
}

.footer-block.profit-block-styling {
  border-right: 1px solid #000 !important;
  border-radius: 0;
  padding-right: 0;
  margin-right: 10px;
  min-width: 150px;
}

.light-liner {
  color: #aba9a9;
  margin: 1rem 0 !important;
  background-color: currentColor !important;
  opacity: 0.25;
}

.sales-history-link {
  margin-top: 0.25rem;
  gap: 0.5rem;
  width: 17.875rem;
  height: 10.375rem;

  .sub-btn {
    padding: 0 !important;
    border-radius: 0 !important;
    margin-bottom: 0.375rem;
  }

  .see-item-badge {
    .MuiBadge-badge {
      background-color: $darkGray;
      top: 0.625rem;
      height: 0.25rem;
      min-width: 0.25rem;
    }
  }

  button {
    border-bottom: 1px solid #000;
    cursor: pointer;
    border-radius: 0;
    color: #000000 !important;
    font-weight: 600;
    padding: 0;
    background-color: transparent !important;

    span {
      font-size: 0.875rem !important;

      @media (min-width: 1200px) {
        font-size: 0.813rem !important;
      }

      @media (min-width: 1400px) {
        font-size: 0.938rem !important;
      }
    }
  }
}

.pick-quanity-page-wrapper {
  main.pick-quanity-page-main-sec {
    grid-template-rows: unset;
    grid-template-columns: repeat(13, 1fr);
  }
}

.ext-price,
.net-price {
  word-break: break-word;
}

.loading-div {
  display: grid;
  place-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

@keyframes spinner {
  to {
    transform: rotate(1turn);
    -webkit-transform: rotate(1turn);
    -moz-transform: rotate(1turn);
    -ms-transform: rotate(1turn);
    -o-transform: rotate(1turn);
  }
}

.loading-spinner {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border: 3px solid black;
  border-bottom-color: transparent;
  position: relative;
  animation: spinner 1s linear infinite paused;
  -webkit-animation: spinner 1s linear infinite;
}

.search-button {
  display: flex !important;
  justify-content: center !important;
  padding: 0.3125rem !important;

  .loader-button {
    width: 15.3125rem !important;
    padding: 0 1.25rem;
    height: 3.75rem;
    background-color: #eeeeee !important;
    color: black;
    border: none;
    border-radius: 1.25rem;
    display: flex;
    align-items: center !important;
    gap: 0.625rem;
    cursor: pointer;
    overflow: hidden;
    user-select: none;
    will-change: scale;
    transition: all 0.1s ease-out;

    .btn-group {
      display: flex;
      align-items: center;
      gap: 0.625rem;
      width: 100%;
      height: 100%;
    }

    &:focus-visible {
      outline: 2px solid #000;
      outline-offset: 2px;
    }

    &:active {
      transform: scale(0.985);
      transition: all 0s ease-out;
    }

    .btn-icon {
      height: 24px !important;
      width: 24px !important;
    }

    .btn-icon svg {
      fill: currentColor !important;
    }

    .btn-label {
      font-size: 18px;
      font-family: "Inter", sans-serif;
    }

    .btn-label-show {
      font-size: 18px;
      font-family: "Inter", sans-serif;
      margin-left: 27px;
    }

    .loading {
      display: flex !important;
      color: black !important;
      background-color: transparent !important;
      margin-left: 55px;
    }

    .loading {
      .btn-group {
        justify-self: flex-start;
      }
    }

    &.loading::before {
      content: "";
      position: absolute;
      background-color: rgba(200, 200, 200, 0.95);
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }

    &.loading::after {
      content: "";
      position: absolute;
      background-color: rgba(0, 0, 0, 0);
      height: 20px;
      width: 20px;
      border: 3px solid rgba(0, 0, 0, 1);
      border-right-color: rgba(0, 0, 0, 0);
      border-radius: 500px;
      will-change: transform;
      animation: loading-spinner-anim 0.8s linear infinite;
    }

    @keyframes loading-spinner-anim {
      to {
        transform: rotate(360deg);
      }
    }
  }
}

button.tax-upload-btn {
  background-color: #000000;
  width: 116px;
  height: 44px;
  display: flex;
  justify-content: center;
  border-radius: 15px;
  align-items: center;
  color: white;
  font-family: "inter" !important;
  cursor: pointer;
}

button.tax-chnage-btn {
  text-align: left;
  text-decoration: underline;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  text-transform: unset;
}

// extra styling
* {
  font-family: "Inter", sans-serif;
}

.row-product-drawer-info,
.shelf-product-drawer-info,
.unit-product-drawer-info {
  width: 33.33%;

  // max-width: 170px;
  select {
    width: 100% !important;
    padding: 14px 30px 14px 18px !important;
    height: auto !important;
    border-radius: 8px !important;
    display: flex;
    align-items: center;

    &:focus {
      background-color: #f1f1f1;
    }
  }
}

.row-product-drawer-info {
  padding-right: 10px;
}

.shelf-product-drawer-info {
  padding: 0 5px;
}

.unit-product-drawer-info {
  padding-left: 10px;
}

.quantity-info {
  display: flex;
  align-items: center;
  margin-top: 20px;

  .quantity-left-info {
    min-width: 140px;
    padding-right: 20px;

    .add-unit-qty-section {
      margin-bottom: 10px;
    }

    .available-unit-qty-section {}
  }

  .quantity-right-info {
    display: flex;
    border: 1px solid #e0e5ec;
    padding: 12px;
    align-items: center;

    .quantity-add-input {
      width: 100%;

      // max-width: 60px;
      input {
        margin-top: 0;
        width: 100%;
        padding: 0;
        background-color: transparent;
      }
    }

    .quantity-save-btn {
      padding-left: 20px;

      button {
        padding: 14px 20px !important;
        margin-top: 0;
        width: 100% !important;
      }
    }
  }
}

.unit-quantity-main-section {
  border: 1px solid #e0e5ec;
  padding: 15px 15px 25px 15px;
  margin-top: 48px;
}

.edit-button.btn.btn-discard-edit {
  padding: 12px 16px;
  border-radius: 8px;
  font-weight: bold;
  justify-content: flex-start;

  img {
    margin-right: 8px;
  }

  &:hover {
    color: #000;
  }
}

.job-name-edit-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.063rem solid #eee;

  .form-field-title {
    color: #000;
    font-weight: 500;
    margin-bottom: 10px;
  }
}

.btn.cart-bottom-btn {
  padding: 0.938rem !important;
  height: 44px;
  display: flex;
  justify-content: space-between;
  border-radius: 15px;

  .add-to-cart,
  .add-to-cart-symbol {
    padding-top: 0;
  }

  .label-group {
    display: none;
  }

  &:hover {
    color: #fff;
  }
}

.cart-drawer-styling {
  .MuiPaper-root {
    overflow: hidden;

    .drawer-content-scroll {
      overflow-y: auto;
    }
  }
}

.back-to-top-section {
  // bottom: 0;
  width: 100%;
  background-color: #fff;
  position: absolute;
}

.drawer-bottom-sec-styling {
  padding-bottom: 116px;
  padding-top: 20px;
}

.cash-hide-price-option {
  border-top: 1px solid #dddddd;
  margin-top: 40px;

  // padding: 0 10px;
  .additional-settings-title {
    font-size: 10px;
    color: #000;
    font-weight: 500;
    margin: 20px 0 10px;
  }

  .show-price-sec {
    align-items: center;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 10px;

    .hide-price-title {
      margin-left: 10px;
      color: #000;
      font-weight: 600;
    }

    .hide-price-icon {
      background-color: #eeeeee;
      padding: 10px 12px;
      border-radius: 30px;

      img {
        margin-right: 0;
      }
    }

    .MuiSwitch-track {
      background-color: #aaaaaa;
    }
  }
}

.save-later-text-gray-bg {
  background-color: #eeeeee;
}

.payment-phone-number-input {
  margin-right: 0;
  width: 100%;
  background-color: #fff;
  border: 0.063rem solid;
  border-radius: 0.625rem;
  padding: 1.156rem 0.875rem;
  border-width: 0.125rem;

  input {
    width: 100%;
    background-color: inherit;
    max-width: unset;
    height: 100%;
    border-radius: 0.625rem;
  }
}

.payment-country-code-input {
  width: 30%;
}

.generic-payment-phone-number {
  margin-bottom: 25px;
}

.backorder-info {
  background-color: #f1f1f1;
  width: fit-content;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  border-radius: 25px;
  margin-left: 10px;
  height: fit-content;
}

.cart-per-value-info {
  color: #999999;
  font-size: 14px;
}

.recent-order-info {
  font-size: 1rem;
  color: #000;
  font-weight: 600;
}

.popular-recent-order-info {
  .popular-item-info {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 0.625rem;
    font-weight: 600;

    span {
      font-size: 0.625rem;
      color: #000;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    img {
      height: 14px;
      width: 14px;
      margin-left: 0.313rem;
    }
  }
}

.overflow-auto {
  overflow: auto;
}

.modal-box {
  width: 600px;
}

// .drawer--600px
.main-drawer-classes {
  .MuiPaper-root {
    width: 100%;
    max-width: 46.875rem;
  }
}

// TODO:need this code
// .cart-drawer-content {
//   min-width: 1200px !important; // 600px
// }
// cart-drawer-1200px
.cart-page-drawer-sec {
  .MuiPaper-root {
    max-width: 1200px !important;
    width: 90% !important;
  }

  .MuiPaper-root.MuiDrawer-paper {
    overflow-y: unset;

    .MuiBox-root.cart-drawer-content {
      position: relative;
      overflow-y: auto;

      .cart-drawer-close-icon {
        position: absolute;
        left: -80px;
        top: 80px;
      }
    }
  }
}

.new-customer-taker-modal {
  width: unset !important;

  .modal-content-style {
    padding: 0;
  }
}

.delete-circular-bar {
  margin-left: 0.4375rem;
}

.taker-modal-section {
  width: 600px;
}

.no-customer-found-errors {
  height: 100%;
  max-height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;

  .products-suggestion-sp {
    margin-top: 0.625rem;
  }
}

.font-weight-bold {
  font-weight: bold;
}

.order-search-input-returncart {
  margin: 0 10px;
  width: 90%;
  border: none !important;
  height: 44px;
  font-size: 15px;
  font-family: "Inter", Regular;
  background-color: #eeeeee;
  font-weight: 500;
  padding: 10px;
  color: #000;

  &::placeholder {
    color: #000;
  }

  border-radius: 10px;
}

.order-search-input-returncart1 {
  margin: 0 10px;
  // width: 90%;
  border: none !important;
  height: 56px;
  font-size: 15px;
  font-family: "Inter", Regular;
  background-color: #eeeeee;
  font-weight: 500;
  padding: 10px;
  color: #000;
  border-left: 1px solid !important;

  &::placeholder {
    color: #000;
  }

  border-radius: 0px 10px 10px 0px;
}

.order-search-input-returncartmenu {
  margin: 0 10px;
  // width: 90%;
  border-right: solid !important;
  height: 4px;
  font-size: 15px;
  font-family: "Inter", Regular;
  background-color: #eeeeee;
  font-weight: 500;
  padding: 10px;
  color: #000;

  &::placeholder {
    color: #000;
  }

  // border-radius: 10px;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-500 {
  font-weight: 500;
}

.delete-saved-draft {
  text-decoration: underline !important;
  font-weight: 34.375rem !important;
  font-size: 0.9375rem !important;

  &:focus-visible {
    outline: 0.13rem solid var(--bs-gray-600) !important;
    border-radius: 4px !important;
    background-color: #EEEEEE;
  }
}

.delete-space {
  display: flex;
  justify-content: space-between;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.select-field-focus-styling {
  .form-control.ws-select:focus {
    background-color: #f1f1f1;
  }

  select {
    width: 100% !important;
  }
}

.clearance-sale-percentage {
  color: #5856d6;
  font-size: 1rem;
  font-weight: 500;
}

.clearance-sale-qty {
  color: #000000;
  font-size: 1rem;
  font-weight: 500;
}

.clearance-info-div {
  background-color: #5856d621;
  padding: 0.5rem 0.375rem;
  border-radius: 0.5rem;
  border: none;
}

.product-detailed-info {
  background-color: #f9f9f9;
  padding: 0.5rem 0.375rem;
  border-radius: 0.5rem;
  height: auto;
  border: none !important;
}

.double-bordered-input .MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline {
  border-width: 0.125rem !important;
}

.dollar-with-input-section {
  position: relative;
  display: flex;
  align-items: center;

  .dollar-icon {
    position: absolute;
    left: 0.375rem;
  }

  input {
    padding-left: 0.625rem !important;
  }
}

.search-with-close-input {
  position: relative;
  display: flex;
  align-items: center;

  .search-icon,
  .close-icon {
    position: absolute;
  }

  .search-icon {
    left: 0.938rem;

    svg {
      color: #000;
    }
  }

  .close-icon {
    right: 0.938rem;
  }

  input {
    padding: 0 1.25rem !important;
  }

  fieldset {
    border-radius: 0.938rem;
    border-width: 0.125rem !important;
  }
}

.d-none {
  display: none;
}

.h-100 {
  height: 100%;
}

.upload-icon-spacing {
  margin-right: 0.3rem;
}

.setting-icon {
  height: 1.375rem;
  width: 1.375rem;
  cursor: pointer;
  padding: 2px;

  &:hover {
    background-color: #dddddd;
    border-radius: 0.625rem;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.MuiButton-root.btn.workstation-continue-btn {
  cursor: pointer;
  background-color: $black-color;
  color: $white-color;
  padding: 1.25rem;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  border-radius: 1.25rem;
  width: 20.3125rem;
  height: 3.75rem;
  margin-top: 1.25rem;
  font-size: 1.1rem;

  span {
    font-weight: 500 !important;
  }
}

.return-product-modal-data ::-webkit-scrollbar {
  width: 0.313rem !important;
}

.return-product-modal-data ::-webkit-scrollbar-thumb {
  background-color: $light-gray-color !important;
}

.cursor-pointer {
  cursor: pointer;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.tax-ded {
  text-align: left;
  font: normal normal normal 14px/20px Inter;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.btn.back-next-btn-small-tax {
  background-color: transparent;
  width: 60px;
  height: 60px;
  border-radius: 20px;
  background-color: #f1f1f1;
  border: none;
  position: fixed;
  right: 780px;
  top: 80px;
}

.sticky-header-style {
  position: sticky !important;
  position: -webkit-sticky !important;
  height: fit-content;
  background-color: white;
}

.shipping-address-block {
  display: flex;
  flex-direction: column;

  .shipping-badge {
    border: 0.125rem solid $dusty-gray-color;
    height: 0.25rem;
    width: 0.25rem;
    border-radius: 1.25rem;
    margin: 0 0.625rem;
  }
}

.drawer-static-header {
  position: relative;

  @media only screen and (min-width: 768px) {
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 41.875rem;
    background: white;
    z-index: 11;
  }
}

.customer-search-input-spacing {
  input {
    padding-left: 0.6rem !important;

    &.MuiInput-input {
      padding: 1.15rem 0.85rem !important;
    }
  }
}

.font-weight-400 {
  font-weight: 400 !important;
}

.new-order-main-header-spacing {
  margin-right: 1.875rem;
}

.white-btn,
.white-btn span {
  color: $white-color !important;
}

.btn.set-charges-skip {
  background-color: $bgColor;
  margin-left: 0.625rem;
  width: 100%;
  max-width: 21.875rem;
  color: $black-color;
}

.btn.payment-skip-btn {
  min-width: 21.5rem;
  max-width: 21.5rem;
  height: 4.3rem;
  margin-right: 1rem;
}

.btn.save-skip-btn {
  min-width: 19.5rem;
  max-width: 19.5rem;
  margin-right: 1rem;
}

// Start New History back button css
.history-main-section {
  padding: 1.875rem 0;

  .item-history-section {
    padding: 2rem;

    .item-history-container {
      display: flex;
      justify-items: flex-start;
      width: 100%;
      align-items: center;
      height: 100%;
      gap: 36px;

      .back-button {
        border-radius: 1.25rem;
        background: $bgColor 0% 0% no-repeat padding-box;
        padding: 1.125rem;

        .back-icon {
          fill: $black-color;
        }
      }

      .item-history-title-container {
        width: 100%;
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
        gap: 30px;

        @media only screen and (min-width: 1200px) {
          gap: 170px;
        }

        .item-history-products-container {
          width: 100%;
          border: 0.065rem solid $border-color;
          max-width: 40rem;
          border-radius: 0.9375rem;
          padding: 0.625rem;

          .product-ledger-image {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 0.8rem;

            .product-name {
              flex: 1 1 50%;
              max-width: 50%;

              .name {
                font-weight: 500;
              }

              .key {
                font-size: 12px;
              }
            }

            .customer-name {
              flex: 1 1 50%;
              max-width: 50%;
              border-left: 0.0625rem solid var(--bs-gray-400);
              padding-left: 0.925rem;

              .name {
                font-weight: 500;
              }

              .key {
                font-size: 12px;
                letter-spacing: 1px;
                text-transform: uppercase;
              }
            }

            .product-ledger-name {
              .name {
                font-weight: 500;
              }

              .key {
                font-size: 12px;
              }
            }
          }
        }

        .title {
          letter-spacing: -0.035rem;
        }
      }
    }
  }

  .table-main-container {
    padding-left: 7.625rem;
    padding-right: 7.625rem;
    margin-top: 1rem;
  }
}

.tabIndex-focused:focus-visible {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px var(--bs-gray-600) !important;
}

.job-name-cancel-btn {
  width: 20rem;
  min-width: 20rem;
  max-width: 20rem;
  height: 4rem;
}

.job-name-save-btn {
  width: 20rem;
  min-width: 20rem;
  max-width: 20rem;
  height: 4rem;
}

.w-5 {
  width: 5% !important
}

.w-35 {
  width: 35% !important
}

.w-20 {
  width: 20% !important
}

.w-15 {
  width: 15% !important
}

.new-registered-btn {
  width: 17rem;
  min-width: 17rem;
  max-width: 17rem;
  height: 3rem;
}

.order-complete-print-invoice-btn {
  // width: 18.75rem;
  min-width: 18.75rem;
  max-width: 18.75rem;
  height: 2.75rem;
}

.save-for-later-modal-btn {
  width: 20rem;
  min-width: 20rem;
  max-width: 20rem;
  height: 4.2rem
}

.save-dont-save-btn {
  width: 20rem;
  min-width: 20rem;
  max-width: 20rem;
  height: 3.75rem;
}

.payment-hide-price-option {
  border-top: 0.0625rem solid $border-color;
  margin-top: 0.3125rem;
  margin-bottom: 1.25rem;
}

.payment-hide-price-option-div {
  border-top: 0.0625rem solid $gray-option;
  margin-top: 0.3125rem;
  margin-bottom: 1.25rem;
}

.edit_icon {
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-bottom: 0.25rem
}

.divider-date-div {
  height: 0.0625rem;
  width: 88%;
  background-color: $gray-option;
  margin-left: 0.625rem;
}

.int-20-reg-bold-margin-top {
  margin-top: -20px;
}

.input-btn-spacing-div {
  display: flex;
  gap: 2rem;
  width: 43.625rem;
  justify-content: space-between;
}

.form-field-space-custom-drop-down-div {
  margin-top: 34px;
  // width: 44%;
}

.paylater-margin-top {
  margin-top: -42px;
}

.cash-margin-top {
  margin-top: -48px;
}

.check-margin-top,
.card-margin-top {
  margin-top: -22px;
}

.pricing-schedule-heading-checkout {
  font-size: 1.5625rem;
  font-weight: 700;
  color: $black-color;
  font-family: "Inter", Semibold;
  letter-spacing: -0.0313rem;
}

.btn-margin-top-30 {
  margin-top: 1.875rem;
}

.btn-margin-top-0 {
  margin-top: 0;
}

.complete-order-btn {
  .MuiButton-containedPrimary {
    height: 2.75rem;
    width: 11.313rem;
  }
}

.shipping-instructions-btn-size {
  font-size: 1rem;
}

.empty-state-fonts {
  font-size: 1.125rem !important;
  max-width: 27.5rem;
  margin: 0 auto;
  margin-top: 1.25rem !important
}

.job-input {
  .MuiFilledInput-root {
    padding: 0.9375rem !important;
  }
}

.job-input-span {
  margin-bottom: 15px !important;
}

.product-found-header {
  margin-left: -0.875rem;
}

.justify-start {
  justify-content: start;
}

.change-doc-btn-focus {
  &:focus-visible {
    outline: 0.13rem solid var(--bs-gray-600) !important;
  }
}

.Manage-Product-link {
  letter-spacing: 0rem;
  display: flex;
  gap: 0.875rem;
  color: $black-color;
  align-items: center;
  flex-wrap: wrap;

  .see-all {
    font-weight: bold;
    text-decoration: underline;
    padding: 0;
    border: 0;
    background: transparent;

    &:hover {
      color: unset !important;
    }
  }
}

.save-later-message {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  background-color: #EEEEEE;
  border-radius: 10px;
  padding: 10px;
  margin-right: 20px;

  .save-later-message-style {
    margin-left: 10px;
  }
}

.save-document-count {
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-weight: 500;
}

.review-billing-info {
  grid-column: span 17;
  display: flex;
  gap: 28px;

  .label-info {
    font: normal normal bold 22px/16px Inter;
    letter-spacing: -0.44px;
    color: #AAAAAA;
  }

  .order-info {
    text-align: left;
    font: normal normal bold 22px/16px Inter;
    letter-spacing: -0.44px;
    color: #000000;
  }

}

.review-form-billing {
  // display: grid;
  grid-column-start: 1;
  grid-column-end: 10;
  // grid-template-columns: repeat(8, 55%);
  gap: 10px;
}

.review-form-billing-second {
  display: grid;
  grid-column: span 7;
  gap: 10px;
  grid-column-start: 10;
  grid-column-end: 17;
}

.review-div {
  display: grid;
  grid-template-columns: repeat(17, 55%);
}

.review-billing-label {
  grid-column-start: 1;
  grid-column-end: 4;
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 0 !important;
  margin: 0 !important;
}

.review-input {
  display: flex;
  gap: 5px;
  grid-column-start: 4;
  grid-column-end: 9;
}

.address-selected-div {
  display: flex;
  align-items: center;
  gap: 0.3125rem;

  .address-selected-icon {
    margin-top: 0.1875rem;
  }

  .address-selected-text {
    font-size: 1.125rem;
    letter-spacing: 0rem;
    font-weight: 600;
  }
}

.delivery-select-address {
  padding: 1.25rem 0rem;
  position: sticky;
  bottom: 0;
  background-color: white;
  border-top: 0.125rem solid #eee;

  button {
    justify-content: start !important;
    padding: 1.25rem 1rem !important;

    span {
      font-weight: 500 !important;
    }
  }
}

.all-facilities-select-address {
  height: 25rem;
}

.email-quote-link {
  letter-spacing: 0rem;
  display: flex;
  gap: 0.875rem;
  color: $black-color;
  align-items: center;
  flex-wrap: wrap;

  .see-all {
    font-size: 0.875rem;
    text-decoration: underline;
    padding: 0;
    border: 0;
    background: transparent;

    &:hover {
      color: unset !important;
    }
  }
}

.email-quote-modal-container {
  .MuiDialog-container {
    .MuiDialog-paper {
      width: 100%;
      min-width: 34.375rem;
      border-radius: 1.25rem;

      .MuiDialog-paperWidthSm {
        max-width: 34.375rem;
      }

      .MuiDialogTitle-root {
        padding: 0.9375rem 0.375rem 0.3125rem 1.5rem;
        margin-top: 0.625rem;

        .MuiTypography-root {
          .MuiBox-root {
            .MuiTypography-root {
              justify-content: flex-start;
              font-size: 1.25rem;
              font-weight: 600;
              letter-spacing: 0rem;
              color: $black-color;
              opacity: 1;
            }

            .MuiButtonBase-root {
              padding: 0.25rem 0.3125rem;
              margin-right: 0.9375rem;

              &:hover {
                background-color: $bgColor !important;

                .MuiSvgIcon-root {
                  fill: $black-color;
                  opacity: 1;
                }
              }

              &:focus-visible {
                background-color: $bgColor !important;

                .MuiSvgIcon-root {
                  fill: $black-color;
                  opacity: 1;
                }

                outline: 0.13rem solid var(--bs-gray-600) !important;
              }

              .MuiSvgIcon-root {
                fill: $gray;
                opacity: 1;
                width: 1.25rem;
                height: 1.25rem;
              }
            }
          }
        }
      }
    }
  }
}

.direction-col {
  flex-direction: column;
  text-align: left !important;
}

.padding-all-pic-location {
  padding: 0.938rem !important;
}

.focus-border-pick-qty {
  &:focus-within {
    border: 2px solid #000000;
    border-radius: 0px 0px 10px 0px;
    background-color: white;
  }

}