@import "ui/scss/general.scss";
@import "../../../constants/colors.scss";
.quote-details-rfis,
.quote-details-messages,
.quote-details-documents,
.quote-details-notes,
.quote-details-items,
.quote-details-reps {
  .header-actions {
    display: flex;
    gap: 0.625rem;
    align-items: center;
    position: relative;
    right: auto;
  }
}

.quotes-tab-header {
  padding: 0 1.25rem;
}

.add-quote-button {
  background-color: $black !important;
  opacity: 1 !important;
  .icon {
    color: $white !important;
  }
}

.ticket-button {
  display: flex !important;
  padding: 10px !important;
  width: 100%;
  border: none;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 5px;
  font-family: var(--inter-font-family), "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  background-color: #fff;
  border-bottom: none !important;
  outline: revert !important;

  &:not(:disabled) {
    &:hover,
    &:focus-visible {
      background-color: var(--bs-gray-200);
      .icon {
        visibility: visible;
      }
    }
    &:active {
      background-color: var(--bs-gray-300);
    }
  }
  .icon {
    visibility: hidden;
  }
  &.icon-visible {
    .icon {
      visibility: visible;
    }
  }
}

.search-focus {
  .MuiInputBase-root {
    &:focus-within {
      outline: 0.125rem black solid !important;
      background-color: $white !important;
    }
  }
}

.custom-customer-code-modal-container .MuiDialog-container .MuiDialog-paper {
  width: 80%;
  height: 50rem;
  border-radius: 1.25rem;
  overflow: auto;
}

.custom-customer-code-modal-container .MuiDialog-paperWidthSm {
  max-width: 100%;
  padding: 0.625rem !important;
}

.inbox-detailRightside {
  &::-webkit-scrollbar {
    height: 8px !important;
    width: 8px !important;
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $light-grey !important;
    border-radius: 4px;
    border: 1px solid $light-grey;
  }
}

a:is(
    .copy-quote-link:not(.nav-link, .dropdown-item, .page-tips-footer a, .btn)
  ) {
  color: black !important;
  &:visited,
  &:link,
  &:hover,
  &:active {
    color: black !important;
  }
}

.copy-quote-link:hover,
.copy-quote-link:focus-visible {
  text-decoration: underline;
}

.dropdown {
  .sub-quote-container {
    &-title {
      font-size: 16px;
      color: $black;
      font-weight: 500;
    }
  }
  .dropdown-menu {
    .sub-quote-list {
      overflow-y: scroll;
      max-height: 25.5rem;
    }
  }
}

@mixin commonQuote {
  background-color: rgba(205, 205, 205, 0);
  display: flex;
  border: none;
  margin: 0;
  padding: 0;
  align-items: center;
  color: $gray-800;
  font-weight: 500;
  font-size: $small-font-size-14;
  position: relative;
  border: 1px;
  padding: 0.313rem;
  border-radius: 8px;
}

.sub-quote-count {
  @include commonQuote;
  top: 34px;
  letter-spacing: 0.5px;
  span {
    color: $gray-800;
    font-size: $extra-small-font-size;
  }
  span:last-child {
    align-items: center;
  }
}

.parent-quote {
  gap: 5px;
  bottom: 12px;
  right: 12px;
  @include commonQuote;
}

.sub-quote-count:hover {
  background: $gray-200 !important;
  color: $black !important;
  letter-spacing: 0.5px;
  span {
    color: $black !important;
    font-size: $extra-small-font-size;
  }
  span:last-child {
    color: $black !important;
    padding-left: 0.313rem;
  }
}

.suggested-rep-names {
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;
  margin-top: 0.3125rem;
  .rep-btn {
    display: flex;
    align-items: center;
    border: none;
    gap: 6px;
    background-color: $light-gray;
    border: 0.0625rem solid $bright-Gray;
    border-radius: 6.25rem;
    padding: 0.125rem 0.75rem 0.125rem 0.125rem;
    padding-right: 0.75rem;
    font-size: 0.875rem;
    cursor: pointer;
    opacity: 0;
    transform: translateY(-10%);
    animation: intro 0.4s ease-out forwards;
    @keyframes intro {
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
    &:focus-visible {
      outline: auto;
    }
    &:not(:disabled) {
      &:hover,
      &:focus-visible {
        background-color: $bright-Gray;
      }

      &:active {
        background-color: $border-color;
      }
    }
  }
}

.quote-to-value {
  white-space: nowrap;
  width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
}
