// common elements
.overlay-skrim {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    background-color:#0000006b;
    z-index: 10;

    @media (max-width: 800px) {
        background-color: rgba(0, 0, 0, 0.4);
    }
}
