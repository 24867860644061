.footer-container {
  height: 60px;
  background-color: #fff;
  position: relative;
  bottom: 0;
  width: 100%;
  width: 100%;
  z-index: 50;
  justify-content: center;
  align-items: center;
  display: flex;
}

.margin-auto {
  margin: auto;
}

.mt-20 {
  margin-top: 20px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-100 {
  margin-top: 100px !important;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.ml-5 {
  margin-left: 0.313rem;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.margin-b-0 {
  margin-bottom: 0px;
}

.margin-b-10 {
  margin-bottom: 10px;
}

.margin-b-15 {
  margin-bottom: 15px;
}

.margin-b-20 {
  margin-bottom: 20px !important;
}

.grey-icon-color {
  color: #aaaaaa;
}

.white-icon-color {
  color: #fff;
}

.bg-white {
  background-color: white;
}

.pl-10 {
  padding-left: 10px;
}

.pl-40 {
  padding-left: 40px !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1-5 {
  padding-right: 1.5rem !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.alert-Txt {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 12px;
  color: #aaaaaa;
  padding-left: 0px;
}

.divider-grey {
  background-color: #e0e5ec;
  height: 1px;
}

.divider-grey-search {
  background-color: #e0e5ec;
  height: 1px;
  margin-top: 130px;
  margin-bottom: 20px;
}

.custom-avatar {
  height: 50px !important;
  width: 50px !important;
  border-radius: 50px !important;
}

.fdr {
  flex-direction: row !important;
}

.border-grey-top {
  border-top: 1px solid #eeeeee !important;
}

.inter-sans-serif {
  font-family: "Inter", sans-serif;
}

.inter-reg {
  font-family: "Inter", "Regular" !important;
}

.inter-med {
  font-family: "Inter", "Medium";
}

.roboto-med {
  font-family: "Roboto Slab", "Medium" !important;
}

.roboto-light {
  font-family: "Roboto Slab", "Light" !important;
}

.justify-center {
  justify-content: center;
}

.bg-color-black {
  background-color: black !important;
}

.br-10 {
  border-radius: 10px !important;
}

.ph-10 {
  padding-left: 20px;
  padding-right: 30px;
}

.pv-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pv-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.pb-100 {
  padding-bottom: 100px;
}

.bg-black {
  background: #000;
}

.float-right {
  float: right;
  right: 10px;
}

.favorite-form-footer {
  display: flex;
  justify-content: flex-end;
}

.modal-overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1300;
}

.save-btn-styles {
  background-color: black !important;
  border: 1px solid black !important;
}

.chnages-btn-styles {
  background-color: black !important;
  border: 1px solid black !important;
  width: 150px;
  margin-left: 50px;
}

.cancel-btn-styles {
  background-color: transparent !important;
  border: none !important;
}

.scroll-style {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.table-select {
  border: none !important;
}

.likelihood-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.likelihood-div:first-child {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.likelihood-div:last-child {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.header-icon-color {
  color: #8f9398 !important;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper .pro-icon {
  animation: none !important;
  background-color: #f9f9f9;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item>div>ul {
  background-color: #fcf6f4;
  padding-top: 15px;
  padding-bottom: 15px;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper .pro-icon {
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  color: #000000;
  background-color: #fcf6f4;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item {
  position: relative;
  background-color: #f9f9f9 !important;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-item-content {
  overflow: hidden !important;
  white-space: pre-wrap !important;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-header {
  border-bottom: none !important;
}

.error_404 {
  font: normal normal 300 48px/63px Roboto Slab;
}

.error_404_text {
  font: normal normal normal 16px/24px Inter;
}

.page-not-found {
  padding-top: 15%;
  padding-left: 15% !important;
}

.exclamation-circle {
  margin-left: 100%;
}

.exclamation-circle-text {
  width: 3% !important;
}

.favorite-add-btn {
  height: 10px;
  background-color: #000000 !important;
  border-color: #000000 !important;
}

.favorite-remove-btn {
  height: 10px;
  margin-left: 0.313rem;
  background-color: #000000 !important;
  border-color: #000000 !important;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 16px;
  border-radius: 50%;
  border: 6px solid rgb(0, 0, 0);
  border-color: rgb(0, 0, 0) transparent rgb(0, 0, 0) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  margin-top: 20% !important;
  margin-left: 50% !important;
}

.bg-white {
  background-color: white;
}

.pb-100 {
  padding-bottom: 100px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rebeccapurple;
}

.status-title-color {
  color: #999999 !important;
}

.text-align-justify {
  text-align: justify;
}

.sidenav-ht {
  height: 100vh;
}

.curserPointerUserList {
  padding-left: 1.25rem !important;
  cursor: pointer !important;
}

.user-role {
  line-height: 1.15 !important;
}

.no-record-found {
  background: transparent;
  opacity: 1;
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.message-span {
  padding: 0.938rem;
  letter-spacing: 0.01rem;
}

.invite-first-member {
  background: transparent;
  opacity: 1;
  height: 7.5rem;
  margin-top: 7.188rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.error-message {
  color: red;
  font-size: 0.875rem;
}

.add-user-form-div {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.ht-60 {
  height: 3.75rem;
}

.pb-40 {
  padding-bottom: 2.5rem;
}

.pb-60 {
  padding-bottom: 3.75rem;
}

.pr-50 {
  padding-right: 3.125rem !important;
}

.pl-20 {
  padding-left: 1.25rem !important;
}

.pl-5 {
  padding-left: 0.313rem !important;
}

.mt-4 {
  margin-top: 2.5rem;
}

.invite-button {
  width: 7rem;
  height: 1.25rem;
  font-size: 0.875rem;
  text-decoration: underline;
  text-align: left;
  background: transparent;
  border: none;
  margin-top: -0.625rem;
}

.black-color {
  color: black;
}

.status-title-bg {
  background-color: var(--bs-gray-999) !important;
}

.bg-transp {
  background-color: transparent !important;
}

.mt-50 {
  margin-top: 3.125rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.status-date-color {
  color: var(--description-color);
}

.subQuote-button {
  width: 0.75rem !important;
  height: 0.75rem !important;
  margin: 0;
}

.ml-auto {
  margin-left: auto;
}

.add-subQuote-button {
  width: 0.8125rem !important;
  height: 0.8125rem !important;
}

.subquote-dot {
  padding-top: 0.375rem;
}

.letter-spacing-0 {
  letter-spacing: 0rem;
}

.letter-spacing-1 {
  letter-spacing: 0.075rem;
}

.letter-spacing-012 {
  letter-spacing: 0.0075rem;
}

.doc_type_container {
  width: max-content;
}

.react-document-model {
  position: absolute;
  z-index: 99999;
}