.button-date-picker {
    position: relative;
    font-size: 0.875rem;
    padding-left: 2rem;

    input[type="date"] {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        opacity: 0;
    }
}