@import "apps/pos/assets/css/variable.scss";

.set-upload-doc-modal-container {
  .MuiOutlinedInput-root {
    padding: 0.625rem !important;
  }
  .modal-header {
    display: flex;
    justify-content: space-between;
    padding: 0.938rem 1.7rem !important;
    border-bottom: none;
    .clear-icon {
      font-size: 2.5rem;
      padding: 0.625rem;
      cursor: pointer;
      &:hover {
        background-color: $bright-Gray;
        border-radius: 1.563rem;
      }
    }
    .button-focus {
      &:focus-visible {
        border-radius: 1.563rem !important;
        outline: 0.13rem solid var(--bs-gray-600) !important;
      }
    }
  }
  .MuiDialog-container {
    .MuiDialog-paper {
      height: 600px;
      width: 536px;
      border-radius: 1.25rem;
    }
    .MuiDialogActions-root {
      padding: 1.5rem;
      .add-to-return-cart-button-error {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }
  }

  .file-upload-container {
    height: 100%;
    text-align: center;
    border-radius: 10px;
    max-width: 490px;
    .upload-area {
      height: 100%;
      cursor: pointer;
      position: relative;
      &-icon {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      &-label {
        color: black;
        text-decoration: underline;
        cursor: pointer;
      }

      &-label input {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
      }
    }
  }

  .file-details {
    text-align: left;
    &-size {
      font-size: 12px;
      color: $gray;
    }
    .file-change {
      cursor: pointer;
      grid-gap: 0.625rem;
      gap: 0.625rem;
      padding: 0.5rem 1.25rem;
      border-radius: 0.9375rem;
      background-color: var(--bs-gray-200);
      color: $black-color !important;
      input {
        display: none;
      }
    }
    .file-change:hover {
      background-color: var(--bs-gray-300);
      --active: var(--bs-gray-400);
    }
    .description {
      &-text {
        font-size: 14px;
        letter-spacing: 0.5px;
        .optional {
          color: $gray;
          letter-spacing: 0.5px;
        }
      }
      .new-note-input {
        font-size: 14px;
        width: 100%;
        padding: 0.9375rem;
        min-height: 100px;
        font-family: inherit;
        border-radius: 0.625rem;
        background-color: $bright-Gray;
        transition:
          box-shadow 0.1s ease-out,
          background-color 0.1s ease-out;
      }
    }
    .user-selection {
      display: flex;
      flex-direction: column;
      gap: 17px;
      .documentType {
        &-text {
          font-size: 14px;
          letter-spacing: 0.5px;
        }
        &-select {
          height: 45px;
          width: 100%;
        }
      }
      .rep-selection {
        &-text {
          font-size: 14px;
          letter-spacing: 0.5px;
          .optional {
            color: $gray;
            letter-spacing: 0.5px;
          }
        }
        &-select {
          height: 45px;
          width: 100%;
        }
      }
    }
  }
}
