@import "apps/pos/assets/css/variable.scss";


.document-info-modal {
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  position: relative;

  .MuiDialog-container {
    width: 100% !important;

    .MuiPaper-root {
      max-width: 40rem !important;
      height: 100% !important;
      max-height: 32rem !important;
      border-radius: 1.25rem !important;
      padding-bottom: 6.25rem;
    }
  }

  .close-modal-icon {
    position: absolute;
    width: auto;
    display: flex;
    justify-content: flex-end;
    right: 0.625rem;
    top: 0.625rem;
    background-color: $bright-Gray;
    border-radius: 0;
    // width: 2.5rem; // IMP
    height: 2.5rem;

    svg {
      fill: $gray;
    }
  }

  // important
  // .MuiDialogContent-root {
  //   padding: 0 !important;
  // }

  .document-image-section {
    margin-top: 30px;
    height: 45%;
    width: 100%;
    -moz-box-shadow: inset 0rem -0.75rem 1.438rem -1rem $light-black-color;
    -webkit-box-shadow: inset 0rem -0.75rem 1.438rem -1rem $light-black-color;
    box-shadow: inset 0rem -0.75rem 1.438rem -1rem $light-black-color;

    img {
      height: 100%;
      width: 100%;

      &:hover {
        border-radius: unset;
        background-color: $white-color;
        box-shadow: inherit;
      }
    }
  }

  .open-document-container {
    padding: 0 1.875rem 0;

    .document-title-section {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin: 1.875rem 0 0.625rem;

      .document-title {
        .title-modal {
          font-size: 1.25rem;
          font-weight: 600;
        }
      }
    }
  }

  .document-edit-section {
    background-color: $border-color;
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1.563rem;
    cursor: pointer;
  }

  .document-description {
    font-weight: 500;
  }

  .document-type-info {
    margin: 2.5rem 0 0;

    .document-types {
      border-bottom: 0.063rem solid $bright-Gray;
      display: flex;
      font-weight: 600;
      padding: 0.438rem 0;

      &:first-child {
        border-top: 0.063rem solid $bright-Gray;
      }

      &-title {
        min-width: 11.25rem;
        font-weight: 500;
      }
    }
  }

  .document-bottom-section {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: 0 1.875rem 1.875rem;

    .download-btn {
      padding: 0.75rem 0.625rem !important;
    }

    .new-tab-open-btn {
      margin-left: 0.625rem;
      // padding: 0.625rem 0.625rem !important;  //important

      img {
        height: 1.125rem;
        width: 1.25rem;
      }
    }
  }
}

.show-more-less-clickable {
  font-weight: 700 !important;
}

.document-name-title {
  flex-wrap: wrap;

  .document-name-info {
    margin: 0 1rem 0.5rem 0;

    @media (min-width: 600px) {
      width: calc(100% - 6.875rem);
    }

    .title-modal {
      line-break: anywhere;
    }
  }

  .read-only-tag {
    margin: 0;
  }
}

.add-document-upload-modal {

  .MuiDialogContent-root {
    width: 600px;
    height: 354px;
    padding: 30px !important;
    max-height: 354px;
    overflow-y: auto;
    border-radius: 1.25rem !important;
  }

  .MuiSelect-select {
    padding: 0.9375rem !important;
  }

  .MuiDialog-paper {
    border-radius: 1.25rem;
  }

}